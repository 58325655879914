import {
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { TableSorting } from '../../../../../shared/sortable-table/sortable-table.component';
import { ItcFileUploaderComponent } from 'app/shared/itc/file-uploader/file-uploader.component';
import { CM_COLORS } from '../cm-summary.constants';
import {
    Answer,
    QuestionDescription,
    WizardGroup,
    WizardOptions,
    WizardQuestion,
    WizardSiteUser,
    WizardType,
} from './cm-wizard.model';
import { Attachment } from '../../../inform/attachment.model';
import { Site } from '../../../../shared/site.model';
import {
    ControlsWizardAnswers,
    ControlsWizardDescription,
} from '../../controls-assessment/cm-controls-assessment.wizard';
import { RiskModalInfo } from '../../../../shared/riskModal/risk-modal.model';
import {
    RequirementsWizardAnswers,
    RequirementsWizardDescription,
} from '../../requirements-assessment/cm-requirements-assessment.wizard';
import { StandardsService } from '../../../../shared/standards/standards.service';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';
import { SiteService } from '../../../../shared/site.service';
import { SiteSettingService } from '../../../settings/site-settings.service';
import { FilesService } from '../../../assessments/files/files.service';
import { AuthService } from '../../../../../core/auth';
import { Hotkeys } from '../../../../../core/hotkeys/hotkeys.service';
import { ItcTabsService } from '../../../../../shared/itc/tabs/tabs.service';
import { AngularFuseJsService } from '../../../../../shared/angular-fusejs/angular-fusejs.service';
import { Assignment, SiteUser, SiteUsersService } from 'app/sites/shared/site-users.service';
import {
    assignIn as _assignIn,
    cloneDeep as _cloneDeep,
    findIndex,
    get as _get,
    isEqual as _isEqual,
    pull as _pull,
    uniqWith as _uniqWith,
} from 'lodash-es';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    of,
    Subject,
    Subscription,
    takeUntil,
    takeWhile,
} from 'rxjs';
import * as saveAs from 'file-saver';
import { MatSort, Sort } from '@angular/material/sort';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { TableVirtualScrollStrategy } from '../../../../../shared/table-vs-strategy.service';
import { FileUpload } from 'app/shared/itc/file-uploader/file-uploader.model';
import { CmReadOnlyWizardComponent } from './read-only-wizard/cm-read-only-wizard.component';
import { FILEUPLOADED } from 'app/sites/shared/constants';
import { MessageService } from 'app/core/message.service';
import { MenuItem } from 'primeng/api';
import { Evidence } from '../../data-collection/evidence-compliance/evidence-compliance.model';
import { CmEocReportModalComponent } from '../eoc-report-modal/cm-eoc-report-modal.component';
import { EvidenceComplianceService } from '../../../../shared/evidence-compliance.service';
import { K1organizationService } from 'app/settings/shared/k1organization.service';

declare var $: any;

@Component({
    selector: 'cm-wizard',
    templateUrl: './cm-wizard.component.html',
    styleUrls: ['./cm-wizard.component.scss'],
    providers: [
        {
            provide: VIRTUAL_SCROLL_STRATEGY,
            useClass: TableVirtualScrollStrategy,
        },
    ],
})
export class CmWizardComponent implements OnInit {
    /**
     * Emit event that a response has been updated
     *
     * @param { any } Response - only used by VendorPortal because it updates individual responses.
     */
    @Output() onResponseUpdate: EventEmitter<any> = new EventEmitter();

    /**
     * Emit event to let parent know we want to view results
     */
    @Output() onViewResults: EventEmitter<boolean> = new EventEmitter();

    /**
     * Emit event to say that we have completed all questions
     */
    @Output() onComplete: EventEmitter<any> = new EventEmitter();

    /**
     * Emit event to say we clicked the import button
     * only used by Controls assessment to import RBA
     */
    @Output() onImportClicked: EventEmitter<boolean> = new EventEmitter();

    /**
     * Input to display Assessment Type
     *
     * @param { string } header - Header text to display assessment type
     */
    @Input() header: string;

    /**
     * Input response answers for assessment from wizard.ts
     *
     * @param { Answer[] } answers - Answers from wizard.ts
     */
    @Input() answers: Answer[];

    /**
     * Input culture to display
     *
     * @param { string } Culture
     */
    @Input() culture = 'en-US';

    /**
     * Input questionDescriptions from wizard.ts
     *
     * @param { QuestionDescription[] } questionDescription - Question descriptions to show in control info section.
     */
    @Input() questionDescription: QuestionDescription[];

    /**
     * Input options
     * Pass in options for wizard from wizard.ts
     *
     * @param options - Pass in options, and set defaults when not overwritten in wizard.ts
     */
    @Input() set options(opt: any) {
        this._options = _assignIn(this.defaultOptions, opt);
        console.log('Wizard Options', this.options);
    }

    get options() {
        return this._options;
    }

    /**
     * Input questions
     *
     * @param questions - List of questions to be displayed by wizard
     */
    @Input() set questions(value: any) {
        console.log('questions input updated');
        this._allQuestions = value;
        if (this._allQuestions && this._allQuestions.length) {
            // handle requirement assessment different, sort by standard name because we display it
            if (this.options.type === 'requirement') {
                this._allQuestions.sort(
                    (a, b) =>
                        cmp(a.Standard.Name, b.Standard.Name) ||
                        cmp(a.Standard.Variant, b.Standard.Variant) ||
                        cmp(a.Requirement.RequirementId, b.Requirement.RequirementId) ||
                        cmp(
                            a.Requirement.ShortDesc.LocalizedText[this.culture],
                            b.Requirement.ShortDesc.LocalizedText[this.culture]
                        )
                );
            } else {
                // else it's a controls assessment sort by controlID, then control name
                this._allQuestions.sort(
                    (a, b) =>
                        cmp(a.Control.ControlId, b.Control.ControlId) ||
                        cmp(
                            a.Control.ShortDesc.LocalizedText[this.culture],
                            b.Control.ShortDesc.LocalizedText[this.culture]
                        )
                );
            }

            this._allQuestions.forEach((q) => {
                // set standard name for requirement assessments because we
                if (this.options.type === 'requirement') {
                    q.StandardName = this.standardsService.getStandardDisplayName(q.Standard);
                }

                if (this.options.assessment === 'vendorPortal') {
                    // backup response object because the question format is different
                    q.ResponseBackup = _cloneDeep(q.Response);
                    // save to normal fields so I don't have to rewrite a ton of code, and we can remove it when we save
                    q.Comment = q.ResponseBackup.Notes;
                    q.Response = q.ResponseBackup.AnswerType;
                }

                let thisAnswer =
                    this.options.assessment === 'vendorPortal'
                        ? this.answers?.find((a) => a.dbval === q.Response)
                        : this.answers?.find((a) => a.title === q.Response);

                if (this.options.assessment === 'vendorPortal') {
                    // because we use a different string for vp responses
                    q.ResponseTitle = thisAnswer?.title;
                }
                q.ResponseValue = thisAnswer?.value || 10;
            });

            if (this.options.sortByStandards) {
                this._allQuestions.forEach((q) => {
                    if (q.Standard) {
                        let fullStandardName = this.standardsService.getStandardDisplayName(
                            q.Standard
                        );
                        if (this._questionsByStandard[fullStandardName]) {
                            this._questionsByStandard[fullStandardName].questions.push(q);
                        } else {
                            this._questionsByStandard[fullStandardName] = {
                                standard: q.Standard,
                                questions: [q],
                            };
                        }
                    } else {
                        console.log('Requirement does not have a standard?', q);
                    }
                });
                this.showingCount = 'all ' + this._allQuestions.length;
                this.questionStandards = Object.keys(this._questionsByStandard);
                console.log('Questions by Standard', this._questionsByStandard);
            }

            this.displayedQuestions = this._allQuestions;
            this.listQuestions.next(this._allQuestions);
            this.totalQuestions = this._allQuestions.length;
            setTimeout(() => {
                this.resizeContent();
            }, 1);
            this.activeQuestionIndex = this.getFirstUnanswered();

            // need to have all questions and siteusers/sitegroups, so just make sure
            if (this.siteUsers.length || this.siteGroups.length) {
                this.processAssignments(this._allQuestions);
            }

            if (this.itglueOauthReturnControlId) {
                this.goToQuestion(this.itglueOauthReturnControlId);
                // this.showSearchDialogIfItGlueControlIdSet();
                setTimeout(() => {
                    this.tabsService.setTab('Files');
                    this.showITGlueDocuments();
                }, 1);
            } else {
                this.setQuestion();
            }

            console.log('all Questions', this._allQuestions);
        }
    }

    /**
     * Input isReadOnly from wizard.ts
     *
     * @param { boolean } isReadOnly - makes assessment wizard immutable.
     */
    @Input() isReadOnly: boolean = false;

    /**
     * Input waitForSaveComplete
     *
     * @param { boolean } waitForSaveComplete - when true, the wizard waits for completion of the save operation before allowing to switch step
     */
    @Input() waitForSaveComplete: boolean = false;

    //subscribe to this to know if the parent is currently saving answers and to be notified when saving ends
    private isSaving$ = new BehaviorSubject(false);

    //Contains the current value for the isSaving$ subject to be used in template
    IsSaving: boolean = false;

    /**
     * Container component should call this once the save operation has completed, this is used only if waitForSaveComplete=true
     *
     */
    public saveHasCompleted() {
        this.saveErrors = false;
        this.isSaving$.next(false);
        this.IsSaving = false;
    }

    /**
     * Container component should call this when save operation had an error , this is used only if waitForSaveComplete=true
     *
     */
    public saveCompletedWithErrors() {
        this.saveErrors = true;
        this.isSaving$.next(false);
        this.IsSaving = false;
    }
    saveErrors: boolean = false;

    /**
     * Input loading state of Technical Issues
     *
     * @param { boolean } techIssuesLoading - adds a spinner to in-progress technical issues.
     */
    @Input() techIssuesLoading: boolean = false;
    @Input() isInArchivedAssessment: boolean = false;

    _allQuestions: WizardQuestion[]; // all questions as sent from parent component
    _questionsByStandard = {}; // for requirements assessments we want items by standard
    displayedQuestions: any[];
    questionStandards: string[]; // list of all standards when requirements Assessment
    questionDescriptionText: string;
    questionGuidance: string;
    csProGuidance: string;
    activeQuestion: any;
    activeQuestionIndex: number;
    activeQuestionOriginal: any;
    totalQuestions: number;
    remainingQuestions: number;
    completedQuestions: number;
    autoAdvance = false;
    showHotkeys = false;
    standardSelectCtrl = new UntypedFormControl('all');
    CM_COLORS = CM_COLORS; // need this to be able to pass into template
    selectedStandard = 'all';
    fileDropdownOpen = false;
    showingCount: string;
    _options: WizardOptions;
    // default options when not passed in from wizard.ts file.
    defaultOptions: WizardOptions = {
        assessment: '',
        type: 'control',
        sortByStandards: false,
        showStandardId: false,
        showControlId: true,
        showRelatedControls: false,
        showRelatedIssues: false,
        showImportButton: false,
        showResultsButton: true,
        showFilesTab: true,
        showCommentsTab: true,
        listViewColumns: [],
        firstSort: { active: 'control', direction: 'asc' },
        allowAssignments: true,
        showCSGuidance: true,
        showEvidence: false,
    };
    url: string;
    wizardView = 'wizard';
    isMyWork = false;
    tabNumber = 1;

    /* List View Stuff */
    listQuestions = new BehaviorSubject<WizardQuestion[]>([]);
    allResponsesChecked: boolean | string;
    listViewActiveQuestion: any;
    fileUploadQuestion: any;
    selectedResponses: WizardQuestion[] = [];
    currentSort: Sort;
    unansweredCount: number;
    hideResponded: boolean;
    listViewSearch = new UntypedFormControl('');
    isBulkUpdate = false;
    bulkUpdateResponse: string;
    commentCtrl = new UntypedFormControl('');
    responseColors = { Unanswered: { color: '#fff', colorMuted: '#fff', borderColor: '#333' } };
    BUFFER_SIZE = 5;
    rowHeight = 40;
    headerHeight = 0;
    displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
    listViewDataSource: Observable<Array<any>>;
    tableOffset = 430;
    gridHeight = 400;
    noVirtualScrollLimit = 200; // If we have more items than this, we'll use virtual scrolling
    vpKeys = {};
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(CdkVirtualScrollViewport) vsViewport: CdkVirtualScrollViewport;
    @ViewChild('updateCommentsModal') updateCommentsModal: SemanticModalComponent;
    @ViewChild('updateResponsesModal') updateResponsesModal: SemanticModalComponent;
    @ViewChild('updateFilesModal') updateFilesModal: SemanticModalComponent;
    @ViewChild('updateAssignmentsModal') updateAssignmentsModal: SemanticModalComponent;
    @ViewChild('importEvidenceCompliance') importEvidenceCompliance: SemanticModalComponent;
    @ViewChild('externalContent') externalContent: SemanticModalComponent;

    @ViewChild('prevButton', { static: true }) prevButton: HTMLButtonElement;
    @ViewChild('nextButton', { static: true }) nextButton: HTMLButtonElement;

    @ViewChild(CmReadOnlyWizardComponent) readOnlyModalComponent: CmReadOnlyWizardComponent;
    @ViewChildren('wizardConfirmModal') wizardConfirmModal: any;
    @ViewChild(ItcFileUploaderComponent) fileUploader: ItcFileUploaderComponent;
    uploadingFiles: FileUpload[] = [];
    riskModalInfo: any;
    isTechnicalReviewComplete: boolean = false;
    techIssueMapping: any[];
    aqId: number;
    lvAqId: number;

    siteUsers: WizardSiteUser[] = [];
    siteGroups: WizardGroup[] = [];
    subs: Subscription[] = [];
    site: Site;
    user: any; // i don't think we have a model for this
    attachmentReader: FileReader;
    files: any[];
    filteredFiles: any[];
    selectedFiles: any[];
    showItGlueAttachmentButton: boolean = false;
    itglueButtonTip: string = '';
    itGlueSettingName = 'ITGLUE_ENABLED';
    enableItGlueAttachmentButton: boolean = false;
    itglueOauthReturnControlId: string;
    attachmentModalLoadingComplete: boolean;
    itglueModalLoadingComplete: boolean;
    isFormDirty: boolean = false;
    selectedDeleteAttachment: Attachment;
    itglueSearchTerm: string;
    itglueOrgCtrl: FormControl<string> = new FormControl(null);
    itglueOrgs: any[];
    itglueFiles: any[];
    itglueFileSelection: any[];
    itglueFileSelectionCount: number = 0;
    redirectUrl: string;
    redirectType: string;
    itgWarnCheckbox: UntypedFormControl;
    itgModalContent: string;
    isSearchingITGlue: boolean = false;
    pageNumber: number = 0;
    pagedItGlueFiles: any[];
    @ViewChild('attachmentsModal', { static: true }) attachmentsModal: any;
    @ViewChild('deleteModal', { static: true }) deleteModal: any;
    @ViewChild('deleteModalModified', { static: true }) deleteModalModified: any;
    @ViewChild('eocReportModal', { static: true }) eocReportModal: CmEocReportModalComponent;
    @ViewChild('itglueModal', { static: true }) itglueModal: any;
    responsesFilterText: string = '';
    exportLoading: boolean = false;

    resourceCategories = {};
    dropdownMenuOptions: MenuItem[] = [];
    allResourcesExpanded = false;
    prevFilesSub: Subscription;
    bulkActions: MenuItem[];
    ngUnsubscribe$ = new Subject<void>();

    size: any;
    evidenceList = new Map<string, any>();
    allEvidenceList = [];
    backupEvidenceList = [];
    sortedEvidenceList: {
        module: string;
        evidenceTypeID: string;
        description: string;
        productID: string;
        orgImage: string;
        selected: string;
    }[];
    evidenceNameToDelete: string;
    evidenceToDelete: any;
    countSelectedEvidences = 0;
    allSelected: any = false;
    assigningInProgress = false;

    constructor(
        private standardsService: StandardsService,
        private siteService: SiteService,
        private siteSettingService: SiteSettingService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private filesService: FilesService,
        private authService: AuthService,
        private hotkeys: Hotkeys,
        private tabsService: ItcTabsService,
        private fuseService: AngularFuseJsService<any[]>,
        @Inject(VIRTUAL_SCROLL_STRATEGY)
        private readonly scrollStrategy: TableVirtualScrollStrategy,
        private siteUsersService: SiteUsersService,
        private messageService: MessageService,
        private evidenceComplianceService: EvidenceComplianceService,
        private k1OrganizationService: K1organizationService
    ) {}

    @HostListener('document:click', ['$event.target']) onClick(e) {
        if (e.classList.contains('labelLink') && !e.classList.contains('ROlabelLink')) {
            this.url = e.innerText;
            this.externalContent.show();
        }
    }

    ngOnInit() {
        this.currentSort = this.options.firstSort;
        this.itglueOrgs = [];
        this.itglueFiles = [];
        this.itglueFileSelection = [];
        this.itglueModalLoadingComplete = true;
        this.itgWarnCheckbox = new UntypedFormControl();

        this.user = this.authService.getIdentity();
        if (this.options.assessment !== 'vendorPortal') {
            this.siteUsersService.getMyUser().then((res) => {
                this.user.userFullName = res.FullName;
            });
        }

        if (this.options.assessment === 'vendorPortal') {
            // resize the table offset for virtualScrolling
            this.tableOffset = 208;
        }

        // set colors to use in listview
        this.answers.forEach((a) => {
            this.responseColors[a.title] = {
                color: a.color,
                colorMuted: a.colorMuted,
                border: a.colorMuted,
            };
            if (this.options.assessment === 'vendorPortal') {
                this.vpKeys[a.dbval] = a.title;
            }
        });

        this.commentCtrl.valueChanges.subscribe((val) => {
            if (val === '') this.commentCtrl.markAsPristine();
        });
        // Virtual Scrolling logic for cdk table
        this.gridHeight = window.innerHeight - this.tableOffset;
        const range = Math.ceil(this.gridHeight / this.rowHeight) + this.BUFFER_SIZE;
        this.scrollStrategy.setScrollHeight(this.rowHeight, this.headerHeight);

        this.listViewDataSource = combineLatest([
            this.listQuestions,
            this.scrollStrategy.scrolledIndexChange,
        ]).pipe(
            map((value: any) => {
                let data = this.hideResponded
                    ? this.listQuestions.value.filter(
                          (lq) => lq.Response === '' || lq.ResponseTitle === ''
                      )
                    : this.listQuestions.value;

                if (data.length > this.noVirtualScrollLimit && data.length > 0) {
                    this.gridHeight = window.innerHeight - this.tableOffset;
                    // Determine the start and end rendered range
                    const start = Math.max(0, value[1] - this.BUFFER_SIZE);
                    const end = Math.min(value[0].length, value[1] + range);
                    // Update the datasource for the rendered range of data
                    return data.slice(start, end);
                } else {
                    // less than noVirtualScrollLimit, so just show them all
                    setTimeout(() => {
                        let table = document.querySelector('table[cdk-table]') as HTMLElement;
                        this.gridHeight = table.offsetHeight + 15;
                    }, 1);
                    return data;
                }
            })
        );

        this.siteService.app_getCurrentSite().then((site) => {
            this.onSite(site);
        });

        if (this.options.showFilesTab) {
            this.subs.push(
                this.route.queryParams.subscribe((params) => {
                    this.checkForItglueOAuth(params);
                })
            );
        }

        this.messageService
            .on(FILEUPLOADED)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((res: any) => {
                if (res.page !== 'cmwizard') return;
                console.log('response returned', res);
                this.addNewAttachment(res);
            });

        this.listViewSearch.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(420), takeUntil(this.ngUnsubscribe$))
            .subscribe((searchKey) => {
                this.searchListView(searchKey);
            });

        /* add hotkeys for responses */
        for (let i = 1; i <= this.answers.length; i++) {
            this.hotkeys
                .addShortcut({ keys: i + '' })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((e) => {
                    this.processHotkey(e, i);
                });
        }
        /* add hotkeys for other items */
        this.hotkeys
            .addShortcut({ keys: 'shift.<' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'gotoPrev');
            });
        this.hotkeys
            .addShortcut({ keys: 'shift.>' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'gotoNext');
            });
        this.hotkeys
            .addShortcut({ keys: ',' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'gotoPrev');
            });
        this.hotkeys
            .addShortcut({ keys: 'dot' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'gotoNext');
            });
        this.hotkeys
            .addShortcut({ keys: 'shift.T' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'openTechnicalIssuestab');
            });
        this.hotkeys
            .addShortcut({ keys: 'shift.C' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'openCommentTab');
            });
        this.hotkeys
            .addShortcut({ keys: 'shift.F' })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((e) => {
                this.processHotkey(e, 'openFilesTab');
            });

        // if it's a mywork page, do not show results button
        if (this.router.url.indexOf('my-work') > -1) {
            this.isMyWork = true;
        }
        if (this.options.showRelatedIssues) {
            this.resourceCategories['relatedIssuesOpen'] = false;
        }
        if (this.options.showRelatedControls) {
            this.resourceCategories['relatedControlsOpen'] = false;
        }
        if (this.options.showCommentsTab) {
            this.resourceCategories['commentsOpen'] = false;
        }
        if (this.options.showFilesTab) {
            this.resourceCategories['filesOpen'] = false;
        }
        if (this.options.showRelatedRequirements) {
            this.resourceCategories['relatedRequirementsOpen'] = false;
        }

        let resourceCategoriesToggle = window.localStorage.getItem(
            'resourceCategories' + this.options.assessment.toUpperCase()
        );
        if (resourceCategoriesToggle) {
            this.resourceCategories = JSON.parse(resourceCategoriesToggle);
            this.allResourcesExpanded = Object.values(this.resourceCategories).every((r) => r);
        }

        this.createDropdownMenuOptions();
    }

    async onSite(site: Site) {
        this.site = site;
        // if we're showing files tab, get ITG info
        if (this.options.showFilesTab) {
            this.siteSettingService.getSiteParam(site.Id, this.itGlueSettingName).then((sp) => {
                this.showItGlueAttachmentButton = true;
                this.enableItGlueAttachmentButton = this.isTrueSet(sp.Value);

                this.itglueButtonTip = this.enableItGlueAttachmentButton
                    ? ''
                    : 'Enable in Site Settings > IT Complete';

                this.createDropdownMenuOptions();
            });
        }

        if (this.options.allowAssignments) {
            Promise.all([
                this.siteUsersService.getSiteUsersSafe(this.site.Id),
                this.siteUsersService.getSiteUserGroups(this.site.Id),
            ]).then((data) => {
                this.siteUsers = data[0];
                console.log('this.siteUsers', this.siteUsers);
                this.siteGroups = data[1];
                console.log('this.siteGroups', this.siteGroups);

                // set up formcontrols
                let userFormControlArray = [];
                this.siteUsers.forEach((user) => {
                    let displayName = this.siteUsersService.processUserFullName(user as SiteUser);
                    let assignmentUser: WizardSiteUser = {
                        DisplayName: displayName,
                        Assigned: new FormControl(false),
                    };
                    if (this.isMyWork || this.isReadOnly) {
                        assignmentUser.Assigned.disable();
                    }
                    userFormControlArray.push(assignmentUser);
                });
                this.siteUsers = userFormControlArray;
                console.log('now siteusers', this.siteUsers);

                this.siteGroups.forEach((group: WizardGroup) => {
                    group.Assigned = new FormControl(false);
                    if (this.isMyWork || this.isReadOnly) {
                        group.Assigned.disable();
                    }
                });

                this.siteUsers.sort((a, b) => cmp(a.DisplayName, b.DisplayName));
                this.siteGroups.sort((a, b) => cmp(a.Name, b.Name));

                // need to have all questions and siteusers/sitegroups, so just make sure
                if (this._allQuestions.length) {
                    this.processAssignments(this._allQuestions);
                }
            });
        }
        if (this.options.showEvidence) {
            this.k1OrganizationService
                .isK1OrganizationMapped(this.site.Id)
                .then((res) => {
                    this.options.showEvidence = res;
                    if (res) {
                        this.resourceCategories['evidenceOpen'] = false;
                        this.getEvidenceList();
                    }
                })
                .catch((error) => {
                    this.options.showEvidence = false;
                });
        }
    }

    /**
     * Go through all questions so we can have the username in the response
     *
     * @param obj question(s) to process and create the list and assigned by to make it easier to display in table.
     *
     * This is done like this because we have to run it on update, so we can limit the items it runs it on to only those
     * in the passed in object.
     */
    processAssignments(obj: any) {
        console.log('process Assignments', obj);
        if (!Array.isArray(obj)) {
            obj = [obj];
        }
        obj.forEach((q) => {
            let assignedUsers = [];
            let assignedGroups = [];
            q.AssignedList = '';
            q.Assignments.forEach((a) => {
                let displayName = '';
                if (a.GroupId == -1) {
                    displayName = a.UserDisplayName;
                    assignedUsers.push(displayName);
                } else if (a.GroupId !== -1) {
                    displayName = this.siteGroups.find((group) => group.Id === a.GroupId)?.Name;
                    assignedGroups.push(displayName);
                }
                // set assignedby, we're overwriting it each time, but it's going to be the same for all anyway
                q.AssignedBy = q.AssignedByDisplayName;
            });
            // sort users and groups separate, and then concatenate
            assignedUsers?.sort((a, b) => cmp(a, b));
            assignedGroups?.sort((a, b) => cmp(a, b));
            q.AssignedList = [...assignedUsers, ...assignedGroups].join(', ');
        });
    }

    ngAfterViewInit() {
        this.initDropdown();

        let wizardPrefs = window.localStorage.getItem('wizardPrefs');
        if (wizardPrefs) {
            // get saved preferences
            let prefs = JSON.parse(wizardPrefs);
            this.showHotkeys = prefs.showHotkeys;
            this.autoAdvance = prefs.autoAdvance;
            this.tabNumber = prefs.tabNumber;
            if (this.wizardView == 'wizard' && this.tabNumber) {
                this.tabsService.setTab(this.tabNumber);
            }
        }
    }

    initDropdown() {
        // I have to do this manually because the sm-select and sm-dropdown don't work with checkboxes in the content
        $('#assignToDropUp').dropdown({
            action: 'nothing',
            selectOnKeydown: false,
        });
    }

    isTrueSet(v: any) {
        return v === 'true' || v === 'True';
    }

    ngOnDestroy() {
        // wizard destroyed, check save to see if we should update
        this.checkSave();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        console.log('wizard destroyed');
        this.tabNumber = 1;
        this.updatePrefs();
    }

    navigateTo() {
        window.open(this.url, '_blank');
        this.externalContent.hide();
    }

    toggleView(view) {
        this.checkSave();
        this.isSaving$
            .asObservable()
            .pipe(takeWhile((loading) => loading, true))
            .subscribe((loading) => {
                if (!loading && !this.saveErrors) {
                    if (view == 'list') {
                        this.sortData(this.currentSort);
                        this.wizardView = 'list';
                        if (this.hideResponded) this.setResponsesHidden(true);
                    } else if (view === 'results') {
                        console.log('view results');
                        this.onViewResults.emit(true);
                    } else if (view === 'import') {
                        console.log('emit onImportClicked');
                        this.onImportClicked.emit(true);
                    } else if (view === 'mywork') {
                        // user clicked mywork button, redirect them.
                        this.router.navigate(['..'], { relativeTo: this.route });
                    } else {
                        this.setQuestion(this.activeQuestionIndex);
                        this.wizardView = 'wizard';
                        // setTimeout(() => { this.tabsService.setTab(this.tabNumber); }, 1);
                    }
                    this.updatePrefs();
                    this.allEvidenceList = [...this.backupEvidenceList];
                    this.evidenceAvailableToAdd();
                }
            });
    }

    checkSave(force = false) {
        // we only want to save when the response is changed
        console.log('all questions', this._allQuestions);

        if (!_isEqual(this.activeQuestion, this.activeQuestionOriginal) || force) {
            if (
                this.options.allowAssignments &&
                this.activeQuestion.Assignments !== this.activeQuestionOriginal.Assignments
            ) {
                // assignments have changed, update.
                let questionToUpdate = this.activeQuestion;
                this.siteUsersService
                    .assignWork(this.site.Id, this.activeQuestion.Assignments)
                    .then((returnedAssignments) => {
                        questionToUpdate.Assignments = returnedAssignments;
                        this.processAssignments(questionToUpdate);
                    });
            }

            let responsesToUpdate = this.isBulkUpdate
                ? this.selectedResponses
                : this.wizardView === 'wizard'
                ? [this.activeQuestion]
                : [this.listViewActiveQuestion];

            // fix issue when multiple selected and you leave the component, no need to run this if the responses to update are undefined
            responsesToUpdate = responsesToUpdate.filter((r) => r !== undefined);

            // update _allQuestions to match the processedQuestions array
            let responsesToSave = [];
            responsesToUpdate.forEach((rtu) => {
                let responseToSave;
                if (this.options.type === 'requirement') {
                    responseToSave = this._allQuestions.find(
                        (aq) => rtu.Requirement.RequirementId === aq.Requirement.RequirementId
                    );
                } else {
                    responseToSave = this._allQuestions.find(
                        (aq) => rtu.Control.ControlId === aq.Control.ControlId
                    );
                }
                if (this.options.type === 'control' && this.options.assessment !== 'vendorPortal') {
                    responseToSave.Attachments = rtu.Attachments;
                    responseToSave.Comment = rtu.Comment;
                    responseToSave.Response = rtu.Response;
                    responseToSave.ResponseValue =
                        this.answers.find((a) => a.title === rtu.Response)?.value || 10;
                } else if (this.options.assessment === 'vendorPortal') {
                    // reformat because Vendor Portal is totally different format
                    rtu.ResponseTitle = this.vpKeys[rtu.Response];
                    rtu.ResponseValue =
                        this.answers.find((a) => a.dbval === rtu.Response)?.value || 10;
                    responseToSave = _cloneDeep(rtu);
                    responseToSave.Response = responseToSave.ResponseBackup;
                    console.log('responsetosave', JSON.parse(JSON.stringify(responseToSave)));
                    responseToSave.Response.Notes = rtu.Comment;
                    responseToSave.Response.AnswerType = rtu.Response;

                    delete responseToSave.Checked;
                    delete responseToSave.Comment;
                    delete responseToSave.ResponseBackup;
                    delete responseToSave.ResponseTitle;
                    delete responseToSave.ResponseValue;
                    console.log('rts', JSON.parse(JSON.stringify(responseToSave)));
                }
                responsesToSave.push(responseToSave);
            });

            if (this.waitForSaveComplete) {
                this.isSaving$.next(true);
                this.IsSaving = true;
            }

            this.onResponseUpdate.emit(responsesToSave);
        } else {
            console.log('No save needed ');
        }
    }

    goToQuestion(qid) {
        console.log('gotoquestion qid', qid);
        let questionId;
        if (this.options.type === 'requirement') {
            questionId = this._allQuestions.findIndex((q) => {
                console.log('requirementid', q.Requirement.Id);
                return q.Requirement.Id == qid;
            });
            if (this.selectedStandard !== 'all') {
                if (
                    this.standardsService.getStandardDisplayName(
                        this._allQuestions[questionId].Standard
                    ) !== this.selectedStandard
                ) {
                    console.log('changing to all standards');
                    this.chooseStandard('all');
                    this.standardSelectCtrl.setValue('all', { emitEvent: false });
                }
            }
        } else {
            questionId = this._allQuestions.findIndex((q) => q.Control.Id == qid);
        }
        console.log('setQuestion', questionId + 1);
        this.setQuestion(questionId + 1);
        this.wizardView = 'wizard';
        this.resizeContent();
        this.updatePrefs(); // update preferences to stay on selected wizard
        this.allEvidenceList = [...this.backupEvidenceList];
        this.evidenceAvailableToAdd();
    }

    setQuestion(qid: number = this.activeQuestionIndex): void {
        console.log('trying to set question', qid);

        if (typeof this._allQuestions[qid - 1] !== 'undefined') {
            this.updateCount();
            this.activeQuestion = this._allQuestions[qid - 1];
            if (qid) {
                this.activeQuestionIndex = qid;
                this.aqId =
                    this.options.type === 'control'
                        ? this.activeQuestion.Control.Id
                        : this.activeQuestion.Requirement.Id;
            }
            // backup the original response to know if user changed anything, so we don't save if they are just clicking through
            this.activeQuestionOriginal = _cloneDeep(this.activeQuestion);

            this.questionDescriptionText = this.processQuestionDetails(
                this.activeQuestion,
                this.options.type,
                this.questionDescription
            );
        }
        // fire custom event to resize when we change question
        this.refreshTextAreaHeight();
        this.evidenceAvailableToAdd();
    }

    updateWizardAssessments(): void {
        this.activeQuestion.Assignments = this.generateAssessmentObj(this.activeQuestion);
        if (this.activeQuestion.Assignments.length) {
            this.activeQuestion.AssignedBy = this.siteUsers.find(
                (su) => su.UserId === parseInt(this.user.userId, 10)
            )?.DisplayName;
        }
        this.processAssignments(this.activeQuestion);
    }

    resizeContent() {
        // resize wizard content height to match available space, and scroll if more content
        setTimeout(() => {
            let qnc = document.querySelector('.questionNumberContainer') as HTMLElement;
            let wb = document.querySelector('.wizard-buttons') as HTMLElement;
            let wc = document.querySelector('div.wizardContent') as HTMLElement;
            if (qnc) {
                let contentHeight = 0;
                if (this.showHotkeys) {
                    contentHeight += 42;
                }
                if (wb.offsetHeight > 24) {
                    contentHeight += 8;
                }
                if (this.isReadOnly) {
                    contentHeight += 62;
                }
                wc.style.height = `calc(100% - ${contentHeight}px)`;
            }
        }, 100);
    }

    processHotkey(e, key): void {
        if (
            this.wizardView !== 'wizard' ||
            document.querySelector('cm-wizard').parentElement.getAttribute('hidden') === '' ||
            (document.querySelector('div.ui.modal.active') as HTMLElement)
        ) {
            return;
        } // only use keyboard shortcuts on wizard view and not in a modal

        console.log('e', e);
        console.log('key', key);

        if (e.srcElement.nodeName !== 'TEXTAREA') {
            if (key >= 1 && key <= this.answers.length) {
                let el = document.querySelector('input#resp' + key) as HTMLInputElement;
                el.click();
                el.focus();
                this.responseChanged();
            } else if (key === 'gotoPrev') {
                this.prevButton.click();
            } else if (key === 'gotoNext') {
                this.nextButton.click();
            } else if (key === 'openTechnicalIssuestab') {
                if (this.options.showRelatedControls) {
                    this.tabsService.setTab(1);
                }
            } else if (key === 'openCommentTab') {
                let tabIndex = this.options.showRelatedControls ? 2 : 1;
                this.tabsService.setTab(tabIndex);
                setTimeout(() => {
                    (
                        document.querySelector(
                            'itc-tab[tabTitle=Comments] textarea'
                        ) as HTMLTextAreaElement
                    ).focus();
                }, 10);
            } else if (key === 'openFilesTab') {
                let tabIndex = this.options.showRelatedControls ? 3 : 2;
                this.tabsService.setTab(tabIndex);
            }
        }
    }

    getFirstUnanswered(): number {
        console.log('getting first unanswered Question');
        return this.displayedQuestions.findIndex((q) => q.Response === '') + 1 || 1;
    }

    updateCount(): void {
        this.remainingQuestions = this._allQuestions.filter((q) => q.Response === '').length;
        console.log('this.remainingQuestions', this.remainingQuestions);
        this.completedQuestions = this._allQuestions.length - this.remainingQuestions;
    }

    prevPage(): void {
        this.checkSave();
        this.allSelected = false;
        this.allEvidenceList = [...this.backupEvidenceList];
        this.isSaving$
            .asObservable()
            .pipe(takeWhile((loading) => loading, true))
            .subscribe((loading) => {
                if (!loading && !this.saveErrors) {
                    this.activeQuestionIndex--;
                    this.setQuestion();
                }
            });
    }

    nextPage(): void {
        this.checkSave();
        this.allSelected = false;
        this.allEvidenceList = [...this.backupEvidenceList];
        this.isSaving$
            .asObservable()
            .pipe(takeWhile((loading) => loading, true))
            .subscribe((loading) => {
                if (!loading && !this.saveErrors) {
                    this.activeQuestionIndex++;
                    if (this.activeQuestionIndex > this._allQuestions.length) {
                        //before showing modal update remaining questions and percentage
                        this.updateCount();
                        // They clicked next on the very last question
                        // show modal
                        this.wizardConfirmModal.first.show();
                        // don't go up a number past the last question
                        this.activeQuestionIndex--;
                    } else if (
                        this.options.sortByStandards &&
                        this.activeQuestionIndex > this.displayedQuestions.length
                    ) {
                        // user has a selected standard
                        if (this.selectedStandard !== 'all') {
                            // get the index of the selected standard so we know if we can go to the next one.
                            let standardIndex = this.questionStandards.findIndex(
                                (s) => s == this.selectedStandard
                            );
                            // if there is another standard, we're going to go to it.
                            if (standardIndex <= this.questionStandards.length) {
                                let nextStandard = this.questionStandards[standardIndex + 1];
                                this.standardSelectCtrl.setValue(nextStandard);
                                this.chooseStandard(nextStandard);
                            } else {
                                // if there isn't another standard go to "all"
                                console.log('no further standards');
                            }
                        }
                    } else {
                        this.setQuestion();
                    }
                }
            });
    }

    /* Modal shown when user clicks next on the last question */
    processWizardEndConfirmation() {
        if (!this.remainingQuestions) {
            this.wizardConfirmModal.first.hide();
            this.toggleView('results');
        } else {
            this.activeQuestionIndex = this.getFirstUnanswered();
            this.setQuestion();
        }
    }

    /* A response was chosen, if autoAdvance move to next question. Otherwise, save changes */
    responseChanged(): void {
        if (this.autoAdvance) {
            setTimeout(() => {
                this.nextButton.click();
            }, 150);
        } else {
            setTimeout(() => {
                this.checkSave();
            }, 150);
        }
    }

    /* Save wizard prefs for 'showHotKeys' and 'autoAdvance' */
    updatePrefs(): void {
        let wizardPrefs = {
            showHotkeys: this.showHotkeys,
            autoAdvance: this.autoAdvance,
            wizardView: this.wizardView,
            tabNumber: this.tabNumber,
        };
        window.localStorage.setItem('wizardPrefs', JSON.stringify(wizardPrefs));
        this.resizeContent();
    }

    /* Standard chosen, update displayed Questions, and setQuestion to set it up */
    chooseStandard(std: any): void {
        this.selectedStandard = std;

        // update both views to keep them in sync
        if (std == 'all') {
            this.displayedQuestions = this._allQuestions;
            this.listQuestions.next(this._allQuestions.slice());
        } else {
            this.listQuestions.next(this._questionsByStandard[std].questions);
            this.displayedQuestions = this._questionsByStandard[std].questions;
        }
        setTimeout(() => {
            this.sortData(this.currentSort);
        }, 1);

        this.activeQuestionIndex = this.getFirstUnanswered();
        this.setQuestion();
        // setTimeout(this.resizeContent, 100);
        if (this.wizardView === 'wizard') {
            setTimeout(() => {
                this.resizeContent();
            }, 1);
        }
    }

    toggleAddFilesOptions(evt) {
        evt.stopPropagation();
        this.fileDropdownOpen = !this.fileDropdownOpen;
    }

    processQuestionDetails(
        activeQuestion: WizardQuestion,
        type: WizardType,
        questionDescription: QuestionDescription[],
        readOnly = false
    ): string {
        let displayItems = [];
        let questionFullText = '';

        /*
            We iterate over the questionDescription input to get all the fields to show in the
            question description section, RA is a little different as it also shows the standard.
        */
        questionDescription?.forEach((qd, i) => {
            if (qd.type === 'list') {
                displayItems[qd.title] = [];
            }
            // using lodash _.get to get the dynamic object value
            let valString = qd.content;
            if (qd.localized) {
                valString += '.LocalizedText.' + this.culture;
            }
            // don't show guidance if CSPro because we're showing it in middle column
            if (!readOnly && this.site?.IsCSPro && qd.title === 'Guidance') {
                return;
            }

            if (type === 'requirement' && i === 0) {
                // requirement assessment has a slightly different descriptionText (it adds the standard name and requirementId)
                if (readOnly) {
                    questionFullText += `<p><strong>${activeQuestion.Requirement.RequirementId}</strong></p>`;
                } else {
                    questionFullText += `<p><strong>${this.activeQuestion.StandardName} - ${this.activeQuestion.Requirement?.RequirementId}</strong></p>`;
                }
            }

            let questionDescriptionItemContent;

            if (qd.type === 'list') {
                // question description displays some kind of list.
                let listItems = _get(activeQuestion, qd.content);
                listItems.forEach((li) => {
                    if (qd.title === 'References') {
                    }
                    if (qd.localized) {
                        if (li.LocalizedText[this.culture]) {
                            displayItems[qd.title].push(li.LocalizedText[this.culture]);
                        }
                    } else {
                        if (li) {
                            displayItems[qd.title].push(li);
                        }
                    }
                });
                // remove blank items in case we get them. Not sure this can happen with other changes, but better safe than sorry
                displayItems[qd.title] = displayItems[qd.title].filter((i) => i);

                if (displayItems[qd.title].length) {
                    questionDescriptionItemContent = `<div><strong>${qd.title}</strong>`;
                    questionDescriptionItemContent += `<ul class='wizardList'>`;
                    displayItems[qd.title].forEach((di) => {
                        if (qd.title === 'References') {
                            // we cannot just use a regular link because we need to show a warning modal
                            questionDescriptionItemContent += `<li>${di.Label} - <label class='${
                                readOnly ? 'ROlabelLink' : ''
                            } labelLink' >${di.Url}</label>`;
                        } else {
                            questionDescriptionItemContent += `<li>${di}</li>`;
                        }
                    });
                    questionDescriptionItemContent += `</ul>`;
                } else {
                    questionDescriptionItemContent = '';
                }
            } else if (qd.type === 'text') {
                // question description is just text passed in.
                questionDescriptionItemContent = `<div><strong>${qd.title}</strong>`;
                if (displayItems[qd.prevList]?.length) {
                    questionDescriptionItemContent += qd.content;
                } else {
                    questionDescriptionItemContent += qd.uglyHackText2;
                }
            } else {
                // question description is just a normal value
                questionDescriptionItemContent = `<div><strong>${qd.title}</strong>`;
                let itemContent = _get(activeQuestion, valString);

                // on control assessments append control id to the first question description item
                if (type === 'control' && i == 0) {
                    questionDescriptionItemContent += activeQuestion.Control.ControlId + ' - ';
                }

                questionDescriptionItemContent += itemContent ? itemContent.trim() : qd.ifBlank;
            }

            questionFullText += questionDescriptionItemContent + '</div>';

            if (!readOnly && this.site?.IsCSPro) {
                let wizardType = this.options.type === 'requirement' ? 'Requirement' : 'Control';
                let guidanceField = wizardType + '.Guidance.LocalizedText.' + this.culture;
                let csProGuidanceField =
                    wizardType + '.CsproGuidance.LocalizedText.' + this.culture;
                this.questionGuidance = _get(
                    activeQuestion,
                    guidanceField,
                    `[There is currently no guidance for this ${wizardType.toLowerCase()}.]`
                );
                this.csProGuidance = _get(activeQuestion, csProGuidanceField);

                setTimeout(() => {
                    let guidance = document.querySelector('div.wizardGuidance');
                    if (guidance.scrollHeight > guidance.clientHeight) {
                        guidance.classList.add('guidanceOverflow');
                    } else {
                        guidance.classList.remove('guidanceOverflow');
                    }
                }, 1);
            }
            if (!readOnly && this.options.allowAssignments) {
                this.updateAssignmentsFormControls(activeQuestion);
            }
        });

        return questionFullText;
    }

    showReadOnlyModal(res) {
        console.log('showing read only for ', res);
        let wd;
        let wa;
        let type = res.t;
        let roInfo;
        if (type === 'control') {
            wd = ControlsWizardDescription;
            wa = ControlsWizardAnswers;
            roInfo = _cloneDeep(res.q);
            roInfo.QuestionDescriptionText = this.processQuestionDetails(roInfo, type, wd, true);
            roInfo.Type = res.t;
        } else if (res.t === 'requirement') {
            wd = RequirementsWizardDescription;
            wa = RequirementsWizardAnswers;
            roInfo = { Requirement: _cloneDeep(res.q), Comment: '', Response: '' };
            roInfo.Requirement.QuestionDescriptionText = this.processQuestionDetails(
                roInfo,
                type,
                wd,
                true
            );
            roInfo.Requirement.Type = res.t;
            roInfo.Type = res.t;
        }

        console.log('roinfo', roInfo);
        this.readOnlyModalComponent.siteUsers = this.siteUsers;
        this.readOnlyModalComponent.siteGroups = this.siteGroups;
        this.readOnlyModalComponent.answers = wa;
        this.readOnlyModalComponent.showReadOnlyModal(roInfo);
    }

    showRiskModal(obj: RiskModalInfo) {
        this.readOnlyModalComponent.showRiskModal(obj, false);
    }

    calculateDisplaySeverity(num) {
        if (num > 70) {
            return 'high';
        } else if (num > 50) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    tabChanged(tab) {
        // console.log("huh", tab);
        this.tabNumber = tab.tabNumber;
        this.updatePrefs();
    }

    /* ITGlue */
    checkForItglueOAuth(params: any) {
        // if code is sent in query params then IT Glue is trying to OAuth into our portal
        if (params['oas']) {
            this.itglueOauthReturnControlId = params['oas'];
            this.notificationService.toast.success('IT Glue', 'Temporary Connection Successful');
        }
    }

    addSelectedAttachments() {
        let doSave = false;
        let respToUpdate =
            this.wizardView === 'wizard' ? this.activeQuestion : this.listViewActiveQuestion;

        if (this.selectedFiles) {
            if (this.selectedFiles.length) {
                doSave = true;
            }
            for (let file of this.selectedFiles) {
                let attachment: Attachment = new Attachment();
                attachment.Id = file.fileid;
                attachment.Name = file.filename;
                attachment.LastModified = file.lastmodified;
                attachment.UploadedBy = file.uploadedby;

                respToUpdate.Attachments.push(attachment);
            }
        }
        if (doSave) {
            this.onResponseUpdate.emit(this.activeQuestion);
        }
    }

    showITGlueDocuments() {
        if (!this.enableItGlueAttachmentButton) {
            return;
        }

        this.itgModalContent = '';
        this.itglueModalLoadingComplete = false;
        this.itglueModal.show({ closable: false });

        let currentID =
            this.options.type === 'requirement'
                ? this.activeQuestion.Requirement.Id
                : this.activeQuestion.Control.Id;
        this.filesService
            .checkITGlueOauth(this.site.Id, this.options.assessment, currentID)
            .then((res) => {
                if (res && res.action) {
                    if (res.action === 'redirect') {
                        this.redirectUrl = res.link;
                        this.redirectType = 'ITG';
                        if (this.isFormDirty) {
                            this.itgModalContent = 'redirect';
                        } else {
                            // form not dirty
                            if (localStorage.getItem('noITGWarning')) {
                                // don't want to see warning so just redirect
                                window.location.href = res.link;
                            } else {
                                // they haven't opted out of warning so show it
                                this.itgModalContent = 'interstitial';
                            }
                        }
                        this.itglueModal.modal.nativeElement.classList.remove('large');
                        this.itglueModal.modal.nativeElement.classList.add('small');
                        this.itglueModalLoadingComplete = true;
                    } else if (res.action === 'showorgs') {
                        this.itglueOrgs = [];
                        this.itglueOrgs.push(...JSON.parse(res.orgs));

                        if (this.itglueOrgs && this.itglueOrgs.length > 0) {
                            this.itglueOrgCtrl.setValue(this.itglueOrgs[0].id + '');
                        }

                        this.itglueSearchTerm = '';
                        this.itgModalContent = 'orgs';
                        this.itglueModal.modal.nativeElement.classList.remove('small');
                        this.itglueModal.modal.nativeElement.classList.add('large');

                        this.itglueModalLoadingComplete = true;
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    submitItGlueForm() {}

    processSearchKey(ev?: any) {
        if (ev) {
            if (ev.keyCode == 13) {
                this.searchITGlueDocuments();
            }
        }
    }

    searchITGlueDocuments() {
        this.isSearchingITGlue = true;
        this.itglueFileSelectionCount = 0;

        this.filesService
            .searchITGlueFiles(this.site.Id, this.itglueSearchTerm, this.itglueOrgCtrl.value)
            .then((res) => {
                this.itglueFiles = [];
                this.pageNumber = 0;
                if (res && res.action && res.action === 'search_result') {
                    this.itglueFiles.push(...JSON.parse(res.files));
                    this.itglueFileSelection = [];
                    for (let file of this.itglueFiles) {
                        this.itglueFileSelection.push(false);
                    }
                }
                this.isSearchingITGlue = false;
            })
            .catch((e) => {
                console.log('error: ', e);
                this.isSearchingITGlue = false;
            });
    }

    resetITGlueDocuments() {
        this.itglueFiles = [];
        this.itglueFileSelectionCount = 0;
        this.pageNumber = 0;
    }

    updateITGcount() {
        // get count of true items in itglueFileSelection array
        this.itglueFileSelectionCount = this.itglueFileSelection.filter(Boolean).length;
    }

    addItglueFiles() {
        for (var i = 0; i < this.itglueFileSelection.length; i++) {
            if (this.itglueFileSelection[i]) {
                let attachment: Attachment = new Attachment();
                attachment.Id = this.itglueFiles[i].url;
                attachment.Name = this.itglueFiles[i].name;
                attachment.LastModified = new Date();
                attachment.UploadedBy = this.authService.getIdentity().username;

                this.activeQuestion.Attachments.push(attachment);
                this.onResponseUpdate.emit(this.activeQuestion);
            }
        }
        this.resetITGlueDocuments();
        this.itglueModal.hide();
    }

    addAttachmentToResponse(event) {
        console.log('event', event);
    }

    uploadAttachment(file) {
        console.log('uploading file', file);
        let formData: FormData = new FormData();
        formData.append('siteid', '' + this.site.Id);
        formData.append('uploadFile', file.file, file.name);
        let aq = this.wizardView === 'wizard' ? this.activeQuestion : this.listViewActiveQuestion;
        this.fileUploader.processUploadProgress(file, this.siteService.uploadAttachment(formData), {
            page: 'cmwizard',
        });
    }

    addPendingUploadFiles(files) {
        if (files) {
            console.log('Pushing to temp', files);
            let aq;
            let aqid = files[0].extraInfo;
            if (this.options.type === 'control') {
                aq = this._allQuestions.find((q) => q.Control.Id === aqid);
            } else {
                aq = this._allQuestions.find((q) => q.Requirement.Id === aqid);
            }
            if (!aq?.UploadingAttachments) {
                aq.UploadingAttachments = [];
            }
            if (Array.isArray(files)) {
                aq.UploadingAttachments = [...aq.UploadingAttachments, ...files];
            } else {
                aq.UploadingAttachments.push(files);
            }
        }
    }

    addNewAttachment(uploadResponse: FileUpload) {
        console.log('adding new uploaded attachment', uploadResponse);
        // let aq = this.wizardView === 'wizard' ? this.activeQuestion : this.listViewActiveQuestion;
        let aqid = uploadResponse.extraInfo;
        let aq;
        if (this.options.type === 'control') {
            aq = this._allQuestions.find((q) => q.Control.Id === aqid);
        } else {
            aq = this._allQuestions.find((q) => q.Requirement.Id === aqid);
        }

        aq.UploadingAttachments.splice(
            aq.UploadingAttachments.findIndex((u) => u.name === uploadResponse.response.Name),
            1
        );

        aq.Attachments.push(uploadResponse.response);
        this.onResponseUpdate.emit(aq);
        console.log('File added to question', uploadResponse.response);
    }

    downloadAttachment(attachment: Attachment) {
        this.exportLoading = true;
        this.notificationService.toast.info('File Download', 'Attachment download request sent...');
        this.siteService.downloadAttachment(this.site.Id, attachment.Id).then((res) => {
            var bin = window.atob(res);
            var ab = this.s2ab(bin);
            saveAs(new Blob([ab]), attachment.Name);
            this.notificationService.toast.success(
                'File Download',
                'Attachment download is complete.'
            );
            this.exportLoading = false;
        });
    }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    startRemove(attachment: Attachment) {
        this.selectedDeleteAttachment = attachment;
        this.deleteModal.show();
    }

    removeAttachmentFromResponse() {
        try {
            if (this.wizardView === 'list') {
                let index = this.listViewActiveQuestion.Attachments.indexOf(
                    this.selectedDeleteAttachment
                );
                if (index > -1) {
                    this.listViewActiveQuestion.Attachments.splice(index, 1);
                    this.onResponseUpdate.emit(this.listViewActiveQuestion);
                }
            } else {
                let index = this.activeQuestion.Attachments.indexOf(this.selectedDeleteAttachment);
                if (index > -1) {
                    this.activeQuestion.Attachments.splice(index, 1);
                    this.onResponseUpdate.emit(this.activeQuestion);
                }
            }
        } catch (e) {}
        this.deleteModal.hide();
    }

    showPreviouslyUploaded() {
        this.selectedFiles = [];
        this.attachmentModalLoadingComplete = false;
        this.attachmentsModal.show({ onHidden: () => this.cancelPreviouslyUploaded() });
        this.prevFilesSub = this.filesService
            .getFilesMetadataObs(this.site.Id, '__current')
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                catchError((err) => {
                    console.log('Error: ', err);
                    this.attachmentModalLoadingComplete = true;
                    return of([]);
                })
            )
            .subscribe((metas) => {
                this.files = metas;
                for (let file of this.files) {
                    (file as any).link = file.fileid; // the file extension must be included during encoding
                }
                this.filteredFiles = this.files;

                this.filterFiles();
                this.attachmentModalLoadingComplete = true;
            });
    }

    cancelPreviouslyUploaded() {
        this.prevFilesSub.unsubscribe();
    }

    filterFiles(filteredData?: any[]) {
        if (filteredData) {
            this.filteredFiles = filteredData;
        } else {
            this.filteredFiles = this.files;
        }
        this.sortFiles();
    }

    isSelected(file: any): boolean {
        return findIndex(this.selectedFiles, file) > -1;
    }

    fileCompareFunctions = {
        filename: (a, b) => a.filename.toLowerCase().localeCompare(b.filename.toLowerCase()),
        lastmodified: (a, b) =>
            a.lastmodified.toLowerCase().localeCompare(b.lastmodified.toLowerCase()),
        uploadedby: (a, b) => a.uploadedby.toLowerCase().localeCompare(b.uploadedby.toLowerCase()),
    };

    sortFiles(sorting?: TableSorting) {
        let compareFunction;

        if (sorting) {
            compareFunction = this.fileCompareFunctions[sorting.sortColumn];
            if (compareFunction && this.filteredFiles) {
                this.filteredFiles.sort(compareFunction);
                if (sorting.sortDirection === 'desc') {
                    this.filteredFiles.reverse();
                }
            }
        }

        if ((!sorting || !compareFunction) && this.filteredFiles) {
            this.filteredFiles.sort(this.fileCompareFunctions.filename);
        }
    }

    toggleAttachmentFile(file: any) {
        let thischeckbox = document.querySelector(
            '.modal.visible #f' + file.fileid + ' input[type=checkbox]'
        ) as HTMLInputElement;
        if (findIndex(this.selectedFiles, file) > -1) {
            _pull(this.selectedFiles, file);
        } else {
            this.selectedFiles.push(file);
        }
    }

    redirectWarningAction(val) {
        // just adding this check to future proof if we need to add other redirects in the future
        if (this.redirectType == 'ITG') {
            if (val) {
                // user wanted to save, save work and then redirect
                this.onResponseUpdate.emit(this.redirectUrl);
            } else {
                // user doesn't want to save, just redirect
                if (this.itgWarnCheckbox.value) {
                    localStorage.setItem('noITGWarning', 'true');
                    this.itgWarnCheckbox.setValue(null);
                } else {
                    localStorage.removeItem('noITGWarning');
                }
                window.location.href = this.redirectUrl;
            }
        }
    }

    /**
     *   Action when you select a single row
     */
    checkResponse(res: WizardQuestion): void {
        if (this.allResponsesChecked) {
            this.allResponsesChecked = false;
        }
        res.Checked = !res.Checked;
        this.updateSelected();
    }

    /**
     * Toggle all responses in list view table
     *
     * @param status {boolean}
     */
    checkAllResponses(status: boolean): void {
        this.listQuestions.value.forEach((r) => {
            r.Checked = this.hideResponded && r.Response !== '' ? false : status;
        });
        this.allResponsesChecked = status;
        this.updateSelected();
        // this.listQuestions.next();
    }

    /**
     * Update the selected rows to selectedResponses variable
     */
    updateSelected(): void {
        this.selectedResponses = this.listQuestions.value.filter((lq) => lq.Checked);
    }

    /**
     * Search questions in list view using fuseJS
     * I was playing around with it if we want to add fuzzy search
     *
     * @param key {string}
     */
    searchListView(key: string): void {
        this.checkAllResponses(false);
        this.standardSelectCtrl.setValue('all');

        const filter = this._allQuestions.filter((p) =>
            (
                p.StandardName +
                '-' +
                (p.Requirement ? p.Requirement.RequirementId : '') +
                '-' +
                (p.Requirement ? p.Requirement.ShortDesc.LocalizedText[this.culture] : '') +
                '-' +
                (p.Control ? p.Control.ControlId : '') +
                '-' +
                (p.Control ? p.Control.ShortDesc.LocalizedText[this.culture] : '')
            )
                .toLowerCase()
                .includes(key.toLowerCase())
        );
        this.listQuestions.next(filter);
        this.setResponsesHidden(this.hideResponded);

        this.sortData(this.currentSort);
    }

    /**
     * Sort list view questions when column headers clicked
     *
     * @param sort {Sort}
     */
    sortData(sort: Sort): void {
        const data = this.listQuestions.value;
        this.currentSort = sort;
        if (!sort.active || sort.direction === '') {
            this.listQuestions.next(this._allQuestions.slice());
            return;
        }
        // let sortBy: any[];
        let sortFunction;
        let dontReverse = false; // used when we are using cmpEmpty to ignore blank rows, so don't reverse() the array
        switch (sort.active) {
            case 'control':
                sortFunction = (a, b) => cmp(a.Control.ControlId, b.Control.ControlId);
                break;
            case 'requirement':
                sortFunction = (a, b) =>
                    cmp(a.Standard.Name, b.Standard.Name) ||
                    cmp(a.Standard.Variant, b.Standard.Variant) ||
                    cmp(a.Requirement.RequirementId, b.Requirement.RequirementId);
                break;
            case 'response':
                if (this.options.type === 'requirement') {
                    sortFunction = (a, b) =>
                        cmp(a.ResponseValue || 10, b.ResponseValue || 10) ||
                        cmp(a.Standard.Name, b.Standard.Name) ||
                        cmp(a.Standard.Variant, b.Standard.Variant) ||
                        cmp(a.Requirement.RequirementId, b.Requirement.RequirementId) ||
                        cmp(
                            a.Requirement.ShortDesc.LocalizedText[this.culture],
                            b.Requirement.ShortDesc.LocalizedText[this.culture]
                        );
                } else {
                    sortFunction = (a, b) =>
                        cmp(a.ResponseValue || 10, b.ResponseValue || 10) ||
                        cmp(a.Control.ControlId, b.Control.ControlId);
                }
                break;
            case 'comment':
                // dontReverse = true;
                if (this.options.type === 'requirement') {
                    sortFunction = (a, b) =>
                        cmp(a.Comment, b.Comment) ||
                        cmp(a.Standard.Name, b.Standard.Name) ||
                        cmp(a.Standard.Variant, b.Standard.Variant) ||
                        cmp(a.Requirement.RequirementId, b.Requirement.RequirementId);
                } else {
                    sortFunction = (a, b) =>
                        cmp(a.Comment, b.Comment) || cmp(a.Control.ControlId, b.Control.ControlId);
                }
                break;
            case 'files':
                // dontReverse = true;
                if (this.options.type === 'requirement') {
                    sortFunction = (a, b) =>
                        cmp(a.Attachments.length, b.Attachments.length) ||
                        cmp(a.Standard.Name, b.Standard.Name) ||
                        cmp(a.Standard.Variant, b.Standard.Variant) ||
                        cmp(a.Requirement.RequirementId, b.Requirement.RequirementId);
                } else {
                    sortFunction = (a, b) =>
                        cmp(a.Attachments.length, b.Attachments.length) ||
                        cmp(a.Control.ControlId, b.Control.ControlId);
                }
                break;
            case 'assignments':
                // dontReverse = true;
                if (this.options.type === 'requirement') {
                    sortFunction = (a, b) =>
                        cmp(a.AssignedList, b.AssignedList) ||
                        cmp(a.Standard.Name, b.Standard.Name) ||
                        cmp(a.Standard.Variant, b.Standard.Variant) ||
                        cmp(a.Requirement.RequirementId, b.Requirement.RequirementId);
                } else {
                    sortFunction = (a, b) =>
                        cmp(a.AssignedList, b.AssignedList) ||
                        cmp(a.Control.ControlId, b.Control.ControlId);
                }
                break;
        }

        let sortedQuestions = data.sort(sortFunction);
        if (sort.direction == 'desc' && !dontReverse) {
            sortedQuestions.reverse();
        }
        this.listQuestions.next(sortedQuestions as WizardQuestion[]);
        if (this.vsViewport) {
            this.vsViewport.scrollToOffset(0);
        }
    }

    jumpToCSPro() {
        let csGuidanceSection = document.getElementById('csProGuidance');
        csGuidanceSection.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    /**
     * Toggle all responses checked/unchecked
     *
     * @param checked {boolean}
     *
     * "Hide questions with response" clicked, update unanswered questions count
     */
    setResponsesHidden(checked: boolean): void {
        this.checkAllResponses(false); // force all checked responses to be false otherwise they'll have items they don't mean to.
        this.unansweredCount = checked
            ? this.listQuestions.value.filter((lq) => lq.Response === '').length
            : 0;
        // force table update
        this.listQuestions.next(this.listQuestions.value);
    }

    /**
     * Show modal to update files for single question
     *
     * @param event {Event} - To stop propagation
     * @param resp {WizardQuestion} - Question to update
     */
    showUpdateFiles(event: Event, resp: WizardQuestion): void {
        event.stopPropagation();
        this.listViewActiveQuestion = resp;
        this.lvAqId = this.options.type === 'control' ? resp.Control.Id : resp.Requirement.Id;
        this.updateFilesModal.show();
    }

    /**
     * Update Response for single question
     */
    updateResponse(): void {
        this.listViewActiveQuestion.Response = this.bulkUpdateResponse;
    }

    /**
     * Show modal to bulk update responses
     *
     * @param answer {string}
     */
    showBulkUpdateResponses(answer: string): void {
        this.isBulkUpdate = true;
        this.bulkUpdateResponse = answer;
        this.updateResponsesModal.show();
    }

    /**
     * Bulk update Responses for multiple questions
     */
    bulkUpdateResponses(): void {
        // let thisAnswer = this.answers.find(a => a.title === this.bulkUpdateResponse);
        this.selectedResponses = this.listQuestions.value.filter((lq) => lq.Checked);
        this.selectedResponses.forEach((sr) => {
            let thisAnswer = this.answers.find((a) => a.title === this.bulkUpdateResponse);
            if (this.options.assessment === 'vendorPortal') {
                sr.Response = thisAnswer.dbval;
                sr.ResponseTitle = this.bulkUpdateResponse;
            } else {
                sr.Response = this.bulkUpdateResponse;
            }
        });
        this.checkSave(true);
        this.updateCount();
    }

    /**
     * Show modal to update single comment
     *
     * @param event {Event}
     * @param res {WizardQuestion}
     */
    showUpdateComment(event: Event, res: WizardQuestion): void {
        event.stopPropagation();
        this.isBulkUpdate = false;
        this.listViewActiveQuestion = res;
        this.commentCtrl.setValue(res.Comment);
        this.updateCommentsModal.show();
        // fire custom event to resize when we change question
        this.refreshTextAreaHeight();
    }

    /**
     * Update comment for single question
     */
    updateComment(): void {
        this.listViewActiveQuestion.Comment = this.commentCtrl.value;
        this.checkSave(true);
    }

    /**
     * Show modal to bulk update comments
     *
     * @param event {Event}
     */
    showBulkUpdateComments(event: Event): void {
        event.stopPropagation();
        if (!this.selectedResponses.length) {
            return;
        }

        this.isBulkUpdate = true;
        this.commentCtrl.setValue('');
        this.updateCommentsModal.show();
        // fire custom event to resize when we change question
        this.refreshTextAreaHeight();
    }

    /**
     * Bulk update comments
     */
    bulkUpdateComments(): void {
        this.selectedResponses = this.listQuestions.value.filter((lq) => lq.Checked);
        this.selectedResponses.forEach((sr) => {
            sr.Comment = this.commentCtrl.value;
        });
        this.checkSave(true);
    }

    /**
     * Show modal to update assignments
     *
     * @param event {Event}
     * @param res {WizardQuestion}
     */
    showUpdateAssignments(event: Event, res: WizardQuestion): void {
        event.stopPropagation();
        this.isBulkUpdate = false;
        this.listViewActiveQuestion = res;
        this.updateAssignmentsFormControls(this.listViewActiveQuestion);
        this.updateAssignmentsModal.show({ autofocus: false });
    }

    /**
     * Update assignment for single question
     */
    updateAssignments(): void {
        let assignmentsArray: Assignment[] = [];

        assignmentsArray = this.generateAssessmentObj(this.listViewActiveQuestion);

        // if no assignments, send special one to remove all
        if (assignmentsArray.length === 0) {
            assignmentsArray = this.generateDefaultDeleteObj(this.listViewActiveQuestion);
        }
        console.log('Sending Assignments', assignmentsArray);
        this.siteUsersService
            .assignWork(this.site.Id, assignmentsArray)
            .then((returnedAssignments) => {
                this.listViewActiveQuestion.Assignments = returnedAssignments;
                this.processAssignments(this.listViewActiveQuestion);
            });
    }

    /**
     * Show modal to bulk update assignments
     *
     * @param event {Event}
     */
    showBulkUpdateAssignments(event: Event): void {
        event.stopPropagation();
        if (!this.selectedResponses.length) {
            return;
        }
        this.isBulkUpdate = true;
        [...this.siteUsers, ...this.siteGroups].forEach((item) => {
            item.Assigned.setValue(false);
        });

        this.updateAssignmentsModal.show({ autofocus: false });
    }

    /**
     * Bulk update assignments
     */
    bulkUpdateAssignments(): void {
        this.assigningInProgress = true;
        this.notificationService.toast.info('User and Groups assignments are being applied.', '');
        // Bulk update assignments
        let assignmentsArray: Assignment[] = [];

        this.selectedResponses.forEach((sr) => {
            let assignmentObjs = this.generateAssessmentObj(sr);
            assignmentsArray = [...assignmentsArray, ...assignmentObjs];
        });
        assignmentsArray = _uniqWith(assignmentsArray, _isEqual);

        console.log('assignmentsArray', assignmentsArray);
        // if no assignments, send special one to remove all
        if (assignmentsArray.length === 0) {
            this.selectedResponses.forEach((sr) => {
                let assignmentObjs = this.generateDefaultDeleteObj(sr);
                assignmentsArray = [...assignmentsArray, ...assignmentObjs];
            });
        }
        console.log('Sending Assignments', assignmentsArray);
        this.siteUsersService
            .assignWork(this.site.Id, assignmentsArray)
            .then((returnedAssignments) => {
                this.selectedResponses.forEach((sr) => {
                    let thisItemAssignments = returnedAssignments.filter((ra) => {
                        if (this.options.type === 'control') {
                            return ra.WorkId === sr.Control.Id + '';
                        }
                        if (this.options.type === 'requirement') {
                            return ra.WorkId === sr.Requirement.Id + '';
                        }
                    });
                    sr.Assignments = thisItemAssignments;
                    this.processAssignments(sr);
                });
                this.assigningInProgress = false;
                this.notificationService.toast.success('Assignments complete.', '');
            })
            .catch((err) => {
                this.assigningInProgress = false;
                this.notificationService.toast.error(
                    'Assigning failed.',
                    'Assigning of survey questions has failed, please try again.'
                );
            });
    }

    /**
     * Take question passed in and generate the assessments to be sent to api
     *
     * @param obj {WizardQuestion}
     * @returns Assignment
     */
    generateAssessmentObj(obj: WizardQuestion): Assignment[] {
        let returnAssignments = [];
        [...this.siteUsers, ...this.siteGroups]
            .filter((d) => d.Assigned.value)
            .forEach((u) => {
                let assignment: Assignment = {
                    UserId: u.UserId ? u.UserId : -1,
                    GroupId: u.Id ? u.Id : -1,
                    SiteId: u.SiteId,
                    AssignedById: parseInt(this.user.userId, 10),
                    WorkType: this.options.assessment,
                    WorkId:
                        this.options.type !== 'requirement'
                            ? obj.Control.Id + ''
                            : obj.Requirement.Id + '',
                    UserDisplayName: u.DisplayName,
                    AssignedByDisplayName: this.user.userFullName,
                };
                returnAssignments.push(assignment);
            });
        return returnAssignments;
    }

    /**
     * To delete items (when no checkboxes are checked)
     * we have to pass in this assignment so the backend knows what to do
     *
     * @param obj {WizardQuestion}
     * @returns Assignment[]
     */
    generateDefaultDeleteObj(obj: WizardQuestion): Assignment[] {
        let assignmentsArray = [];
        assignmentsArray.push({
            UserId: -1,
            GroupId: -1,
            SiteId: -1,
            AssignedById: -1,
            WorkType: this.options.assessment,
            WorkId:
                this.options.type !== 'requirement'
                    ? obj.Control.Id + '' + ''
                    : obj.Requirement.Id + '',
            UserDisplayName: '',
            AssignedByDisplayName: '',
        });
        return assignmentsArray;
    }

    /**
     * Update all user and group form controls to set them to the correct status
     * for the object passed in
     *
     * @param obj
     */
    updateAssignmentsFormControls(obj) {
        [...this.siteUsers, ...this.siteGroups].forEach((item) => {
            item.Assigned.setValue(false);
        });
        if (obj.Assignments)
            obj.Assignments.forEach((a) => {
                if (a.UserDisplayName) {
                    let fcToUpdate = this.siteUsers.find(
                        (su) => su.DisplayName === a.UserDisplayName
                    );
                    fcToUpdate?.Assigned?.setValue(true);
                }
                if (a.GroupId !== -1) {
                    let fcToUpdate = this.siteGroups.find((sg) => sg.Id === a.GroupId);
                    fcToUpdate?.Assigned?.setValue(true);
                }
            });
        this.initDropdown();
    }

    toggleResource(resource) {
        this.resourceCategories[resource] = !this.resourceCategories[resource];
        this.allResourcesExpanded = Object.values(this.resourceCategories).every((r) => r);
        localStorage.setItem(
            'resourceCategories' + this.options.assessment.toUpperCase(),
            JSON.stringify(this.resourceCategories)
        );
        if (resource === 'commentsOpen') {
            this.refreshTextAreaHeight();
        }
    }

    toggleAllResources() {
        this.allResourcesExpanded = !this.allResourcesExpanded;
        Object.keys(this.resourceCategories).forEach(
            (r) => (this.resourceCategories[r] = this.allResourcesExpanded)
        );
        localStorage.setItem(
            'resourceCategories' + this.options.assessment.toUpperCase(),
            JSON.stringify(this.resourceCategories)
        );
    }

    createBulkActions() {
        /* Create bulk actions for bulk action dropdown menu  */
        let bulkActions = [
            {
                label: 'SET RESPONSES',
                items: [],
            },
            {
                label: 'COMMENTS',
                items: [
                    {
                        label: 'Change Comment',
                        disabled: !this.selectedResponses.length,
                        command: () => this.showBulkUpdateComments(null),
                    },
                ],
            },
            {
                label: 'ASSIGNMENTS',
                items: [
                    {
                        label: 'Assign Question',
                        disabled: !this.selectedResponses.length,
                        command: () => this.showBulkUpdateAssignments(null),
                    },
                ],
            },
        ];

        // the options include the different answers
        this.answers.forEach((a) => {
            bulkActions[0].items.push({
                label: a.title,
                disabled: !this.selectedResponses.length,
                command: () => this.showBulkUpdateResponses(a.title),
            });
        });

        this.bulkActions = bulkActions;
    }

    createDropdownMenuOptions() {
        this.dropdownMenuOptions = [
            {
                label: 'From Previously Uploaded',
                command: () => {
                    this.showPreviouslyUploaded();
                },
            },
            {
                label: 'From IT Glue',
                disabled: !this.enableItGlueAttachmentButton,
                command: () => {
                    this.showITGlueDocuments();
                },
            },
        ];
    }

    getEvidenceList() {
        this.siteService
            .getEvidenceList(this.site.Id)
            .then((data) => {
                if (data) {
                    data.forEach((evidence) => {
                        const newEvidence = {
                            CurrentCoverage: evidence.CurrentCoverage,
                            Description: evidence.Description,
                            LastImported: evidence.LastImported,
                            LastUpdated: evidence.LastUpdated,
                            Metrics: evidence.Metrics,
                            Name: evidence.Name,
                            ProductIconUrl: evidence.ProductIconUrl,
                            ProductName: evidence.ProductName,
                            TotalCoverage: evidence.TotalCoverage,
                            selected: false,
                        };
                        this.allEvidenceList.push(newEvidence);
                        this.backupEvidenceList.push(newEvidence);
                    });
                }
            })
            .catch((error) => {
                this.options.showEvidence = false;
            });
    }

    startDelete(evidence: object) {
        this.evidenceNameToDelete = evidence['Name'];
        this.evidenceToDelete = evidence;
        this.allSelected = false;
        this.deleteModalModified.show();
    }

    showReport(evidence: object) {
        this.eocReportModal.showModal(evidence);
    }

    evidenceSelected(event: boolean, evidence: object) {
        const evidenceString = JSON.stringify(evidence);
        if (event) {
            this.allEvidenceList.forEach((value) => {
                const valueString = JSON.stringify(value);
                if (valueString === evidenceString) {
                    value.selected = 'true';
                }
            });

            this.countSelectedEvidences++;

            if (this.countSelectedEvidences == this.allEvidenceList.length) {
                this.allSelected = true;
            } else {
                this.allSelected = 'mixed';
            }
        } else {
            this.countSelectedEvidences--;
            this.allEvidenceList.forEach((value) => {
                const valueString = JSON.stringify(value);
                if (valueString === evidenceString) {
                    value.selected = 'false';
                }
            });

            if (this.countSelectedEvidences == 0) {
                this.allSelected = false;
            } else {
                this.allSelected = 'mixed';
            }
        }
    }

    allEvidencesSelected(event: boolean) {
        if (event) {
            this.allEvidenceList.forEach((value) => {
                value.selected = 'true';
            });
            this.countSelectedEvidences = this.allEvidenceList.length;
            this.allSelected = true;
        } else {
            this.allEvidenceList.forEach((value) => {
                value.selected = 'false';
            });
            this.countSelectedEvidences = 0;
            this.allSelected = false;
        }
    }

    deleteEvidence(evidence: object) {
        const evidenceString = JSON.stringify(evidence);
        let deleted = false;
        this.activeQuestion.Evidence.forEach((value, index) => {
            const valueString = JSON.stringify(value);
            if (evidenceString === valueString && !deleted) {
                this.activeQuestion.Evidence.splice(index, 1);
                deleted = true;
            }
        });
        const newEvidence = {
            CurrentCoverage: evidence['CurrentCoverage'],
            Description: evidence['Description'],
            LastImported: evidence['LastImported'],
            LastUpdated: evidence['LastUpdated'],
            Metrics: evidence['Metrics'],
            Name: evidence['Name'],
            ProductIconUrl: evidence['ProductIconUrl'],
            ProductName: evidence['ProductName'],
            TotalCoverage: evidence['TotalCoverage'],
            selected: false,
        };
        this.allEvidenceList.push(newEvidence);
        this.evidenceAvailableToAdd();
        this.deleteModalModified.hide();
        this.onResponseUpdate.emit(this.activeQuestion);
    }

    addEvidenceOfCompliance() {
        const listToAdd = [...this.allEvidenceList];
        listToAdd.forEach((evidence, index) => {
            if (evidence.selected == 'true') {
                const evidenceToAdd = {
                    CurrentCoverage: evidence.CurrentCoverage,
                    Description: evidence.Description,
                    LastImported: evidence.LastImported,
                    LastUpdated: evidence.LastUpdated,
                    Metrics: evidence.Metrics,
                    Name: evidence.Name,
                    ProductIconUrl: evidence.ProductIconUrl,
                    ProductName: evidence.ProductName,
                    TotalCoverage: evidence.TotalCoverage,
                };
                if (!this.activeQuestion?.Evidence) {
                    this.activeQuestion.Evidence = [];
                }
                this.activeQuestion.Evidence.push(evidenceToAdd);
                this.allEvidenceList.splice(index, 1);
            }
        });
        this.onResponseUpdate.emit(this.activeQuestion);
        this.importEvidenceCompliance.hide();
    }

    sortEvidence(sorting?: TableSorting) {
        if (!sorting) {
            sorting = { sortColumn: 'name', sortDirection: 'asc' };
        }

        let hit: boolean = false;

        switch (sorting.sortColumn) {
            case 'name':
                hit = true;
                this.sortedEvidenceList = this.allEvidenceList.sort((a, b) =>
                    a.module.toLowerCase().localeCompare(b.module.toLowerCase())
                );
                break;
            case 'evidence':
                hit = true;
                this.sortedEvidenceList = this.allEvidenceList.sort((a, b) =>
                    a.description.toLowerCase().localeCompare(b.description.toLowerCase())
                );
                break;
            default: {
                break;
            }
        }

        if (hit && sorting.sortDirection == 'desc') {
            this.sortedEvidenceList.reverse();
        }

        this.sortedEvidenceList = [...this.sortedEvidenceList];
    }

    evidenceAvailableToAdd() {
        this.allEvidenceList.forEach((evidence, index) => {
            const newEvidence = {
                Name: evidence.Name,
                ProductName: evidence.ProductName,
            };
            evidence.selected = false;
            this.activeQuestion?.Evidence?.forEach((oldEvidence) => {
                if (
                    newEvidence.ProductName == oldEvidence.ProductName &&
                    newEvidence.Name == oldEvidence.Name
                ) {
                    this.allEvidenceList.splice(index, 1);
                }
            });
        });
    }

    showImportComplianceModal() {
        this.allSelected = false;
        this.allEvidenceList.forEach((evidence, index) => {
            const newEvidence = {
                Name: evidence.Name,
                ProductName: evidence.ProductName,
            };
            this.activeQuestion?.Evidence?.forEach((oldEvidence) => {
                if (
                    newEvidence.ProductName == oldEvidence.ProductName &&
                    newEvidence.Name == oldEvidence.Name
                ) {
                    this.allEvidenceList.splice(index, 1);
                }
            });
        });
        this.importEvidenceCompliance.show({ closable: false });
    }

    downloadEvidence(evidence: object) {
        evidence['downloading'] = true;
        this.evidenceComplianceService
            .downloadEvidence(this.site.Id, new Evidence(evidence as any))
            .then((res) => {
                var bin = atob(res);
                var ab = this.s2ab(bin);
                saveAs(new Blob([ab]), `${evidence['ProductName']} ${evidence['Name']}.xlsx`);
                this.notificationService.toast.success('Success', 'Evidence Download is Complete');
                this.exportLoading = false;
            })
            .catch((ex) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was an error downloading an evidence'
                );
            })
            .finally(() => {
                evidence['downloading'] = false;
            });
    }

    refreshTextAreaHeight() {
        // trigger a custom event to fire the autoResize changes to reset
        setTimeout(() => {
            let ta = document.querySelectorAll('textarea[autoResize]');
            const refreshHeight = new CustomEvent('refreshHeight');
            ta.forEach((textarea) => {
                textarea.dispatchEvent(refreshHeight);
            });
        }, 0);
    }
}

/**
 * Compare function to sort questions
 *
 * @param a {number|string}
 * @param b {number|string}
 * @returns Sort value
 */
function cmp(a: number | string, b: number | string): number {
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    } else if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: 'base',
            ignorePunctuation: true,
        });
    }
}

/**
 * Compare function to handle comparing but moving blank items to the bottom
 *
 * @param a {number|string}
 * @param b {number|string}
 * @param sort {boolean} passed in to handle sorting numbers by direction
 * @returns Sort value
 */
function cmpEmpty(a: number | string, b: number | string, sort?: Sort): number {
    if (typeof a === 'number' && typeof b === 'number') {
        a = a === 0 && sort?.direction === 'asc' ? Infinity : a;
        b = b === 0 && sort?.direction === 'asc' ? Infinity : b;
        return sort?.direction === 'asc' ? a - b : b - a;
    } else if (typeof a === 'string' && typeof b === 'string') {
        if (sort?.direction === 'asc') {
            return a ? (b ? a.toLowerCase().localeCompare(b.toLowerCase()) : -1) : 1;
        } else {
            return b ? (a ? b.toLowerCase().localeCompare(a.toLowerCase()) : 1) : -1;
        }
    }
}
