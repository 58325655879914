import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteUser, SiteUsersService } from 'app/sites/shared/site-users.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { UiService } from 'app/core/ui.service';
import { AuthService, Roles } from 'app/core/auth/auth.service';
import { SettingService } from 'app/settings/shared/setting.service';

import { SiteService } from '../../../shared/site.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

export interface RoleObject {
    key: string;
    name: string;
    enabled: boolean;
    extraClasses?: string;
    showDAToggle?: boolean;
    users: SiteUser[];
}

@Component({
    selector: 'sds-role-management',
    templateUrl: './role-management.component.html',
    styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit, OnDestroy {
    validForm: boolean;

    constructor(
        private messageService: MessageService,
        private siteUsersService: SiteUsersService,
        private notificationService: NotificationService,
        private siteService: SiteService,
        private authService: AuthService,
        private settingService: SettingService,
        private uiService: UiService
    ) {}

    @ViewChild('modal', { static: true }) modal: any;

    site: Site;
    isAG: boolean;
    isIndoc: boolean;
    isKvs: boolean;
    isGrc: boolean;
    isNdp: boolean;
    orgLevelDiscoveryAgents: boolean = false;
    overrideDA: boolean = false;
    isSuperUser = false;

    allUsers: SiteUser[];
    availableUsers: SiteUser[];

    fcs: UntypedFormControl[] = [];

    // most roles
    roles: RoleObject[];
    // other roles to show up in a different container
    otherRoles: RoleObject[];

    orgRoles: string[] = ['DISCOVERY_AGENT'];

    selectedRole: RoleObject;

    msgSub: Subscription;

    siteUsers: SiteUser[];

    loadingComplete: boolean;

    breadcrumbs = [
        { path: '..', text: 'Home' },
        { path: '.', text: 'Roles' },
    ];

    ngOnInit() {
        this.isSuperUser =
            this.authService.userIsRole(Roles.Admin) || this.authService.userIsRole(Roles.Master);
        this.settingService.getSetting('ALL_USERS_ACCESS_DA').then((enableAllOrgDA) => {
            if (enableAllOrgDA) {
                this.overrideDA = enableAllOrgDA.Value === 'TRUE';
            }
        });
        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;
            this.orgLevelDiscoveryAgents =
                site.Organization &&
                (this.siteService.isComplianceManagerGRC(site) ||
                    this.siteService.isKVS(site) ||
                    this.siteService.isNDPro(site) ||
                    this.siteService.isIndoc(site));
            this.site = site;
            this.uiService.setTitle('Roles', site.Name);
            this.isAG =
                this.siteService.isComplianceManager(this.site) ||
                this.siteService.isComplianceManagerGRC(this.site);
            this.isIndoc = site.IsIndoc;
            this.isKvs = this.siteService.isKVS(site);
            this.isGrc = this.siteService.isComplianceManagerGRC(site);
            this.isNdp = this.siteService.isNDProWeb(site) || this.siteService.isNDPro(site);

            this.roles = [
                {
                    key: 'SDS_SITEADMIN',
                    name: 'Site Admin',
                    enabled: true,
                    showDAToggle: true,
                    users: [],
                },
                {
                    key: 'SDS_TECH',
                    name: 'Technician',
                    enabled: true,
                    showDAToggle: true,
                    users: [],
                },
                {
                    key: 'SDS_AUDITOR',
                    name: 'Internal Auditor',
                    enabled: this.site && this.isAG,
                    users: [],
                },
                {
                    key: 'AG_SME',
                    name: 'Subject Matter Expert',
                    enabled: this.site && this.isAG,
                    users: [],
                },
                {
                    key: 'GRC_REPORT_VIEWER',
                    name: 'Reports Viewer',
                    enabled: this.site && this.isGrc,
                    users: [],
                },
                // {
                //     key: 'DISCOVERY_AGENT',
                //     name: 'Discovery Agent Manager',
                //     enabled: true,
                //     users: [],
                // },
                {
                    key: 'EMP_PORTAL_ADMIN',
                    name: 'Employee Portal Admin',
                    enabled: this.site && this.isGrc,
                    users: [],
                },
            ];
            this.otherRoles = [
                {
                    key: 'IND_CLIENT',
                    name: 'Client View',
                    enabled: this.site && (this.isIndoc || this.isKvs || this.isNdp),
                    extraClasses: 'otherRoleContainer',
                    users: [],
                },
            ];
            console.log('this.role', this.roles);

            this.loadingComplete = false;

            this.siteUsersService
                .getSiteUsers(this.site.Id)
                .then((siteUsers) => {
                    this.siteUsers = siteUsers;

                    this.allUsers = [];
                    for (let user of this.siteUsers) {
                        if (user.Role) {
                            for (let role of user.Role.split(',')) {
                                this.roles.find((r) => r.key === role)?.users.push(user);
                                this.otherRoles.find((r) => r.key === role)?.users.push(user);
                            }
                        }
                        this.allUsers.push(user);
                    }

                    this.loadingComplete = true;
                })
                .catch((err) => {
                    this.loadingComplete = true;
                    console.error(err);
                });
        });
        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
    }

    showModal(role: RoleObject) {
        this.selectedRole = role;

        this.fcs = [];
        this.availableUsers = [];
        setTimeout(() => {
            for (let user of this.allUsers) {
                if (
                    (role.key == 'IND_CLIENT' && user.Role.length) ||
                    (role.key != 'IND_CLIENT' && user.Role.indexOf('IND_CLIENT') >= 0)
                )
                    continue;
                if (
                    user.Role.split(',').indexOf(role.key) < 0 ||
                    (this.orgRoles.indexOf(role.key) > -1 &&
                        user.OrgRole.split(',').indexOf(role.key) < 0)
                ) {
                    this.availableUsers.push(user);
                    let newFC = new UntypedFormControl({
                        value: false,
                        disabled: this.disableUserCheckbox(user, role.key),
                    });
                    this.fcs.push(newFC);
                }
            }
        }, 1);
        this.modal.show();
    }

    validateForm() {
        this.validForm = false;
        for (let i = 0; i < this.fcs.length; i++) {
            if (this.fcs[i].value) {
                this.validForm = true;
            }
        }
    }

    addSelectedUsersToSelectedRole() {
        let users: SiteUser[] = [];
        for (let i = 0; i < this.fcs.length; i++) {
            if (this.fcs[i].value) {
                users.push(this.availableUsers[i]);
            }
        }

        let c = 0;
        for (let user of users) {
            let roles: string[] = user.Role.split(',');
            if (roles[0] == '') roles = [];

            user.Role = roles.concat(this.selectedRole.key).join();

            if (user.Role.indexOf('IND_CLIENT') >= 0 && user.Role != 'IND_CLIENT') {
                this.notificationService.toast.error(
                    'Error',
                    "'Client' role cannot be used with any other roles."
                );
                continue;
            }
            this.siteUsersService
                .updateSiteUserRole(user)
                .then((res) => {
                    this.roles.find((r) => r.key === this.selectedRole.key)?.users.push(user);
                    this.otherRoles.find((r) => r.key === this.selectedRole.key)?.users.push(user);
                    if (++c == users.length) {
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // handle error
                });
        }

        this.modal.hide();
    }
    toggleUserInOrgRole(user: SiteUser, role: string, event: any) {
        if (event) {
            if (this.orgRoles.indexOf(role) > -1) {
                let roles: string[] = user.OrgRole?.split(',');
                if (roles[0] == '') roles = [];

                user.OrgRole = roles.concat(role).join();

                this.siteUsersService
                    .updateOrgUserRole(user)
                    .then((res) => {
                        this.roles[role].push(user);
                    })
                    .catch((err) => {
                        console.error(err);
                        // handle error
                    });
            }
        } else {
            let roles = user.OrgRole.split(',');
            roles.splice(roles.indexOf(role), 1);
            user.OrgRole = roles.join();
            this.siteUsersService
                .updateOrgUserRole(user)
                .then((res) => {
                    this.roles[role].splice(this.roles[role].indexOf(user), 1);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    removeUserFromRole(user: SiteUser, role: string) {
        let roles = user.Role.split(',');
        roles.splice(roles.indexOf(role), 1);
        user.Role = roles.join();
        this.siteUsersService
            .updateSiteUserRole(user)
            .then((res) => {
                this.roles
                    .find((r) => r.key === role)
                    ?.users.splice(this.roles.find((r) => r.key === role)?.users.indexOf(user), 1);
                this.otherRoles
                    .find((r) => r.key === role)
                    ?.users.splice(
                        this.otherRoles.find((r) => r.key === role)?.users.indexOf(user),
                        1
                    );
            })
            .catch((err) => {
                console.error(err);
            });
    }

    getLabelTitle(user: SiteUser, selectedRole: string) {
        let userRoles = user.Role.split(',');
        if (selectedRole !== 'AG_SME') {
            if (userRoles.indexOf('AG_SME') >= 0) {
                return "User is a 'Subject Matter Expert' and not available for this role.";
            }
        }
        /* I'm doing it this way since users can have multiple roles, so we just go by order of important */
        if (selectedRole === 'AG_SME') {
            if (userRoles.indexOf('SDS_SITEADMIN') >= 0) {
                return "User is a 'Site Admin' and not available for this role.";
            } else if (userRoles.indexOf('SDS_TECH') >= 0) {
                return "User is a 'Technician' and not available for this role.";
            } else if (userRoles.indexOf('SDS_AUDITOR') >= 0) {
                return "User is an 'Internal Auditor' and not available for this role.";
            }
        }

        if (selectedRole === 'EMP_PORTAL_ADMIN') {
            if (userRoles.indexOf('SDS_SITEADMIN') >= 0) {
                return "User is a 'Site Admin' and not available for this role.";
            } else if (userRoles.indexOf('SDS_TECH') >= 0) {
                return "User is a 'Technician' and not available for this role.";
            } else if (userRoles.indexOf('SDS_AUDITOR') >= 0) {
                return "User is an 'Internal Auditor' and not available for this role.";
            } else if (userRoles.indexOf('AG_SME') >= 0) {
                return "User is a 'Subject Matter Expert' and not available for this role.";
            } else if (userRoles.indexOf('GRC_REPORT_VIEWER') >= 0) {
                return "User is a 'Reports Viewer' and not available for this role.";
            }
        }

        if (selectedRole === 'GRC_REPORT_VIEWER') {
            if (userRoles.indexOf('SDS_SITEADMIN') >= 0) {
                return "User is a 'Site Admin' and not available for this role.";
            } else if (userRoles.indexOf('SDS_TECH') >= 0) {
                return "User is a 'Technician' and not available for this role.";
            } else if (userRoles.indexOf('SDS_AUDITOR') >= 0) {
                return "User is an 'Internal Auditor' and not available for this role.";
            } else if (userRoles.indexOf('AG_SME') >= 0) {
                return "User is a 'Subject Matter Expert' and not available for this role.";
            }
        }

        if (selectedRole !== 'EMP_PORTAL_ADMIN') {
            if (userRoles.indexOf('EMP_PORTAL_ADMIN') >= 0) {
                return "User is an 'Employee Portal Admin' and not available for this role.";
            }
        }

        return '';
    }

    disableUserCheckbox(user: SiteUser, selectedRole: string) {
        let userRoles = user.Role.split(',');
        if (selectedRole === 'GRC_REPORT_VIEWER') {
            return (
                userRoles.indexOf('SDS_SITEADMIN') >= 0 ||
                userRoles.indexOf('SDS_TECH') >= 0 ||
                userRoles.indexOf('SDS_AUDITOR') >= 0 ||
                userRoles.indexOf('EMP_PORTAL_ADMIN') >= 0 ||
                userRoles.indexOf('AG_SME') >= 0
            );
        } else if (selectedRole === 'EMP_PORTAL_ADMIN') {
            return (
                userRoles.indexOf('SDS_SITEADMIN') >= 0 ||
                userRoles.indexOf('SDS_TECH') >= 0 ||
                userRoles.indexOf('SDS_AUDITOR') >= 0 ||
                userRoles.indexOf('GRC_REPORT_VIEWER') >= 0 ||
                userRoles.indexOf('AG_SME') >= 0
            );
        } else {
            return (
                (selectedRole !== 'EMP_PORTAL_ADMIN' &&
                    userRoles.indexOf('EMP_PORTAL_ADMIN') >= 0) ||
                (selectedRole !== 'AG_SME' && userRoles.indexOf('AG_SME') >= 0) ||
                (selectedRole === 'AG_SME' &&
                    (userRoles.indexOf('SDS_SITEADMIN') >= 0 ||
                        userRoles.indexOf('SDS_TECH') >= 0 ||
                        userRoles.indexOf('SDS_AUDITOR') >= 0))
            );
        }
    }
}
