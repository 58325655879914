import { ITGlueOrganization } from 'app/organizations/itglueorganization.model';
import { Connection } from 'app/connections';
import { FormControl, FormGroup } from '@angular/forms';
import { Option } from 'app/shared/itc/multiselect/multiselect.component';
import { AudITReportSettings } from './audIT/audIT.model';

export class NdpAssessment {
    AccountId: string;
    SiteId: number;
    Id: number;
    Name: string;
    IsActive: boolean;
    Selected?: boolean;
    CreatedDate: Date;
    ModifiedDate: Date;
    DataTypes: string[];
}

export class NdpReport {
    Module: string;
    Name: string;
    ModuleName: string;
    Description: string;
    Category: string;
    FileExtension: string;
    ProductCode: string;
    IsInform: boolean;
    IsBeta: boolean;
    IsSupportingBeta: boolean;
    IsChange: boolean;
    RequiredDependencies: string[];
    OptionalDependencies: string[];
    IsTrend: boolean;
}

export interface NdpReportGenerate extends NdpReport {
    IsSelected: boolean;
    IsSelectedForExportToITGlue: boolean;
    IsTempSelected: boolean;
    ReportType: string;
    AssociatedReportSets: NdpReportSet[];
    RequiredScanText: string;
    OptionalScanText: string;
}

export class NdpGenerateReportRequest {
    ReportsToGenerate: string[];
    ReportName: string;
    AssessmentId: number;
    AssessmentIdArchive: number;
    AssessmentIdsTrend: number[];
    AudITSettings: AudITReportSettings;
}

export class NdpUserReportSet {
    Id: number;
    AccountId: string;
    SiteId: number;
    Name: string;
    LastModified: Date;
    Reports: any[];
}

export type HMLRanking = 'High' | 'Medium' | 'Low';
export interface Issue {
    Category: string;
    Id: string;
    Name: string;
    Checked?: boolean;
    Severity?: HMLRanking;
    Probability?: HMLRanking;
    Score?: number;
    Enabled?: boolean;
}
export class NdpIssueOverride {
    Id: string;
    Severity?: HMLRanking;
    Probability?: HMLRanking;
    Score?: number;
    Enabled?: boolean;
}
export const NDP_USE_GLOBAL_ISSUE_OVERRIDES_PNAME: string = 'NDP_USE_GLOBAL_ISSUE_OVERRIDES';

export interface NdpCategory {
    Name: string;
    IsSelected: boolean;
}

export interface NdpReportSet extends NdpUserReportSet {
    Name: string;
    IsSelected: boolean;
}

export interface NdpTrendSetting {
    include: 'previous' | 'selection';
    previous: number;
    selection: number[];
}

export const FRIENDLY_FILETYPES: any = {
    NDF: 'Network Scan',
    SDF: 'Security Scan',
    CDF: 'Computer Scan',
    EDF: 'Exchange Scan',
    DDF: 'SQL Server Scan',
    AWS: 'AWS Scan',
    MDF: 'Mobile Security Scan',
    LDF: 'Logon Data File',
    AZDF: 'Microsoft Azure Scan',
    ADF: 'Microsoft Cloud Scan',
    VUL: 'External Vulnerability Scan',
    IVUL: 'Internal Vulnerability Scan',
    DWID: 'Dark Web Scan',
    DUC: 'Datto Unified Continuity Scan',
    IDF: 'InForm',
};

export interface Computer extends BaseComputer {
    HasCdf: boolean;
}

export interface NdpComputer extends BaseComputer {
    HasNdf: boolean;
    NumberOfCdf: number;
}

export interface BaseComputer {
    Name: string;
    Ip: string;
}

export interface NdpScanFile {
    'content-length': string;
    'filename': string;
    'lastmodified': string;
    'iscyberscan'?: string;
    'iseuiscan'?: string;
    'scancontents'?: string;
    'importsource'?: string;
}

export interface ScanObj {
    fileid: string;
    filename: string;
    filetype: string;
    lastmodified: string;
}

export type NdpState = 'empty' | 'active' | 'archive';

export type CloudTypes = 'aws' | 'ms';
export interface AwsCredentials {
    awsId?: string;
    awsSecret?: string;
    awsIdEnc?: string;
    awsSecretEnc?: string;
}
export interface AzureCredentials {
    msType: 'Azure' | 'OAUTH';
    msClient?: string;
    msSecret?: string;
    msTenant?: string;
    msClientEnc?: string;
    msSecretEnc?: string;
    msTenantEnc?: string;
    msGcc?: boolean;
}

export const scanTypes = [
    'lan_ad',
    'lan_wg',
    'azure',
    'aws',
    'computer_local',
    'computer_push',
    'exchange',
    'sql',
    'extvuln',
] as const;
export type ScanType = (typeof scanTypes)[number];
export const typeNames = {
    lan_ad: 'LAN Scan (Active Directory)',
    lan_wg: 'LAN Scan (Workgroup)',
    azure: 'Microsoft Cloud Scan',
    aws: 'AWS Cloud Scan',
    computer_local: 'Computer Scan (Local on Remote Data Collector)',
    computer_push: 'Computer Scan (Push Deploy)',
    exchange: 'Exchange Scan',
    sql: 'SQL Scan',
    extvuln: 'External Vulnerability Scan',
};
export interface ScanConfigs {
    lan_ad: any;
    lan_wg: any;
    computer_push: any;
    computer_local: any;
    aws: any;
    azure: any;
    exchange: any;
    sql: any;
    extvuln: any;
}
export interface ScanToggle {
    lan: boolean;
    computer: boolean;
    aws: boolean;
    azure: boolean;
    exchange: boolean;
    sql: boolean;
    extvuln: boolean;
}
export interface LanScanAdvancedOptions {
    skipAD: boolean;
    skipNetwork: boolean;
    skipLog: boolean;
    skipDHCP: boolean;
    skipTrace: boolean;
    skipWHOIS: boolean;
    skipLevel23: boolean;
    skipADComputer: boolean;
    skipSQL: boolean;
    skipLogon: boolean;
    skipVMware: boolean;
    skipSpeed: boolean;
    skipDW: boolean;
    skipHost: boolean;
}

export interface AutomationJob {
    Id: number;
    AccountId: string;
    SiteId: number;
    SiteName: string;
    RobotId: string;
    Type: 'SCAN' | 'REPORT' | 'EXPORT';
    Label: string;
    ConfigJson: string;
    ScheduledActionIds: number[];
    ScheduleCron: string;
    ScheduleOneTime: boolean;
    ScheduleTimeZone: string;
    NextRun: Date;
    Status: string;
    Computer: string;
}

export class ExchangeScanCredentials {
    Exchange: string;
    UseLegacy: boolean;
    Username: string;
    Password: string;
    EncryptedPassword: string;
    UsePs1Override: boolean;
    Ps1Override: string;
    Organization: string;
    AppId: string;
    Thumbprint: string;
    ApplianceId: string;
    SiteName: string;
}

export interface SqlCredentials {
    server?: string;
    port?: string;
    username?: string;
    password?: string;
    passwordEnc?: string;
}

export class LoaderModel {
    title: string;
    text: string;
    guid: string;
}

export interface ReportAutomationConfig {
    reportCodes: string[];
    connectors: string[];
    labels: string[];
    vulscanSites: string[];
    maxScanAge: number;
    emailSubject: string;
    emailRecipients: string[];
    emailAttach: boolean;
    emailAttachPwd: string;
    encemailAttachPwd: string;
    clientPortalSetName: string;
    clientPortalAppend: boolean;
    taskLabel: string;
    schedule: Schedule;
    guid: string;
    //Export ITGlue
    dateLocation: string;
    attachSiteName: boolean;
    reportFolderName: string;
    delimiter: string;
    itGlueOrganization: ITGlueOrganization;
    itGlueConnection: Connection;
    itGlueReportCodes: string[];
}

export interface ExportAutomationConfig {
    connectors: string[];
    exportConfig: ExportConfig;
    exportType: 'ticket' | 'config' | 'contact';
    connectionId: number;
    taskLabel: string;
    schedule: Schedule;
    guid: string;
}

export class ExportNameValuePair {
    Name: string;
    Value: any;
}

export class ExportConfig {
    SelectedModules?: string[];
    Psa?: string;
    Username?: string;
    Password?: string;
    ConnectionCompany?: string;
    Url?: string;
    Tenant?: string;
    CreateNewConfigurationItems?: boolean;
    UpdateExistingRecordsWithSameReferenceTitle?: boolean;
    Company?: ExportNameValuePair;
    SourceProduct?: ExportNameValuePair;
    AccountLocation?: ExportNameValuePair;
    MapComputers?: ExportNameValuePair;
    MapServers?: ExportNameValuePair;
    MapNonADDevices?: ExportNameValuePair;
    MapPrinters?: ExportNameValuePair;
    MapSqlServers?: ExportNameValuePair;
    MapExchangeServers?: ExportNameValuePair;
    OverwriteFirstName?: boolean;
    OverwriteLastName?: boolean;
    OverwritePhone?: boolean;
    OverwriteMobilePhone?: boolean;
    OverwriteAlternatePhone?: boolean;
    OverwriteCity?: boolean;
    OverwriteState?: boolean;
    OverwriteZipCode?: boolean;
    Representative?: ExportNameValuePair;
    CreateNewContact?: boolean;
    UpdateExistingContact?: boolean;
    CreateMultipleTickets?: boolean;
    OnlyExportNewIssues?: boolean;
    WorkType?: ExportNameValuePair;
    AssignedResource?: ExportNameValuePair;
    Role?: ExportNameValuePair;
    DueDateTime?: number;
    IssueType?: ExportNameValuePair;
    SubIssueType?: ExportNameValuePair;
    Queue?: ExportNameValuePair;
    Priority?: ExportNameValuePair;
    Status?: ExportNameValuePair;
    Source?: ExportNameValuePair;
    Contract?: ExportNameValuePair;
    ServiceBoard?: ExportNameValuePair;
    ServiceType?: ExportNameValuePair;
    SubServiceType?: ExportNameValuePair;
    ServiceItem?: ExportNameValuePair;
    Contact?: ExportNameValuePair;
    ExportContacts?: boolean;
    ExportComputers?: boolean;
    ExportFlexibleAssets?: boolean;
    ExcludeServerFeaturesAndOptions?: boolean;
    ExcludeNetworkShares?: boolean;
    ExcludeStartupPrograms?: boolean;
    ExcludeWindowsServices?: boolean;
    ExcludeInstalledApplications?: boolean;
    IncludeMSCloudOrganizations?: boolean;
    IncludeMSCloudPartnerRelationships?: boolean;
    IncludeMSCloudDomains?: boolean;
    IncludeMSCloudApplications?: boolean;
    IncludeMSCloudProxyAddresses?: boolean;
    IncludeMSCloudGroups?: boolean;
    IncludeMSCloudOnPremiseSyncedGroups?: boolean;
    IncludeMSCloudUsers?: boolean;
    IncludeMSCloudGuestUsers?: boolean;
    IncludeMSCloudDevices?: boolean;
    IncludeAwsEc2Instances?: boolean;
    IncludeAwsS3Buckets?: boolean;
    IncludeAwsRdsInstances?: boolean;
    IncludeAwsEnabledServices?: boolean;
    IncludeAwsIamUsers?: boolean;
    IncludeAwsRegions?: boolean;
}

export class Schedule {
    TaskRepeatInterval: string = '';
    TaskRepeatPeriod: string = '';
    TaskRepeatMonth: string = '';
    TaskRepeatTime: Date = null;
    TaskRepeatTimeHHMM: string = '';
    TimeZoneId: string = null;
}

export interface LanScanAdSaveObj extends LanScanWGSaveObj {
    additionalCredentials: any[];
    domainGatherAll: boolean;
    domainNodes: any;
}

export interface LanScanWGSaveObj {
    credentials: any[];
    ipRanges: string[];
    excludedIPs: string[];
    communityStrings: string[];
    vmWare: any[];
    externalDomains: string[];
    scanDarkWeb: boolean;
    advancedOptions: LanScanAdvancedOptions;
    snmpNonPing: boolean;
    snmpTimeout: number;
}

export interface ComputerScanAdvancedOptions {
    maliciousDownloadValidation: boolean;
    detectStoredBrowserCredentials: boolean;
    scanHostComputer: boolean;
    sensitiveDataScan: boolean;
    PII: boolean;
    PCI: boolean;
    PD: boolean;
    HIPAA: boolean;
    onlyScanDocsAndDownloadFolders: boolean;
    ZIP: boolean;
    PDF: boolean;
    skipPortTest: boolean;
    skipURLTest: boolean;
    skipWifiData: boolean;
    skipRSOP: boolean;
    skipScreenSaver: boolean;
    skipUSBDetection: boolean;
    skipInternetAccess: boolean;
    skipLogonData: boolean;
    pdfTimeout: number;
    skipSecurity: boolean;
}
export interface ComputerScanLocalSaveObj {
    advancedOptions: ComputerScanAdvancedOptions;
}

export interface Connector {
    Label: string;
    Id: number;
    Selected?: boolean;
}

export interface NdpIssueAndException {
    CategoryIndex?: number;
    TopicIndex?: number;
    IsSelected: boolean;
    Category: string;
    Issue: string;
    Exception: string;
}

export interface ReportAugmentRow {
    ProductName: string;
    ServiceName: string;
    Codes: string;
}

export interface AutomationSettings {
    MergeSettings: AutomationMergeSettings;
    DataExploreSettings: AutomationDataExploreSettings;
}

export interface AutomationMergeSettings {
    MergeType: 'PrimaryDomain' | 'SpecifiedDomain' | 'NoMerge';
    Domain?: string;
}

export interface AutomationDataExploreSettings {
    Frequency: 'None' | 'Day' | 'Week';
    Days: ('Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat')[];
    MaxScanAge: number;
    Connectors: string[];
    Labels: string[];
}

export interface AssessmentScanDataIssue {
    IsSelected: boolean;
    Title: string;
    Issue: string;
    Recommendation: string;
    Details: string[];
}

export class AssessmentAutotaskIssueTicket {
    Connection: string = '0';
    Account: string = '0';
    WorkType: string = '0';
    AssignedResource: string = '0';
    Role: string = '0';
    DueTime: string = '';
    IssueType: string = '0';
    SubIssueType: string = '0';
    Queue: string = '0';
    Priority: string = '0';
    Status: string = '0';
    Source: string = '0';
    Contract: string = '0';
    SeparateTicket: boolean = true;
    SendEmail: boolean;
    Emails: string[];
    Issues: AssessmentScanDataIssue[];
}

export class AssessmentBmsIssueTicket {
    Connection: string = '0';
    Account: string = '0';
    Location: string = '0';
    Contact: string = '0';
    TicketSource: string = '0';
    TicketType: string = '0';
    Priority: string = '0';
    Status: string = '0';
    Queue: string = '0';
    PrimaryAssignee: string = '0';
    SeparateTicket: boolean = true;
    SendEmail: boolean;
    Emails: string[];
    CreatedDate: string;
    Issues: AssessmentScanDataIssue[];
}

export class AssessmentConnectWiseIssueTicket {
    Connection: string = '0';
    Company: string = '';
    ServiceBoardId: string = '0';
    ServiceBoard: string = '';
    StatusId: string = '0';
    Status: string = '';
    ServiceTypeId: string = '0';
    ServiceType: string = '';
    SubServiceType: string = '';
    ServiceItem: string = '';
    Source: string = '';
    Priority: string = '';
    SeparateTicket: boolean = true;
    SendEmail: boolean;
    Emails: string[];
    Issues: AssessmentScanDataIssue[];
}

export interface AssessmentScanDataConfigurations {
    IsSelected: boolean;
    Name: string;
    Type: string;
    Status: string;
}

export class AssessmentAutotaskConfigTicket {
    Connection: string = '0';
    Account: string = '0';
    Computer: string = '0';
    Server: string = '0';
    Printer: string = '0';
    NonAd: string = '0';
    SqlServer: string = '0';
    ExchangeServer: string = '0';
    NewConfig: boolean = true;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Configs: AssessmentScanDataConfigurations[];
}

export class AssessmentBmsConfigTicket {
    Connection: string = '0';
    Account: string = '0';
    Location: string = '0';
    Computer: string = '0';
    Server: string = '0';
    Printer: string = '0';
    NonAd: string = '0';
    SqlServer: string = '0';
    ExchangeServer: string = '0';
    NewConfig: boolean = true;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Configs: AssessmentScanDataConfigurations[];
}

export class AssessmentConnectWiseConfigTicket {
    Connection: string = '0';
    Company: string = '0';
    Computer: string = '0';
    Server: string = '0';
    Printer: string = '0';
    NonAd: string = '0';
    SqlServer: string = '0';
    ExchangeServer: string = '0';
    NewConfig: boolean = true;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Configs: AssessmentScanDataConfigurations[];
}

export interface AssessmentScanDataContacts {
    IsSelected: boolean;
    Name: string;
    PrimarySmtpAddress: string;
    Status: string;
    FirstName: string;
    LastName: string;
    DisplayName: string;
    IsValid: string;
    City: string;
    Fax: string;
    MobilePhone: string;
    Phone: string;
    Title: string;
    OtherPhone: string;
    ZipCode: string;
    State: string;
}

export class AssessmentAutotaskContactTicket {
    Connection: string = '0';
    Account: string = '0';
    FirstName: boolean = false;
    LastName: boolean = false;
    Phone: boolean = false;
    MobilePhone: boolean = false;
    AlternatePhone: boolean = false;
    City: boolean = false;
    State: boolean = false;
    ZipCode: boolean = false;
    NewContact: boolean = false;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Contacts: AssessmentScanDataContacts[];
}

export class AssessmentBmsContactTicket {
    Connection: string = '0';
    Account: string = '0';
    Location: string = '0';
    FirstName: boolean = false;
    LastName: boolean = false;
    Phone: boolean = false;
    NewContact: boolean = false;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Contacts: AssessmentScanDataContacts[];
}

export class AssessmentConnectWiseContactTicket {
    Connection: string = '0';
    Company: string = '0';
    FirstName: boolean = false;
    LastName: boolean = false;
    Phone: boolean = false;
    MobilePhone: boolean = false;
    AlternatePhone: boolean = false;
    City: boolean = false;
    State: boolean = false;
    ZipCode: boolean = false;
    NewContact: boolean = false;
    UpdateExisting: boolean = false;
    SendEmail: boolean = true;
    Emails: string[];
    Contacts: AssessmentScanDataContacts[];
}

export interface DashboardData {
    Assessment?: DashboardDomainInfo;
    Automation?: DashboardDomainInfo;
}

export interface DashboardDomainInfo {
    items: any;
    MetricsData: any;
    notes: any;
    summaryInfo: any;
    state: string;
}

export interface InForm {
    id: number;
    name: string;
    instruction: string;
    category: string;
    form: string;
    lastModified: Date | string;
}

export type MergeType = 'PrimaryDomain' | 'SpecifiedDomain' | 'NoMerge';
export type Frequency = 'None' | 'Day' | 'Week';
export interface Label {
    Label: string;
    Value: string;
    Selected?: boolean;
}
export interface AutomationSettingsForm {
    MergeSettings: FormGroup<{
        MergeType: FormControl<MergeType | string>;
        Domain: FormControl<string>;
    }>;
    DataExploreSettings: FormGroup<{
        Frequency: FormControl<Frequency | string>;
        FrequencyInfo: FormGroup<{
            Days: FormGroup<{
                Sun: FormControl<boolean>;
                Mon: FormControl<boolean>;
                Tue: FormControl<boolean>;
                Wed: FormControl<boolean>;
                Thu: FormControl<boolean>;
                Fri: FormControl<boolean>;
                Sat: FormControl<boolean>;
            }>;
            doNotUseScans: FormControl<boolean>;
            MaxScanAge: FormControl<number | null>;
        }>;
        Connectors: FormControl<Option[]>;
        Labels: FormControl<Option[]>;
    }>;
}
