<div class="input-container">
    <sm-input
        name="searchInput"
        debounce="200"
        [control]="searchKeyControl"
        (input)="searchSites()"
        class="searchInput"
        placeholder="Search keywords..."></sm-input>

    <div class="filterSitesCheck">
        <strong>{{ filteredSites?.length }} of {{ allSites?.length }}</strong>
        Results
    </div>
</div>
<div>
    <table
        class="itc-table scrollable sortable top-aligned"
        sortable-table
        sortColumn="Name"
        sortDirection="asc"
        id="allSitesTable"
        (sorted)="onSortSites($event)">
        <thead>
            <tr>
                <th sortable-column="Name">Site</th>
                <th sortable-column="Organization">Organization</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let site of filteredSites" [class.expiredSite]="site.IsLicenseInactive">
                <td
                    style="max-width: 200px"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        *ngIf="!IsNDPro(site)"
                        class="linkColor breakWord"
                        [routerLink]="!site.IsLicenseInactive ? site.GoToSiteLink : null"
                        (click)="siteDashboardService.goToSiteDashboard(site)">
                        {{ site.Name }}
                    </a>
                    <a
                        *ngIf="IsNDPro(site)"
                        class="linkColor breakWord"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToNDPAssessment(site)">
                        {{ site.Name }}
                    </a>
                    <i
                        *ngIf="site.IsCSPro"
                        style="margin-top: -8px"
                        class="compliance-manager2-cspro site_type"></i>
                </td>
                <td
                    class="truncateStyle"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        style="color: black"
                        class="breakWord"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToOrganization(site)">
                        {{ site.Organization }}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>

    <empty-state
        icon="results"
        title="No Sites Found"
        text="No sites were found matching the given criteria."
        *ngIf="!filteredSites?.length"></empty-state>
</div>
