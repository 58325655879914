import { Component, Input } from '@angular/core';
import { RFT_URL } from 'app/core/constants';

@Component({
    selector: 'help-button',
    template: `
        <div
            class="help-button"
            [tooltip]="text"
            [ttLink]="url ? { url: this.rft_url + this.url, text: linkText, newTab: true } : null"
            [ttTitle]="title"
            ttPosition="bottom">
            <fa-icon [icon]="icon" [fixedWidth]="true"></fa-icon>
        </div>
    `,
    styles: [
        ':host { display: inline-block; }',
        'div.help-button { display: inline-block; padding: 4px; margin-left: 4px; cursor: pointer; }',
        'fa-icon { display: flex; width: 16px; height: 16px; }',
    ],
})
export class HelpButtonComponent {
    @Input() title: string;
    @Input() text: string;
    @Input() noLink = false;
    @Input() url: string;
    @Input() linkText: string = 'Learn More';
    @Input() command: () => void;
    @Input() icon = 'circle-question';

    rft_url: string = RFT_URL;

    constructor() {}
}
