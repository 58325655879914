import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { orgNameRegExp } from './regex.constants';

@Directive({
    selector: '[orgNameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: OrgNameValidatorDirective, multi: true }],
})
export class OrgNameValidatorDirective implements Validator {
    @Input() orgNameCurrent: string;
    @Input() existingNames: string[] = [];
    regex: RegExp = orgNameRegExp;
    maxLength = 50;

    validate(control: AbstractControl): ValidationErrors | null {
        let orgErrors = {};

        if (this.orgNameCurrent && control.value == this.orgNameCurrent) {
            orgErrors['sameoldorg'] = true;
        }

        if (control.value?.charAt(0) === ' ') {
            orgErrors['cannotStartWithSpace'] = true;
        }

        if (!this.regex.test(control.value)) {
            orgErrors['regex'] = true;
        }

        if (control.value?.length > this.maxLength) {
            orgErrors['length'] = true;
        }

        if (this.existingNames.includes(control.value?.toLowerCase().trim())) {
            orgErrors['duplicate'] = true;
        }

        return Object.keys(orgErrors).length ? orgErrors : null;
    }
}
