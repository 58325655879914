import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autoGrowInput]',
    standalone: true,
})
export class AutoGrowDirective {
    constructor(private el: ElementRef) {}

    @Input() autoGrowMaxWidth: number = 0;

    contentHolder: HTMLElement;

    ngOnInit(): void {
        this.el.nativeElement.style.minWidth = '100px';
        this.el.nativeElement.addEventListener('input', (evt) => {
            this.resizeInput();
        });
        let span = document.createElement('span');
        span.style.display = 'none';
        span.style.font = window.getComputedStyle(this.el.nativeElement).font;
        this.contentHolder = this.el.nativeElement.parentNode.insertBefore(
            span,
            this.el.nativeElement
        );

        setTimeout(() => {
            this.resizeInput();
        }, 1);
    }

    resizeInput(): void {
        this.contentHolder.innerText =
            this.el.nativeElement.value || this.el.nativeElement.placeholder;
        this.contentHolder.style.display = 'inline';
        if (this.contentHolder.offsetWidth < this.autoGrowMaxWidth - 100) {
            this.el.nativeElement.style.width = this.contentHolder.offsetWidth + 8 + 'px';
        } else {
            this.el.nativeElement.style.width = this.autoGrowMaxWidth - 70 + 'px';
        }
        this.contentHolder.style.display = 'none';
    }
}
