export class Site {
    public Id: number;
    public AccountId: string;
    public Name: string;
    public Type: string;

    public Appliances?: string[];
    public Todos?: any[];
    public LastScan?: Date;
    public UserRoles?: string;
    public OpenTodos?: number;

    public HasLicenseViolation?: boolean;
    public LicensedNumber?: number;
    public DeployedNumber?: number;
    public IsIndoc?: boolean;
    public IsCSPro?: boolean;

    public HasFusebill?: boolean;

    public CYSURANCE_PID?: string;

    public TypeDesc?: string;
    public Organization?: string;
    public OrganizationId?: number;

    public IsLicenseInactive?: boolean;
    public LicenseEndDate?: Date;
    public Tooltip?: string;
}

export interface SiteTypeIcon {
    title: string;
    class: string;
}
