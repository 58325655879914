import {
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ItcSidebarComponent } from '../../itc/itc-sidebar/itc-sidebar.component';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UtilityService } from '../../utility.service';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { distinctUntilChanged, Observable, Subject, switchMap, takeUntil, zip } from 'rxjs';
import { FormGroupControls } from '../../form-group-controls.type';
import { DropdownItem } from '../../primeNG/primengDropdown.directive';
import {
    ItcCollapsibleComponent,
    ItcCollapsiblePanelComponent,
} from '../../itc/collapsible/itc-collapsible.component';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';
import { NotificationService } from '../../itc/notification/notification.service';
import {
    DailyScan,
    DaysOfWeek,
    DiscoveryAgentSidebarData,
} from '../models/discovery-agent-sidebar-data.model';
import { DiscoveryAgentService } from '../services/discovery-agent.service';
import { DiscoveryAgentScanDataAdapterService } from '../services/discovery-agent-scan-data-adapter.service';
import { DiscoveryAgentSettingsService } from '../services/discovery-agent-settings.service';

@Component({
    selector: 'sds-discovery-agent-scan-schedule-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        ItcSidebarComponent,
        ReactiveFormsModule,
        FormsModule,
        ItcCollapsibleComponent,
        ItcCollapsiblePanelComponent,
    ],
    templateUrl: './discovery-agent-scan-schedule-sidebar.component.html',
    styleUrls: ['./discovery-agent-scan-schedule-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiscoveryAgentScanScheduleSidebarComponent implements OnInit, OnDestroy {
    @Input({ required: true }) organizationId: number;
    @Input({ required: true }) discoveryAgentSidebarData: DiscoveryAgentSidebarData;
    @Input() hasAnyAgentsInstalled = true;
    @ViewChild('sidebar') sidebar: ItcSidebarComponent;
    @ViewChild('deepFilesScanDayGuard', { static: true })
    deepFilesScanDayGuard: SemanticModalComponent;
    availableWeekdays: DropdownItem[];
    isRotatingInstallKey = false;
    selectedDeepFilesScanDay: string;
    isDailyWeekDayChangeConfirmed = false;
    applyingScanSchedule = false;
    dailyScanActiveIndex = -1;
    deepFileScanActiveIndex = -1;

    private readonly weekdays: DropdownItem[] = [
        {
            label: 'Sunday',
            value: 'sun',
        },
        {
            label: 'Monday',
            value: 'mon',
        },
        {
            label: 'Tuesday',
            value: 'tue',
        },
        {
            label: 'Wednesday',
            value: 'wed',
        },
        {
            label: 'Thursday',
            value: 'thu',
        },
        {
            label: 'Friday',
            value: 'fri',
        },
        {
            label: 'Saturday',
            value: 'sat',
        },
    ];
    private readonly formBuilder = inject(FormBuilder);
    private readonly utilityService = inject(UtilityService);
    private readonly organizationService = inject(OrganizationsService);
    private readonly discoveryAgentService = inject(DiscoveryAgentService);
    private readonly notificationService = inject(NotificationService);
    private readonly discoveryAgentScanDataAdapterService = inject(
        DiscoveryAgentScanDataAdapterService
    );
    private readonly discoveryAgentSettingsService = inject(DiscoveryAgentSettingsService);
    private readonly unsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.whenDailyScanIsDisabled_DeepFileScan_And_DailyScanOptions_AreDisabled();
        this.whenDeepFileScanIsDisabled_DeepFileScanOptions_AreDisabled();
        this.whenPdfScanIsUnchecked_PdfTimeout_IsDisabled();
        this.whenUrlTestingIsUnchecked_URLTestingOptions_AreUnchecked_AndDisabled();
        this.whenNoUrlTestingOptionsAreChecked_URLTesting_IsDisabled();
        this.whenDailyScanDaysAreChanged$().subscribe((days) => {
            this.changeDeepFileScanDays_when_deepFileScanDay_is_dailyScanDay_turning_off(days);
            this.turnDailyScanOff_when_noneOfDaysSelected(days);
        });
        this.setDeepFileScanSelectedDayLabel();

        this.buildForm();
    }

    open() {
        this.sidebar.open();
    }

    async copyInstallKey() {
        await this.utilityService.copyToClipboard(this.scanScheduleForm.controls.installKey.value, {
            showNotification: true,
        });
    }

    cancelDayListChange() {
        this.scanScheduleForm
            .get(`dailyScan.days.${this.scanScheduleForm.get('deepFileScan.dayOfWeek').value}`)
            .setValue(true);
        this.deepFilesScanDayGuard.hide();
    }

    confirmDayListChange() {
        this.isDailyWeekDayChangeConfirmed = true;
        this.deepFilesScanDayGuard.hide();
        this.scanScheduleForm
            .get(`dailyScan.days.${this.scanScheduleForm.get('deepFileScan.dayOfWeek').value}`)
            .setValue(false);
    }

    async rotateInstallKey(): Promise<void> {
        this.isRotatingInstallKey = true;
        const newInstallKey = await this.organizationService.rotateInstallKey(this.organizationId);
        this.discoveryAgentSettingsService.installKey = newInstallKey;
        this.scanScheduleForm.controls.installKey.setValue(newInstallKey);
        this.isRotatingInstallKey = false;
    }

    onSubmit() {
        this.applyingScanSchedule = true;
        const formData = this.scanScheduleForm.getRawValue() as DiscoveryAgentSidebarData;

        const dailyScanDays =
            this.discoveryAgentScanDataAdapterService.mapDiscoveryAgentSidebarDataToWeeklyScanDayString(
                formData
            );
        const deepFileScanDay = this.discoveryAgentScanDataAdapterService.mapWeekDayValueToLabel(
            formData.deepFileScan.dayOfWeek
        );
        const deepFileScanSettings =
            this.discoveryAgentScanDataAdapterService.mapDiscoveryAgentSidebarDataToDeepFileScanSettingsString(
                formData
            );
        const advancedOptions =
            this.discoveryAgentScanDataAdapterService.mapDiscoveryAgentSidebarDataToAdvancedOptions(
                formData
            );

        this.discoveryAgentService
            .scheduleAgentForOrg$(
                this.organizationId,
                dailyScanDays,
                deepFileScanSettings,
                deepFileScanDay,
                formData.deepFileScan.isEnabled,
                formData.dailyScan.performVulScan,
                advancedOptions
            )
            .pipe(
                switchMap(() =>
                    zip([
                        this.discoveryAgentService.getDeepFileScanSettings$(this.organizationId),
                        this.discoveryAgentService.getDeepFileScanSettingsStatus$(
                            this.organizationId
                        ),
                    ])
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: ([deepFileSettings, deepFileSchedule]) => {
                    this.discoveryAgentSettingsService.deepFileScanSettings = deepFileSettings;
                    this.discoveryAgentSettingsService.deepFileScanSchedule = deepFileSchedule;

                    this.notificationService.toast.success(
                        'Success',
                        'Discovery Agent scheduled successfully'
                    );
                    this.applyingScanSchedule = false;
                    this.hideSidebar();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'Failed to schedule Discovery Agent'
                    );
                    this.applyingScanSchedule = false;
                },
            });
    }

    hideSidebar() {
        this.sidebar.close();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private readonly dailyScanValidator: ValidatorFn = (
        scanScheduleForm: FormGroup<FormGroupControls<DailyScan>>
    ) => {
        if (scanScheduleForm.get('isEnabled').value) {
            const dailyScanDays = scanScheduleForm.get('days').value;
            const isAnyDaySelected = Object.values(dailyScanDays).some((day) => day);
            if (!isAnyDaySelected) {
                return { noDaysSelected: true };
            }
        }
        return null;
    };

    scanScheduleForm: FormGroup<FormGroupControls<DiscoveryAgentSidebarData>> =
        this.formBuilder.group({
            installKey: ['', Validators.required],
            dailyScan: this.formBuilder.group(
                {
                    isEnabled: [false],
                    days: this.formBuilder.group({
                        sun: [false],
                        mon: [false],
                        tue: [false],
                        wed: [false],
                        thu: [false],
                        fri: [false],
                        sat: [false],
                    }),
                    performVulScan: [false],
                },
                { validators: this.dailyScanValidator }
            ),
            deepFileScan: this.formBuilder.group({
                isEnabled: [false],
                dayOfWeek: null as null | 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat',
                options: this.formBuilder.group({
                    computerDriveEncryptionDetection: [false],
                    ePhi: [false],
                    cardholder: [false],
                    gdprPersonal: [false],
                    pii: [false],
                    zipScan: [false],
                    pdfScan: [false],
                    pdfTimeoutValue: [5, [Validators.min(1), Validators.max(60)]],
                }),
            }),
            advancedOptions: this.formBuilder.group({
                urlTesting: this.formBuilder.group({
                    isEnabled: [false],
                    options: this.formBuilder.group({
                        entertainment: [false],
                        pornography: [false],
                        shareware: [false],
                        socialMedia: [false],
                        warez: [false],
                        webMail: [false],
                    }),
                }),
                portTesting: [false],
                wifiData: [false],
                policies: [false],
                screenSaving: [false],
                usb: [false],
                internetAccessibility: [false],
                loginDetails: [false],
            }),
        });

    private buildForm(): void {
        this.scanScheduleForm.setValue(this.discoveryAgentSidebarData);
    }

    private setDeepFileScanSelectedDayLabel() {
        this.scanScheduleForm
            .get('deepFileScan.dayOfWeek')
            .valueChanges.pipe(filterIsNotUndefined(), takeUntil(this.unsubscribe$))
            .subscribe((day) => {
                this.selectedDeepFilesScanDay = this.weekdays.find(
                    (weekday) => weekday.value === day
                ).label;
            });
    }

    private whenUrlTestingIsUnchecked_URLTestingOptions_AreUnchecked_AndDisabled() {
        this.scanScheduleForm
            .get('advancedOptions.urlTesting.isEnabled')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((isEnabled) => {
                Object.keys(
                    (this.scanScheduleForm.get('advancedOptions.urlTesting.options') as FormGroup)
                        .controls
                ).forEach((key) => {
                    const control = this.scanScheduleForm.get(
                        `advancedOptions.urlTesting.options.${key}`
                    ) as unknown as FormControl<boolean>;
                    if (key !== 'isEnabled' && control !== null) {
                        if (isEnabled) {
                            control.setValue(true);
                            control.enable();
                        } else {
                            control.setValue(false, { emitEvent: false });
                        }
                    }
                });
            });
    }

    private whenNoUrlTestingOptionsAreChecked_URLTesting_IsDisabled() {
        this.scanScheduleForm
            .get('advancedOptions.urlTesting.options')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((options) => {
                const isAnyOptionChecked = Object.values(options).some((option) => option);
                if (!isAnyOptionChecked) {
                    this.scanScheduleForm
                        .get('advancedOptions.urlTesting.isEnabled')
                        .setValue(false, { emitEvent: false });
                } else if (
                    !this.scanScheduleForm.get('advancedOptions.urlTesting.isEnabled').value
                ) {
                    this.scanScheduleForm
                        .get('advancedOptions.urlTesting.isEnabled')
                        .setValue(true, { emitEvent: false });
                }
            });
    }

    private whenPdfScanIsUnchecked_PdfTimeout_IsDisabled() {
        this.scanScheduleForm
            .get('deepFileScan.options.pdfScan')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((isPdfScanEnabled) => {
                const pdfTimeoutValueControl = this.scanScheduleForm.get(
                    'deepFileScan.options.pdfTimeoutValue'
                );
                if (isPdfScanEnabled) {
                    pdfTimeoutValueControl.enable();
                } else {
                    pdfTimeoutValueControl.disable();
                }
            });
    }

    private whenDeepFileScanIsDisabled_DeepFileScanOptions_AreDisabled() {
        this.scanScheduleForm
            .get('deepFileScan.isEnabled')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((isDeepFileScanEnabled) => {
                Object.keys(this.scanScheduleForm.controls.deepFileScan.controls).forEach((key) => {
                    const control = this.scanScheduleForm.get(`deepFileScan.${key}`);
                    if (key !== 'isEnabled' && control !== null) {
                        isDeepFileScanEnabled ? control.enable() : control.disable();
                    }
                });
                if (
                    isDeepFileScanEnabled &&
                    this.scanScheduleForm.get('deepFileScan.dayOfWeek').value === null
                ) {
                    this.scanScheduleForm
                        .get('deepFileScan.dayOfWeek')
                        .setValue(
                            this.availableWeekdays[0].value as
                                | 'sun'
                                | 'mon'
                                | 'tue'
                                | 'wed'
                                | 'thu'
                                | 'fri'
                                | 'sat'
                        );
                }
            });
    }

    private whenDailyScanDaysAreChanged$(): Observable<DaysOfWeek> {
        return this.scanScheduleForm
            .get('dailyScan.days')
            .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$));
    }

    private changeDeepFileScanDays_when_deepFileScanDay_is_dailyScanDay_turning_off(
        days: DaysOfWeek
    ): void {
        const deepFileScanDay = this.scanScheduleForm.get('deepFileScan.dayOfWeek').value;

        if (days[deepFileScanDay] === false) {
            if (this.isDailyWeekDayChangeConfirmed) {
                this.isDailyWeekDayChangeConfirmed = false;
                const firstDailyScanDay = Object.keys(days).find((day) => days[day]) || null;
                this.scanScheduleForm
                    .get('deepFileScan.dayOfWeek')
                    .setValue(
                        firstDailyScanDay as
                            | null
                            | 'sun'
                            | 'mon'
                            | 'tue'
                            | 'wed'
                            | 'thu'
                            | 'fri'
                            | 'sat'
                    );
            } else {
                this.deepFilesScanDayGuard.show();
                return;
            }
        }
    }

    private turnDailyScanOff_when_noneOfDaysSelected(days: DaysOfWeek) {
        const isAnyDaySelected = Object.values(days).some((day) => day);
        if (!isAnyDaySelected) {
            this.scanScheduleForm.get('dailyScan.isEnabled').setValue(false);
            this.scanScheduleForm.get('dailyScan.performVulScan').setValue(false);
            this.scanScheduleForm.get('dailyScan.performVulScan').disable();
        } else {
            this.scanScheduleForm.get('dailyScan.performVulScan').enable();
            this.scanScheduleForm.get('deepFileScan.isEnabled').enable();
        }

        this.availableWeekdays = this.weekdays.filter((day) => days[day.value as string]);
    }

    private whenDailyScanIsDisabled_DeepFileScan_And_DailyScanOptions_AreDisabled() {
        this.scanScheduleForm
            .get('dailyScan.isEnabled')
            .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe((isDailyScanEnabled) => {
                const deepFileScanEnabledControl =
                    this.scanScheduleForm.get('deepFileScan.isEnabled');
                const deepFileScanScheduleControl =
                    this.scanScheduleForm.get('deepFileScan.dayOfWeek');
                if (isDailyScanEnabled) {
                    const dailyScanDays = this.scanScheduleForm.get('dailyScan.days').value;
                    const isAnyDaySelected = Object.values(dailyScanDays).some((day) => day);
                    if (isAnyDaySelected) {
                        deepFileScanEnabledControl.enable();
                    }
                } else {
                    deepFileScanEnabledControl.setValue(false);
                    deepFileScanScheduleControl.setValue(null);
                    deepFileScanEnabledControl.disable();
                }

                Object.keys(this.scanScheduleForm.controls.dailyScan.controls).forEach((key) => {
                    const control = this.scanScheduleForm.get(`dailyScan.${key}`);
                    if (key !== 'isEnabled' && control !== null) {
                        if (control instanceof FormGroup) {
                            Object.keys(control.controls).forEach((subKey) => {
                                const subControl = control.get(subKey);
                                if (subControl instanceof FormControl) {
                                    if (isDailyScanEnabled) {
                                        subControl.enable({ emitEvent: false });
                                    } else {
                                        subControl.setValue(false, { emitEvent: false });
                                        subControl.disable({ emitEvent: false });
                                    }
                                }
                            });
                        } else if (control instanceof FormControl) {
                            if (isDailyScanEnabled) {
                                control.enable({ emitEvent: false });
                            } else {
                                control.setValue(false, { emitEvent: false });
                                control.disable({ emitEvent: false });
                            }
                        }
                    }
                });
            });
    }
}
