<itc-button
    type="primary"
    (onclick)="start()"
    *ngIf="displayType == 'button'"
    [disabled]="disabled">
    {{ displayLabel }}
</itc-button>
<a
    (click)="start()"
    style="cursor: pointer"
    *ngIf="displayType == 'link'"
    [class.disabled]="disabled">
    {{ displayLabel }}
</a>
<itc-button
    type="icon"
    tooltip="Manage"
    [icon]="icon"
    (onclick)="start()"
    *ngIf="displayType == 'icon'"
    [disabled]="disabled"></itc-button>

<sm-modal
    class="manage-modal sm"
    [title]="manageType == '' ? 'Manage Appliance ' + appId : modalTitle"
    #manageCollectorModal>
    <modal-content>
        <sds-loader [complete]="!manageRunning">
            <div *ngIf="isHostedEVS">
                <div style="width: 100%; overflow-x: auto">
                    <table class="itc-table"
                           *ngIf="pagedHevsScans.length"
                           sortable-table
                           sortColumn="{{'StartDateTime'}}"
                           sortDirection="asc"
                           (sorted)="sortReports($event)">
                        <thead>
                            <tr>
                                <th sortable-column="Id">
                                    ID
                                </th>
                                <th sortable-column="Targets">
                                    Target
                                </th>
                                <th sortable-column="Type">
                                    Scan Type
                                </th>
                                <th sortable-column="StartDateTime">
                                    Start Date
                                    <span class="UTCHeaderRef">UTC</span>
                                </th>
                                <th sortable-column="EndDateTime">
                                    End Date
                                    <span class="UTCHeaderRef">UTC</span>
                                </th>
                                <th sortable-column="Status">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let report of pagedHevsScans; let ind = index"
                                (click)="rowSelect($event, report)"
                                [class.selected]="report.flag === true">
                                <td class="collapsing">
                                    {{ report.Id }}
                                </td>
                                <td class="collapsing">
                                    {{ report.Targets }}
                                </td>

                                <td class="collapsing">
                                    Hosted External Vulnerability Scan
                                </td>
                                <!--    <td  class="collapsing">
                                   {{ report.MB < 0.01 ? '<0.01' : report.MB }}
                               </td> -->
                                <td class="collapsing">
                                    {{ report.StartDateTime | date : timeDateFormat }}
                                </td>
                                <td class="collapsing">
                                    {{
                                        report.Status != 'Done' && report.Status != 'Completed'
                                            ? '-'
                                            : (report.EndDateTime | date : timeDateFormat)
                                    }}
                                </td>
                                <td class="collapsing">
                                    {{ report.Status }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <itc-paginator [data]="reports"
                                   (onPage)="pagedHevsScans = $event"></itc-paginator>

                    <itc-button type="primary"
                                icon="fa-arrow-down-to-square"
                                (onclick)="downloadHEVSScans()"
                                [loading]="activeDownload == 'true'"
                                [disabled]="
                            getSelected().length == 0 ||
                            activeDownload != '' ||
                            activeDelete != '' ||
                            isUnqueuing ||
                            isCancelling ||
                            isQueuedOrRunning
                        ">
                        Download Selected
                    </itc-button>
                    <itc-button type="primary"
                                icon="fa-trash-can"
                                (onclick)="deleteHEVSScans()"
                                [loading]="activeDelete == 'true'"
                                [disabled]="
                            getSelected().length == 0 || activeDownload != '' || activeDelete != ''
                        ">
                        Delete Selected
                    </itc-button>
                    <itc-button type="primary"
                                icon="fa-arrow-left-rotate"
                                (onclick)="unqueueHEVSScans()"
                                [loading]="isUnqueuing"
                                [disabled]="
                        !canCancelQueue() ||
                           activeDownload != '' ||
                            activeDelete != '' ||
                            isUnqueuing ||
                            isCancelling
                        ">
                        Remove from Queue
                    </itc-button>
                    <itc-button type="primary"
                                icon="fa-ban"
                                (onclick)="cancelHEVSScans()"
                                [loading]="isCancelling"
                                [disabled]="
                        !canCancelScan() ||
                        !isQueuedOrRunning &&
                            activeDownload != '' ||
                            activeDelete != '' ||
                            isUnqueuing ||
                            isCancelling
                        ">
                        Cancel Selected
                    </itc-button>
                </div>

            </div>
            <div *ngIf="!isHostedEVS">
                <div *ngIf="manageType == ''">
                    <sds-data-collector-info [apps]="app"
                                             [showManageSection]="false"
                                             *ngIf="showAppInfo"></sds-data-collector-info>
                    <table class="ui very basic collapsing table" style="margin: 0; max-width: 500px">
                        <tbody>
                            <tr>
                                <td style="width: 100%">Update</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Update')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 100%">Set Auto-Update</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Set Auto-Update')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr *ngIf="
                                    appType != 'Remote Data Collector' && appType != 'Discovery Agent'
                                ">
                                <td style="width: 100%">Health Check</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Health Check')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 100%">Download Logs</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Download Logs')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr *ngIf="appType != 'Discovery Agent'">
                                <td style="width: 100%">Manage Scans</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Manage Scans')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr *ngIf="
                                    appType != 'Remote Data Collector' &&
                                    appType != 'Cyber Hawk' &&
                                    appType != 'Discovery Agent'
                                ">
                                <td style="width: 100%">Manage Reports</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Manage Reports')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                            <tr style="border: none">
                                <td style="width: 100%">Download Audit</td>
                                <td>
                                    <itc-button type="primary" (onclick)="runManage('Download Audit')">
                                        RUN
                                    </itc-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="manageType == 'Update' || manageType == 'Set Auto-Update'">
                    {{ modalContent }}
                </div>
                <div *ngIf="manageType == 'Health Check'">
                    <div class="ui secondary segment"
                         style="white-space: pre-wrap; max-height: 500px; overflow-y: scroll"
                         #healthCheckText>
                        {{ modalContent }}
                    </div>
                    <itc-button icon="fa-clipboard" type="primary" (onclick)="copy(modalContent)">
                        Copy to Clipboard
                    </itc-button>
                </div>
                <div *ngIf="manageType == 'Manage Scans' || manageType == 'Manage Reports'">
                    <div style="width: 100%; overflow-x: auto">
                        <table class="itc-table"
                               *ngIf="pagedReports && pagedReports.length"
                               sortable-table
                               sortColumn="{{
                                manageType == 'Manage Reports' ? 'setId' : 'StartDateTime'
                            }}"
                               sortDirection="asc"
                               (sorted)="sortReports($event)">
                            <thead>
                                <tr>
                                    <th *ngIf="manageType == 'Manage Reports'" sortable-column="setId">
                                        Report Set ID
                                    </th>
                                    <th *ngIf="manageType == 'Manage Reports'"
                                        sortable-column="reportName">
                                        Report Name
                                    </th>
                                    <th *ngIf="manageType == 'Manage Reports'"
                                        sortable-column="sizeBytes">
                                        Size (MB)
                                    </th>
                                    <th *ngIf="manageType == 'Manage Reports'"
                                        sortable-column="siteName">
                                        Site Name
                                    </th>
                                    <th *ngIf="manageType == 'Manage Reports'"
                                        sortable-column="timeUtc">
                                        Create Time (UTC)
                                    </th>
                                    <th *ngIf="manageType == 'Manage Reports'"></th>
                                    <th *ngIf="manageType == 'Manage Reports'"></th>

                                    <th *ngIf="manageType == 'Manage Scans'" sortable-column="Id">
                                        ID
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'"
                                        sortable-column="AssessmentName">
                                        Assessment Name
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'" sortable-column="Type">
                                        Scan Type
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'" sortable-column="MB">
                                        Size (MB)
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'"
                                        sortable-column="StartDateTime">
                                        Start Date
                                        <span class="UTCHeaderRef">UTC</span>
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'"
                                        sortable-column="EndDateTime">
                                        End Date
                                        <span class="UTCHeaderRef">UTC</span>
                                    </th>
                                    <th *ngIf="manageType == 'Manage Scans'" sortable-column="Status">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let report of pagedReports; let ind = index"
                                    (click)="rowSelect($event, report)"
                                    [class.selected]="report.flag === true">
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        {{ report.setId }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        {{ report.reportName }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        {{ report.sizeBytes }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        {{ report.siteName }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        {{ report.timeUtc | date : timeDateFormat }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        <itc-button type="icon"
                                                    icon="fa-arrow-down-to-square"
                                                    tooltip="download"
                                                    (onclick)="downloadReport(report)"
                                                    [loading]="activeDownload == report.reportPath"
                                                    [disabled]="
                                                activeDownload != '' || activeDelete != ''
                                            "></itc-button>
                                    </td>
                                    <td *ngIf="manageType == 'Manage Reports'" class="collapsing">
                                        <itc-button type="icon"
                                                    icon="fa-trash-can"
                                                    tooltip="delete"
                                                    (onclick)="deleteReport(report)"
                                                    [loading]="activeDelete == report.reportPath"
                                                    [disabled]="
                                                activeDownload != '' || activeDelete != ''
                                            "></itc-button>
                                    </td>

                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.Id }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.AssessmentName }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.Type.replace('Detector', 'Cyber Hawk') }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.MB < 0.01 ? '<0.01' : report.MB }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.StartDateTime | date : timeDateFormat }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{
                                            report.Status != 'Done' && report.Status != 'Completed'
                                                ? '-'
                                                : (report.EndDateTime | date : timeDateFormat)
                                        }}
                                    </td>
                                    <td *ngIf="manageType == 'Manage Scans'" class="collapsing">
                                        {{ report.Status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <itc-paginator [data]="reports"
                                       (onPage)="pagedReports = $event"></itc-paginator>

                        <itc-button type="primary"
                                    icon="fa-arrow-down-to-square"
                                    (onclick)="downloadScans()"
                                    *ngIf="manageType == 'Manage Scans' && !manageTarget.startsWith('RDC')"
                                    [loading]="activeDownload == 'true'"
                                    [disabled]="
                                getSelected().length == 0 ||
                                activeDownload != '' ||
                                activeDelete != '' ||
                                isUnqueuing ||
                                isCancelling ||
                                isQueuedOrRunning
                            ">
                            Download Selected
                        </itc-button>
                        <itc-button type="primary"
                                    icon="fa-trash-can"
                                    (onclick)="deleteScans()"
                                    *ngIf="manageType == 'Manage Scans'"
                                    [loading]="activeDelete == 'true'"
                                    tooltip="Scans should be deleted within the Automation section of Network Detective Pro."
                                    [ttHidden]="!disableDelete"
                                    [disabled]="
                                getSelected().length == 0 || activeDownload != '' || activeDelete != '' || disableDelete
                            ">
                            Delete Selected
                        </itc-button>
                        <itc-button type="primary"
                                    icon="fa-arrow-left-rotate"
                                    (onclick)="unqueueScans()"
                                    *ngIf="manageType == 'Manage Scans'"
                                    [loading]="isUnqueuing"
                                    [disabled]="
                                !canCancelQueue() ||
                                activeDownload != '' ||
                                activeDelete != '' ||
                                isUnqueuing ||
                                isCancelling
                            ">
                            Remove from Queue
                        </itc-button>
                        <itc-button type="primary"
                                    icon="fa-ban"
                                    (onclick)="cancelScans()"
                                    *ngIf="manageType == 'Manage Scans'"
                                    [loading]="isCancelling"
                                    [disabled]="
                                !canCancelScan() ||
                                activeDownload != '' ||
                                activeDelete != '' ||
                                isUnqueuing ||
                                isCancelling
                            ">
                            Cancel Selected
                        </itc-button>
                    </div>
                </div>
            </div>

        </sds-loader>
        <p *ngIf="manageRunning" style="position:absolute;left:24px;top:44px">
            Connecting to Agent. This may take a few minutes.
        </p>
    </modal-content>
    <modal-actions>
        <itc-button
            type="secondary"
            *ngIf="manageType != '' && manageType != 'HEVS'"
            (onclick)="showOverview()" >
            Back
        </itc-button>
        <itc-button
            type="secondary"
            (onclick)="manageCollectorModal.hide()"
            [disabled]="manageRunning">
            Close
        </itc-button>
        <itc-button
            type="primary"
            (onclick)="doManage()"
            *ngIf="manageType == 'Update' || manageType == 'Set Auto-Update'"
            [disabled]="manageRunning">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>
