import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'accessRole',
    standalone: true,
})
export class AccessRoleTextPipe implements PipeTransform {
    transform(value: number, isSiteUser?: string): string {
        switch (value) {
            case 1:
                return 'Admin';
            case 2:
                return 'Restricted';
            case 3:
                return 'All';
            case 33:
                return 'Site Restricted';
            default:
                return isSiteUser ? 'Site Restricted' : 'None';
        }
    }
}
