import { Injectable } from '@angular/core';
import { AuthHttp } from 'app/core/auth';
import { environment } from 'environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { DateTime } from '@grapecity/wijmo';
import { KvsReport } from '../site/vulnerability-scanner/reports/kvs-reports.component';
import { ItcFileUploaderService } from 'app/shared/itc';
import { KvsScanResultsReq } from './kvs-scan-results-req.model';
import { OpenPortsRequest, OpenPortsData } from '../site/vulnerability-scanner/kvs.model';
import { DiscoveryAgentAdvancedOptions } from '../../shared/discovery-agent-legacy/discovery-agent-advanced-options.model';

@Injectable({ providedIn: 'root' })
export class KvsService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private fileUploader: ItcFileUploaderService) {
        this.apiUrl = environment.apiUrl;
    }

    isKVSPortal() {
        const subdomain = window.location.hostname.split('.').shift().toLowerCase();
        // way to force KVS for testing on local
        if (localStorage.getItem('forceKVS')) {
            return true;
        }
        return (
            subdomain == 'vulnerabilityscanner' || subdomain.indexOf('vulnerabilityscanner-') == 0
        );
    }

    getFalsePositiveRules(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `FalsePositiveRules/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    addFalsePositiveRule(newRule: any, offset: number): Promise<any> {
        const url: string = this.apiUrl + 'FalsePositiveRule';
        return this.http
            .post(url, { newRule: newRule, offset })
            .toPromise()
            .then((res) => res);
    }

    updateFalsePositiveRule(oldRule: any, newRule: any): Promise<any> {
        const url: string = this.apiUrl + 'FalsePositiveRule';
        let rules = { oldRule: oldRule, newRule: newRule, offset: new Date().getTimezoneOffset() };
        return this.http
            .put(url, rules)
            .toPromise()
            .then((res) => res);
    }

    deleteFalsePositiveRule(newRule: any): Promise<any> {
        const url: string = this.apiUrl + 'DeleteFalsePositiveRule';
        return this.http
            .post(url, { newRule: newRule, offset: new Date().getTimezoneOffset() })
            .toPromise()
            .then((res) => res);
    }

    getKvsAppliances(siteId?: number): Promise<any> {
        siteId = !siteId ? -1 : siteId;
        const url: string = this.apiUrl + `KvsAppliances/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsScanTasks(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsScanTasks/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getHEVSLicense(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${siteId}/HEVSLicense`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    addKvsScanTasks(newScanTask: any): Promise<any> {
        const url: string = this.apiUrl + `KvsScanTasks`;
        return this.http
            .post(url, newScanTask)
            .toPromise()
            .then((res) => res);
    }

    getKvsNotificationTasks(siteId: number): Promise<any> {
        const url: string =
            this.apiUrl + `KvsNotificationTasks/${siteId}/${new Date().getTimezoneOffset()}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    addKvsNotificationTask(notificationTask: any): Promise<any> {
        const url: string = this.apiUrl + `KvsNotificationTask`;
        return this.http
            .post(url, notificationTask)
            .toPromise()
            .then((res) => res);
    }

    updateKvsNotificationTaskConnections(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `UpdateNotificationTaskConnections/${siteId}`;
        return this.http
            .post(url, new Date().getTimezoneOffset())
            .toPromise()
            .then((res) => res);
    }

    getKvsVulnerabilitiesOverTime(siteId: number, days: number, scanType?: string): Promise<any> {
        scanType = typeof scanType !== 'undefined' && scanType !== '' ? scanType : 'ALL';
        const url: string =
            this.apiUrl + `KvsVulnerabilitiesOverTime/${siteId}/${days}/${scanType}`;
        return this.http
            .get(url, scanType)
            .toPromise()
            .then((res) => res);
    }

    getKvsHighestRisk(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsHighestRisk/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsHighestCve(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsHighestCve/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsMostRecentVulnerabilities(siteId: number, scanType?: string): Promise<any> {
        scanType = typeof scanType !== 'undefined' && scanType !== '' ? scanType : 'ALL';
        const url: string = this.apiUrl + `KvsMostRecentVulnerabilities/${siteId}/${scanType}`;
        return this.http
            .get(url, scanType)
            .toPromise()
            .then((res) => res);
    }

    getKvsScanResultsByIssue(siteId: number, maxDaysOld: number, minCvss: number): Promise<any> {
        const url: string = this.apiUrl + `KvsScanResultsByIssue`;
        let req = { SiteId: siteId, MaxDaysOld: maxDaysOld, MinCvss: minCvss };
        return lastValueFrom(this.http.post(url, req)).then((res) => res);
    }

    getKvsScanResultsByDevice(siteId: number, maxDaysOld: number, minCvss: number): Promise<any> {
        const url: string = this.apiUrl + `KvsScanResultsByDevice`;
        let req = { SiteId: siteId, MaxDaysOld: maxDaysOld, MinCvss: minCvss };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    getKvsScanResults(
        siteId: number,
        maxDaysOld: number,
        minDate: Date = new Date('2010-01-01'),
        maxDate: Date = new Date('2010-01-01')
    ): Promise<any> {
        const url: string = this.apiUrl + `KvsScanResults`;
        let req: KvsScanResultsReq = {
            SiteId: siteId,
            MaxDaysOld: maxDaysOld,
            MinDate: minDate,
            MaxDate: maxDate,
            IncludeScanBytes: false,
            AppliancePrefixes: [],
            IncludeDetails: true,
        };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    getKvsScanResultsWithAppliancePrefixes(
        siteId: number,
        maxDaysOld: number,
        appliancePrefixes: string[]
    ): Promise<any> {
        let minDate: Date = new Date('2010-01-01');
        let maxDate: Date = new Date('2010-01-01');
        const url: string = this.apiUrl + `KvsScanResults`;
        let req: KvsScanResultsReq = {
            SiteId: siteId,
            MaxDaysOld: maxDaysOld,
            AppliancePrefixes: appliancePrefixes,
            MinDate: minDate,
            MaxDate: maxDate,
            IncludeScanBytes: false,
            IncludeDetails: false,
        };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    getKvsTrendReport(
        siteId: number,
        startDate: Date,
        endDate: Date,
        isMonthly: boolean
    ): Promise<{ minDate: Date; reports: KvsReport[] }> {
        const url: string =
            this.apiUrl +
            `KvsTrendReport/${siteId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&isMonthly=${isMonthly}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    kvsRunNow(scheduleId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsRunNow/${scheduleId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    kvsRunNowNotification(notificationId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsRunNowNotification/${notificationId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    deleteScanTask(scanTask: any): Promise<any> {
        const url: string = this.apiUrl + 'DeleteScanTask';
        return this.http
            .post(url, scanTask)
            .toPromise()
            .then((res) => res);
    }

    deleteNotificationTask(notificationTask: any): Promise<any> {
        const url: string = this.apiUrl + 'DeleteNotificationTask';
        return this.http
            .post(url, notificationTask)
            .toPromise()
            .then((res) => res);
    }

    getCredentials(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsCredential/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    deleteCredential(credential: any): Promise<any> {
        const url: string = this.apiUrl + 'DeleteKvsCredential';
        return this.http
            .post(url, credential)
            .toPromise()
            .then((res) => res);
    }

    addCredential(credential: any): Promise<any> {
        const url = this.apiUrl + `KvsCredential`;
        return this.http
            .post(url, credential)
            .toPromise()
            .then((res) => res);
    }

    updateCredential(oldCredential: any, newCredential: any): Promise<any> {
        const url = this.apiUrl + `KvsCredential`;
        let credentials = { oldCredential: oldCredential, newCredential: newCredential };
        return this.http
            .put(url, credentials)
            .toPromise()
            .then((res) => res);
    }
    getCredentialsMinimal(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsCredential/${siteId}/Minimal`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsCredentialScanTasks(siteId: number, credentialId: string) {
        const url = this.apiUrl + `KvsCredential/ScanTasks/${siteId}/${credentialId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    generateVulScanReport(
        siteId: number,
        scanResults: any[],
        resultsFilter: any,
        statistics: any[],
        byIssue: boolean,
        resultQuery: any = {}
    ): Promise<any> {
        const url: string = this.apiUrl + `KvsGenerateReport/${siteId}`;
        let postBody = {
            scanResults: scanResults,
            resultsFilter: resultsFilter,
            statistics: statistics,
            byIssue: byIssue,
            resultQuery: resultQuery,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    generateVulScanExcelReport(
        siteId: number,
        scanResults: any[],
        resultsFilter: any,
        byIssue: boolean
    ): Promise<any> {
        const url = this.apiUrl + `KvsGenerateExcelReport/${siteId}`;
        let postBody = {
            scanResults: scanResults,
            resultsFilter: resultsFilter,
            byIssue: byIssue,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    generateVulScanCsvReport(siteId: number, scanResults: any[], byIssue: boolean): Promise<any> {
        const url = this.apiUrl + `KvsGenerateCsvReport/${siteId}`;
        const postBody = {
            scanResults: scanResults,
            byIssue: byIssue,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    getRCOrganizations(checkConnectionData: any): Promise<any> {
        const url: string = this.apiUrl + `RCOrganizations`;
        return this.http
            .post(url, checkConnectionData)
            .toPromise()
            .then((res) => res as any);
    }

    getCustomProfiles(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsCustomProfile/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    deleteCustomProfile(profile: any): Promise<any> {
        const url: string = this.apiUrl + 'DeleteKvsCustomProfile';
        return this.http
            .post(url, profile)
            .toPromise()
            .then((res) => res);
    }

    addCustomProfile(profile: any): Promise<any> {
        const url = this.apiUrl + `KvsCustomProfile`;
        return this.http
            .post(url, profile)
            .toPromise()
            .then((res) => res);
    }

    updateCustomProfile(
        oldCustomProfile: any,
        newCustomProfile: any,
        scanTasks: any[]
    ): Promise<any> {
        const url = this.apiUrl + `KvsCustomProfile`;
        let profiles = {
            oldCustomProfile: oldCustomProfile,
            newCustomProfile: newCustomProfile,
            scanTasks: scanTasks,
        };
        return this.http
            .put(url, profiles)
            .toPromise()
            .then((res) => res);
    }

    getCustomProfilesMinimal(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `KvsCustomProfile/${siteId}/Minimal`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsCustomProfileScanTasks(siteId: number, profileId: string) {
        const url = this.apiUrl + `KvsCustomProfile/ScanTasks/${siteId}/${profileId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKvsRivsScanTasks(siteId: number) {
        const url = this.apiUrl + `KvsRivs/ScanTasks/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateTask(tasks: any, profile: any, site: any): Promise<any> {
        const data = { tasks: tasks, profile: profile, siteId: site };
        const url: string = this.apiUrl + `updateTask`;
        return this.http
            .put(url, data)
            .toPromise()
            .then((res) => res);
    }

    getSiteKey(siteId: number): Promise<string> {
        const url = this.apiUrl + `VulscanAgent/GetSiteKey/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    generateSiteKey(siteId: number): Promise<string> {
        const url = this.apiUrl + `VulscanAgent/GenerateSiteKey/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    agentRunNow(siteId: number, agentId: string): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${siteId}/${agentId}/Run`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    scheduleAgent(siteId: number, days: string): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${siteId}/Schedule`;
        return lastValueFrom(this.http.post(url, { scheduleDays: days })).then((res) => res);
    }

    scheduleAgentForOrg(
        organizationId: number,
        days: string,
        deepScanSettings: string,
        weeklyScanDay: string,
        deepFileScanStatus: boolean,
        is3ppScan: boolean,
        advancedOptions: DiscoveryAgentAdvancedOptions
    ): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${organizationId}/ScheduleForOrg`;
        return lastValueFrom(
            this.http.post(url, {
                scheduleDays: days,
                deepScanSettings: deepScanSettings,
                weeklyScanDay: weeklyScanDay,
                deepFileScanStatus: deepFileScanStatus,
                is3ppScan: is3ppScan,
                advancedOptions: advancedOptions,
            })
        ).then((res) => res);
    }

    deprovisionAgent(siteId: number, agentId: string): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${siteId}/${agentId}/Deprovision`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    deprovisionAgentForOrg(agentId: string): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${agentId}/DeprovisionForOrg`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    agentRunNowOrg(
        organizationId: number,
        agentId: string,
        deepScanSettings: string,
        is3ppScan: boolean
    ): Promise<any> {
        const url = this.apiUrl + `VulscanAgent/${organizationId}/${agentId}/RunDiscoveryAgent`;
        return lastValueFrom(
            this.http.post(url, { deepScanSettings: deepScanSettings, is3ppScan: is3ppScan })
        ).then((res) => res);
    }

    cancelAllScans(agentId: string, organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/${agentId}/CancelAllScans`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    generateVulScanTrendingReport(
        siteId: number,
        type: string,
        datas: any[],
        cvssFilter: any,
        minDate: Date
    ): Promise<any> {
        const url: string = this.apiUrl + `KvsGenerateTrendingReport/${siteId}`;
        let postBody = {
            type: type,
            datas: datas,
            cvssFilter: cvssFilter,
            minDate: minDate,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    generateVulScanOpenPortsReport(siteId: number, reportData: any[]): Promise<any> {
        const url: string = this.apiUrl + `KvsGenerateOpenPortsReport/${siteId}`;
        let postBody = {
            data: reportData,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    getVulscanTicketsByIntg(siteId: number, connType: string): Promise<any> {
        const url: string = this.apiUrl + `KvsTicket/${siteId}/${connType}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getVulscanTicketIssuesByIntg(siteId: number, connType: string): Promise<any> {
        const url: string = this.apiUrl + `KvsTicket/byIssue/${siteId}/${connType}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getLastTrendingReportDetailDate(siteId: number, timeRange: string): Promise<Date> {
        const url = this.apiUrl + `KvsGetLastTrendingReportDetailDate/${siteId}/${timeRange}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    checkVulScanProxyVpnConfigExists(siteId: number) {
        const url = this.apiUrl + `vulscan/rivs/vpn-config/existence/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    uploadProxyConfiguration(siteId: number, formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile(`vulscan/proxy/vpn-config/${siteId}/upload`, formdata);
    }

    checkProxyConfiguration(siteId: number) {
        const url = this.apiUrl + `vulscan/proxy/vpn-config/check/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCurrentProxyConfig(siteId: number) {
        const url = this.apiUrl + `vulscan/proxy/vpn-config/current/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    saveProxyConfiguration(siteId: number) {
        const url = this.apiUrl + `vulscan/proxy/vpn-config/save/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    removeProxyConfiguration(siteId: number) {
        const url = this.apiUrl + `vulscan/proxy/vpn-config/remove/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getOpenPorts(openPortsRequest: OpenPortsRequest, siteId: number): Promise<OpenPortsData[]> {
        const url: string = this.apiUrl + `OpenPorts/${siteId}`;
        return lastValueFrom(this.http.post(url, openPortsRequest)).then((res) => res);
    }

    buyMoreHevsLicense(): Promise<boolean> {
        return lastValueFrom(this.http.get(`${this.apiUrl}BuyMoreHEVSLicense`));
    }
}

export class KvsTicket {
    public Id: number;
    public AccountId: string;
    public SiteId: string;
    public TicketNumber: string;
    public IntegrationTicketId: number;
    public PsaType: string;
    public OpenDate: DateTime;
    public Issues: KvsTicketIssue[];
    public BaseUrl: string;
}

export class KvsTicketIssue {
    public Oid: string;
    public Devices: KvsTicketDevice[];
}

export class KvsTicketDevice {
    public Ip: string;
    public Hostname: string;
    public MacAddress: string;
}

export class KvsTicketRow {
    public TicketId: number;
    public TicketNumber: string;
    public OpenDate: DateTime;
    public Oid: string;
    public Ip: string;
    public Hostname: string;
    public MacAddress: string;
    public DeviceIdentifier: string;
}
