import { NgModule } from '@angular/core';
import {
    CommonModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    LowerCasePipe,
    PercentPipe,
    UpperCasePipe,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CapitalizePipe } from './capitalize.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { MapToIterablePipe } from './map-to-iterable.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import { TruncatePipe } from './truncate.pipe';
import { UriComponentPipe } from './uri-component.pipe';
import { KeysPipe } from './keys.pipe';
import { nl2brPipe } from './nl2br.pipe';
import { SidebarLayoutComponent } from './sidebar/layout/sidebar-layout.component';
import { RiskScoreCardComponent } from './cards/risk-score-card/risk-score-card.component';
import { RelatedItemsCardComponent } from './cards/related-items-card/related-items-card.component';
import { ButtonComponent } from './button/button.component';
import { MessageComponent } from './message/message.component';
import { DataSearchFilterComponent } from './data-search-filter/data-search-filter.component';
import { LoaderComponent } from './loader/loader.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { PrettifyPipe } from './prettify.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { BreadcrumbTrailComponent } from './breadcrumbs/breadcrumb-trail.component';
import { InitDirective } from './init.directive';
import {
    SortableTableComponent,
    SortableTableDirective,
} from './sortable-table/sortable-table.component';
import { SortableColumnComponent } from './sortable-table/sortable-column.component';
import { ModalDebugComponent } from './modal-debug/modal-debug.component';
import { BadgedIconComponent } from './badged-icon/badged-icon.component';
import { ConnectorFilesComponent } from './connector-files/connector-files.component';
import { SiteEncodePipe } from './siteEncode.pipe';
import { SiteDecodePipe } from './siteDecode.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { OrgNameValidatorDirective } from './org-name-validator.directive';
import { SplitCamelCasePipe } from './split-camel-case.pipe';
import { getMaxDatePipe } from './getMaxDate.pipe';

/* Wijmo */
import { setLicenseKey } from '@grapecity/wijmo';
import { WjChartModule } from '@grapecity/wijmo.angular2.chart';
import { WjGaugeModule } from '@grapecity/wijmo.angular2.gauge';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjGridDetailModule } from '@grapecity/wijmo.angular2.grid.detail';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
/* End Wijmo */
/* Angular Material/CDK */
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AngularFuseJsModule } from './angular-fusejs/angular-fusejs.module';
import { TextareaAutoResizeDirective } from './textarea-auto-resize.directive';
import { InlineEditComponent } from './inline-edit/inline-edit.component';

/* Font Awesome */
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faAlien,
    faArrowDownToBracket,
    faArrowDownToLine,
    faArrowDownToSquare,
    faArrowLeft,
    faArrowLeftRotate,
    faArrowRight,
    faArrowRightRotate,
    faArrowRotateLeft,
    faArrowsRotate,
    faArrowUp,
    faArrowsUpDownLeftRight,
    faArrowUpRightFromSquare,
    faBan,
    faBell,
    faBoxArchive,
    faCalendar,
    faCalendarDay,
    faCamera,
    faCaretDown,
    faChartBar,
    faChartPie,
    faChartPieSimple,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleArrowDown,
    faCircleCheck,
    faCircleInfo,
    faCircleMinus,
    faCircleQuestion,
    faCircleUser,
    faCircleXmark,
    faClipboard,
    faClipboardListCheck,
    faClone,
    faCoffee,
    faCog,
    faComment,
    faCompress,
    faCopy,
    faDatabase,
    faDiagramNested,
    faDisplay,
    faDownload,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopesBulk,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFileCertificate,
    faFileChartColumn,
    faFileCode,
    faFileExcel,
    faFileExport,
    faFileImage,
    faFileLines,
    faFileWord,
    faFileZipper,
    faFloppyDisk,
    faFolder,
    faGear,
    faGlobe,
    faHardDrive,
    faHouseBlank,
    faInfoSquare,
    faKey,
    faLaptopMobile,
    faLink,
    faListCheck,
    faLock,
    faLockKeyhole,
    faMagnifyingGlass,
    faMailBulk,
    faMapMarkerAlt,
    faMemoCircleCheck,
    faMessageExclamation,
    faMessageDots,
    faMinus,
    faNote,
    faNoteSticky,
    faPaintBrush,
    faPaintBrushFine,
    faPaperclip,
    faPaperclipVertical,
    faPaperPlane,
    faPaste,
    faPause,
    faPencil,
    faPenToSquare,
    faPlay,
    faPlus,
    faPrint,
    faQuestionCircle,
    faRectangleXmark,
    faReply,
    faServer,
    faSitemap,
    faSparkles,
    faSquareArrowUpRight,
    faSquareCheck,
    faSquareChevronRight,
    faSquareLeft,
    faSquareList,
    faStar,
    faTableCellsLarge,
    faTag,
    faTerminal,
    faTimes,
    faTrash,
    faTrashCan,
    faTrashCanList,
    faTriangleExclamation,
    faUpload,
    faUser,
    faUserLock,
    faUserPlus,
    faUsers,
    faWifi,
    faX,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCaretDown as fasCaretDown,
    faCaretUp as fasCaretUp,
    faCircle as fasCircle,
    faCircle0 as fasCircle0,
    faCircle1 as fasCircle1,
    faCircle2 as fasCircle2,
    faCircle3 as fasCircle3,
    faCircle4 as fasCircle4,
    faCircle5 as fasCircle5,
    faCircle6 as fasCircle6,
    faCircle7 as fasCircle7,
    faCircle8 as fasCircle8,
    faCircle9 as fasCircle9,
    faCircleCheck as fasCircleCheck,
    faCircleHalfStroke as fasCircleHalfStroke,
    faCircleInfo as fasCirclInfo,
    faCircleXmark as fasCircleXmark,
} from '@fortawesome/pro-solid-svg-icons';
/* End Font Awesome */
/* ITC */
import {
    DropdownButtonComponent,
    DropdownButtonTagsDirective,
    FileListItemComponent,
    ItcAppLauncherComponent,
    ItcBulkActionsComponent,
    ItcButtonComponent,
    ItcCardComponent,
    ItcCardTagsDirective,
    ItcCheckboxComponent,
    ItcDropdownButtonComponent,
    ItcDropdownButtonTagsDirective,
    ItcExampleComponent,
    ItcFileUploaderComponent,
    ItcFileUploaderService,
    ItcInputComponent,
    ItcLoaderComponent,
    ItcPaginatorComponent,
    ItcPaginatorOldComponent,
    ItcProgressBarComponent,
    ItcRadioComponent,
    ItcSpinnerComponent,
    ItcTabComponent,
    ItcTabsComponent,
    ItcTabsService,
    ItcTagComponent,
    ItcTextareaComponent,
    ItcToggleComponent,
    ItcTooltipDirective,
    LegacyDonutChartComponent,
    NotificationModule,
    StatusIconComponent,
} from './itc';
/* End ITC */
import { DynamicPipe } from './dynamic.pipe';
import { accountDatePipe } from './accountDate.pipe';
import { accountDateTimePipe } from './accountDateTime.pipe';
import { RelativeTimePipe } from './relativeTime.pipe';
import { SemanticLegacyModule } from 'app/semantic-legacy/semantic-legacy.module';
import { ItcInlineEditComponent } from './itc/inline-edit/inline-edit.component';
import { ViewModeDirective } from './itc/inline-edit/view-mode.directive';
import { EditModeDirective } from './itc/inline-edit/edit-mode.directive';
import { EditableOnEnterDirective } from './itc/inline-edit/edit-on-enter.directive';
import { FocusableDirective } from './itc/inline-edit/focusable.directive';
import { DiscoveryAgentComponent } from './discovery-agent/discovery-agent.component';
import { DiscoveryAgentService } from './discovery-agent/discovery-agent.service';
import { DeviceIdentifierPipe } from './device-identifier.pipe';
import { HelpButtonComponent } from './help-button/help-button.component';
import { NotBlankValidator } from './not-blank.validator';
import { PsaTicketsComponent } from './psa-tickets/psa-tickets.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { NdpUserReportSetsPortableComponent } from './ndp-user-report-sets-portable/ndp-user-report-sets-portable.component';
import { ListReducePipe } from './list-reduce.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { PrimeNGDateFormattingPipe } from './primeNG/date-formatting.pipe';
import { MetricBoxComponent } from 'app/shared/dashboard/metric-box/metric-box.component';
import { MenuModule } from 'primeng/menu';
import { PrimengDropdownDirective } from './primeNG/primengDropdown.directive';
import { PrimengInputNumberDirective } from './primeNG/primengInputNumber.directive';
import { validIpAddressValidator } from './ipaddress.validator';
import { ValidDomainValidator } from './domain.validator';
import { PrimengMultiselectDirective } from './primeNG/primengMultiselect.directive';
import { TruncateTextDirective } from './truncateText.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { RFTColorPickerComponent } from './color-picker/color-picker.component';
import { FilterObjectsPipe } from './filterObjects.pipe';
import { ShowHideComponent } from './show-hide/show-hide.component';
import { TextareaCharLimitComponent } from './textarea-char-limit/textarea-char-limit.component';

import { ItcProgressTrackerModule } from './itc/progress-tracker/progress-tracker.module';
import { PageMenuComponent } from './itc/page-menu/page-menu.component';
import { NoDuplicatesValidatorDirective } from './validation/no-duplicates-validator.directive';
import { CannotStartWithSpaceValidatorDirective } from './validation/cannot-start-with-space-validator.directive';
import { InputNumberModule } from 'primeng/inputnumber';
import { ManageApplianceComponent } from 'app/sites/shared/manage-appliance/manage-appliance.component';
import { NdpIssueOverridesPortableComponent } from './ndp-issue-overrides-portable/ndp-issue-overrides-portable.component';
import { TableModule } from 'primeng/table';
import {
    ValidExternalScanTargetValidator,
    ValidInternalScanTargetValidator,
} from './validation/scan-target-validator.directive';
import { OrderByPipe } from './orderBy.pipe';
import { IpRangeComponent } from '../sites/shared/ip-range/ip-range.component';
import { SNMPEntriesComponent } from 'app/sites/shared/snmp-entries/snmp-entries.component';
import { PortValidator } from './port.validator';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { FilenameValidator } from './filename.validator';
import { TaskCheckboxComponent } from './check-tasklist/task-checkbox.component';
import { TaskChecklistComponent } from './check-tasklist/task-checklist.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AccordionModule } from 'primeng/accordion';
import { RftMultiselectComponent } from './itc/multiselect/multiselect.component';
import { AccessRoleTextPipe } from './access-role-text.pipe';
import { RoleNamePipe } from './role-name.pipe';
import { CmoStatusCardComponent } from 'app/sites/site/compliance-manager/compliance-monitor/shared/cmo-status-card/cmo-status-card.component';
import { DonutChartComponent } from './itc/donut-chart/donut-chart.component';
import { ChartModule } from 'primeng/chart';
import { CWCompaniesLookupComponent } from './cw-companies-lookup/cw-companies-lookup.component';
import { Base64UrlService } from 'app/shared/base64url.service';

setLicenseKey(
    'RapidFireTools,591595783983247#B0cI9RiwiI34zZx9mWSBHdQhUY9VVdSVTS6sCa4lFSlRGSlBTdsx6TZh6MvhkNmV6LChjd8MXTTl7bXVmaalzZhZWONFnbQRjawtWbiBFaOBTZYJjezBTZ5JXa6ZXUmh7N8F6VMJ6M6gWVkZUNMhXe0llbiJ7NrdUaroFM7xENvJGMrIlaEZTV4IUauRmbIRWRudzd48mM7g7YMRUR8AjewYHZ0VUN8oWdB3EeRtiM0BDWhNDcSNjV6pXcyJkRNREa7sEaEljSrkkQw94Vu56TOl7RhBXcrsST8MWZiZkMhJ7cVV6cnNWezMndyRHNWJXOFxEZUdzUwVnULlnT9VEb6lWQMBTYzkEMJxUUsdzVHJFN4kle4ImT4YWTxYlSzonS7sSdatyVNh6b6Undjp7cjJXNBtCZwI5TldkZmVzUPp7L7Y5ckFzQUp6Lkp6bsBlQjJ6KJN5bJ5mSWV5TPllI0IyUiwiICljNyIjR7cjI0ICSiwCM4QTO8UDO8ITM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiAjMwITNwACNwgDMyIDMyIiOiQncDJCLiMHbv3GVlJXaGRWawFmUiojIh94QiwiI7QjMzgTOzgzN5kTNxkTNiojIklkIs4XXbpjInxmZiwiIyYnMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbYJbe'
);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SemanticLegacyModule,
        WjChartModule,
        WjGaugeModule,
        WjGridModule,
        WjGridDetailModule,
        WjInputModule,
        WjNavModule,
        CdkTableModule,
        MatSortModule,
        ScrollingModule,
        FontAwesomeModule,
        AngularFuseJsModule,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        OverlayPanelModule,
        NotificationModule,
        MenuModule,
        ItcProgressTrackerModule,
        MultiSelectModule,
        ColorPickerModule,
        InputNumberModule,
        TableModule,
        ItcButtonComponent,
        ItcCheckboxComponent,
        IpRangeComponent,
        SNMPEntriesComponent,
        ItcFileUploaderComponent,
        FileListItemComponent,
        ItcProgressBarComponent,
        MultiselectComponent,
        ItcTagComponent,
        SelectButtonModule,
        AccordionModule,
        ItcInputComponent,
        ItcLoaderComponent,
        ItcTooltipDirective,
        ItcRadioComponent,
        RftMultiselectComponent,
        TextareaAutoResizeDirective,
        AccessRoleTextPipe,
        RoleNamePipe,
        EmptyStateComponent,
        CmoStatusCardComponent,
        ChartModule,
        FileSizePipe,
        RelativeTimePipe,
        ItcCardComponent,
        ItcCardTagsDirective,
        ItcTabsComponent,
        ItcTabComponent,
        DonutChartComponent,
        TimeAgoPipe,
        TruncateTextDirective,
        InlineEditComponent,
        NotBlankValidator,
        SidebarLayoutComponent,
        BreadcrumbTrailComponent,
        NoDuplicatesValidatorDirective,
    ],
    declarations: [
        CapitalizePipe,
        LinkifyPipe,
        MapToIterablePipe,
        OrderByPipe,
        PaginatorComponent,
        TruncatePipe,
        KeysPipe,
        UriComponentPipe,
        nl2brPipe,
        RiskScoreCardComponent,
        RelatedItemsCardComponent,
        ButtonComponent,
        DataSearchFilterComponent,
        MessageComponent,
        LoaderComponent,
        PrettifyPipe,
        SafeHtmlPipe,
        InitDirective,
        SortableTableComponent,
        SortableTableDirective,
        SortableColumnComponent,
        ModalDebugComponent,
        BadgedIconComponent,
        ConnectorFilesComponent,
        ItcAppLauncherComponent,
        LegacyDonutChartComponent,
        ItcTextareaComponent,
        ItcToggleComponent,
        ItcExampleComponent,
        ItcPaginatorComponent,
        ItcPaginatorOldComponent,
        ItcDropdownButtonComponent,
        ItcDropdownButtonTagsDirective,
        StatusIconComponent,
        DropdownButtonComponent,
        DropdownButtonTagsDirective,
        DynamicPipe,
        accountDatePipe,
        accountDateTimePipe,
        ItcInlineEditComponent,
        ViewModeDirective,
        EditModeDirective,
        EditableOnEnterDirective,
        FocusableDirective,
        DiscoveryAgentComponent,
        SiteEncodePipe,
        SiteDecodePipe,
        OrgNameValidatorDirective,
        DeviceIdentifierPipe,
        HelpButtonComponent,
        PsaTicketsComponent,
        SplitCamelCasePipe,
        NdpUserReportSetsPortableComponent,
        ListReducePipe,
        PrimeNGDateFormattingPipe,
        ItcSpinnerComponent,
        ItcBulkActionsComponent,
        getMaxDatePipe,
        PrimengDropdownDirective,
        PrimengInputNumberDirective,
        validIpAddressValidator,
        ValidDomainValidator,
        PrimengMultiselectDirective,
        MetricBoxComponent,
        RFTColorPickerComponent,
        FilterObjectsPipe,
        ShowHideComponent,
        TextareaCharLimitComponent,
        PageMenuComponent,
        CannotStartWithSpaceValidatorDirective,
        ManageApplianceComponent,
        NdpIssueOverridesPortableComponent,
        ValidInternalScanTargetValidator,
        ValidExternalScanTargetValidator,
        PortValidator,
        FilenameValidator,
        TaskCheckboxComponent,
        TaskChecklistComponent,
        CWCompaniesLookupComponent,
    ],
    exports: [
        CapitalizePipe,
        LinkifyPipe,
        MapToIterablePipe,
        OrderByPipe,
        PaginatorComponent,
        TruncatePipe,
        KeysPipe,
        UriComponentPipe,
        SidebarLayoutComponent,
        RiskScoreCardComponent,
        RelatedItemsCardComponent,
        ButtonComponent,
        DataSearchFilterComponent,
        MessageComponent,
        LoaderComponent,
        TimeAgoPipe,
        PrettifyPipe,
        SafeHtmlPipe,
        BreadcrumbTrailComponent,
        InitDirective,
        SortableTableComponent,
        SortableTableDirective,
        SortableColumnComponent,
        ModalDebugComponent,
        BadgedIconComponent,
        ConnectorFilesComponent,
        ItcAppLauncherComponent,
        LegacyDonutChartComponent,
        ItcInputComponent,
        ItcTextareaComponent,
        ItcCheckboxComponent,
        ItcToggleComponent,
        ItcExampleComponent,
        ItcTooltipDirective,
        ItcPaginatorComponent,
        ItcPaginatorOldComponent,
        ItcTabsComponent,
        ItcTabComponent,
        ItcRadioComponent,
        ItcDropdownButtonComponent,
        ItcDropdownButtonTagsDirective,
        StatusIconComponent,
        DropdownButtonComponent,
        DropdownButtonTagsDirective,
        ItcCardComponent,
        ItcCardTagsDirective,
        accountDatePipe,
        accountDateTimePipe,
        nl2brPipe,
        WjChartModule,
        WjGaugeModule,
        WjGridModule,
        WjGridDetailModule,
        WjInputModule,
        WjNavModule,
        SemanticLegacyModule,
        CdkTableModule,
        MatSortModule,
        ScrollingModule,
        FontAwesomeModule,
        AngularFuseJsModule,
        ItcInlineEditComponent,
        ViewModeDirective,
        EditModeDirective,
        EditableOnEnterDirective,
        FocusableDirective,
        DiscoveryAgentComponent,
        SiteEncodePipe,
        SiteDecodePipe,
        OrgNameValidatorDirective,
        HelpButtonComponent,
        NotBlankValidator,
        ItcFileUploaderComponent,
        ItcProgressBarComponent,
        PsaTicketsComponent,
        EmptyStateComponent,
        SplitCamelCasePipe,
        NdpUserReportSetsPortableComponent,
        ListReducePipe,
        EmptyStateComponent,
        PrimeNGDateFormattingPipe,
        CalendarModule,
        ItcSpinnerComponent,
        ItcLoaderComponent,
        NotificationModule,
        ItcBulkActionsComponent,
        ItcTagComponent,
        getMaxDatePipe,
        DropdownModule,
        OverlayPanelModule,
        MultiSelectModule,
        PrimengDropdownDirective,
        PrimengInputNumberDirective,
        validIpAddressValidator,
        ValidDomainValidator,
        ValidInternalScanTargetValidator,
        ValidExternalScanTargetValidator,
        PrimengMultiselectDirective,
        MetricBoxComponent,
        TruncateTextDirective,
        RFTColorPickerComponent,
        FilterObjectsPipe,
        ShowHideComponent,
        TextareaCharLimitComponent,
        ItcProgressTrackerModule,
        PageMenuComponent,
        NoDuplicatesValidatorDirective,
        CannotStartWithSpaceValidatorDirective,
        InputNumberModule,
        ManageApplianceComponent,
        NdpIssueOverridesPortableComponent,
        TableModule,
        ItcButtonComponent,
        ItcButtonComponent,
        ItcCheckboxComponent,
        IpRangeComponent,
        PortValidator,
        MultiselectComponent,
        FilenameValidator,
        TaskCheckboxComponent,
        TaskChecklistComponent,
        SelectButtonModule,
        AccordionModule,
        ItcInputComponent,
        ItcLoaderComponent,
        RftMultiselectComponent,
        TextareaAutoResizeDirective,
        CmoStatusCardComponent,
        DonutChartComponent,
        RelativeTimePipe,
        InlineEditComponent,
        CWCompaniesLookupComponent,
    ],
    providers: [
        DynamicPipe,
        DatePipe,
        UpperCasePipe,
        LowerCasePipe,
        CurrencyPipe,
        DecimalPipe,
        PercentPipe,
        ItcTabsService,
        accountDateTimePipe,
        SiteEncodePipe,
        SiteDecodePipe,
        accountDateTimePipe,
        DeviceIdentifierPipe,
        ItcFileUploaderService,
        FileSizePipe,
        SplitCamelCasePipe,
        ListReducePipe,
        PrimeNGDateFormattingPipe,
        FilterObjectsPipe,
        getMaxDatePipe,
        MapToIterablePipe,
        OrderByPipe,
        AccessRoleTextPipe,
        RoleNamePipe,
        DiscoveryAgentService,
        RelativeTimePipe,
        Base64UrlService
    ],
})
export class SharedModule {
    constructor(library: FaIconLibrary, faConfig: FaConfig) {
        faConfig.defaultPrefix = 'far';
        /* Regular Icons */
        library.addIcons(faAlien);
        library.addIcons(faArrowDownToBracket);
        library.addIcons(faArrowDownToLine);
        library.addIcons(faArrowDownToSquare);
        library.addIcons(faArrowLeft);
        library.addIcons(faArrowLeftRotate);
        library.addIcons(faArrowRight);
        library.addIcons(faArrowRightRotate);
        library.addIcons(faArrowRotateLeft);
        library.addIcons(faArrowsRotate);
        library.addIcons(faArrowUp);
        library.addIcons(faArrowUpRightFromSquare);
        library.addIcons(faArrowsUpDownLeftRight);

        library.addIcons(faBan);
        library.addIcons(faBell);
        library.addIcons(faBoxArchive);

        library.addIcons(faCalendar);
        library.addIcons(faCalendarDay);
        library.addIcons(faCamera);
        library.addIcons(faCaretDown);
        library.addIcons(faChartBar);
        library.addIcons(faChartPie);
        library.addIcons(faChartPieSimple);
        library.addIcons(faCheck);
        library.addIcons(faChevronDown);
        library.addIcons(faChevronLeft);
        library.addIcons(faChevronRight);
        library.addIcons(faChevronUp);
        library.addIcons(faCircle);
        library.addIcons(faCircleArrowDown);
        library.addIcons(faCircleCheck);
        library.addIcons(faCircleInfo);
        library.addIcons(faCircleQuestion);
        library.addIcons(faCircleXmark);
        library.addIcons(faCircleUser);
        library.addIcons(faClipboard);
        library.addIcons(faClipboardListCheck);
        library.addIcons(faClone);
        library.addIcons(faCoffee);
        library.addIcons(faCog);
        library.addIcons(faComment);
        library.addIcons(faCompress);
        library.addIcons(faCopy);

        library.addIcons(faDatabase);
        library.addIcons(faDiagramNested);
        library.addIcons(faDisplay);
        library.addIcons(faDownload);

        library.addIcons(faEllipsisVertical);
        library.addIcons(faEnvelope);
        library.addIcons(faEnvelopesBulk);
        library.addIcons(faExclamationCircle);
        library.addIcons(faExclamationTriangle);
        library.addIcons(faExpand);
        library.addIcons(faExternalLink);
        library.addIcons(faEye);
        library.addIcons(faEyeSlash);

        library.addIcons(faFile);
        library.addIcons(faFileCertificate);
        library.addIcons(faFileChartColumn);
        library.addIcons(faFileCode);
        library.addIcons(faFileExcel);
        library.addIcons(faFileExport);
        library.addIcons(faFileImage);
        library.addIcons(faFileLines);
        library.addIcons(faFileWord);
        library.addIcons(faFileZipper);
        library.addIcons(faFloppyDisk);
        library.addIcons(faFolder);

        library.addIcons(faGear);
        library.addIcons(faGlobe);

        library.addIcons(faCircleMinus);

        library.addIcons(faHardDrive);
        library.addIcons(faHouseBlank);

        library.addIcons(faInfoSquare);

        library.addIcons(faKey);

        library.addIcons(faLaptopMobile);
        library.addIcons(faLink);
        library.addIcons(faListCheck);

        library.addIcons(faLock);
        library.addIcons(faLockKeyhole);

        library.addIcons(faMagnifyingGlass);
        library.addIcons(faMailBulk);
        library.addIcons(faMapMarkerAlt);
        library.addIcons(faMemoCircleCheck);
        library.addIcons(faMessageExclamation);
        library.addIcons(faMessageDots);
        library.addIcons(faMinus);

        library.addIcons(faNote);
        library.addIcons(faNoteSticky);

        library.addIcons(faPaintBrush);
        library.addIcons(faPaintBrushFine);
        library.addIcons(faPaperclip);
        library.addIcons(faPaperclipVertical);
        library.addIcons(faPaperPlane);
        library.addIcons(faPaste);
        library.addIcons(faPause);
        library.addIcons(faPencil);
        library.addIcons(faPenToSquare);
        library.addIcons(faPlay);
        library.addIcons(faPlus);
        library.addIcons(faPrint);

        library.addIcons(faQuestionCircle);

        library.addIcons(faRectangleXmark);
        library.addIcons(faReply);

        library.addIcons(faServer);
        library.addIcons(faSitemap);
        library.addIcons(faSparkles);
        library.addIcons(faSquareArrowUpRight);
        library.addIcons(faSquareCheck);
        library.addIcons(faSquareChevronRight);
        library.addIcons(faSquareList);
        library.addIcons(faSquareLeft);
        library.addIcons(faStar);

        library.addIcons(faTableCellsLarge);
        library.addIcons(faTag);
        library.addIcons(faTerminal);
        library.addIcons(faTimes);
        library.addIcons(faTrash);
        library.addIcons(faTrashCan);
        library.addIcons(faTrashCanList);
        library.addIcons(faTriangleExclamation);

        library.addIcons(faUpload);
        library.addIcons(faUser);
        library.addIcons(faUserLock);
        library.addIcons(faUsers);
        library.addIcons(faUserPlus);

        library.addIcons(faWifi);

        library.addIcons(faX);
        library.addIcons(faXmark);

        /* Solid Icons */
        library.addIcons(fasCaretDown);
        library.addIcons(fasCaretUp);
        library.addIcons(fasCircle);
        library.addIcons(fasCircleCheck);
        library.addIcons(fasCircleHalfStroke);
        library.addIcons(fasCirclInfo);
        library.addIcons(fasCircleXmark);
        library.addIcons(fasCircle0);
        library.addIcons(fasCircle1);
        library.addIcons(fasCircle2);
        library.addIcons(fasCircle3);
        library.addIcons(fasCircle4);
        library.addIcons(fasCircle5);
        library.addIcons(fasCircle6);
        library.addIcons(fasCircle7);
        library.addIcons(fasCircle8);
        library.addIcons(fasCircle9);
    }
}
