import { inject, Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RFT_URL } from '../constants';
import {
    Resource,
    ResourceCategoryInfo,
    ResourceProduct,
    ResourceProductInfo,
} from './resources.model';
import { BehaviorSubject, combineLatest, lastValueFrom, map, Observable } from 'rxjs';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { _ParseAST } from '@angular/compiler';
import { Option } from 'app/shared/itc/multiselect/multiselect.component';
import { Router } from '@angular/router';
import { UiService } from '../ui.service';
import { AuthService } from '../auth';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class ResourcesService {
    private http = inject(HttpClient);
    private router = inject(Router);
    private uiService = inject(UiService);
    private authService = inject(AuthService);

    private apiUrl: string;
    private resources = new BehaviorSubject<Resource | 'loading' | null>(null);
    private availableCategories = new BehaviorSubject<ResourceCategoryInfo[]>(null);
    private breadcrumbs = new BehaviorSubject<Breadcrumb[]>([{ text: 'Resources' }]);
    private cm1Standards = new BehaviorSubject<Option[]>([]);
    private product = new BehaviorSubject<ResourceProductInfo>(null);
    private standard = new BehaviorSubject<string>('');
    private category = new BehaviorSubject<string>('');

    private needsName = false;

    constructor() {
        this.apiUrl = environment.apiUrl;
    }

    getResources(): Observable<Resource | 'loading' | null> {
        if (this.resources.value === null) {
            const accountProducts = this.authService.getProducts();
            this.resources.next('loading');
            let resourcejson = isDevMode()
                ? 'resources.portal-dev.v5.json?v=' + Math.floor(Math.random() * Math.floor(10))
                : 'resources.portal.v5.json?v=' + Math.floor(Math.random() * Math.floor(10));
            $.getJSON(`${RFT_URL}/data/${resourcejson}`, (data) => {
                for (const [key, value] of Object.entries(data)) {
                    if (
                        !(value as ResourceProduct).enabled ||
                        !accountProducts[(value as ResourceProduct).requiresProduct]
                    ) {
                        delete data[key];
                    } else {
                        if (data[key].standards) {
                            data[key].standards = data[key].standards.filter((s) => {
                                return s.enabled && accountProducts[s.requiresProduct];
                            });
                        }
                        if (data[key].categories) {
                            data[key].categories = data[key].categories.filter((c) => c.enabled);
                        }
                    }
                }
                // going this way because we don't have the resources yet, but need to get the name when we do
                // did this so I didn't need a hard coded list of product names
                if (this.needsName) {
                    let product = this.product.value;
                    product.name = data[product.key].name;
                    this.product.next(product);
                    this.needsName = false;
                }
                this.resources.next(data);
                this.setCM1Standards();
            });
        }
        return this.resources.asObservable();
    }

    setBreadcrumbs(product: string, standard: string, category: string): void {
        let breadcrumbs = [{ text: 'Resources' }];
        let pageTitle = 'Resources - ';
        if (product) {
            breadcrumbs.push({ text: product });
            pageTitle += product;
        }
        if (standard) {
            breadcrumbs.push({ text: standard });
            pageTitle += ` - ${standard}`;
        }
        breadcrumbs.push({ text: category || 'All' });
        pageTitle += ` - ${category || 'All'}`;
        this.breadcrumbs.next(breadcrumbs);
        this.uiService.setTitle(pageTitle);
    }

    getBreadcrumbs(): Observable<Breadcrumb[]> {
        return this.breadcrumbs.asObservable();
    }

    getProduct(): Observable<ResourceProductInfo> {
        return this.product.asObservable();
    }

    setProduct(product: ResourceProductInfo): void {
        // because of routing, CM1 doesn't have a product entered, but has the other items
        if (typeof product.key === 'undefined') {
            product = { key: 'compliance-manager', name: 'compliance-manager' };
        }
        if (!this.resources.value || this.resources.value === 'loading') {
            this.needsName = true;
        } else {
            if (this.resources.value[product.key]) {
                product.name = this.resources.value[product.key].name;
            }
        }
        this.product.next(product);
    }

    getStandard(): Observable<string> {
        return this.standard.asObservable();
    }

    setStandard(standard: string): void {
        if (standard) {
            this.setProduct({ key: 'compliance-manager', name: 'Compliance Manager (Legacy)' });
            this.standard.next(standard);
        } else {
            this.standard.next(null);
        }
    }

    getCategory(): Observable<string> {
        return this.category.asObservable();
    }

    setCategory(category: string): void {
        this.category.next(category);
    }

    getResourceItems(): Observable<ResourceProduct> {
        return combineLatest([this.resources, this.product, this.standard, this.category]).pipe(
            map(([resources, product, standard, category]) => {
                if (resources === 'loading') return 'loading';

                let prodName;
                let stdName;
                let catName;
                if (resources && resources[product.key]) {
                    let resource = JSON.parse(JSON.stringify(resources[product.key]));
                    prodName = resource.name;
                    if (standard) {
                        resource = resource.standards.find((s) => s.key === standard);
                        if (resource) {
                            stdName = resource.name;
                        } else {
                            // not a good standard, just send to cm1
                            this.router.navigate(['/resources', product.key]);
                        }
                    }
                    if (category) {
                        resource.categories = resource.categories.filter(
                            (c) => c.key === category && c.enabled
                        );
                        catName = resource.categories[0].name;
                    }
                    this.setAvailableCategories();
                    this.setBreadcrumbs(prodName, stdName, catName);
                    return resource;
                } else {
                    // bad product name, just redirct
                    this.router.navigate(['/', 'resources']);
                }
                return null;
            })
        );
    }

    setAvailableCategories(): void {
        let resourceObj: ResourceProduct;
        let availableCategories: ResourceCategoryInfo[] = [];
        let resourceItems = this.resources.value[this.product.value.key];

        if (resourceItems.hasOwnProperty('standards')) {
            resourceObj = resourceItems.standards.find((s) => s.key === this.standard.value);
        } else {
            resourceObj = resourceItems;
        }
        if (typeof resourceObj !== 'undefined') {
            availableCategories.push({ key: 'all', name: 'All', url: resourceObj.url });
            resourceObj.categories.forEach((c) => {
                if (c.enabled) {
                    availableCategories.push({
                        key: c.key,
                        name: c.name,
                        url: [...resourceObj.url, c.key],
                    });
                }
            });
            this.availableCategories.next(availableCategories);
        }
    }

    getAvailableCategories(): Observable<ResourceCategoryInfo[]> {
        return this.availableCategories.asObservable();
    }

    setCM1Standards(): void {
        let cm1 = this.resources.value['compliance-manager'];
        if (cm1) {
            let standards: Option[] = [];
            cm1.standards.forEach((standard) => {
                if (standard.enabled) {
                    standards.push({
                        Label: standard.name,
                        Value: standard.key,
                    });
                }
            });
            this.cm1Standards.next(standards.sort((a, b) => a.Label.localeCompare(b.Label)));
        }
    }

    getCM1Standards(): Observable<Option[]> {
        return this.cm1Standards.asObservable();
    }

    getPriceSheet(): Promise<any> {
        const url: string = this.apiUrl + 'Resources/PriceSheet';

        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    // I believe 'name' should include the file extension here
    getBrochure(name: string): Promise<any> {
        const url: string = this.apiUrl + `Resources/Brochure?name=${name}`;

        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    // getCysuranceJSON(): Promise<any> {
    //     return this.cysuranceFile as any;
    // }

    resetService(): void {
        this.resources.next(null);
        this.resources.complete();
        this.breadcrumbs.next(null);
        this.breadcrumbs.complete();
        this.cm1Standards.next(null);
        this.cm1Standards.complete();
        this.product.next(null);
        this.product.complete();
        this.standard.next(null);
        this.standard.complete();
        this.category.next(null);
        this.category.complete();
    }
}
