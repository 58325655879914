import { ChangeDetectionStrategy, Component, Input, SimpleChange } from '@angular/core';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnglishPackage from '@zxcvbn-ts/language-en';

@Component({
    selector: 'password-strength-meter',
    standalone: true,
    imports: [],
    template: `
        <div>
            <ul class="pws{{ score }}">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <p class="scoreLabel">{{ scoreLabel[score] || 'Password Strength' }}</p>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                line-height: 0;
                padding-top: 8px;
            }
            ul {
                list-style-type: none;
                display: inline-flex;
                padding-left: 0;
                margin: 0;
                width: 100%;
                gap: 4px;
                li {
                    height: 5px;
                    flex: 1;
                    border-radius: 4px;
                    background-color: var(--tag-solid-semantic-default);
                }
                &.pws0 > li:nth-child(-n + 1) {
                    background-color: var(--tag-solid-semantic-danger);
                }
                &.pws1 > li:nth-child(-n + 2) {
                    background-color: var(--tag-solid-semantic-warning-orange);
                }
                &.pws2 > li:nth-child(-n + 3) {
                    background-color: var(--tag-solid-semantic-warning-yellow);
                }
                &.pws3 > li:nth-child(-n + 4) {
                    background-color: var(--tag-solid-semantic-success-subtle);
                }
                &.pws4 > li:nth-child(-n + 5) {
                    background-color: var(--tag-solid-semantic-success);
                }
            }
            .scoreLabel {
                text-align: right;
                font-size: small;
                color: var(--text-secondary);
                line-height: normal;
                margin-top: 4px;
                margin-bottom: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthMeterComponent {
    @Input() password = '';
    score: number;

    scoreLabel = ['Very weak', 'Weak', 'Fair', 'Good', 'Strong'];
    ngOnInit(): void {
        const zxcvbnPWOptions = {
            dictionary: {
                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnglishPackage.dictionary,
            },
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            translations: zxcvbnEnglishPackage.translations,
        };
        zxcvbnOptions.setOptions(zxcvbnPWOptions);
    }

    ngOnChanges(changes: SimpleChange) {
        if (changes['password']) {
            if (this.password?.trim()) {
                let result = zxcvbn(this.password);
                this.score = result.score;
            } else {
                this.score = null;
            }
        }
    }
}
