import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { AuthHttp } from 'app/core/auth';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class K1organizationService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getK1Organization(): Promise<any> {
        const url: string = this.apiUrl + `k1organizations`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    isK1OrganizationMapped(siteId: number): Promise<any> {
        const url = this.apiUrl + `isK1OrganizationMapped/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    checkK1Id(): Promise<any> {
        const url: string = this.apiUrl + `CheckK1Credentials`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    saveOrganizationMapping(data: any): Promise<any> {
        const url: string = this.apiUrl + `SaveOrganizationMapping`;
        return lastValueFrom(this.http.post(url, data)).then((res) => res);
    }

    getOrganizationMapping(): Promise<any> {
        const url: string = this.apiUrl + `GetOrganizationMapping`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    saveLastSynced(): Promise<any> {
        const url: string = this.apiUrl + `SaveLastSynced`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
}
