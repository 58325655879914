<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>VulScan</h1>
</header>

<div class="itc tabs container">
    <div class="tabs_header">
        <div
            class="tab"
            [routerLink]="['../', 'all-results']"
            [class.tab-active]="isIntResults && isExtResults">
            All
        </div>
        <div
            class="tab"
            [routerLink]="['../', 'internal-results']"
            [class.tab-active]="isIntResults && !isExtResults && !isAgtResults">
            Internal
        </div>
        <div
            class="tab"
            [routerLink]="['../', 'external-results']"
            [class.tab-active]="isExtResults && !isIntResults && !isAgtResults">
            External
        </div>
        <div
            class="tab"
            [routerLink]="['../', 'discovery-results']"
            [class.tab-active]="!isIntResults && !isExtResults && isAgtResults">
            Discovery Agents
        </div>
    </div>
</div>

<sds-loader [complete]="loadingComplete">
    <div class="ui dashboard" *ngIf="loadingComplete" style="padding-bottom: 25px">
        <div class="ui grid tabs_content">
            <div class="eleven wide column grid" style="padding-top: 0">
                <div class="ui equal width grid">
                    <div class="column">
                        <div class="ui card chartCard excludeBoxShadow">
                            <div class="content">
                                <div class="header">
                                    <span>
                                        Vulnerabilities by Device
                                        <span class="subHeader">Last 30 Days</span>
                                    </span>
                                    <a
                                        [routerLink]="[
                                            '/site',
                                            makeURI(site?.Name),
                                            'vulnerability-scanner',
                                            'scan-results',
                                            'by-device'
                                        ]">
                                        View All
                                    </a>
                                </div>
                            </div>
                            <div class="content">
                                <kvs-vulnerabilities-donut-chart
                                    [data]="kvsMostRecentVulnerabilities"
                                    #kvsChartMostRecentVul></kvs-vulnerabilities-donut-chart>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="ui card chartCard excludeBoxShadow">
                            <div class="content">
                                <div class="header">
                                    <span>
                                        Vulnerabilities Over Time
                                        <span class="subHeader">Last 30 Days</span>
                                    </span>
                                    <a
                                        [routerLink]="[
                                            '/site',
                                            makeURI(site?.Name),
                                            'vulnerability-scanner',
                                            'scan-results',
                                            'by-device'
                                        ]">
                                        View All
                                    </a>
                                </div>
                            </div>
                            <div class="content" style="overflow: hidden">
                                <kvs-vulnerabilities-over-time-chart
                                    [data]="kvsVulnerabilitiesOverTime"
                                    #kvsChartVulOverTime></kvs-vulnerabilities-over-time-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui equal width grid" style="margin-top: 10px; margin-bottom: 15px">
                    <div class="column">
                        <div class="ui card chartCard highestRiskDevices excludeBoxShadow">
                            <div class="content">
                                <div class="header">
                                    <span>
                                        Highest Risk Devices
                                        <span class="subHeader">Last 30 Days</span>
                                    </span>
                                    <a
                                        [routerLink]="[
                                            '/site',
                                            makeURI(site?.Name),
                                            'vulnerability-scanner',
                                            'scan-results',
                                            'by-device'
                                        ]">
                                        View All
                                    </a>
                                </div>
                            </div>
                            <div class="content">
                                <kvs-highest-risk-chart
                                    [data]="kvsHighestRisk"
                                    (redirect)="redirectToDevice($event)"
                                    #kvsChartHighestRisk></kvs-highest-risk-chart>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div
                            *ngIf="kvsHighestCve?.length > 0"
                            class="ui card chartCard highestCve excludeBoxShadow">
                            <div class="content">
                                <div class="header">
                                    <span>
                                        Critical and High Risk CVE
                                        <span class="subHeader">Last 30 Days</span>
                                    </span>
                                    <a
                                        [routerLink]="[
                                            '/site',
                                            makeURI(site?.Name),
                                            'vulnerability-scanner',
                                            'scan-results',
                                            'by-device'
                                        ]">
                                        View All
                                    </a>
                                </div>
                            </div>
                            <div class="content">
                                <kvs-highest-cve-chart
                                    [data]="kvsHighestCve"
                                    (redirect)="redirectToDevice($event)"
                                    #kvsChartHighestRisk></kvs-highest-cve-chart>
                            </div>
                        </div>
                    </div>
                </div>

                <sds-scan-status-card
                    *ngIf="(isIntResults || isExtResults) && !isClientView"
                    [appliance]="kvsScanStatusApps[0]"
                    [loaded]="appliancesLoaded"
                    title="Scan Status"
                    [isVulScanDashboard]="true"
                    [type]="scanStatusScanType"></sds-scan-status-card>
            </div>
            <div class="five wide column">
                <sds-appliance-card
                    *ngIf="selectedTab !== 'discovery'"
                    [type]="kvsApps.Type"
                    [appliances]="kvsApps.App"
                    [loaded]="appliancesLoaded"
                    [showRunningTasks]="false"
                    [isVulScanDashboard]="true"
                    [isReadOnly]="isReadOnly"></sds-appliance-card>

                <div class="ui card kvsIssuesCard excludeBoxShadow">
                    <div class="content">
                        <div class="header" style="display: flex; justify-content: space-between">
                            <fa-icon icon="clipboard-list-check"></fa-icon>
                            <span>
                                Highest Risk Issues
                                <span class="subHeader">Last 30 Days</span>
                            </span>
                            <a
                                [routerLink]="[
                                    '/site',
                                    makeURI(site?.Name),
                                    'vulnerability-scanner',
                                    'scan-results'
                                ]"
                                class="viewMoreLink"
                                style="padding-right: 5px">
                                View All
                            </a>
                        </div>
                    </div>
                    <div class="content">
                        <kvs-issues
                            [issues]="kvsIssues"
                            [site]="site"
                            [connection]="conn"
                            [isVulScanDashboard]="true"
                            (RefreshResults)="refreshResults()"></kvs-issues>
                    </div>
                </div>
                <div class="ui card kvsIssuesCard excludeBoxShadow">
                    <div class="content">
                        <div class="header" style="display: flex; justify-content: space-between">
                            <fa-icon icon="clipboard-list-check"></fa-icon>
                            <span>
                                Known Exploited Vulnerabilities
                                <span class="subHeader">Last 30 Days</span>
                            </span>
                            <a
                                [routerLink]="[
                                    '/site',
                                    makeURI(site?.Name),
                                    'vulnerability-scanner',
                                    'scan-results',
                                    'by-issue'
                                ]"
                                [queryParams]="{ kev: true }"
                                class="viewMoreLink"
                                style="padding-right: 5px">
                                View All
                            </a>
                        </div>
                    </div>
                    <div class="content">
                        <kvs-issues
                            [issues]="kevIssues"
                            [site]="site"
                            [connection]="conn"
                            noIssuesFoundText="No Known Exploited Vulnerabilities found."
                            [isVulScanDashboard]="true"
                            (RefreshResults)="refreshResults()"></kvs-issues>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sds-loader>
