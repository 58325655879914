import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    Injector,
    Input,
    Output,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ItcButtonComponent } from '../itc';
import { AutoGrowDirective } from '../auto-grow.directive';
import { debounce } from 'lodash-es';
import { TruncateTextDirective } from '../truncateText.directive';

@Component({
    selector: 'inline-edit',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ItcButtonComponent,
        TruncateTextDirective,
        AutoGrowDirective,
    ],
    template: `
        <ng-container *ngIf="!isEditing; else editContent">
            <div class="textContainer">
                <span truncateText truncateWidth="auto" truncateMaxWidth="calc(100% - 22px)">
                    {{ this.control.value }}
                </span>
                <itc-button type="icon" icon="fa-edit" (onclick)="updateText()"></itc-button>
            </div>
        </ng-container>

        <ng-template #editContent>
            <form class="inline-edit" (ngSubmit)="updateText(true)">
                <input
                    type="text"
                    name="text"
                    [formControl]="control"
                    [attr.maxlength]="maxLength ? maxLength : null"
                    [autoGrowInput]="autoGrowInput ? autoGrowInput : null"
                    [autoGrowMaxWidth]="parentElementWidth" />
                <itc-button type="icon" icon="fa-times" (onclick)="updateText(false)"></itc-button>
                <itc-button
                    type="icon"
                    icon="fa-check"
                    [disabled]="control.invalid && control.value !== originalText"
                    (onclick)="updateText(true)"></itc-button>
            </form>
        </ng-template>
    `,
    styles: [
        `
            :host {
                .textContainer {
                    display: flex;
                    border-left: 1px solid transparent;
                    span {
                        display: inline-block;
                        margin-right: 4px;
                    }
                }
                form {
                    input {
                        padding: 0;
                        font-weight: inherit;
                        margin-right: 8px;
                    }
                }
                .inline-edit itc-button::ng-deep {
                    .button.icon {
                        box-shadow: var(--elevation-xs);
                        border-radius: 4px;
                        border: 1px solid var(--button-secondary-border);
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InlineEditComponent),
            multi: true,
        },
    ],
})
export class InlineEditComponent implements ControlValueAccessor {
    private cdr = inject(ChangeDetectorRef);
    private injector = inject(Injector);

    constructor(el: ElementRef) {
        this.el = el;
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (!this.isEditing) return;

        if (event.key === 'Escape') {
            this.updateText(false);
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize = debounce((evt) => {
        this.getParentWidth();
    }, 250);

    @Input() set text(text: string) {
        this.originalText = text;
        this.value = text;
    }
    @Input() required = true;
    @Input() maxLength: number;
    @Input() autoGrow = true;
    @Output() onupdate = new EventEmitter<string>();
    el: ElementRef;
    parentElementWidth: number;
    control: FormControl<string> = new FormControl('');
    originalText: string = '';
    isEditing = false;
    disabled = false;
    value = '';
    parentElementInterval: any;
    parentElementInervalCount = 0;

    ngAfterViewInit(): void {
        this.parentElementInterval = setInterval(() => {
            this.getParentWidth();
        }, 10);
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
        } else {
            this.control = new FormControl(this.value);
        }
        this.cdr.markForCheck();
    }

    getParentWidth(): void {
        if (this.el.nativeElement.getBoundingClientRect().width) {
            this.parentElementWidth = this.el.nativeElement.parentElement.offsetWidth;
            clearInterval(this.parentElementInterval);
        } else {
            this.parentElementInervalCount++;
            if (this.parentElementInervalCount > 100) {
                clearInterval(this.parentElementInterval);
            }
        }
        this.cdr.markForCheck();
    }

    updateText(result?: boolean): boolean {
        if (this.control?.invalid && this.control.value !== this.originalText && result) return;

        if (typeof result === 'undefined') {
            this.isEditing = !this.isEditing;
            return;
        }

        if (result) {
            this.control.markAsPristine();
            this.onupdate.emit(this.control.value);
            this.writeValue(this.control.value);
            this.originalText = this.control.value;
        } else {
            this.onChange(this.originalText);
            this.control.markAsPristine();
        }
        this.isEditing = false;
        this.cdr.markForCheck();
        return false;
    }

    onChange = (_) => {};
    onTouched = (_) => {};

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        if (!this.originalText) this.originalText = value;
        if (!this.value || this.value !== value) this.value = value;
        if (!this.control) this.control = new FormControl(value);
        this.onChange(value);
        this.cdr.markForCheck();
    }
}
