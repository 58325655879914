import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { DiscoveryAgentTableService } from '../services/discovery-agent-table.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';

@Component({
    standalone: true,
    imports: [CommonModule, SharedModule],
    selector: 'sds-discovery-agent-text-filter',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="discovery-agent-text-filter-container">
            <itc-input
                [control]="textFilterControl"
                class="discovery-agent-text-filter"
                placeholder="Filter..."></itc-input>
            <div>
                <strong>{{ filteredCount + ' of ' + totalCount }}</strong>
                Results
            </div>
        </div>
    `,
    styles: [
        `
            .discovery-agent-text-filter-container {
                display: flex;
                gap: 16px;
                align-items: center;
            }
            itc-input.discovery-agent-text-filter {
                flex-grow: 1;

                .itc.input.discovery-agent-text-filter {
                    width: 100% !important;
                }
                input[type='text'] {
                    margin-bottom: 0 !important;
                }
            }
        `,
    ],
})
export class DiscoveryAgentTextFilterComponent implements OnInit, OnDestroy {
    textFilterControl: FormControl<string> = new FormControl<string>('');
    filteredCount = 0;
    totalCount = 0;

    private readonly ngOnDestroy$ = new Subject<void>();

    constructor(private discoveryAgentTableService: DiscoveryAgentTableService) {}

    ngOnInit() {
        this.textFilterControl.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
            .subscribe((value) => {
                this.discoveryAgentTableService.filterData({
                    text: value,
                });
            });

        this.discoveryAgentTableService
            .getFilteredPagedItems()
            .pipe(
                map((pagedInfo) => pagedInfo.result?.total_record_count),
                filterIsNotUndefined(),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((count) => {
                if (!this.totalCount) {
                    this.totalCount = count;
                }
                this.filteredCount = count;
            });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
