import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavService } from 'app/core/nav/nav.service';
import { Breadcrumb } from '../../breadcrumbs/breadcrumb.model';
import { Organization } from '../../../organizations/organization.model';
import { OrganizationsService } from '../../../organizations/organizations.service';
import { UiService } from '../../../core/ui.service';
import {
    combineLatest,
    map,
    mergeMap,
    Observable,
    shareReplay,
    Subject,
    switchMap,
    takeUntil,
    zip,
} from 'rxjs';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';
import { take } from 'rxjs/operators';
import { UtilityService } from '../../utility.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { DiscoveryAgentService } from '../services/discovery-agent.service';
import { LastScanResultCardComponent } from '../last-scan-result-card/last-scan-result-card.component';
import { LastScanResultStatsModel } from '../models/last-scan-result-stats.model';
import { ApplianceStatusStatsModel } from '../models/appliance-status-stats.model';
import { ApplianceStatusCardComponent } from '../appliance-status-card/appliance-status-card.component';
import { ComplianceMonitorStatsModel } from '../models/compliance-monitor-stats.model';
import { ComplianceMonitorCardComponent } from '../compliance-monitor-card/compliance-monitor-card.component';
import { DiscoveryAgentQuickFilterComponent } from '../discovery-agent-quick-filter/discovery-agent-quick-filter.component';
import { DiscoveryAgentScanScheduleSidebarComponent } from '../discovery-agent-scan-schedule-sidebar/discovery-agent-scan-schedule-sidebar.component';
import { DiscoveryAgentSidebarData } from '../models/discovery-agent-sidebar-data.model';
import { DiscoveryAgentScanDataAdapterService } from '../services/discovery-agent-scan-data-adapter.service';
import { AppliancesCountByFilterModel } from '../models/appliances-count-by-filter.model';
import { DiscoveryAgentTextFilterComponent } from '../discovery-agent-text-filter/discovery-agent-text-filter.component';
import { DiscoveryAgentTableService } from '../services/discovery-agent-table.service';
import { getEmptyDiscoveryAgentFilters } from '../models/discovery-agent-filters.model';
import { DiscoveryAgentTableComponent } from '../discovery-agent-table/discovery-agent-table.component';
import { Site, SiteService } from '../../../sites';
import { DiscoveryAgentSettingsService } from '../services/discovery-agent-settings.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        LastScanResultCardComponent,
        ApplianceStatusCardComponent,
        ComplianceMonitorCardComponent,
        DiscoveryAgentQuickFilterComponent,
        DiscoveryAgentScanScheduleSidebarComponent,
        DiscoveryAgentTextFilterComponent,
        DiscoveryAgentTableComponent,
    ],
    providers: [DiscoveryAgentTableService, DiscoveryAgentSettingsService],
    selector: 'sds-discovery-agent-page',
    templateUrl: './org-discovery-agent-page.component.html',
    styleUrls: ['./org-discovery-agent-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrgDiscoveryAgentPageComponent implements OnInit, OnDestroy {
    private _displayBreadcrumbs: boolean = true;
    get displayBreadcrumbs(): boolean {
        return this._displayBreadcrumbs;
    }
    @Input() set displayBreadcrumbs(value: boolean) {
        this._displayBreadcrumbs = value ?? true;
    }

    @ViewChild('sidebar') scanScheduleSidebar: DiscoveryAgentScanScheduleSidebarComponent;

    allOrganizations$ = this.organizationsService
        .getOrganizationsObs()
        .pipe(filterIsNotUndefined());
    allChartData$: Observable<AllChartData>;
    organization$: Observable<Organization>;
    title: string;
    breadcrumbs: Breadcrumb[];
    installKey$: Observable<string>;
    appliancesCountByQuickFilter$: Observable<AppliancesCountByFilterModel>;
    discoveryAgentSidebarData$: Observable<DiscoveryAgentSidebarData>;
    hasDiscoveryAgents: boolean;
    ngUnsubscribe$ = new Subject<void>();
    private scanResultData$: Observable<LastScanResultStatsModel>;
    private applianceStatusData$: Observable<ApplianceStatusStatsModel>;
    private complianceMonitorData$: Observable<ComplianceMonitorStatsModel>;

    constructor(
        private route: ActivatedRoute,
        private navService: NavService,
        private organizationsService: OrganizationsService,
        private discoveryAgentService: DiscoveryAgentService,
        private discoveryAgentScanDataAdapterService: DiscoveryAgentScanDataAdapterService,
        private discoveryAgentTableService: DiscoveryAgentTableService,
        private discoveryAgentSettingsService: DiscoveryAgentSettingsService,
        private siteService: SiteService,
        private router: Router,
        private uiService: UiService,
        private utilityService: UtilityService
    ) {}

    ngOnInit() {
        this.organizationsService.getAllOrganizations();
        this.title = 'Discovery Agents';
        this.uiService.setTitle(this.title);
        this.organization$ = this.getOrganization$().pipe(
            filterIsNotUndefined(),
            take(1),
            shareReplay(1)
        );
        this.setBreadCrumb();
        this.initializeDiscoveryAgentTableFilter();
        this.getInstallKey();
        this.getChartData();
        this.getAppliancesCountByQuickFilter();
        this.getDeepFileSettings();
        this.getDiscoveryAgentSidebarData();
    }

    async copy(val: string) {
        await this.utilityService.copyToClipboard(val, { showNotification: true });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private getOrganization$(): Observable<Organization> {
        if (this.router.url.indexOf('/site/') > -1) {
            return combineLatest([this.siteService.getSiteObs(), this.allOrganizations$]).pipe(
                map(([site, organizations]: [Site, Organization[]]) =>
                    organizations.find((o) => o.Name == site.Organization)
                )
            );
        }

        return combineLatest([this.route.params, this.allOrganizations$]).pipe(
            map(([params, organizations]: [Params, Organization[]]) =>
                organizations.find((o) => o.Name == decodeURIComponent(params.organization))
            )
        );
    }

    private setBreadCrumb() {
        if (!this.displayBreadcrumbs) {
            return;
        }

        this.organization$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((organization) => {
            this.navService.setHeaderText(organization?.Name);
            if (this.router.url.indexOf('/site/') === 0) {
                if (this.router.url.includes('compliance-monitor')) {
                    this.breadcrumbs = [
                        { text: 'Compliance Monitor' },
                        { path: '..', text: 'Scan Settings', link: true },
                        { path: '.', text: 'Manage Agents' },
                    ];
                } else if (this.router.url.includes('compliance-manager-grc')) {
                    this.breadcrumbs = [
                        { path: '../../..', text: 'Compliance Manager GRC' },
                        { path: '../..', text: 'Data Collection' },
                        { path: '..', text: 'Discovery Agent Scans', link: true },
                        { path: '.', text: 'Manage Agents' },
                    ];
                }
            } else {
                this.breadcrumbs = [
                    { path: '..', text: organization?.Name, link: true },
                    { path: '.', text: 'Discovery Agents' },
                ];
            }
        });
    }

    private initializeDiscoveryAgentTableFilter() {
        this.organization$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((organizationId) => {
            this.discoveryAgentTableService.filterData({
                id: organizationId.Id,
                page: 0,
                limit: 10,
                orderby: 'Name',
                sortby: 'asc',
                ...getEmptyDiscoveryAgentFilters(),
            });
        });
    }

    private getInstallKey(): void {
        this.installKey$ = this.discoveryAgentSettingsService.installKey$;

        this.organization$.pipe(
            mergeMap((organization) => this.discoveryAgentService.getInstallKey(organization?.Id)),
            map((installKeyObject) => installKeyObject?.InstallKey ?? ''),
            takeUntil(this.ngUnsubscribe$)
        ).subscribe(installKey => {
            this.discoveryAgentSettingsService.installKey = installKey;
        });
    }

    private getAppliancesCountByQuickFilter(): void {
        this.appliancesCountByQuickFilter$ = zip([
            this.applianceStatusData$,
            this.complianceMonitorData$,
        ]).pipe(
            filterIsNotUndefined(),
            map(([applianceStatusData, complianceMonitorData]) => ({
                Online: applianceStatusData.Online,
                Offline: applianceStatusData.Offline + applianceStatusData.OfflineMoreThan90Days,
                UpdateAvailable: applianceStatusData.UpdateAvailable,
                ComplianceMonitorEnabled: complianceMonitorData.Enabled,
                ComplianceMonitorDisabled: complianceMonitorData.Disabled,
                ComplianceMonitorNotSupported: complianceMonitorData.NotSupported,
            }))
        );
    }

    private getChartData(): void {
        this.scanResultData$ = this.organization$.pipe(
            mergeMap((organization) =>
                this.discoveryAgentService.getLastScanResultStats(organization.Id)
            ),
            filterIsNotUndefined()
        );
        this.applianceStatusData$ = this.organization$.pipe(
            mergeMap((organization) =>
                this.discoveryAgentService.getApplianceStatusStats(organization.Id)
            ),
            filterIsNotUndefined()
        );
        this.complianceMonitorData$ = this.organization$.pipe(
            mergeMap((organization) =>
                this.discoveryAgentService.getComplianceMonitorStats(organization.Id)
            ),
            filterIsNotUndefined()
        );

        this.allChartData$ = combineLatest([
            this.scanResultData$,
            this.applianceStatusData$,
            this.complianceMonitorData$,
        ]).pipe(
            map(
                ([scanResultData, applianceStatusData, complianceMonitorData]: [
                    LastScanResultStatsModel,
                    ApplianceStatusStatsModel,
                    ComplianceMonitorStatsModel
                ]) => ({
                    scanResultData,
                    applianceStatusData,
                    complianceMonitorData,
                })
            )
        );
    }
    
    private getDeepFileSettings(): void {
        this.organization$
            .pipe(
                switchMap((organization) =>
                    zip([
                        this.discoveryAgentService.getDeepFileScanSettings$(organization.Id),
                        this.discoveryAgentService.getDeepFileScanSettingsStatus$(organization.Id),
                    ])
                ),
                take(1),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(([deepFileScanSettings, deepFileScanSchedule]) => {
                this.discoveryAgentSettingsService.deepFileScanSettings = deepFileScanSettings;
                this.discoveryAgentSettingsService.deepFileScanSchedule = deepFileScanSchedule;
            });
    }

    private getDiscoveryAgentSidebarData(): void {
        this.discoveryAgentSidebarData$ = this.organization$.pipe(
            mergeMap((organization) => {
                return zip([
                    this.installKey$,
                    this.discoveryAgentService.getOrgDiscoveryAgentDefaultSettings$(
                        organization.Id
                    ),
                    this.discoveryAgentSettingsService.deepFileScanSettings$,
                    this.discoveryAgentSettingsService.deepFileScanSchedule$,
                ]).pipe(
                    map(
                        ([
                            installKey,
                            discoveryAgentSettingsDTO,
                            deepFileScanSettings,
                            deepFileScanSettingsStatus,
                        ]) =>
                            this.discoveryAgentScanDataAdapterService.mapDiscoveryAgentSettingsDTOToDiscoveryAgentSidebarData(
                                installKey,
                                discoveryAgentSettingsDTO,
                                deepFileScanSettings,
                                deepFileScanSettingsStatus
                            )
                    )
                );
            })
        );
    }

    setHasDiscoveryAgents(evt: boolean) {
        this.hasDiscoveryAgents = evt;
    }
}

interface AllChartData {
    scanResultData: LastScanResultStatsModel;
    applianceStatusData: ApplianceStatusStatsModel;
    complianceMonitorData: ComplianceMonitorStatsModel;
}
