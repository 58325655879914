import {
    Component,
    ComponentRef,
    ElementRef,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { Alert } from '../shared/alert.model';
import { AlertButton } from '../shared/alertbutton.model';
import { AlertService } from '../shared/alert.service';
import { AlertActionLookup, AlertStatusLookup, ALERT_STATUSES, OBJECT_TYPE_INFO, TAG_OBJECT_TYPES, TACs } from '../shared/constants';
import { Audit } from 'app/sites/site/audit/shared/audit.model';
import { AuditService } from 'app/sites/site/audit/shared/audit.service';
import { AuthService, SiteRoles } from 'app/core/auth';
import { Connection, ConnectionService } from 'app/connections';
import { ConfirmData, ConfirmService } from 'app/core/confirm/confirm.service';
import { ErrorService } from 'app/core/error/error.service';
import { LicenseService } from 'app/core/license.service';
import { NavService } from 'app/core/nav/nav.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { UiService } from 'app/core/ui.service';
import { PING_SITE } from 'app/sites/shared/constants';
import { MessageService } from 'app/core/message.service';
import { FilesService } from 'app/sites/site/assessments/files/files.service';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import * as saveAs from 'file-saver';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

declare var $: any;

@Component({
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private auditService: AuditService,
        private authService: AuthService,
        private connService: ConnectionService,
        private confirmService: ConfirmService,
        private errorService: ErrorService,
        private licenseService: LicenseService,
        private navService: NavService,
        private notificationService: NotificationService,
        private siteService: SiteService,
        private uiService: UiService,
        private messageService: MessageService,
        private filesService: FilesService,
        private cdr: ChangeDetectorRef,
        private siteDashboardService: SiteDashboardService
    ) { }

    @ViewChild('dataControl') dataControl: any;
    @ViewChild('infoBox', { static: true }) infoBox: ElementRef;
    @ViewChild('historyBox', { static: true }) historyBox: ElementRef;
    @ViewChild('similarBox', { static: true }) similarBox: ElementRef;
    @ViewChild('batchConfirmModal', { static: true })
    batchConfirmModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('uploadCdfModal', { static: true })
    uploadCdfModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('rollbackActionModal', { static: true })
    rollbackActionModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('rollbackErrorModal', { static: true })
    rollbackErrorModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('sopBox', { static: true }) sopBox: ElementRef;
    @ViewChild('sopModal') sopModal: any;
    @ViewChild('connectorModal', { static: true }) connectorModal: any;

    alert: Alert;
    header: string;
    messageIcon: string;
    messageClass: string;

    alertHistory: Audit[];

    params: any;

    statuses: any = ALERT_STATUSES;
    statusMessage: string;

    suggestedActions: any[] = [];
    actionControls: UntypedFormControl[] = [];

    isTech: boolean;

    userNotes: string;
    ignoreReason: string;

    listError: boolean;
    actionError: boolean;
    reasonError: boolean;
    selectedActions: string[];
    selectedItems: any;

    similarAlerts: Alert[];
    waitingSimilar: boolean;
    batchAction: string;

    isTask: boolean;

    loadingComplete: boolean;
    completing: boolean = false;
    formguid: string;

    sortMethod: string = 'Created';
    sortDirection: string = 'desc';
    invertDefault: boolean = false;

    site: Site;
    isAG: boolean;
    isCH: boolean;
    isSME: boolean;
    connection: Connection;

    breadcrumbs: any[];

    rollbackType: string;
    rollbackImpact: string[];
    rollbackError: string;

    //connectorFiles: any[];
    selectedConnectorFiles: any[];
    connectorModalLoadingComplete: boolean;
    loadingZip: boolean;
    deletingFiles: boolean;
    doReload: number;

    ngOnInit() {
        this.loadingComplete = false;
        this.loadingZip = false;
        this.doReload = 0;
        this.navService.clearReturnUrl();

        if (typeof this.route.parent.component !== 'undefined') {
            // console.log((this.route.parent.component as any).name);
        } else {
            // console.log('No parent component');
        }

        let breadcrumbs = [];

        if (this.router.url.indexOf('alert') > -1)
            breadcrumbs = [
                { path: '..', text: 'Alerts', link: true },
                { path: '.', text: 'Details' },
            ];
        else if (this.router.url.indexOf('todo') > -1)
            breadcrumbs = [
                { path: '..', text: 'To Do', link: '..' },
                { path: '.', text: 'Details' },
            ];
        this.uiService.setTitle('Details');
        this.breadcrumbs = breadcrumbs; // Setting here first; since there's currently no way of knowing if no site is available (vs. not retrieved yet), it has to be overwritten below

        this.siteService.app_getCurrentSite().then((site: Site) => {
            if (site) {
                this.site = site;
                this.uiService.setTitle('Details', site.Name);
                this.isAG = this.siteService.isComplianceManager(this.site);
                this.isCH = this.siteService.isCyberHawk(this.site);

                if (this.isAG) breadcrumbs.unshift({ path: '../../', text: 'Compliance Manager' });
                else if (this.isCH) breadcrumbs.unshift({ path: '../../', text: 'Cyber Hawk' });

                this.breadcrumbs = breadcrumbs;

                this.authService.setCurrentSiteRoles(site.UserRoles);
                let siteRoles = this.authService.getCurrentSiteRoles();
                this.isSME = siteRoles && siteRoles.indexOf(SiteRoles.SME) > -1;

                this.connService.getConnectionBySite(site.Id).then(conn => {
                    this.connection = conn;
                });
            }
        });
        this.checkParams();
    }

    checkParams() {
        this.route.queryParams.subscribe((params: Params) => {
            // Check if encrypted parameters exist

            if (params['p']) {
                this.alertService.decryptParams(params.p).then((dParams) => {
                    if (!environment.production) console.log(dParams);

                    this.params = dParams;
                    this.siteService.app_setCurrentSite(null);
                    this.fetchAlert();
                });
            } else if (params['guid'] && !environment.production) {
                /*this.route.params
                  .switchMap((params: Params) => this.alertService.getAlert(params['guid']))
                  .subscribe((s) => this.onAlert(s));*/
                this.params = params;

                this.fetchAlert();
            } else {
                this.route.params.subscribe((ps: Params) => {
                    if (ps['guid']) {
                        this.isTech = true;
                        this.params = ps;

                        this.fetchAlert();
                    }
                    //else
                    // some kind of error?
                });
            }
        });
    }

    fetchAlert() {
        delete this.alert;
        this.suggestedActions = [];
        this.actionControls = [];

        this.similarAlerts = [];

        this.loadingComplete = false;
        this.alertService
            .getAlert(this.params['guid'])
            .then((alert) => this.onAlert(alert))
            .catch((err) => {
                // error
                this.error(err);
            });
    }

    async onAlert(alert: Alert) {
        this.alert = alert;

        if (this.alert.AlertData != '') this.alert.DataObject = JSON.parse(this.alert.AlertData);

        if (!environment.production) console.log(this.alert);

        if (!this.isTech && this.alert.Status != ALERT_STATUSES.END_USER.value) {
            //console.log('redirect here');
        }

        this.messageIcon = TACs[this.alert.TAC].icon;
        this.messageClass = TACs[this.alert.TAC].message;

        if (
            this.alert.Status == ALERT_STATUSES.TODO.value ||
            this.alert.Status == ALERT_STATUSES.TASK.value
        ) {
            this.statusMessage = AlertActionLookup(this.alert.ActionType).message;
        } else if (
            this.alert.Status == ALERT_STATUSES.TASK_PENDING.value ||
            this.alert.Status == ALERT_STATUSES.TASK_COMPLETE.value
        ) {
            this.statusMessage = AlertStatusLookup(this.alert.Status).message;
        } else {
            this.statusMessage = AlertStatusLookup(this.alert.Status).message;
        }

        if (this.alert.AlertType == 'ABDSScan') {
            this.alert.DataObject.Primary.Diagnostics.push({
                Name: 'SiteName',
                Value: this.alert.SiteName,
                Parent: null,
                Children: [],
            });

            this.licenseService.getSiteABDSLicense(this.alert.SiteId).then((res) => {
                this.alert.DataObject.Primary.Diagnostics.push({
                    Name: 'ABDS_Licensed',
                    Value: res.abds,
                    Parent: null,
                    Children: [],
                });

                this.alert = Object.assign({}, this.alert); // have to do this or it will not detect the object has changed (based on the reference)
            });
        }

        if (!this.isTech) {
            this.loadingComplete = true;
            return;
        }

        await this.getAlertHistory();


        // Semantic UI calls to activate accordions
        $(this.infoBox.nativeElement).accordion();
        $(this.historyBox.nativeElement).accordion();
        $(this.similarBox.nativeElement).accordion();
        $(this.sopBox.nativeElement).accordion();

        if (this.alert.Status == ALERT_STATUSES.TODO.value) {
            this.alertService.getSimilarAlerts(this.alert).then((alerts) => {
                for (let alert of alerts) {
                    alert.DataObject = JSON.parse(alert.AlertData);
                }

                // only include TODO
                alerts = alerts.filter((a) => a.Status == ALERT_STATUSES.TODO.value);

                // sort DESC
                this.similarAlerts = alerts.sort((a, b) => {
                    return (
                        new Date(b.Created).valueOf() - new Date(a.Created).valueOf()
                    );
                });

                if (this.waitingSimilar) this.loadingComplete = true;
            });

            this.alertService.getSuggestedTags(this.alert.AlertType).then((tags) => {
                if (!environment.production) console.log(tags);

                for (let tag of tags['tag']) {
                    this.suggestedActions.push({
                        label:
                            'Add "' +
                            tag.Name +
                            '" tag to the ' +
                            OBJECT_TYPE_INFO[TAG_OBJECT_TYPES[tag.AppliesTo]].name +
                            's selected above.',
                        value: 'tag_' + tag.TagType,
                    });

                    this.actionControls.push(this.getNewActionControl());
                }

                for (let tag of tags['untag']) {
                    this.suggestedActions.push({
                        label:
                            'Remove "' +
                            tag.Name +
                            '" tag from the ' +
                            OBJECT_TYPE_INFO[TAG_OBJECT_TYPES[tag.AppliesTo]].name +
                            's selected above.',
                        value: 'untag_' + tag.TagType,
                    });

                    this.actionControls.push(this.getNewActionControl());
                }

                let requestedBy = this.alertHistory.find(
                    (a: Audit) => a.ModificationType == 'USER_UPDATE'
                );
                let wasEndUser = requestedBy ? true : false;

                let hasTicket = this.alertHistory.find(
                    (a: Audit) => a.ModificationType == 'TICKET'
                )
                    ? true
                    : false;

                if (!environment.production) console.log(this.alertHistory);

                if (wasEndUser) {
                    let email = requestedBy.ModifiedBy;
                    this.suggestedActions.push({
                        label:
                            'Do not send this alert for the selected items to "' +
                            email +
                            '" again.',
                        value: 'ignore_notify',
                    });
                    this.actionControls.push(this.getNewActionControl());

                    this.alert.CreatedBy = email;
                }

                this.suggestedActions.push({
                    label: 'Do not send this alert for the selected items again (ignore completely).',
                    value: 'ignore_alert',
                });
                this.actionControls.push(this.getNewActionControl());

                if (this.connection && (this.connection?.TicketMetadata?.length > 0)) {

                    let meta = JSON.parse(this.connection.TicketMetadata);
                    let info = {
                        typeName: '',
                        boardName: '',
                        boardLabel: '',
                    };

                    switch (this.connection.Type) {
                        case 'ATWS':
                            info.typeName = 'Autotask';
                            info.boardName = 'Queue';
                            info.boardLabel = meta.QueueName;
                            break;
                        case 'ATREST':
                            info.typeName = 'Autotask';
                            info.boardName = 'Category';
                            info.boardLabel = meta.categoryName ? meta.categoryName : meta.categoryId;
                            break;
                        case 'CW':
                        case 'CWREST':
                            info.typeName = 'ConnectWise';
                            info.boardName = 'Service Board';
                            info.boardLabel = meta.ServiceBoardT;
                            break;
                        case 'TP':
                            info.typeName = 'TigerPaw';
                            info.boardName = 'Service Board';
                            info.boardLabel = meta.ServiceBoardName;
                            break;
                        case 'KBMS':
                            info.typeName = 'Kaseya BMS';
                            info.boardName = 'Account ID';
                            info.boardLabel = meta.AccountId;
                            break;
                    }

                    if (!hasTicket) {
                        this.suggestedActions.push({
                            label:
                                'Create a ticket in ' +
                                info.typeName +
                                ' (' +
                                info.boardName +
                                ': ' +
                                info.boardLabel +
                                ')',
                            value: 'ticket',
                        });
                        this.actionControls.push(this.getNewActionControl());
                    }
                }

                if (!environment.production) {
                    this.suggestedActions.push({
                        label: 'NO OP (Debug Only)',
                        value: 'nop',
                    });
                    this.actionControls.push(this.getNewActionControl());
                }

                if (this.similarAlerts) {
                    this.loadingComplete = true;
                }
                else {
                    this.waitingSimilar = true;
                }
            });
            //.catch((err) => this.error(err))
        } else {
            if (
                this.alert.Status == ALERT_STATUSES.TASK.value ||
                this.alert.Status == ALERT_STATUSES.TASK_PENDING.value ||
                this.alert.Status == ALERT_STATUSES.TASK_COMPLETE.value
            ) {
                this.isTask = true;
            }

            this.loadingComplete = true;
        }

    }

    async getAlertHistory() {
        try {
            let logs = await this.auditService.getHistoryForAlert(this.alert);
            this.alertHistory = logs.sort((a, b) => {
                return new Date(b.ModifiedDate).valueOf() - new Date(a.ModifiedDate).valueOf();
            });
        } catch (e) {
            this.alertHistory = [];
        }

    }

    getNewActionControl(): UntypedFormControl {
        let fc = new UntypedFormControl();

        let sub = fc.valueChanges.subscribe((value) => {
            this.actionError = false;
            sub.unsubscribe();
        });

        return fc;
    }

    error(err: any) {
        if (!environment.production) console.error(err);

        let error = { title: 'Error', description: 'An unknown error occurred.' };

        if (err.status == 404) {
            error = { title: 'Expired Link', description: 'The linked item no longer exists.' };
        }

        if (err.status == 403) {
            this.loadingComplete = true;
            return;
        }

        this.errorService.error(error);
    }

    todoAction(action: string) {
        if (action == 'submit' && !this.validate()) return;

        this.completing = true;
        if (this.similarAlerts && this.similarAlerts.length) {
            this.batchAction = action;
            (this.batchConfirmModal as any).show({ closable: false });
        } else {
            switch (action) {
                case 'submit':
                    this.submit();
                    break;
                case 'complete':
                    this.markComplete();
                    break;
                case 'cancel':
                    this.cancel();
                    break;
            }
        }
    }

    multiAction() {
        (this.batchConfirmModal as any).hide();

        if (!this.selectedActions) this.selectedActions = [];
        this.selectedActions.push('batch_related');

        switch (this.batchAction) {
            case 'submit':
                this.submit();
                break;
            case 'complete':
                this.markComplete();
                break;
            case 'cancel':
                this.cancel();
                break;
        }
    }

    cancelBatch() {
        this.completing = false;
        this.selectedActions = [];
    }

    validate() {
        this.actionError = false;
        this.listError = false;
        delete this.selectedActions;
        delete this.selectedItems;

        // Build list of selected actions
        let selectedActions = [];
        for (let i = 0; i < this.actionControls.length; i++) {
            if (this.actionControls[i].value) {
                selectedActions.push(this.suggestedActions[i].value);
            }
        }

        if (selectedActions.length == 0) {
            this.actionError = true;
        }

        // Build object of selected data
        let item = Object.assign({}, this.alert.DataObject);

        let secondary = [];
        for (let i = 0; i < item.Secondary.length; i++) {
            if (this.dataControl.sf[i].value) secondary.push(this.alert.DataObject.Secondary[i]);
        }

        if (secondary.length == 0 && !this.dataControl.pf.value) {
            this.listError = true;
        }

        if (this.actionError || this.listError) return false;

        item.Secondary = secondary;

        this.selectedActions = selectedActions;
        this.selectedItems = item;

        return true;
    }

    submit() {
        if (!this.selectedActions || !this.selectedActions) {
            if (!this.validate()) return;
        }

        this.loadingComplete = false;
        // Submit changes
        this.alertService
            .alertAction(this.alert, this.selectedActions, this.selectedItems)
            .then((res) => {
                this.loadingComplete = true;
                this.returnToParentPage('Your changes have been processed.');
            })
            .catch((err) => {
                this.loadingComplete = true;
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem processing your request. Please try again soon.'
                );
            });
    }

    submitNew(action: string) {
        this.loadingComplete = false;
        this.alertService
            .alertAction(this.alert, [action], this.alert.DataObject)
            .then((res) => {
                if (action == 'todo') {
                    this.ngOnInit();
                    this.notificationService.toast.success(
                        'Success',
                        'The alert was changed to "To Do"'
                    );
                } else {
                    this.loadingComplete = true;
                    this.returnToParentPage('Ticket created.');
                }
            })
            .catch((err) => {
                this.error(err);
            });
    }

    markComplete() {
        if (!this.selectedActions) this.selectedActions = [];
        this.selectedActions.push('complete');

        this.loadingComplete = false;
        this.alertService
            .alertAction(this.alert, this.selectedActions, this.alert.DataObject)
            .then((res) => {
                this.loadingComplete = true;
                if (this.batchAction)
                    this.returnToParentPage(`${this.similarAlerts.length} items marked complete.`);
                else this.returnToParentPage('To Do item was marked complete.');
            })
            .catch((err) => this.error(err));
    }

    markGdprComplete() {
        if (!this.selectedActions) this.selectedActions = [];
        this.selectedActions.push('gdprtaskcomplete');

        this.loadingComplete = false;
        this.alertService
            .alertAction(this.alert, this.selectedActions, this.alert.DataObject)
            .then((res) => {
                this.loadingComplete = true;
                if (this.batchAction)
                    this.returnToParentPage(`${this.similarAlerts.length} items marked complete.`);
                else this.returnToParentPage('Task was marked complete.');
            })
            .catch((err) => this.error(err));
    }

    // Single function to return to the parent page
    returnToParentPage(successMessage?: string) {
        //let next = '';

        //// append parent route paths
        //// note this will only work for one level deep
        //if (this.route.parent.snapshot.url.length) {
        //  for (let segment of this.route.parent.snapshot.url)
        //    next += `/${segment.path}`
        //}

        //let parent = '', idx = 0;
        //while (idx <= this.route.snapshot.url.length - 2) {
        //  parent += `/${this.route.snapshot.url[idx].path}`;
        //  idx++;
        //}

        //next += parent;

        this.router.navigate(['..'], { relativeTo: this.route });
        if (successMessage) this.notificationService.toast.success('Success', successMessage);
    }

    // angular sanitizes any [href] not starting with "href" so I was not able to use javascript:functionName in the button url without handling it seperately
    customClickCommand(commandName: string) {
        //console.log('Custom Click Command Run');
        this.setPrevAlert();
        if (commandName) {
            //console.log('commandName: ' + commandName);

            if (commandName === 'startAssessment') {
                this.startAssessment();
            } else if (commandName === 'startArchivedAssessment') {
                this.startArchivedAssessment();
            } else if (commandName === 'rescan') {
                this.rescan();
            } else if (commandName === 'uploadscan') {
                this.uploadscan();
            } else if (commandName === 'doprescan') {
                this.doprescan();
            } else if (commandName === 'skip') {
                this.skipupdate();
            } else if (commandName === 'rescannetwork') {
                this.rescanNetwork();
            } else if (commandName === 'rescanpush') {
                this.rescanPush();
            } else if (commandName === 'rescanivul') {
                this.rescanIvul();
            } else if (commandName === 'yes') {
                this.answerYes();
            } else if (commandName === 'no') {
                this.answerNo();
            } else if (commandName === 'goToConnections') {
                this.goToConnections();
            } else if (commandName === 'cmmclevel1') {
                this.cmmclevel1();
            } else if (commandName === 'cmmclevel2') {
                this.cmmclevel2();
            } else if (commandName === 'cmmclevel3') {
                this.cmmclevel3();
            } else if (commandName === 'connectorboth') {
                this.showConnectorBothFiles();
            } else if (commandName === 'doazurescan') {
                this.doazurescan();
            }
        }
    }

    skipupdate() {
        this.notificationService.toast.info('Please Wait', 'Update Skipping.');
        this.siteService.skipUpdate(this.alert.SiteId).then((s) => {
            this.returnToParentPage('Update Skipped.');
        });
    }

    doprescan() {
        this.notificationService.toast.success('Please Wait', 'Prescan Restarting.');
        this.siteService.doPrescan(this.alert.SiteId).then((s) => {
            this.returnToParentPage();
        });
    }

    doazurescan() {
        this.notificationService.toast.success('Please Wait', 'Azure Scan Restarting.');
        this.siteService.doAzurescan(this.alert.SiteId).then((s) => {
            this.returnToParentPage();
        });
    }

    startArchivedAssessment() {
        this.siteService.createGdprTodo5(this.alert.SiteId).then((s) => {
            let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
            this.router.navigateByUrl(nextUrl);

            this.notificationService.toast.success('Success', 'Assessment was started.');
        });
    }

    goToConnections() {
        this.router.navigateByUrl('/settings/connections');
    }

    startAssessment() {
        this.siteService
            .createGdprAssessment(this.alert.SiteId)
            .then((s) => {
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);

                this.notificationService.toast.success('Success', 'Assessment was started.');

                this.siteDashboardService.setDashboardItem(this.site.Id, 'Assessment');
            })
            .catch((err) => this.error(err));
    }

    answerYes() {
        this.siteService
            .answerTodoYN(this.alert.SiteId, this.alert, true)
            .then((s) => {
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            })
            .catch((err) => this.error(err));
    }

    answerNo() {
        this.siteService
            .answerTodoYN(this.alert.SiteId, this.alert, false)
            .then((s) => {
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            })
            .catch((err) => this.error(err));
    }

    cmmclevel1() {
        this.siteService
            .setCmmcLevel(this.alert.SiteId, '1')
            .then((s) => {
                this.setSiteDescription('CMMC Level 1');
                this.messageService.broadcast(PING_SITE);
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            })
            .catch((err) => this.error(err));
    }

    cmmclevel2() {
        this.siteService
            .setCmmcLevel(this.alert.SiteId, '2')
            .then((s) => {
                this.setSiteDescription('CMMC Level 2');
                this.messageService.broadcast(PING_SITE);
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            })
            .catch((err) => this.error(err));
    }

    cmmclevel3() {
        this.siteService
            .setCmmcLevel(this.alert.SiteId, '3')
            .then((s) => {
                this.setSiteDescription('CMMC Level 3');

                this.messageService.broadcast(PING_SITE);
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            })
            .catch((err) => this.error(err));
    }

    setSiteDescription(desc: string) {
        this.siteService.app_getCurrentSite().then((site: Site) => {
            if (site) {
                site.TypeDesc = desc;
            }
        });
    }

    rescan() {
        this.siteService.rescanGdprCdfs(this.alert.SiteId);
    }

    rescanNetwork() {
        this.siteService.rescanGdprNetwork(this.alert.SiteId).then((s) => {
            this.returnToParentPage();
        });
    }

    rescanPush() {
        this.siteService.rescanGdprPush(this.alert.SiteId).then((s) => {
            this.returnToParentPage();
        });
    }

    rescanIvul() {
        this.siteService.rescanGdprIvul(this.alert.SiteId).then((s) => {
            this.returnToParentPage();
        });
    }

    rerunAction() {
        this.rollbackType = 'Re-running';
        this.rollback();
    }

    modifyWorksheet() {
        this.rollbackType = 'Modifying';
        this.rollback();
    }

    rollback() {
        this.loadingComplete = false;
        this.siteService
            .getRollbackToTodoImpact(this.alert.SiteId, this.alert.AlertType)
            .then((s) => {
                this.loadingComplete = true;
                this.rollbackImpact = [];
                this.rollbackImpact.push(...s);

                (this.rollbackActionModal as any).show();
            })
            .catch((err) => {
                this.loadingComplete = true;
                this.rollbackError = err.error;
                (this.rollbackErrorModal as any).show();
            });
    }

    confirmRollbackError() {
        (this.rollbackErrorModal as any).hide();
    }

    confirmRollback(confirmed: boolean) {
        (this.rollbackActionModal as any).hide();

        if (confirmed) {
            this.loadingComplete = false;
            this.notificationService.toast.info(this.rollbackType, 'Processing Action.');
            this.siteService.rollbackToTodo(this.alert.SiteId, this.alert.AlertType).then((s) => {
                //console.log('rollback complete');
                this.loadingComplete = true;
                this.notificationService.toast.success(this.rollbackType, 'Action was successful.');

                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            });
        }
    }

    fileChange(evt: any) {
        (this.uploadCdfModal as any).hide();

        let fileList: FileList = evt.target.files;

        if (fileList.length > 0) {
            this.notificationService.toast.info('File Upload', 'File is uploading...');
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('siteid', '' + this.alert.SiteId);
            formData.append('todomsg', this.alert.Message);
            formData.append('uploadFile', file, file.name);

            this.siteService.uploadCdfAlerts(formData).then((s) => {
                this.notificationService.toast.success(
                    'File Upload',
                    'File upload is complete. Merging of file will complete in 5 to 10 minutes'
                );
                this.checkParams();
            });
        }
    }

    uploadscan() {
        (this.uploadCdfModal as any).show();
    }

    cancel() {
        if (!this.selectedActions) this.selectedActions = [];
        this.selectedActions.push('cancel');

        this.loadingComplete = false;
        this.alertService
            .alertAction(this.alert, this.selectedActions, this.alert.DataObject)
            .then((res) => {
                if (this.batchAction)
                    this.returnToParentPage(`${this.similarAlerts.length} items canceled.`);
                else this.returnToParentPage('To Do item was canceled.');
            })
            .catch((err) => this.error(err));
    }

    endUserSubmit() {
        let action: string;

        if (this.params.action == 'investigate') action = this.params.action;
        else {
            if (this.ignoreReason) action = 'ignore_' + this.ignoreReason;
            else {
                this.reasonError = true;
                return;
            }
        }

        this.alert.Notes = this.userNotes || '';

        this.loadingComplete = false;
        this.alertService
            .endUserAction(this.alert, action, this.params.email)
            .then((res) => {
                this.loadingComplete = true;

                let data: ConfirmData = {
                    heading: 'Request Submitted',
                    message: 'Your request has been submitted successfully.',
                    proceedTo: '',
                };

                this.confirmService.confirm(data);
            })
            .catch((err) => {
                this.loadingComplete = true;

                this.notificationService.toast.error(
                    'Error',
                    'There was a problem processing your request. Please try again soon.'
                );
            });
    }

    setPrevAlert() {
        //console.log("Setting prevalert to; ", this.router.url);
        this.navService.saveReturnUrl();
        if (this.site && this.site.Id) {
            this.uiService.setControlValue('ASSESSMENT_SELECT_' + this.site.Id, '__current');
        }
    }

    getButtonHref(button: AlertButton) {
        let url = button.Url.replace('{sitename}', this.alert.SiteName);
        // if (this.params.guid) { url = url + '?todo='+ this.params.guid; }
        // console.log(url);
        return url;
    }

    showConnectorBothFiles() {
        this.selectedConnectorFiles = [];
        this.connectorModal.show();
        this.connectorModalLoadingComplete = true;

        // this.getConnectorFilesList('both').then((s) => {
        //     this.connectorModalLoadingComplete = true;
        // });
    }

    addSelectedConnectorFiles() {
        if (this.selectedConnectorFiles) {
            for (let file of this.selectedConnectorFiles) {
                this.filesService
                    .setConnectorFilesToMerge(
                        this.site.Id,
                        this.alert.AlertType,
                        this.selectedConnectorFiles
                    )
                    .then((res) => {
                        (this.connectorModal as any).hide();
                        this.notificationService.toast.success(
                            'File Upload',
                            'File upload is complete. Merging of file will complete in 5 to 10 minutes'
                        );
                        this.checkParams();
                    });
            }
        }
    }

    downloadSelectedConnectorFiles() {
        this.loadingZip = true;
        this.filesService
            .getConnectorFilesZip(this.site.Id, this.selectedConnectorFiles)
            .then((b64) => {
                this.loadingZip = false;
                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, 'ConnectorFiles.zip');
            })
            .catch((err) => {
                this.loadingZip = false;
                console.error(err);
            });
    }

    deleteSelectedConnectorFiles() {
        if (
            confirm(
                'Files will be removed permanently and will no longer be available for import. Proceed?'
            )
        ) {
            this.deletingFiles = true;
            this.filesService
                .deleteConnectorFiles(this.site.Id, this.selectedConnectorFiles)
                .then((res) => {
                    this.doReload++;
                    this.cdr.detectChanges();
                    this.deletingFiles = false;
                })
                .catch((err) => {
                    this.deletingFiles = false;
                    console.error(err);
                });
        }
    }

    sortTodos() {
        if (!this.similarAlerts) return;
        if (this.sortMethod === 'Created' || this.sortMethod === undefined) {
            this.similarAlerts = this.similarAlerts.sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return (
                        new Date(a.Created).getTime() - new Date(b.Created).getTime() ||
                        a.AlertType.localeCompare(b.AlertType)
                    );
                } else {
                    return (
                        new Date(b.Created).getTime() - new Date(a.Created).getTime() ||
                        b.AlertType.localeCompare(a.AlertType)
                    );
                }
            });
        } else {
            this.similarAlerts = this.similarAlerts.sort((a, b) => this.sortByColumn(a, b));
        }
    }

    processSort(sortBy: string) {
        this.sortDirection = sortBy.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sortBy.replace('-', '');
        this.sortTodos();
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }

    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) || this.cmpTime(a.Created, b.Created)
        );
    }
}
