<div class="audit-search-container">
    <div class="item">
        <div class="audit-search-box" style="margin-left: 0">
            Range: &nbsp;
            <p-calendar
                [style]="{ width: '210px' }"
                [(ngModel)]="dateRange"
                (onSelect)="filter()"
                (onInput)="filter()"
                selectionMode="range"
                [dateFormat]="dateFormat | primeNGDateFormat"
                appendTo="body"
                        [class]="dateRange == null ? 'ng-dirty ng-invalid': ''"
                #calendar></p-calendar>
        </div>
    </div>
    <div class="item searchInput">
        <sm-input
            placeholder="Search..."
            [(model)]="searchKey"
            (modelChange)="filter()"
            debounce="420"
            icon="icon-search"></sm-input>
    </div>
    <div class="item">
        <itc-checkbox
            label="Show Admin Messages"
            [control]="showAdminControl"
            (checked)="handleShowAdminChecked($event)"></itc-checkbox>
    </div>
</div>
