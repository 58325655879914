<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Users</h1>
    <div class="flex-right">
        <itc-button
            type="primary"
            icon="fa-plus"
            [disabled]="!loadingComplete"
            (onclick)="addEditUserRef.show()">
            Add User
        </itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-banner type="info" [closable]="true">
        Removing a user from the table below only removes that user's access from this site. To
        delete a user, have the global administrator remove the user from Admin Settings.
    </itc-banner>

    <table
        class="itc-table"
        sortable-table
        sortColumn="Username"
        sortDirection="asc"
        (sorted)="sortUsers($event)">
        <thead>
            <tr>
                <th sortable-column="Username">Username</th>
                <th sortable-column="FirstName">First Name</th>
                <th sortable-column="LastName">Last Name</th>
                <th sortable-column="GlobalRoleId">Global Access Level</th>
                <th sortable-column="roleText">Site Roles</th>
                <th sortable-column="groupsText">Groups</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!siteUsers?.length">
                <td colspan="6"><strong>There are no entries yet.</strong></td>
            </tr>
            <tr *ngFor="let user of siteUsers">
                <td>{{ user.Username }}</td>
                <td>{{ user.FirstName }}</td>
                <td>{{ user.LastName }}</td>
                <td>{{ user.GlobalRoleId | accessRole : 'siteUser' }}</td>
                <td>{{ getUserRoleName(user.Role) }}</td>
                <td>
                    <span *ngFor="let group of getGroupsByUserId(user.UserId); last as isLast">
                        {{ group?.Name + (!isLast ? ', ' : '') }}
                    </span>
                </td>
                <td style="text-align: right">
                    <itc-button
                        type="icon"
                        icon="fa-envelope"
                        (onclick)="showUserInviteModal(user)"
                        tooltip="Invite"></itc-button>
                    <itc-button
                        type="icon"
                        icon="fa-trash-can"
                        (onclick)="showUserDeleteModal(user)"
                        tooltip="Delete"></itc-button>
                </td>
            </tr>
        </tbody>
    </table>
</sds-loader>

<add-edit-user-form
    userType="site"
    [allowExisting]="true"
    [existingUsers]="fmitUsers"
    [siteId]="site?.Id"
    (userAdded)="addUser($event)"
    (usersAdded)="addExisting($event)"
    #addEditUserRef></add-edit-user-form>

<!-- Delete User Modal -->
<sm-modal title="Delete User" class="sm" #userDeleteModal>
    <modal-content>
        <itc-banner type="warning">
            Are you sure you want to remove this user from the site?
        </itc-banner>

        <table class="ui table">
            <tbody>
                <tr>
                    <td><b>Name</b></td>
                    <td>
                        {{ (selectedUser?.FirstName || '') + ' ' + (selectedUser?.LastName || '') }}
                    </td>
                </tr>
                <tr>
                    <td><b>Username</b></td>
                    <td>{{ selectedUser?.Username }}</td>
                </tr>
                <tr>
                    <td><b>Roles</b></td>
                    <td>{{ getUserRoleName(selectedUser?.Role) }}</td>
                </tr>
            </tbody>
        </table>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" [disabled]="deleteUserWaiting">
            Cancel
        </itc-button>
        <itc-button
            type="danger"
            [disabled]="deleteUserWaiting"
            (onclick)="removeUser(selectedUser)">
            Delete
        </itc-button>
    </modal-actions>
</sm-modal>

<!--Invite User Modal -->
<sm-modal title="Invite User" class="sm" #userInviteModal>
    <modal-content>
        <p>Send invitation for the {{ site?.Name }} to {{ selectedUser?.Username }} ?</p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button type="primary" (onclick)="sendInvitation(selectedUser)">
            Send Invitation
        </itc-button>
    </modal-actions>
</sm-modal>

<!--No role for invite user modal-->

<sm-modal title="Invite User" class="sm" #noRolInviteModal>
    <modal-content>
        <p>Role must be assigned to this user before the user can be invited to this site.</p>
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (onclick)="noRolInviteModal.hide()">OK</itc-button>
    </modal-actions>
</sm-modal>
