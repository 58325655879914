<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail
        [crumbs]="breadcrumbs"
        *ngIf="dataExplorerType === 'indoc'"></sds-breadcrumb-trail>
    <div class="ui grid fluid dashboard indocDetail">
        <div class="ten wide column">
            <!-- Left Column -->
            <div class="ui grid">
                <div class="sixteen wide column">
                    <div class="ui card computerInfoCard indocDetailCard">
                        <a href="#" class="settingsIcon"><i class="setting icon"></i></a>
                        <h1>
                            {{ computer?.Domain }}{{ computer ? '\\' : '' }}{{ computer?.Name }}
                        </h1>
                        <div class="meta">{{ computer?.Ip?.split(',').join(', ') }}</div>
                        <div
                            class="ui top attached secondary menu siteDetailTabs"
                            id="siteDataComputersTabs">
                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'system'"
                                (click)="selectedTab = 'system'">
                                System
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'disk'"
                                (click)="selectedTab = 'disk'">
                                Disk
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'security'"
                                (click)="selectedTab = 'security'">
                                Security
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'patches'"
                                (click)="selectedTab = 'patches'">
                                Patches
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'licensekeys'"
                                (click)="selectedTab = 'licensekeys'">
                                License Keys
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'shares'"
                                (click)="selectedTab = 'shares'">
                                Shares
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'printers'"
                                (click)="selectedTab = 'printers'">
                                Printers
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'apps'"
                                (click)="selectedTab = 'apps'">
                                Apps
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'ports'"
                                (click)="selectedTab = 'ports'">
                                Open Ports
                            </a>

                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'users'"
                                (click)="selectedTab = 'users'">
                                Users
                            </a>
                        </div>

                        <!-- System -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="system"
                            [class.active]="selectedTab == 'system'">
                            <table class="ui small table">
                                <tbody>
                                    <tr>
                                        <td colspan="2"><b>Network Information</b></td>
                                    </tr>
                                    <tr>
                                        <td>DNS Name(s)</td>
                                        <td>{{ computer?.Details.System.Info.DNSName }}</td>
                                    </tr>
                                    <tr>
                                        <td class="truncatableCell">IP Address(es)</td>
                                        <td class="breakAll">
                                            {{ computer?.Ip?.split(',').join(', ') }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Last AD Checkin</td>
                                        <td>
                                            {{
                                                computer?.Details.System.Info
                                                    .LastADCheckingFormatted == 'Never'
                                                    ? 'Never'
                                                    : (computer?.Details.System.Info
                                                          .LastADCheckingFormatted + '+00:00'
                                                      | accountDateTime)
                                            }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2"><b>System Profile</b></td>
                                    </tr>
                                    <tr>
                                        <td>OS</td>
                                        <td>{{ computer?.Os }}</td>
                                    </tr>
                                    <tr *ngIf="computer?.Details.System.Profile.WindowsKey">
                                        <td>Windows Key</td>
                                        <td>{{ computer?.Details.System.Profile.WindowsKey }}</td>
                                    </tr>
                                    <tr>
                                        <td>Manufacturer</td>
                                        <td>{{ computer?.Details.System.Profile.Manufacturer }}</td>
                                    </tr>
                                    <tr>
                                        <td>Service Tag</td>
                                        <td>{{ computer?.Details.System.Profile.ServiceTag }}</td>
                                    </tr>
                                    <tr>
                                        <td>CPU</td>
                                        <td>{{ computer?.Details.System.Profile.CPU }}</td>
                                    </tr>
                                    <tr>
                                        <td>RAM</td>
                                        <td>{{ computer?.Details.System.Profile.RAM }} MB</td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.System.Profile
                                                .ComputerMemoryBank | mapToIterable : 'name'
                                        ">
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.value }}</td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.System.Profile.ComputerDisk
                                                | mapToIterable : 'name'
                                        ">
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Disk -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'disk'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Drive</th>
                                        <th>Capacity</th>
                                        <th>Used</th>
                                        <th>% Used</th>
                                        <th>Available</th>
                                        <th>% Available</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let disk of computer?.Details.Disk.Drives">
                                        <td>{{ disk.DriveName }}</td>
                                        <td>{{ disk.Capacity }} GB</td>
                                        <td>{{ disk.Used }} GB</td>
                                        <td>{{ disk.PercentUsed }}</td>
                                        <td>{{ disk.Available }} GB</td>
                                        <td>{{ disk.PercentAvailable }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="computer?.Details.Disk.Drives.length == 0"
                                        style="text-align: center">
                                        <td colspan="6" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Security -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="security"
                            [class.active]="selectedTab == 'security'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>On</th>
                                        <th>Current</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="3"><b>Anti-Spyware</b></td>
                                    </tr>
                                    <tr
                                        *ngIf="
                                            !computer?.Details.EndpointSecurity.AntiSpyware.length
                                        ">
                                        <td colspan="3" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.EndpointSecurity
                                                .AntiSpyware
                                        ">
                                        <td>{{ row.Name }}</td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.On == 'True'"
                                                [class.check]="row.On == 'True'"
                                                [class.red]="row.On != 'True'"
                                                [class.times]="row.On != 'True'"></i>
                                        </td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.Current == 'True'"
                                                [class.check]="row.Current == 'True'"
                                                [class.red]="row.Current != 'True'"
                                                [class.times]="row.Current != 'True'"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3"><b>Anti-Virus</b></td>
                                    </tr>
                                    <tr
                                        *ngIf="
                                            !computer?.Details.EndpointSecurity.AntiVirus.length
                                        ">
                                        <td colspan="3" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.EndpointSecurity.AntiVirus
                                        ">
                                        <td>{{ row.Name }}</td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.On == 'True'"
                                                [class.check]="row.On == 'True'"
                                                [class.red]="row.On != 'True'"
                                                [class.times]="row.On != 'True'"></i>
                                        </td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.Current == 'True'"
                                                [class.check]="row.Current == 'True'"
                                                [class.red]="row.Current != 'True'"
                                                [class.times]="row.Current != 'True'"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3"><b>Backup</b></td>
                                    </tr>
                                    <tr *ngIf="!computer?.Details.EndpointSecurity.BackUp.length">
                                        <td colspan="3" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.EndpointSecurity.BackUp
                                        ">
                                        <td>{{ row.Name }}</td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.On == 'True'"
                                                [class.check]="row.On == 'True'"
                                                [class.red]="row.On != 'True'"
                                                [class.times]="row.On != 'True'"></i>
                                        </td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.Current == 'True'"
                                                [class.check]="row.Current == 'True'"
                                                [class.red]="row.Current != 'True'"
                                                [class.times]="row.Current != 'True'"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3"><b>Firewall</b></td>
                                    </tr>
                                    <tr *ngIf="!computer?.Details.EndpointSecurity.Firewall.length">
                                        <td colspan="3" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.EndpointSecurity.Firewall
                                        ">
                                        <td>{{ row.Name }}</td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.On == 'True'"
                                                [class.check]="row.On == 'True'"
                                                [class.red]="row.On != 'True'"
                                                [class.times]="row.On != 'True'"></i>
                                        </td>
                                        <td>
                                            <i
                                                class="icon"
                                                [class.green]="row.Current == 'True'"
                                                [class.check]="row.Current == 'True'"
                                                [class.red]="row.Current != 'True'"
                                                [class.times]="row.Current != 'True'"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Patches -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="patches"
                            [class.active]="selectedTab == 'patches'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Issue</th>
                                        <th>Score</th>
                                        <th>Assessment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="computer?.Details.PatchStatus.WindowsUpdates">
                                        <td>
                                            <b>Windows Updates</b>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.PatchStatus.WindowsUpdates
                                        ">
                                        <td>{{ row.Issue }}</td>
                                        <td>{{ row.Score }}</td>
                                        <td>{{ row.Assessment }}</td>
                                    </tr>

                                    <tr
                                        *ngIf="
                                            computer?.Details.PatchStatus.WindowsUpdates.length == 0
                                        "
                                        style="text-align: center">
                                        <td colspan="3" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- License Keys -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="licensekeys"
                            [class.active]="selectedTab == 'licensekeys'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Application Name</th>
                                        <th>License Key</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let row of computer?.Details.LicenseKeys.Applications
                                        ">
                                        <td>{{ row.ApplicationName }}</td>
                                        <td>{{ row.LicenseKey }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="!computer?.Details.LicenseKeys.Applications.length"
                                        style="text-align: center">
                                        <td colspan="2" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Shares -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="shares"
                            [class.active]="selectedTab == 'shares'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Share</th>
                                        <th class="collapsing">Share Type</th>
                                        <th>User/Group</th>
                                        <th class="collapsing">File System Permissions</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-template
                                        ngFor
                                        let-item
                                        [ngForOf]="
                                            computer?.Details.SharedConnected.PermissionsForShare
                                        ">
                                        <tr>
                                            <td>{{ item.ShareName }} ({{ item.SharePath }})</td>
                                            <td>{{ item.ShareType }}</td>
                                            <td>{{ item.FolderSecurityList[0]?.Identity }}</td>
                                            <td>{{ item.FolderSecurityList[0]?.Rights }}</td>
                                            <td>{{ item.FolderSecurityList[0]?.Type }}</td>
                                        </tr>
                                        <tr *ngFor="let row of item.FolderSecurityList.slice(1)">
                                            <td></td>
                                            <td></td>
                                            <td>{{ row.Identity }}</td>
                                            <td>{{ row.Rights }}</td>
                                            <td>{{ row.Type }}</td>
                                        </tr>
                                    </ng-template>
                                    <tr
                                        *ngIf="
                                            !computer?.Details.SharedConnected.PermissionsForShare
                                                .length
                                        ">
                                        <td colspan="5" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Printers -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'printers'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>IP Address</th>
                                        <th>Printer Name</th>
                                        <th>Accessed From</th>
                                        <th>Location</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let printer of computer?.Details.ConnectedPrinters
                                                .Printers
                                        ">
                                        <td class="breakAll">{{ printer.IPAddress }}</td>
                                        <td>{{ printer.PrinterName }}</td>
                                        <td>{{ printer.AccessedFrom }}</td>
                                        <td>{{ printer.Location }}</td>
                                        <td>{{ printer.Comment }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="
                                            computer?.Details.ConnectedPrinters.Printers.length == 0
                                        "
                                        style="text-align: center">
                                        <td colspan="5" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Apps -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'apps'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Application Name</th>
                                        <th>Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let app of computer?.Details.Apps.App">
                                        <td>{{ app.ApplicationName }}</td>
                                        <td>{{ app.Version }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="computer?.Details.Apps.App.length == 0"
                                        style="text-align: center">
                                        <td colspan="2" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Ports -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="ports"
                            [class.active]="selectedTab == 'ports'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Listening Ports</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let port of computer?.Details.ListeningPorts.Ports">
                                        <td>{{ port }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="computer?.Details.ListeningPorts.Ports.length == 0"
                                        style="text-align: center">
                                        <td style="text-align: left"><em>None Detected</em></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Users -->
                        <div
                            class="ui bottom attached tab segment"
                            data-tab="users"
                            [class.active]="selectedTab == 'users'">
                            <sm-select [(model)]="loginType" (onChange)="setUserTotals($event)">
                                <option value="All">All</option>
                                <option value="InteractiveLogon">Interactive Login</option>
                                <option value="NetworkLogon">Network Login</option>
                                <option value="RemoteLogon">Remote Login</option>
                            </sm-select>

                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th colspan="2">Past 30 Days</th>
                                        <th colspan="2">Past 7 Days</th>
                                        <th colspan="2">Past 24 Hours</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of users">
                                        <td>{{ row.UserName }}</td>
                                        <td>{{ row.Success30 }}</td>
                                        <td>{{ row.Failed30 }}</td>
                                        <td>{{ row.Success7 }}</td>
                                        <td>{{ row.Failed7 }}</td>
                                        <td>{{ row.Success24 }}</td>
                                        <td>{{ row.Failed24 }}</td>
                                    </tr>
                                    <tr *ngIf="users.length == 0" style="text-align: center">
                                        <td colspan="7" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six wide column">
            <!-- Right column -->
            <itglue-sidebar [deviceData]="deviceData"></itglue-sidebar>

            <div class="ui grid">
                <div class="sixteen wide column" style="display: none">
                    <!--TODO add this back in after we have data to feed it-->
                    <sds-risk-score-card [riskScore]="riskScore"></sds-risk-score-card>
                    <!-- End Risk Score Card -->
                </div>

                <div class="sixteen wide column">
                    <sds-notes-card
                        [(notes)]="notes"
                        [itemType]="'Computer'"
                        [itemValue]="itemId"
                        (onUpdate)="saveNotes($event)"></sds-notes-card>
                </div>

                <div class="sixteen wide column">
                    <sds-sop-card
                        [SOPs]="SOPs"
                        [siteId]="site?.Id"
                        [itemJSON]="itemJSON"
                        (onUpdate)="saveProcedures($event)"></sds-sop-card>
                </div>
                <div class="sixteen wide column">
                    <sds-related-items-card
                        [parentId]="computerId"
                        [parentType]="'computer'"
                        [site]="site"
                        [items]="computer?.RelatedItems"></sds-related-items-card>
                </div>
                <div class="sixteen wide column">
                    <!-- disabled for now while we work on some things -->
                    <sds-tags-card
                        [parentId]="computerId"
                        [siteId]="site?.Id"
                        [tags]="computer?.SmartTags"></sds-tags-card>
                </div>
            </div>
        </div>
    </div>
</sds-loader>
