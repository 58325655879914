import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { ItcTooltipDirective, TooltipPosition } from './itc/itc-tooltip.directive';
@Directive({
    selector: '[truncateItems]',
    standalone: true,
    hostDirectives: [ItcTooltipDirective],
})
export class TruncateItemsDirective implements OnInit {
    @Input({ required: true }) truncateItems: number;
    @Input() truncateItemsDelimiter: string = ',';
    @Input() ttPosition: TooltipPosition = 'bottom-left';
    @Input() ttScrollable = false;
    @Input() ttDelimiter = ',\n';

    private tooltipDirective = inject(ItcTooltipDirective);
    private fullText = '';
    private fullTextArray: string[];

    constructor(private el: ElementRef) {}
    ngOnInit(): void {
        setTimeout(() => {
            this.fullText = this.el.nativeElement.innerText;
            this.fullTextArray = this.fullText.split(this.truncateItemsDelimiter);
            if (this.fullTextArray.length > this.truncateItems) {
                this.el.nativeElement.innerHTML =
                    this.fullTextArray.slice(0, this.truncateItems).join(', ') +
                    ' <span style="white-space: nowrap;">+' +
                    (this.fullTextArray.length - this.truncateItems) +
                    '</span>';
                this.tooltipDirective.tooltipText = this.fullTextArray.join(this.ttDelimiter);
                this.tooltipDirective.ttScrollable = this.ttScrollable;
                this.tooltipDirective.ttPosition = this.ttPosition;
            }
        }, 1);
    }
}
