export class Base64UrlService {
    constructor() { }

    encode(url: string): string {
        const bytes = new TextEncoder().encode(url);
        const binString = String.fromCodePoint(...bytes);
        const base64Encoded = btoa(binString);
        return base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }
}
