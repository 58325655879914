import {
    Directive,
    Input,
    ElementRef,
    HostListener,
    Renderer2,
    Output,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Directive({
    selector: '[tooltip]',
    standalone: true,
})
export class ItcTooltipDirective {
    /** Text to display in tooltip */
    @Input('tooltip') tooltipText: string;
    /** Title to display in tooltip */
    @Input() ttTitle: string;
    /**
     * Tooltip Link
     *
     * @param TTLink: TTLINK;
     */
    @Input() set ttLink(link: TTLink | TTLink[] | string) {
        if (typeof link === 'string') {
            // string will just be the url
            this._ttLink.url = link;
            this._ttLinks = [this._ttLink];
        } else if (Array.isArray(link) && link.length) {
            // TTLink array
            this._ttLinks = [...link];
        } else if (!Array.isArray(link)) {
            // single TTLink
            this._ttLinks = [link];
        }
        // backwards compatability, should be using .url for now
        this._ttLinks.forEach((link) => {
            if (link.href) {
                link.url = link.href;
                delete link.href;
            }
        });
    }

    /** How to trigger the tooltip */
    @Input() ttTrigger: 'hover' | 'click' | 'focus' = 'hover';
    /** Whether to display tooltip or not */
    @Input() set ttHidden(val: boolean) {
        this._hidden = val;
        // this logic is for when the "ttHidden" variable may change while it's already displayed (app launcher)
        if (val && this.tooltip) {
            this.hide();
        }
    }
    get ttHidden() {
        return this._hidden;
    }
    /** Location of tooltip, relative to anchor */
    @Input() set ttPosition(value: TooltipPosition) {
        this._position = value.replace(' ', '-');
    }
    /** How far to offset the pointer from the host */
    @Input() set ttOffset(offset: number) {
        offset = typeof offset === 'string' ? parseInt(offset, 10) : offset;
        this._offset = offset;
    }
    /** How long to delay before showing tooltip*/
    @Input() set ttDelay(delay: number) {
        delay = typeof delay === 'string' ? parseInt(delay, 10) : delay;
        this._delay = delay;
    }
    @Input() ttScrollable = false;
    @Input() ttLinksDelimiter: string = '&nbsp;&#9679;&nbsp;';

    @Output() ttLinkClick = new EventEmitter<TTLink>();
    @Output() onLinkClick = new EventEmitter<TTLink>();

    _position = 'top';
    _offset = 4;
    // _delay = 25;
    _delay = 0;
    tooltip: HTMLElement;
    _hidden = false;
    _ttLink: TTLink = {
        url: '',
        text: 'Learn More',
        newTab: true,
    };
    _ttLinks: TTLink[] = [];

    isLinkInputted = false;
    prevText: string;

    constructor(
        public el: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute
    ) {
        /* This is needed to clear the tooltip when navigating, mainly found on org folders */
        router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                take(1)
            )
            .subscribe((event) => {
                if (this.tooltip) {
                    this.hide(true);
                }
            });
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.ttTrigger !== 'hover') {
            return;
        }

        // add hover to element when it's hovered over
        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.ttTrigger !== 'hover') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }
    }

    @HostListener('click') onClick() {
        if (this.ttTrigger !== 'click') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }

        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('focus') onFocus() {
        if (this.ttTrigger !== 'focus') {
            return;
        }

        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('blur') onBlur() {
        if (this.ttTrigger !== 'focus') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }
    }

    show() {
        if (!this.tooltipText || this.tooltipText === '' || this.tooltipText === null) {
            return;
        }

        this.create();
        this.setPosition();
        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip--active');
        this.el.nativeElement.classList.add('kaseya-ui-tooltip--hover');
    }

    hide(forceClose = false) {
        // don't hide if tooltip or element is being hovered over (and has a link)
        if (
            !forceClose &&
            (this._ttLinks.length || this.ttScrollable) &&
            (!this.tooltip ||
                this.el.nativeElement.matches(':hover') ||
                this.tooltip.matches(':hover'))
        ) {
            return;
        }

        if (this.tooltip) {
            this.el.nativeElement.classList.remove('kaseya-ui-tooltip--hover');
            this.renderer.removeClass(this.tooltip, 'kaseya-ui-tooltip--active');
            if (this.prevText && this.tooltipText !== this.prevText) {
                this.tooltipText = this.prevText;
            }
            window.setTimeout(() => {
                if (this.tooltip) {
                    this.renderer.removeChild(document.body, this.tooltip);
                    this.tooltip = null;
                }
            }, this._delay);
        }
    }

    create() {
        let tooltipContent;
        let tooltipTitle;
        let tooltipText;
        let tooltipLinks;

        this.tooltip = this.renderer.createElement('div');

        tooltipContent = this.renderer.createElement('div');
        this.renderer.addClass(tooltipContent, 'kaseya-ui-tooltip__content');
        if (this._ttLinks.length > 1) {
            // add class so we don't show pointer
            this.renderer.addClass(tooltipContent, 'kaseya-ui-tooltip__content--links');
        } else if (this._ttLinks.length === 1) {
            // only 1 link, add listener to whole tooltip
            this.renderer.setStyle(this.tooltip, 'cursor', 'pointer');
            this.renderer.listen(this.tooltip, 'click', () =>
                this.onLinkItemButtonClicked(this._ttLinks[0])
            );
        }

        if (this.ttTitle) {
            tooltipTitle = this.renderer.createElement('div');
            this.renderer.addClass(tooltipTitle, 'kaseya-ui-tooltip__title');
            this.renderer.appendChild(tooltipTitle, this.renderer.createText(this.ttTitle));
            this.renderer.appendChild(tooltipContent, tooltipTitle);
        }

        tooltipText = this.renderer.createElement('div');
        this.renderer.addClass(tooltipText, 'kaseya-ui-tooltip__text');
        tooltipText.innerHTML = this.tooltipText;
        this.renderer.appendChild(tooltipContent, tooltipText);

        if (this._ttLinks.length > 0) {
            tooltipLinks = this.renderer.createElement('div');
            this._ttLinks.forEach((link, i) => {
                let linkItem = link.url
                    ? this.renderer.createElement('a')
                    : this.renderer.createElement('span');
                this.renderer.addClass(linkItem, 'kaseya-ui-tooltip__link');
                this.renderer.appendChild(linkItem, this.renderer.createText(link.text));
                if (link.url) {
                    this.renderer.setAttribute(linkItem, 'href', link.url);
                    this.renderer.setAttribute(
                        linkItem,
                        'target',
                        link.newTab ? '_blank' : '_self'
                    );
                } else {
                    this.renderer.listen(linkItem, 'click', () =>
                        this.onLinkItemButtonClicked(link)
                    );
                }
                this.renderer.appendChild(tooltipLinks, linkItem);

                if (i !== this._ttLinks.length - 1) {
                    let divider = this.renderer.createElement('span');
                    this.renderer.addClass(divider, 'kaseya-ui-tooltip__link-divider');
                    divider.innerHTML = this.ttLinksDelimiter;
                    this.renderer.appendChild(tooltipLinks, divider);
                }
            });
            this.renderer.setStyle(tooltipLinks, 'display', 'flex');
            this.renderer.setStyle(tooltipLinks, 'flex-direction', 'row');
            this.renderer.setStyle(tooltipLinks, 'justify-content', 'flex-end');

            this.renderer.appendChild(tooltipContent, tooltipLinks);
        }

        this.renderer.appendChild(document.body, this.tooltip);
        this.renderer.appendChild(this.tooltip, tooltipContent);

        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip');
        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip__' + this._position);
        if (this.ttScrollable) {
            this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip--scrollable');
        }
        this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, 'z-index', '5000'); // this is for tooltips in modals and sidebars

        if (!this.ttScrollable) {
            this.renderer.setStyle(tooltipContent, 'padding-bottom', this._offset + 'px');
        }

        this.tooltip.addEventListener('mouseleave', () => {
            this.hide();
        });
    }

    onLinkItemButtonClicked(link: TTLink): void {
        this.ttLinkClick.emit(link);
        if (link.command) {
            link.command();
            this.hide();
            return;
        }

        if (link.url) {
            this.openLink(link);
            return;
        }
        if (link.routerLink) {
            this.navigateToRoute(link);
            return;
        }
    }

    /** Open link in new window or tab */
    openLink(link: TTLink) {
        this.hide();
        if (link.newTab) {
            window.open(link.url);
        } else {
            window.location.href = link.url;
        }
    }

    navigateToRoute(link: TTLink) {
        let route;
        let navigateOptions = {};
        this.hide();
        if (link.routerLinkRelative) {
            navigateOptions = { relativeTo: this.route };
        }
        if (typeof link.routerLink === 'string') {
            route = [link.routerLink];
        } else {
            route = link.routerLink;
        }
        this.router.navigate(route, navigateOptions);
    }

    ngOnDestroy() {
        if (this.tooltip) {
            this.hide();
        }
    }

    setPosition() {
        const hostPos = this.el.nativeElement.getBoundingClientRect();
        const tooltipPos = this.tooltip.getBoundingClientRect();

        const scrollPos =
            window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let position = this._position;
        if (position.startsWith('flex')) {
            let topClearance = hostPos.top;
            let bottomClearance = window.innerHeight - hostPos.bottom;
            if (topClearance > bottomClearance) {
                position = position.replace('flex', 'top');
            } else {
                position = position.replace('flex', 'bottom');
            }
        }

        let top, left, paddingTop, paddingRight, paddingBottom, paddingLeft;

        if (position === 'top') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
            paddingBottom = 4;
        }

        if (position === 'top-left') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left + hostPos.width - tooltipPos.width;
            paddingBottom = 4;
        }

        if (position === 'top-right') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left;
            paddingBottom = 4;
        }

        if (position === 'bottom') {
            top = hostPos.bottom - 1;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
            paddingTop = 4;
        }

        if (position === 'bottom-left') {
            top = hostPos.bottom - 1;
            left = hostPos.left - tooltipPos.width + hostPos.width;
            paddingTop = 4;
        }

        if (position === 'bottom-right') {
            top = hostPos.bottom - 1;
            left = hostPos.left;
            paddingTop = 4;
        }

        if (position === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.left - tooltipPos.width - 4;
            paddingRight = 4;
        }

        if (position === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.right + 4;
            paddingLeft = 4;
        }

        top = top >= 4 ? top : 4;
        left = left >= 4 ? left : 4;
        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos + 1}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
        if (paddingTop) {
            this.renderer.setStyle(this.tooltip, 'padding-top', `${paddingTop}px`);
        }
        if (paddingRight) {
            this.renderer.setStyle(this.tooltip, 'padding-right', `${paddingRight}px`);
        }
        if (paddingBottom) {
            this.renderer.setStyle(this.tooltip, 'padding-bottom', `${paddingBottom}px`);
        }
        if (paddingLeft) {
            this.renderer.setStyle(this.tooltip, 'padding-left', `${paddingLeft}px`);
        }
        this.checkScreenPosition();
    }

    // used to programatically force a tooltip to show or change text
    forceShow(text: string = null, time: number = 1500) {
        // get current text before hiding
        let currentText = this.tooltipText;
        this.hide();
        // set timeout to give time for the tooltip to hide
        setTimeout(() => {
            if (text) {
                this.prevText = currentText;
                this.tooltipText = text;
            }
            this.show();
            if (time) {
                setTimeout(() => {
                    this.hide();
                }, time);
            }
        }, this._delay);
    }

    checkScreenPosition() {
        let screenPadding = 8;
        let windowHeight = window.innerHeight;
        let windowWith = window.innerWidth;
        let tooltipRect = this.tooltip.getBoundingClientRect();

        if (tooltipRect.bottom > windowHeight) {
            // if it goes off the bottom of the screen
            if (this._position === 'bottom') {
                this._position = 'left';
                this.setPosition();
            } else {
                this.renderer.setStyle(
                    this.tooltip,
                    'transform',
                    `translateY(-${tooltipRect.bottom - windowHeight + screenPadding}px)`
                );
            }
        } else if (tooltipRect.top < screenPadding) {
            // if it goes off the top of the screen
            if (this._position === 'top') {
                this._position = 'left';
                this.setPosition();
            } else {
                this.renderer.setStyle(this.tooltip, 'transform', `translateY(${screenPadding}px)`);
            }
        } else if (tooltipRect.right > windowWith) {
            // if it goes off the right of the screen
            if (this._position === 'right') {
                this._position = 'left';
                this.setPosition();
            } else if (this._position === 'bottom-right') {
                this._position = 'bottom-left';
                this.setPosition();
            } else if (this._position === 'top-right') {
                this._position = 'top-left';
                this.setPosition();
            } else {
                this.renderer.setStyle(
                    this.tooltip,
                    'transform',
                    `translateX(-${tooltipRect.right - windowWith + screenPadding}px)`
                );
            }
        } else if (tooltipRect.left < screenPadding) {
            // if it goes off the left of the screen
            if (this._position === 'left') {
                this._position = 'right';
                this.setPosition();
            } else if (this._position === 'bottom-left') {
                this._position = 'bottom-right';
                this.setPosition();
            } else if (this._position === 'top-left') {
                this._position = 'top-right';
                this.setPosition();
            } else {
                this.renderer.setStyle(this.tooltip, 'transform', `translateX(${screenPadding}px)`);
            }
        }
    }
}

export interface TTLink {
    text: string;
    url?: string;
    href?: string;
    routerLink?: string | string[];
    routerLinkRelative?: boolean;
    newTab?: boolean;
    command?: Function;
}

export type TooltipPosition =
    | 'left'
    | 'top-left'
    | 'top'
    | 'top-right'
    | 'right'
    | 'bottom-right'
    | 'bottom'
    | 'bottom-left';
