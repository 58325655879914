import { Component, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CanComponentDeactivate } from 'app/deactivate-guard.service';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'app/core/ui.service';
import { SettingService } from 'app/settings/shared/setting.service';
import { Setting } from 'app/settings/shared/setting.model';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { K1_DISABLE } from '../../shared/constants';
import { MessageService } from 'app/core/message.service';
import { Subscription, takeUntil, Subject } from 'rxjs';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SemanticSelectComponent } from 'app/semantic-legacy/semantic-legacy.module';
import { AuthService } from 'app/core/auth';
import { RFT_URL } from 'app/core/constants';
import { AccessGroupRow } from './access-group-row.model';
import { K1AccessGroup } from './kaseya-one-access-group.model';
import { LowestCommonDenominatorSiteRoles, SiteTypeAccessGroupRoles, SiteRoles } from 'app/core/auth';
import { SiteRoleLabel } from 'app/core/auth/site-role-label.model';


@Component({
    templateUrl: './kaseya-one.component.html',
    styleUrls: ['./kaseya-one.component.scss'],
})
export class KaseyaOneComponent implements OnInit, CanComponentDeactivate {
    constructor(
        private uiService: UiService,
        private settingService: SettingService,
        private messageService: MessageService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private siteService: SiteService,
        private authService: AuthService
    ) { }

    @Output() breadCrumbs: EventEmitter<Breadcrumb[]> = new EventEmitter<Breadcrumb[]>();

    loadingComplete: boolean;

    autoUserCreationLoaded: boolean;
    accessGroupLoaded: boolean;
    kaseyaOneRequiredLoaded: boolean;
    enableKaseyaOneCtrl: FormControl<boolean> = new FormControl<boolean>(false);
    defaultAccessLevelCtrl: FormControl<string> = new FormControl<string>('');
    enableKaseyaOneAutomaticUserCreationCtrl: FormControl<boolean> = new FormControl<boolean>(
        false
    );
    enableKaseyaOneAccessGroupCtrl: FormControl<boolean> = new FormControl<boolean>(false);

    csub: Subscription;
    pSub: Subscription;
    automaticSub: Subscription;
    accessSub: Subscription;

    breadcrumbs = [{ text: 'Admin' }, { text: 'IT Complete' }, { text: 'KaseyaOne' }];

    groupSelectOptions: any;
    @ViewChild('defaultSites') defaultSites: SemanticSelectComponent;
    defaultSiteRoles: FormControl<string> = new FormControl();
    defaultAccessRoles: SiteRoleLabel[] = [];
    rftGlobalRoles: any[] = [
        { Id: 1, Name: 'Admin' },
        { Id: 33, Name: 'Site Restricted' },
    ];
    defaultRole: string;
    defaultAccessSites: string;
    defaultAccessRole: string;
    autoUserCreation_parameter_name: string = 'K1_AUTO_USER_CREATION';
    defaultAccessLevel_parameter_name: string = 'K1_DEFAULT_ACCESS_LEVEL';
    defaultAccessSites_parameter_name: string = 'K1_DEFAULT_SITES';
    defaultAccessRole_parameter_name: string = 'K1_DEFAULT_ROLE';
    accessGroup_parameter_name: string = 'K1_ACCESSGROUP';
    accessGroup_preference_parameter_name: string = 'K1_ACCESSGROUP_PREFERENCE';

    sites: Site[];

    ngUnsubscribe: Subject<any> = new Subject();
    rft_url = RFT_URL;

    accessGroupRows: AccessGroupRow[] = [];
    k1AccessGroups: K1AccessGroup[] = [];
    hasAvailableAccessGroup: boolean = false;

    k1AccessGroupSync: string;
    k1AccessGroupSyncing: boolean;


    async ngOnInit() {
        this.uiService.setTitle('IT Complete - KaseyaOne');
        this.settingService.setITCompleteBreadcrumbs(this.breadcrumbs);

        this.loadingComplete = false;

        this.autoUserCreationLoaded = false;
        this.accessGroupLoaded = false;
        this.k1AccessGroupSyncing = false;
        this.kaseyaOneRequiredLoaded = false;
        this.subscribeToItCompleteChange();

        this.subscribeToAutomaticUserCreationChange();
        this.subscribeToAccessGroupChange();

        await this.settingService.isK1SsoV2Enabled().then((res) => {
            this.enableKaseyaOneCtrl.setValue(res);
            this.loadingComplete = true;
        });

        this.pSub = this.route.queryParams.subscribe((params) => {
            this.checkForK1SsoV2Error(params);
        });

        this.groupSelectOptions = { onChange: this.multiselect };

        this.defaultAccessLevelCtrl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((type) => { });

        await this.loadAutoUserCreation();
        await this.loadGlobalAccessLevel();
        await this.loadAllSites();
        await this.loadAutoUserCreationSites();
        await this.loadDefaultUserRole();
        this.autoUserCreationLoaded = true;
        await this.loadAccessGroup();
        await this.syncAccessGroups();

        await this.loadAccessGroupPreference();
        this.accessGroupLoaded = true;
    }

    async loadGlobalAccessLevel() {
        await this.settingService
            .getItCompleteSetting(this.defaultAccessLevel_parameter_name)
            .then((res) => {
                if (res?.Value) {
                    this.defaultAccessLevelCtrl.setValue(res.Value);
                } else {
                    this.defaultAccessLevelCtrl.setValue('site_restricted');
                    this.setGlobalAccessLevel();
                }
            });
    }

    setGlobalAccessLevel() {
        this.updateSetting(
            this.defaultAccessLevel_parameter_name,
            this.defaultAccessLevelCtrl.value
        );
    }

    async loadAutoUserCreation() {
        if (!this.enableKaseyaOneCtrl.value) {
            this.enableKaseyaOneAutomaticUserCreationCtrl.setValue(false);
            this.setGlobalAccessLevel();
            return;
        }
        await this.settingService
            .getItCompleteSetting(this.autoUserCreation_parameter_name)
            .then((res) => {
                if (res?.Value) {
                    this.enableKaseyaOneAutomaticUserCreationCtrl.setValue(JSON.parse(res.Value));
                } else {
                    this.enableKaseyaOneAutomaticUserCreationCtrl.setValue(false);
                    this.setGlobalAccessLevel();
                }
            });
    }

    setAutoUserCreation() {
        if (this.autoUserCreationLoaded) {
            this.updateSetting(
                this.autoUserCreation_parameter_name,
                this.enableKaseyaOneAutomaticUserCreationCtrl.value.toString()
            );
        }
    }

    updateSetting(name: string, value: string) {
        if (this.autoUserCreationLoaded) {
            let sets: Setting[] = [];
            sets.push({
                AccountId: this.authService.getIdentity().accountId,
                Name: name,
                Value: value,
            });
            this.settingService.updateItCompleteSettings(sets);
        }
    }

    async loadAllSites() {
        await this.siteService.getSites().then((res) => {
            this.sites = res;
        });
    }

    async loadDefaultUserRole() {
        await this.settingService
            .getItCompleteSetting(this.defaultAccessRole_parameter_name)
            .then((res) => {
                if (res?.Value) {
                    this.defaultRole = res.Value;
                    this.defaultSiteRoles.setValue(this.defaultRole);
                }
            });
    }

    setDefaultUserRole() {
        this.updateSetting(this.defaultAccessRole_parameter_name, this.defaultRole);
    }

    setDefaultRole(roleEvent: any) {
        if (this.autoUserCreationLoaded) {
            this.defaultRole = roleEvent;
            this.setDefaultUserRole();
        }
    }

    checkForK1SsoV2Error(params: any) {
        if (params['k1ssov2error']) {
            if (params['k1ssov2error'] === 'oiu') {
                this.notificationService.toast.error(
                    'Error',
                    "The K1 User's organization is already being used by a different account."
                );
            }
            if (params['k1ssov2error'] === 'nau') {
                this.notificationService.toast.error(
                    'Error',
                    'Registration could not be completed because the K1 User does not have an administrative role.'
                );
            }
        }
    }

    isTrueSet(v: any) {
        return v === 'true' || v === 'True';
    }

    subscribeToItCompleteChange() {
        this.csub = this.enableKaseyaOneCtrl.valueChanges.subscribe((value) => {
            this.onEnableItCompleteControlChange(value);
        });
    }

    subscribeToAutomaticUserCreationChange() {
        this.automaticSub = this.enableKaseyaOneAutomaticUserCreationCtrl.valueChanges.subscribe(
            (value) => {
                this.onEnableKaseyaOneAutomaticUserCreationControlChange(value);
            }
        );
    }

    onEnableItCompleteControlChange(value: any) {
        if (this.loadingComplete) {
            if (!value) {
                if (
                    confirm(
                        'Are you sure you wish to disable Log in with KaseyaOne for your account?'
                    )
                ) {
                    this.settingService.k1Ssov2Deregistration().then((res) => {
                        this.messageService.broadcast(K1_DISABLE);
                        this.notificationService.toast.success(
                            'Success',
                            'IT Complete setting was updated.'
                        );
                    });
                } else {
                    this.csub.unsubscribe();
                    window.setTimeout(() => {
                        this.setK1SsoV2Enabled();
                    }, 100);
                }
            } else {
                this.settingService.k1Ssov2Registration();
            }
        }
    }

    onEnableKaseyaOneAutomaticUserCreationControlChange(value: any) {
        this.setAutoUserCreation();
        this.loadGlobalAccessLevel();
    }

    onDefaultSiteChange(evt: any) {
        this.defaultAccessSites = JSON.stringify(evt);
        this.setAutoUserCreationSites();
        this.setDefaultSiteRoles();
    }

    setDefaultSiteRoles() {
        let siteIds: number[] = [];
        let defaultAccessSiteIds: string[] = this.convertJsonToArray(this.defaultAccessSites);

        defaultAccessSiteIds.forEach(das => {
            siteIds.push(Number(das));
        });

        this.defaultAccessRoles = this.GetSiteTypeRoles(siteIds)

    }


    setK1SsoV2Enabled() {
        this.enableKaseyaOneCtrl.setValue(true);
        this.subscribeToItCompleteChange();
    }

    convertJsonToArray(strval: string): string[] {
        return <string[]>JSON.parse(strval);
    }

    async loadAutoUserCreationSites() {
        await this.settingService
            .getItCompleteSetting(this.defaultAccessSites_parameter_name)
            .then((res) => {
                if (res?.Value) {
                    this.defaultAccessSites = res.Value;

                    let accessSites: string[] = this.convertJsonToArray(this.defaultAccessSites);
                    setTimeout(() => {
                        this.defaultSites.runBehavior('clear');
                        this.defaultSites.runBehavior('set selected', accessSites);
                    });
                }
            });
    }

    setAutoUserCreationSites() {
        this.updateSetting(this.defaultAccessSites_parameter_name, this.defaultAccessSites);
    }

    multiselect = ($choice, value, text) => {
        if (this.autoUserCreationLoaded) {
            this.defaultAccessSites = JSON.stringify($choice);
            this.setAutoUserCreationSites();
        }
    };

    hasUnsavedAutoUserCreationChanges() {
        if (!this.enableKaseyaOneAutomaticUserCreationCtrl?.value) {
            return false;
        }

        if (this.defaultAccessLevelCtrl.value == 'admin') {
            return false;
        }

        if (!this.defaultAccessSites) {
            return true;
        }
        if (this.defaultRole == undefined || this.defaultRole.length === 0) {
            return true;
        }

        let accessSites: string[] = <string[]>JSON.parse(this.defaultAccessSites);
        return accessSites?.length === 0;
    }

    getAvailableAccessGroupOptions(row: AccessGroupRow): K1AccessGroup[] {
        let selectedAccessGroupIds: string[] = [];
        this.accessGroupRows.forEach((agr) => {
            selectedAccessGroupIds.push(agr.AccessGroupId);
        });

        if (row) {
            selectedAccessGroupIds = selectedAccessGroupIds.filter(
                (sagi) => sagi != row.AccessGroupId
            );
        }

        let availableAccessGroupOptions: K1AccessGroup[] = [];
        this.k1AccessGroups.forEach((kag) => {
            if (selectedAccessGroupIds.indexOf(kag.Id) < 0) {
                availableAccessGroupOptions.push(kag);
            }
        });

        return availableAccessGroupOptions;
    }

    addAccessGroup() {
        let availableAccessGroupOptions: K1AccessGroup[] =
            this.getAvailableAccessGroupOptions(undefined);
        let firstAccessGroupId: string = '';
        let firstAccessGroupName: string = '';

        if (availableAccessGroupOptions?.length > 0) {
            firstAccessGroupId = availableAccessGroupOptions[0].Id;
            firstAccessGroupName = availableAccessGroupOptions[0].Name;
        }

        if (availableAccessGroupOptions.length == 0) {
            this.hasAvailableAccessGroup = false;
            return;
        }

        let row: AccessGroupRow = {
            Order: this.accessGroupRows.length + 1,
            AccessGroupId: firstAccessGroupId,
            AccessGroupName: firstAccessGroupName,
            GlobalRoleId: 33,
            SiteIds: [],
            SiteRole: '',
            PlaceHolder: '',
            AccessGroupOptions: availableAccessGroupOptions,
            AvailableSiteRoles: LowestCommonDenominatorSiteRoles
        };

        this.accessGroupRows.push(row);
        this.setAccessGroupOptions();
    }

    setRowOrder() {
        for (let i = 0; i < this.accessGroupRows.length; i++) {
            this.accessGroupRows[i].Order = (i + 1);
        }

    }
    startDelete(row: AccessGroupRow) {
        this.hasAvailableAccessGroup = true;

        for (let i = 0; i < this.accessGroupRows.length; i++) {
            if (this.accessGroupRows[i].Order === row.Order) {
                this.accessGroupRows.splice(i, 1);
                break;
            }
        }
        this.setRowOrder();

        this.setAccessGroupOptions();

        this.setAccessGroupPreference();
    }

    async loadAccessGroup() {
        if (!this.enableKaseyaOneCtrl.value) {
            this.enableKaseyaOneAccessGroupCtrl.setValue(false);

            return;
        }
        await this.settingService
            .getItCompleteSetting(this.accessGroup_parameter_name)
            .then((res) => {
                if (res?.Value) {
                    this.enableKaseyaOneAccessGroupCtrl.setValue(
                        res.Value.toLowerCase() === 'true'
                    );
                } else {
                    this.enableKaseyaOneAccessGroupCtrl.setValue(false);
                }
            });
    }

    subscribeToAccessGroupChange() {
        this.accessSub = this.enableKaseyaOneAccessGroupCtrl.valueChanges.subscribe((value) => {
            this.onEnableKaseyaOneAccessGroupCtrlChange(value);
        });
    }

    onEnableKaseyaOneAccessGroupCtrlChange(value: any) {
        this.setAccessGroup();
    }

    setAccessGroup() {
        if (this.accessGroupLoaded) {
            this.updateSetting(
                this.accessGroup_parameter_name,
                this.enableKaseyaOneAccessGroupCtrl.value.toString()
            );

            if (this.enableKaseyaOneAccessGroupCtrl.value) {
                this.syncAccessGroups();
            }
        }
    }

    async loadK1AccessGroups() {
        await this.settingService.getK1SsoV2AccessGroups().then((res) => {
            this.k1AccessGroups = res.AccessGroups;
            this.k1AccessGroupSync = res.Sync;
            this.hasAvailableAccessGroup = this.k1AccessGroups?.length > 0;
        });
    }

    setAccessGroupOptions() {
        this.accessGroupRows.forEach((agr) => {
            agr.AccessGroupOptions = this.getAvailableAccessGroupOptions(agr);
        });
    }

    setAccessGroupInRow(evt: any, row: AccessGroupRow) {
        row.AccessGroupName = this.k1AccessGroups.filter((kag) => kag.Id == evt.value)[0].Name;
        this.setAccessGroupOptions();
        this.setAccessGroupPreference();
    }

    setGlobalRoleInRow(evt: any, row: AccessGroupRow) {
        if (evt.value == 1) {
            row.SiteIds = [];
            row.SiteRole = '';
        }

        this.setAccessGroupPreference();
    }

    getSite(id: number): Site {
        return this.sites.find((s) => s.Id === id);
    }

    setSitesInRow(evt: any, row: AccessGroupRow, i: number) {
        this.setAccessGroupPreference();
        this.setAvailableSiteRoles(row);
    }

    setSiteRoleInRow(evt: any, row: AccessGroupRow) {
        this.setAccessGroupPreference();
    }

    setAccessGroupPreference() {
        this.settingService.updateItCompleteLargeSetting(
            this.accessGroup_preference_parameter_name,
            JSON.stringify(this.accessGroupRows)
        );
    }

    GetSiteTypeRoles(siteIds: number[]): SiteRoleLabel[] {
        let siteTypeRoles: SiteRoleLabel[] = [];
        if (siteIds && siteIds.length>0) {
            let siteTypes: string[] = [];
            siteIds.forEach(s => {
                let mySites = this.sites.filter(site => site.Id == s);
                if (mySites?.length > 0) {
                    if (siteTypes.indexOf(mySites[0].Type) < 0) {
                        siteTypes.push(mySites[0].Type);
                    }
                }
            });
            siteTypeRoles.push(...LowestCommonDenominatorSiteRoles);

            siteTypeRoles.push(...this.GetLowestCommonDenominatorRolesForSiteTypes(siteTypes));
        }
        return siteTypeRoles;
    }

    GetLowestCommonDenominatorRolesForSiteTypes(siteTypes: string[]): SiteRoleLabel[] {
        let rValue: SiteRoleLabel[] = [];

        let siteRoleCountMap = new Map();
        siteRoleCountMap.set(SiteRoles.Auditor,0);
        siteRoleCountMap.set(SiteRoles.SME, 0);
        siteRoleCountMap.set(SiteRoles.Client, 0);
        siteRoleCountMap.set(SiteRoles.EmployeePortalAdmin, 0);
        siteRoleCountMap.set(SiteRoles.GrcReportViewer, 0);

        siteTypes.forEach(st => {
            if (SiteTypeAccessGroupRoles[st]) {
                SiteTypeAccessGroupRoles[st].forEach(stagr => {
                    let stagrValue = siteRoleCountMap.get(stagr.value);
                    siteRoleCountMap.set(stagr.value, stagrValue + 1);
                });
            }
        });

        for (let k of siteRoleCountMap.keys()) {
            if (siteRoleCountMap.get(k) === siteTypes.length) {
                rValue.push(...SiteTypeAccessGroupRoles[siteTypes[0]].filter(srl => srl.value == k));
            }
        };

        return rValue;
    }




    setAvailableSiteRoles(row: AccessGroupRow) {
        if (row.SiteIds) {
            let siteTypeRoles = this.GetSiteTypeRoles(row.SiteIds);
            row.AvailableSiteRoles = siteTypeRoles;
        }
    }

    k1AccessGroupRowExists(accessGroupId: string): boolean {
        let accessGroupWithId = this.k1AccessGroups.filter(kag => kag.Id == accessGroupId);

        return accessGroupWithId && accessGroupWithId.length > 0;
    }

    async loadAccessGroupPreference() {
        await this.settingService
            .getItCompleteLargeSetting(this.accessGroup_preference_parameter_name)
            .then((res) => {
                if (res) {
                    this.accessGroupRows = <AccessGroupRow[]>JSON.parse(res);
                    this.accessGroupRows?.forEach((agr) => {
                        if (!this.k1AccessGroupRowExists(agr.AccessGroupId)) {
                            agr.PlaceHolder = agr.AccessGroupName;
                        }
                        this.setAvailableSiteRoles(agr);
                    });

                    this.setAccessGroupOptions();
                }
            });
    }

    isChecked(val, control) {
        return control.includes(val);
    }

    closeSiteSelection(row: AccessGroupRow, i: number) {
        row.SiteIds = [];
    }

    async syncAccessGroups() {
        if (this.enableKaseyaOneAccessGroupCtrl.value === true) {
            this.k1AccessGroupSyncing = true;
            await this.settingService.syncK1SsoV2AccessGroups();
            await this.loadK1AccessGroups();
            this.k1AccessGroupSyncing = false;
        }
    }

    canDeactivate() {
        if (!this.hasUnsavedAutoUserCreationChanges()) {
            return true;
        } else {
            return confirm('You have unsaved changes. Do you want to leave without saving?');
        }
    }

    ngOnDestroy() {
        this.csub?.unsubscribe();
        this.pSub?.unsubscribe();
        this.automaticSub.unsubscribe();
        this.accessSub.unsubscribe();
        this.ngUnsubscribe?.next(void 0);
        this.ngUnsubscribe?.complete();
    }
}
