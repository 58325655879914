import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { NavService } from 'app/core/nav/nav.service';
import { Site } from '../shared/site.model';

import { SiteService } from '../shared/site.service';

import {
    SidebarMenuExtras,
    SidebarMenuItem,
} from 'app/shared/sidebar/menu/sidebar-menu-item.model';
import { PING_SITE, SITE } from '../shared/constants';
import { UiService } from '../../core/ui.service';
import { Subject, takeUntil } from 'rxjs';

const UI_KEY_PAGENO: string = 'site_todo_page_number';
const UI_KEY_PAGESIZE: string = 'site_todo_page_size';
@Component({
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.css'],
})
export class SiteComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private messageService: MessageService,
        private navService: NavService,
        private siteService: SiteService,
        private uiService: UiService
    ) {}

    site: Site;

    menuTree: SidebarMenuItem[] = [];
    menuExtras: SidebarMenuExtras;

    hideAssessmentChildren: boolean = false;

    indocMenuItems: SidebarMenuItem[];
    ndpDataExplorerItems: SidebarMenuItem[] = [];

    ngUnsubscribe$: Subject<void> = new Subject();

    ngOnInit() {
        this.navService.setHeaderText(' '); // not empty string, so it will force the bar to show right away

        this.route.params.subscribe((params: Params) => {
            let name: string = params['name'];
            //this.navService.setSiteName(name);
            name = name.replace(/%26/g, '&');

            this.siteService.getSiteByName(name).then((site) => this.onSite(site));
        });

        this.messageService
            .on(PING_SITE)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.onPingSite());

        this.messageService
            .on('LICENSE_UPDATED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((model) => {
                let lnum = parseInt(model.split(' ')[1], 10);
                this.site.LicensedNumber = lnum;
                this.site.HasLicenseViolation =
                    false /*(this.site.LicensedNumber < this.site.DeployedNumber)*/;
            });

        this.messageService
            .on('ASSESSMENT_RESTARTED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.site.HasLicenseViolation = false;
                this.site.DeployedNumber = 0;
                this.navService.setSite(this.site);
                this.messageService.broadcast(SITE, this.site);
            });

        this.messageService
            .on('UNIVIEW_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });

        this.messageService
            .on('DATTO_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });

        this.messageService
            .on('DATTO_WORKPLACE_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });
    }

    ngOnDestroy() {
        this.siteService.app_setCurrentSite(null);
        this.siteService.resetSiteObs();
        this.uiService.setControlValue(UI_KEY_PAGENO, '');
        this.uiService.setControlValue(UI_KEY_PAGESIZE, '');

        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    onSite(site: Site) {
        this.site = site;
        this.siteService.app_setCurrentSite(this.site);
        this.siteService.setSiteObs(this.site);

        this.navService.setHeaderText(this.site.Name);
        if (this.site.IsCSPro) {
            this.navService.setHeaderFlair('<i class="cs-pro-logo"></i>');
        }

        let roleId: number = parseInt(this.authService.getIdentity().roleId, 10);

        if (site.UserRoles) {
            this.authService.setCurrentSiteRoles(site.UserRoles);
            this.messageService.broadcast(
                'NAV_SET_LIMITED_CRUMBS',
                this.authService.isOnlySME() || this.authService.isClient()
            );
        } else {
            this.authService.clearCurrentSiteRoles();

            // If no site roles, check global access role
            if (roleId != Roles.Master && roleId != Roles.Admin && roleId != Roles.Restricted) {
                // User does not have access to this site
                this.router.navigateByUrl('/sites');
                return;
            }
            // Global admin privileges cascade down to sites
            else if (roleId == Roles.Admin || roleId == Roles.Master) {
                this.authService.setCurrentSiteRoles(SiteRoles.SiteAdmin);
            }
        }

        //if (this.site.HasLicenseViolation) {
        //  this.router.navigate(['/site', this.site.Name, 'settings', 'advanced']);
        //}
        // Build sidebar menu
        this.buildSidebarMenu();

        this.navService.setSite(site);

        this.messageService.broadcast(SITE, this.site);
    }

    onPingSite() {
        this.messageService.broadcast(SITE, this.site);
    }

    async buildSidebarMenu() {
        let indoc = this.siteService.isIndoc(this.site);
        let ndpro = this.siteService.isNDPro(this.site);
        let cm = this.siteService.isComplianceManager(this.site);
        let ch = this.siteService.isCyberHawk(this.site);
        let kvs = this.siteService.isKVS(this.site);
        let cmgrc = this.siteService.isComplianceManagerGRC(this.site);
        let cmo = this.siteService.isCMO(this.site);
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isAuditor = siteRoles && siteRoles.indexOf(SiteRoles.Auditor) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let menuTree: SidebarMenuItem[] = [];

        // Home
        if (isTech || isAdmin || isAuditor || isPrivileged) {
            menuTree.push(this.siteService.getHomeMenuItems(this.site));
        }

        // InDoc
        if (indoc && !ndpro) menuTree.push(this.siteService.getIndocMenuItems(this.site));

        // NdPro
        if (ndpro) menuTree.push(this.siteService.getNDPMenuItems(this.site));

        // CM legacy
        if (cm) menuTree.push(this.siteService.getCMMenuItems(this.site));

        // CM GRC
        if (cmgrc) menuTree.push(await this.siteService.getCMGRCMenuItems(this.site));

        // CMO
        if (cmo && isDevMode()) menuTree.push(this.siteService.getCMOMenuItems(this.site));

        // Cyber Hawk
        if (ch) menuTree.push(this.siteService.getCHMenuItems(this.site));

        // VulScan
        if (kvs) menuTree.push(this.siteService.getVSMenuItems(this.site));

        if (isAdmin || isPrivileged) {
            menuTree.push({
                label: 'Audit Log',
                href: 'audit-log',
                icon: 'clipboard-list-check',
            });
        }

        this.menuTree = menuTree;
    }
}
