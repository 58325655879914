import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel, UntypedFormControl } from '@angular/forms';

import { AuthService, EndDateService, RFTProduct } from 'app/core/auth';
import { FormService } from 'app/core/form.service';
import { LicenseService } from 'app/core/license.service';
import { SiteService } from 'app/sites';
import { Organization } from 'app/organizations/organization.model';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { Site } from '../site.model';
import { ALL_SITES } from '../../../core/nav/nav.service';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from '../../../shared/itc/notification/notification.service';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';
import { orgNameRegExp, siteNameRegExp } from 'app/shared/regex.constants';

@Component({
    selector: 'sds-add-site-modal',
    templateUrl: './add-site.component.html',
})
export class AddSiteComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private formService: FormService,
        private licenseService: LicenseService,
        private siteService: SiteService,
        private notificationService: NotificationService,
        private organizationsService: OrganizationsService,
        private siteDashboardService: SiteDashboardService,
        private endDateService: EndDateService
    ) {}

    _agppVersion: number;
    @Input() set agppVersion(value: number) {
        this._agppVersion = value;
        let siteType: 'AG' | 'CH' | 'KVS' | 'CM2' | '' = '';
        if (this.newSite) {
            if (this.newSite.Type == 'CH') siteType = 'CH';
            else if (this.newSite.Type == 'KVS') siteType = 'KVS';
            else if (this.newSite.Type == 'CM2') siteType = 'CM2';
            else if (
                this.newSite.Type == 'GDPR' ||
                this.newSite.Type == 'UKGDPR' ||
                this.newSite.Type == 'HIPAA' ||
                this.newSite.Type == 'CYBERINSURANCE' ||
                this.newSite.Type == 'NIST' ||
                this.newSite.Type == 'CMMC'
            )
                siteType = 'AG';
        }

        this.subscriptionOpts = this.licenseService.getSubscriptionOptions(
            siteType,
            void 0,
            this._agppVersion
        );
    }
    get legacyGuru(): boolean {
        return this._agppVersion == 1;
    }

    @Input() isSMB: boolean;
    @Input() isCM: boolean;
    _smbErrorMessages: string[];
    @Input() set smbErrorMessages(errors: string[]) {
        if (!errors) return;
        this._smbErrorMessages = errors;
        for (let error of errors) {
            if (error.indexOf('Cyber Hawk') > -1 || error.indexOf('Security Manager') > -1)
                this.hasCHViolation = true;
            else if (error.indexOf('Audit Guru') > -1 || error.indexOf('Compliance Manager') > -1)
                this.hasAGViolation = true;
        }
    }
    get smbErrorMessages(): string[] {
        return this._smbErrorMessages;
    }

    @Input() set organizations(organizations: Organization[]) {
        if (organizations != undefined) {
            this.organizationChoices = [];
            this.organizationChoices.push(...organizations);
            let newOrganization: Organization = {
                Id: -1,
                Name: 'Add New Organizational Folder',
                AccountId: '',
                Sites: [],
            };
            this.organizationChoices.push(newOrganization);
            this.setOrganizationId();
        }
    }

    readonly orgNameRegExpString = orgNameRegExp.toString();
    // we want the regex without the slashes for the pattern validator
    readonly siteNameRegExpString = siteNameRegExp
        .toString()
        .substring(1, siteNameRegExp.toString().length - 1);

    organizationChoices: Organization[];
    destinationOrganizationName: string;

    @Input() selectedOrganizationId: number;
    @Input() set selectedOrg(val: string) {
        this._selectedOrg = val;
    }
    get selectedOrg(): string {
        return this._selectedOrg;
    }
    _selectedOrg: string;

    hasViolation: boolean;
    hasAGViolation: boolean;
    hasCHViolation: boolean;
    hasKvsViolation: boolean;

    @ViewChild('modal', { static: true }) modal: any;
    @Output('onAdd') onAdd: EventEmitter<Site> = new EventEmitter<Site>();

    GDPRCtrl: UntypedFormControl = new UntypedFormControl();

    licenseCtrl: UntypedFormControl = new UntypedFormControl();
    newSubCtrl: UntypedFormControl = new UntypedFormControl();
    baseSiteTypeCtrl: UntypedFormControl = new UntypedFormControl();
    agSiteTypeCtrl: UntypedFormControl = new UntypedFormControl();
    cmSiteTypeCtrl: UntypedFormControl = new UntypedFormControl();
    autoProvisionVulScanCtrl: UntypedFormControl = new UntypedFormControl();

    userIsKaseya: boolean;

    newSite: Site;
    newSubPricing: any;

    destinationOrganizationId: number;
    newOrganizationName: string;

    showLegacySiteTypes = false;

    setSiteName(name: string) {
        this.newSite.Name = name;
        this.canProceed = false;

        if (!this.formService.validate('addSiteForm')) return;

        if (this.newSite.Type == 'CH')
            this.canProceed = this.products.CH || this.products.KCM || this.products.UCM;
        else if (this.newSite.Type == 'GDPR')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'GDPR' || this.cmSiteTypeCtrl.value == 'GDPR') &&
                this.products.AGGDPR;
        else if (this.newSite.Type == 'UKGDPR')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'UKGDPR' || this.cmSiteTypeCtrl.value == 'UKGDPR') &&
                this.products.AGUKGDPR;
        else if (this.newSite.Type == 'HIPAA')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'HIPAA' || this.cmSiteTypeCtrl.value == 'HIPAA') &&
                this.products.AGHIPAA;
        else if (this.newSite.Type == 'CYBERINSURANCE')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'CYBERINSURANCE' ||
                    this.cmSiteTypeCtrl.value == 'CYBERINSURANCE') &&
                this.products.AGCYBERINSURANCE;
        else if (this.newSite.Type == 'NIST')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'NIST' || this.cmSiteTypeCtrl.value == 'NIST') &&
                this.products.AGNIST;
        else if (this.newSite.Type == 'CMMC')
            this.canProceed =
                (this.agSiteTypeCtrl.value == 'CMMC' || this.cmSiteTypeCtrl.value == 'CMMC') &&
                this.products.AGCMMC;
        else if (this.newSite.Type == 'KVS') this.canProceed = this.products.KVS;
        else if (this.newSite.Type == 'CM2') this.canProceed = this.products.CM2;
        else if (this.newSite.Type == 'NDPRO') this.canProceed = this.products.NdPro;
    }

    _lType: string;

    get licenseType(): string {
        return this._lType;
    }

    set licenseType(val: string) {
        this._lType = val;
        this.canProceed =
            (val == 'new' && this.newSubCtrl.value) ||
            (val == 'existing' && this.licenseCtrl.value);
    }

    licenseSiteType: string;

    nextLoading: boolean = false;
    availableLicenses: any[] = [];
    subscriptionOpts: any[] = [];
    products: any;
    license: any;

    siteTypeDisplay: string = '';

    step: number = 1;
    canProceed: boolean = false;
    @ViewChild('siteName', { static: false }) siteName: NgModel;

    expiredProducts = {};
    ngUnsubscribe$: Subject<any> = new Subject();

    ngOnInit() {
        this.initModal();

        this.subscriptionOpts = this.licenseService.getSubscriptionOptions('');
        this.userIsKaseya = this.authService.userIsKaseya();

        this.licenseCtrl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((v) => {
            if (!v) return;

            this.canProceed = true;
            let selected = this.availableLicenses[v];
            this.license = { level: selected.SubscriptionLevel, model: selected.Model };
        });
        this.newSubCtrl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((v) => {
            if (!v) return;

            this.canProceed = true;
            let selected = this.subscriptionOpts[v];
            this.license = { level: selected.level, model: selected.model };
        });

        this.baseSiteTypeCtrl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((type) => {
                this.licenseSiteType = type;

                var newSiteTypeDescription = this.getSiteTypeDescription();

                this.agSiteTypeCtrl.disable();
                this.cmSiteTypeCtrl.disable();

                if (type == 'CH') {
                    this.newSite.Type = 'CH';
                    this.canProceed = this.newSite.Name != '';
                } else if (type == 'AG') {
                    this.newSite.Type = newSiteTypeDescription;
                    this.agSiteTypeCtrl.enable();
                    this.canProceed =
                        this.newSite.Name !== '' && this.agSiteTypeCtrl.value !== '' ? true : false;
                } else if (type == 'XCM') {
                    this.newSite.Type = newSiteTypeDescription;
                    this.cmSiteTypeCtrl.enable();
                    this.canProceed =
                        this.newSite.Name !== '' && this.agSiteTypeCtrl.value !== '' ? true : false;
                } else if (type == 'XSM') {
                    this.newSite.Type = 'CH';
                    this.canProceed = this.newSite.Name != '';
                } else if (type == 'KVS') {
                    this.newSite.Type = 'KVS';
                    this.canProceed = this.newSite.Name != '';
                } else if (type == 'CM2') {
                    this.newSite.Type = 'CM2';
                    this.canProceed = this.newSite.Name != '';
                } else if (type == 'NDPRO') {
                    this.newSite.Type = 'NDPRO';
                    this.canProceed = this.newSite.Name != '';
                }
            });

        this.agSiteTypeCtrl.valueChanges.subscribe((type) => {
            this.newSite.Type = type;
            this.canProceed = this.newSite.Name != '';
        });

        this.cmSiteTypeCtrl.valueChanges.subscribe((type) => {
            this.newSite.Type = type;
            this.canProceed = this.newSite.Name != '';
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    getSiteTypeDescription() {
        let desc: string = '';

        if (this.products.AGGDPR) {
            desc = 'GDPR';
        } else if (this.products.AGUKGDPR) {
            desc = 'UK GDPR';
        } else if (this.products.AGHIPAA) {
            desc = 'HIPAA';
        } else if (this.products.AGCYBERINSURANCE) {
            desc = 'CYBERINSURANCE';
        } else if (this.products.AGNIST) {
            desc = 'NIST';
        } else if (this.products.AGCMMC) {
            desc = 'CMMC';
        }

        return desc;
    }

    initModal() {
        let user = this.authService.getIdentity();
        this.products = this.authService.getProducts();

        this.newSite = {
            Id: 0,
            AccountId: user.accountId,
            Name: '',
            Type: '',
        };
        this.GDPRCtrl.setValue(true);
        this.baseSiteTypeCtrl.setValue(null);
        this.licenseType = '';
        this.licenseCtrl.setValue(null);
        this.newSubCtrl.setValue(null);
        this.showLegacySiteTypes = false;
        this.canProceed = false;
        this.newOrganizationName = '';
        this.setOrganizationId();
    }

    setOrganizationId() {
        this.selectedOrganizationId = this.organizationChoices?.filter(
            (o) => o.Name === this._selectedOrg
        )[0]?.Id;
    }

    showModal() {
        this.endDateService
            .getExpiredProducts(true)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((expiredProducts) => {
                if (expiredProducts) {
                    this.expiredProducts = expiredProducts;
                }
            });
        this.changeStep(1);

        this.baseSiteTypeCtrl.setValue('');
        this.agSiteTypeCtrl.setValue('');
        this.cmSiteTypeCtrl.setValue('');

        this.modal.show({ closable: false });
        setTimeout(() => {
            this.modal.refresh();
        }, 10);
    }

    closeModal() {
        this.modal.hide();

        setTimeout(() => {
            this.changeStep(1);
            this.initModal();
        }, 50);
    }

    changeStep(step: number) {
        this.step = step;
        this.modal.refresh();
    }

    showOrganizationForm() {
        this.validateCanProceedBySelectedOrganization();

        this.changeStep(3);

        setTimeout(() => {
            if (!this.formService.validate('organizationForm')) {
                this.canProceed = false;
            }
        }, 250);
    }

    advance() {
        if (!this.canProceed) return;

        if (this.nextLoading) return;

        if (this.step == 1) {
            let valid = this.formService.validate('addSiteForm');
            if (valid) {
                switch (this.baseSiteTypeCtrl.value) {
                    case 'AG':
                        this.siteTypeDisplay = 'Compliance Manager';
                        break;
                    case 'CH':
                        this.siteTypeDisplay = 'Cyber Hawk';
                        break;
                    case 'NDPRO':
                        this.siteTypeDisplay = 'Network Detective Pro';
                        break;
                    case 'XCM':
                        this.siteTypeDisplay = 'Compliance Manager';
                        break;
                    case 'XSM':
                        this.siteTypeDisplay = 'Security Manager';
                        break;
                }

                let siteType: 'AG' | 'CH' | 'KVS' | 'CM2' | 'NDPRO' | '' = '';
                if (this.newSite) {
                    if (this.newSite.Type == 'CH') siteType = 'CH';
                    else if (this.newSite.Type == 'KVS') siteType = 'KVS';
                    else if (this.newSite.Type == 'CM2') siteType = 'CM2';
                    else if (this.newSite.Type == 'NDPRO') siteType = 'NDPRO';
                    else if (
                        this.newSite.Type == 'GDPR' ||
                        this.newSite.Type == 'UKGDPR' ||
                        this.newSite.Type == 'HIPAA' ||
                        this.newSite.Type == 'CYBERINSURANCE' ||
                        this.newSite.Type == 'NIST' ||
                        this.newSite.Type == 'CMMC'
                    )
                        siteType = 'AG';
                }

                this.licenseSiteType = siteType;

                let products = this.authService.getProducts();

                if (
                    products.CH &&
                    this.newSite.Type == 'CH' &&
                    !products.CH2019 &&
                    !(
                        this.products.KCM ||
                        this.products.UCM ||
                        this.products.KSM ||
                        this.products.USM
                    )
                ) {
                    this.licenseType = 'cyberhawk';
                    this.canProceed = true;
                    this.showOrganizationForm();
                } else if (this.isSMB || (this.isCM && siteType == 'AG')) {
                    this.hasViolation = this.checkSmbLicenseViolations();
                    if (this.hasViolation) {
                        this.canProceed = false;
                        this.showOrganizationForm();
                        //this.changeStep(3);
                    } else {
                        this.canProceed = true;

                        this.license =
                            this.newSite.Type == 'CH'
                                ? { level: 'ch2000', model: 'CH 2000' }
                                : { level: 'ag2000', model: 'AG 2000' };

                        //this.nextLoading = true;
                        this.showOrganizationForm();
                        //this.addSite();
                    }
                } else if (products.KVS && this.newSite.Type == 'KVS') {
                    this.licenseType = 'kvs';
                    this.canProceed = true;
                    this.showOrganizationForm();
                } else if (products.NdPro && this.newSite.Type == 'NDPRO') {
                    this.licenseType = 'NDPRO';
                    this.canProceed = true;
                    this.showOrganizationForm();
                } else if (products.CM2 && this.newSite.Type == 'CM2') {
                    this.licenseType = 'cm2';
                    this.license = { level: 'ag2000', model: 'AG 2000' };
                    this.canProceed = true;
                    this.showOrganizationForm();
                } else {
                    this.nextLoading = true;

                    this.licenseService
                        .getAvailableLicenses(siteType)
                        .then((licenses) => {
                            let limit250 = false;
                            if (siteType == 'AG' && (this.products.KCM || this.products.UCM))
                                limit250 = true;
                            else if (siteType == 'CH' && (this.products.KSM || this.products.USM))
                                limit250 = true;

                            this.subscriptionOpts = this.licenseService.getSubscriptionOptions(
                                siteType,
                                void 0,
                                this._agppVersion,
                                limit250
                            );

                            this.nextLoading = false;

                            if (this._agppVersion != 1)
                                licenses = licenses.filter(
                                    (l) =>
                                        l.SubscriptionLevel != 'gdpr50' &&
                                        l.SubscriptionLevel != 'gdpr100'
                                );

                            this.availableLicenses = licenses.sort((a, b) => {
                                return (
                                    parseInt(a.Model.split(' ')[1]) -
                                    parseInt(b.Model.split(' ')[1])
                                ); // sort by number of allowed computers
                            });

                            if (this.availableLicenses.length) this.licenseType = 'existing';
                            else this.licenseType = 'new';

                            this.changeStep(2);
                            this.canProceed = false;
                        })
                        .catch((err) => {
                            this.nextLoading = false;
                            console.error(err);
                            this.notificationService.toast.error(
                                'Error',
                                'An error occurred retrieving the account licenses.'
                            );
                        });
                }
            }
        } else if (this.step == 2) {
            if (this.licenseType == 'new') {
                this.nextLoading = true;
                this.licenseService.getSubscriptionPrice(this.license.model).then((sp) => {
                    this.nextLoading = false;
                    this.newSubPricing = sp;
                    this.showOrganizationForm();
                });
            } else {
                this.showOrganizationForm();
            }
        } else if (this.step == 3) {
            if (this.isSMB || (this.isCM && this.licenseSiteType == 'AG')) {
                this.nextLoading = true;
                this.addSite();
            } else {
                this.autoProvisionVulScanCtrl.setValue('true');
                this.changeStep(4);
            }
        } else if (this.step == 4) {
            this.addSite();
        }
    }

    goBack() {
        if (this.step == 1) {
            this.destinationOrganizationName =
                this.selectedOrg === ALL_SITES ? '' : this.selectedOrg;
            this.newOrganizationName = '';
            this.closeModal();
            return;
        }

        let products = this.authService.getProducts();

        if (
            (this.isSMB ||
                (this.isCM &&
                    (this.newSite.Type == 'GDPR' ||
                        this.newSite.Type == 'UKGDPR' ||
                        this.newSite.Type == 'HIPAA' ||
                        this.newSite.Type == 'CYBERINSURANCE' ||
                        this.newSite.Type == 'NIST' ||
                        this.newSite.Type == 'CMMC')) ||
                this.newSite.Type == 'KVS' ||
                this.newSite.Type == 'CM2') &&
            this.step == 3
        ) {
            this.changeStep(1);
        } else if (products.CH && this.step == 3) this.changeStep(1);
        else this.changeStep(this.step - 1);

        this.canProceed = true;
    }

    getFormattedPrice(): string {
        if (!this.newSubPricing) return '';

        switch (this.newSubPricing.currency) {
            case 'USD':
                return this.newSubPricing.symbol + this.newSubPricing.price;
            case 'EUR':
                return this.newSubPricing.price + this.newSubPricing.symbol;
        }
    }

    getDisplayName(name): string {
        switch (name) {
            case 'CYBERINSURANCE':
                return 'CYBER INSURANCE';
            default:
                return name;
        }
    }

    addSiteToOrganization(organizationName: string, siteIds: number[]): Promise<any> {
        return this.organizationsService
            .moveSitesToOrganization(organizationName, siteIds)
            .then(() => {
                this.organizationsService.getLatest();
            });
    }

    addSite() {
        if (
            (this.isSMB ||
                (this.isCM &&
                    (this.newSite.Type == 'GDPR' ||
                        this.newSite.Type == 'UKGDPR' ||
                        this.newSite.Type == 'HIPAA' ||
                        this.newSite.Type == 'CYBERINSURANCE' ||
                        this.newSite.Type == 'NIST' ||
                        this.newSite.Type == 'CMMC'))) &&
            this.hasViolation
        )
            return;

        let hasTodos = false;

        if (
            this.newSite.Type == 'GDPR' ||
            this.newSite.Type == 'UKGDPR' ||
            this.newSite.Type == 'HIPAA' ||
            this.newSite.Type == 'CYBERINSURANCE' ||
            this.newSite.Type == 'NIST' ||
            this.newSite.Type == 'CMMC'
        ) {
            this.newSite.Todos = [null, null]; // stand-ins for the 2 new todos that will be inserted on creation
            hasTodos = true;
        } else if (this.newSite.Type == 'CH') {
            this.newSite.Todos = [null];
            hasTodos = true;
        }

        let existing = this.licenseType == 'existing';
        this.newSite.Name = this.newSite.Name.trim();
        this.nextLoading = true;

        var orgName = this.organizationChoices.filter(
            (oc) => oc.Id == this.selectedOrganizationId
        )[0]?.Name;

        if (this.selectedOrganizationId == -1) {
            orgName = this.newOrganizationName;
        }

        this.siteService
            .provisionSite(
                this.newSite,
                this.license,
                existing,
                orgName,
                this.selectedOrganizationId,
                this.autoProvisionVulScanCtrl.value
            )
            .then((res) => {
                this.organizationsService.getLatest();
                this.success();

                if (orgName && orgName.length > 0) {
                    this.siteDashboardService.setDashboardItem(Number(res), 'OrganizationName');
                }

                if (hasTodos) {
                    this.siteDashboardService.setDashboardItem(Number(res), 'Todo');
                    this.siteDashboardService.setDashboardItem(Number(res), 'AssessmentStatus');
                }
            })
            .catch((res) => {
                this.error(res.error);
            });

        /*this.siteService.postSite(this.newSite)
          .then(res => { this.success(); })
          .catch(err => { this.error(err); });*/
    }

    success() {
        this.nextLoading = false;
        //this.messageService.broadcast('SITE_CREATED', this.newSite);
        this.notificationService.toast.success('Success', 'New site created.');

        let newSite = Object.assign({}, this.newSite);

        this.initModal(); // reset values
        this.modal.hide();

        this.onAdd.emit(newSite);
    }

    selectOrganization(organizationChangeEvent: any) {
        let evtOorganizationId: number = organizationChangeEvent.value;
        let oldOrgId = this.selectedOrganizationId;
        this.destinationOrganizationId = evtOorganizationId;

        if (organizationChangeEvent.value == -1) {
            // if org is all sites, and it wasn't previously,
            // disable button so we can get the new name
            if (oldOrgId != -1) {
                this.canProceed = false;
            }
        } else {
            this.newOrganizationName = ''; // remove new org name
            this.canProceed = true;
        }
        this.validateCanProceedBySelectedOrganization();
    }

    validateCanProceedBySelectedOrganization() {
        if (
            (this.selectedOrganizationId === -1 && this.newOrganizationName?.length == 0) ||
            typeof this.selectedOrganizationId == 'undefined'
        ) {
            this.canProceed = false;
        }
    }

    setNewOrganizationName(organizationName: string) {
        if (!this.formService.validate('organizationForm')) {
            this.canProceed = false;

            return;
        }

        if (!organizationName.trim()) {
            this.formService.error('siteName', 'Can not be only spaces');
            this.canProceed = false;
            return;
        }
        this.canProceed = true;
    }

    error(err: any) {
        this.nextLoading = false;
        if (err && err.ErrorMessage) {
            this.notificationService.toast.error('Error', err.ErrorMessage);
        } else
            this.notificationService.toast.error(
                'Error',
                'There was a problem creating the Site. Please try again shortly.'
            );
    }

    checkSmbLicenseViolations(): boolean {
        if (!this.newSite) return false;
        return (
            (this.newSite.Type == 'CH' && this.hasCHViolation) ||
            ((this.newSite.Type == 'GDPR' ||
                this.newSite.Type == 'UKGDPR' ||
                this.newSite.Type == 'HIPAA' ||
                this.newSite.Type == 'CYBERINSURANCE' ||
                this.newSite.Type == 'NIST' ||
                this.newSite.Type == 'CMMC') &&
                this.hasAGViolation)
        );
    }
}
