import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { CalendarModule } from 'primeng/calendar';

import { SiteAuditComponent } from './audit.component';
import { AuditService } from './shared/audit.service';
import { AuditTableComponent } from './shared/audit-table/audit-table.component';
import { AuditSearchFilterComponent } from './shared/audit-search-filter/audit-search-filter.component';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule, CalendarModule, FormsModule],
    declarations: [SiteAuditComponent, AuditSearchFilterComponent, AuditTableComponent],
    exports: [],
    providers: [AuditService],
})
export class AuditModule {
    constructor() {
        console.log('Audit Module');
    }
}
