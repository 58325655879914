import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Alert, AlertService } from 'app/alerts';
// import { AlertStatusBadgeComponent } from 'app/alerts/shared/alert-status-badge/alert-status-badge.component';
// import { AlertStatusLookup, ALERT_STATUSES } from 'app/alerts/shared/constants';
// import { Audit, AuditService } from 'app/audit';
// import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { Connection, ConnectionService } from 'app/connections';
import { MessageService } from 'app/core/message.service';
// import { Notes } from 'app/notes/notes.model';
// import { NotesService } from 'app/notes/notes.service';
// import { ScanDataService } from 'app/scans/shared/scan-data.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
//
import { UiService } from 'app/core/ui.service';

import { PING_SITE } from 'app/sites/shared/constants';
// import { ASSESSMENT, PING_ASSESSMENT } from 'app/sites/site/assessments/site-assessments.component';
import { KvsService } from '../../../shared/kvs.service';
import { Subject, takeUntil } from 'rxjs';
import { HEVSLicense } from '../kvs.model';

declare var ago: any;

const UI_FILTER_KEY: string = 'kvs_results_filter';

const highestRiskIssuesMaxCount: number = 15;
const kevIssuesMaxCount: number = 15;

@Component({
    selector: 'kvs-dashboard',
    templateUrl: './kvs-dashboard.component.html',
    styleUrls: ['./kvs-dashboard.component.css'],
})
export class KvsDashboardComponent implements OnInit {
    breadcrumbs = [
        { path: '..', text: 'VulScan' },
        { path: '..', text: 'Dashboard' },
    ];

    site: Site;
    // alerts: Alert[];
    apps: any[] = [];
    appliancesLoaded: boolean;

    loadingComplete: boolean;
    selectedTab: string = 'all';
    isIntResults: boolean = true;
    isExtResults: boolean = true;
    isAgtResults: boolean = true;
    isTest: boolean = true;

    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        KVS: 'Vulnerability Scanner',
    };
    siteAppliances: any = {
        indoc: [],
        cyberHawk: [],
        complianceManager: [],
        kvs: [],
    };

    kvsApps: any = {
        App: [],
        Type: 'kvs',
    };

    kvsScanStatusApps: any[] = [];
    scanStatusScanType: string = '';

    kvsVulnerabilitiesOverTime: any[];
    kvsHighestRisk: any[];
    kvsHighestCve: any[];

    kvsMostRecentVulnerabilities: any[];
    kvsMostRecentVulnerabilitiesLoaded: boolean = false;
    kvsIssues: any[];
    kevIssues: any[];
    conn: Connection = new Connection();

    siteTodos: any[] = [];
    isReadOnly: boolean = false;
    isClientView = false;
    ngUnsubscribe$ = new Subject<void>();

    license: HEVSLicense;

    @ViewChild('kvsChartHighestRisk') kvsChartHighestRisk: any;
    @ViewChild('kvsChartVulOverTime') kvsChartVulOverTime: any;
    @ViewChild('kvsChartMostRecentVul') kvsChartMostRecentVul: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        // private alertService: AlertService,
        // private authService: AuthService,
        // private scandataService: ScanDataService,
        private connService: ConnectionService,
        private messageService: MessageService,
        private router: Router,
        private siteService: SiteService,
        private siteSettingService: SiteSettingService,
        private uiService: UiService,
        private kvsService: KvsService
    ) {}

    ngOnInit() {
        this.activatedRoute.data.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data) => {
            this.selectedTab = data.viewType;
            this.isIntResults = data.viewType == 'internal' || data.viewType == 'all';
            this.isExtResults = data.viewType == 'external' || data.viewType == 'all';
            this.isAgtResults = data.viewType == 'discovery' || data.viewType == 'all';

            if (data.viewType == 'external') this.kvsApps.Type = 'EVS';
            else if (data.viewType == 'discovery') this.kvsApps.Type = 'AGT';
        });

        this.siteService.app_getCurrentSite().then((site) => {
            this.onSite(site);
        });
        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onSite(site: Site) {
        this.site = site;
        console.log(this.site);
        let scanType: string = '';
        if (this.isIntResults && this.isExtResults && this.isAgtResults) {
            scanType = 'ALL';
            this.scanStatusScanType = 'kvs';
        } else if (this.isAgtResults) {
            scanType = 'AGT';
            this.scanStatusScanType = 'agt';
        } else if (this.isIntResults) {
            scanType = 'IVS';
            this.scanStatusScanType = 'ivs';
        } else {
            scanType = 'EVS';
            this.scanStatusScanType = 'evs';
        }
        this.isReadOnly = site.UserRoles == 'IND_CLIENT';
        this.isClientView = site.UserRoles == 'IND_CLIENT';
        this.loadingComplete = false;
        if (this.site) {
            let initSite = this.site;
            this.uiService.setTitle('VulScan Dashboard', site.Name);
            this.doApplianceLoad();
            this.addVulScanDiscoveryAgentAppliance();

            this.kvsService
                .getKvsHighestRisk(this.site.Id)
                .then((res) => {
                    this.kvsHighestRisk = this.filterResults(res);
                })
                .catch((e) => {
                    this.kvsHighestRisk = [];
                });

            this.kvsService
                .getKvsMostRecentVulnerabilities(this.site.Id, scanType)
                .then((res) => {
                    this.kvsMostRecentVulnerabilities = res.filter(
                        (i) => i.Severity.toLowerCase() !== 'none'
                    );
                })
                .catch((e) => {
                    this.kvsMostRecentVulnerabilities = [];
                });

            this.kvsService
                .getKvsVulnerabilitiesOverTime(this.site.Id, 14, scanType)
                .then((res) => {
                    this.kvsVulnerabilitiesOverTime = res;
                    console.log('over time', res);
                })
                .catch((e) => {
                    this.kvsVulnerabilitiesOverTime = [];
                });

            this.kvsService
                .getKvsScanResultsByIssue(this.site.Id, 30, 1)
                .then((res) => {
                    res = this.filterResults(res);
                    res.sort((a, b) => b.Cvss - a.Cvss);
                    this.kvsIssues = res.slice(0, highestRiskIssuesMaxCount);
                    this.setKevIssues(res);
                })
                .catch((e) => {
                    this.kvsIssues = [];
                });

            this.kvsService
                .getKvsHighestCve(this.site.Id)
                .then((res) => {
                    res = this.filterResults(res);
                    this.kvsHighestCve = res;
                    console.log('Highest CVE', this.kvsHighestCve);
                })
                .catch((e) => (this.kvsHighestCve = []));

            this.connService.getConnectionBySite(this.site.Id).then((conn) => {
                this.conn = conn;
            });
        }
    }

    addVulScanDiscoveryAgentAppliance() {
        this.kvsService.getKvsAppliances(this.site.Id).then((appliances) => {
            this.kvsService.getHEVSLicense(this.site.Id).then((license: HEVSLicense) => {
                if (appliances || license) {
                    if (appliances) {
                        let appliancesFiltered = appliances;
                        appliancesFiltered.map((p) =>
                            p.IsOnline ? (p.Status = 'Online') : (p.Status = 'Offline')
                        );
                        this.siteAppliances.kvs.push(...appliancesFiltered);
                    }
                    if (license) {
                        var hevsApp = { Id: 'HEVS', Description: 'Hosted EVS', Type: 'HEVS' };
                        this.siteAppliances.kvs.push(hevsApp);
                    }
                    this.filterApps();
                } else {
                    //No appliances available so mark as loaded
                    this.appliancesLoaded = true;
                }
            });
        });
    }

    doApplianceLoad() {
        this.siteSettingService
            .getApplianceInfoForReal(this.site.Id)
            .then((appliances) => {
                console.log('appliances', appliances);
                if (appliances?.length) {
                    let appsCount = 0;
                    let tempSiteAppliances = {
                        indoc: [],
                        cyberHawk: [],
                        complianceManager: [],
                        kvs: [],
                    };
                    appliances.forEach((appliance) => {
                        let updateStatus = appliance.UpdateStatus == 1 ? 'up-to-date' : 'unknown';
                        let updateAvailable = appliance.UpdateStus == 0;

                        let lastCheckin = new Date(appliance.LastCheckin);
                        // let lastCheckinAgo = ago(lastCheckin.getTime(), true) + ' ago';
                        if (lastCheckin.getFullYear() < 2000) {
                            /*this.noCheckin = true; */
                        }
                        let appTypes = appliance.Types.map(
                            (t) => this.friendlyTypes[t]
                        ).join(', ');

                        if (!this.isClientView) {
                            this.siteSettingService
                                .getApplianceScanStatuses(this.site.Id, appliance.Id)
                                .then((scans) => {
                                    for (let sc of scans) {
                                        let startDate = new Date(sc.start);
                                        sc.start = ago(
                                            this.convertUTCDateToLocalDate(startDate).getTime(),
                                            true
                                        );
                                    }
                                    let app = {
                                        Id: appliance.Id,
                                        Description: appliance.Description || '',
                                        Type: appTypes,
                                        LastCheckin: lastCheckin,
                                        UpdateStatus: updateStatus,
                                        UpdateAvailable: updateAvailable,
                                        IsVirtual: appliance.IsVirtual,
                                        IsServer: appliance.IsServer,
                                        Scans: scans,
                                        Status: appliance.IsOnline ? 'Online' : 'Offline',
                                        KvsType: appliance.KvsType,
                                    };
                                    if (app.Type.indexOf('Cyber Hawk') > -1) {
                                        this.siteAppliances.cyberHawk.push(app);
                                    }
                                    if (app.Type.indexOf('Reporter') > -1) {
                                        this.siteAppliances.indoc.push(app);
                                    }
                                    if (
                                        app.Type.indexOf('Audit Guru') > -1 ||
                                        app.Type.indexOf('Compliance Manager') > -1
                                    ) {
                                        this.siteAppliances.complianceManager.push(app);
                                    }

                                    appsCount++;

                                    if (appsCount == appliances.length) {
                                        // all appliances are loaded

                                        // sort appliances by ID
                                        Object.keys(this.siteAppliances).forEach((key) => {
                                            this.siteAppliances[key].sort((a, b) =>
                                                a.Id.localeCompare(b.Id)
                                            );
                                        });
                                    }
                                });
                        }
                    });
                }
                this.loadingComplete = true;
            })
            .catch((err) => {});
    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours + offset);

        return newDate;
    }

    refreshResults() {
        let scanType: string = '';
        if (this.isIntResults && this.isExtResults && this.isAgtResults) scanType = 'ALL';
        else if (this.isAgtResults) scanType = 'AGT';
        else if (this.isIntResults) scanType = 'IVS';
        else scanType = 'EVS';

        this.kvsChartHighestRisk.loadingComplete = false;
        this.kvsChartMostRecentVul.loadingComplete = false;
        this.kvsChartVulOverTime.loadingComplete = false;
        this.kvsService
            .getKvsScanResultsByIssue(this.site.Id, 30, 1)
            .then((res) => {
                res = this.filterResults(res);
                res.sort((a, b) => b.Cvss - a.Cvss);
                this.kvsIssues = res.slice(0, highestRiskIssuesMaxCount);
                this.setKevIssues(res);
            })
            .catch((e) => {
                this.kvsIssues = [];
            });

        this.kvsService
            .getKvsHighestRisk(this.site.Id)
            .then((res) => {
                this.kvsHighestRisk = this.filterResults(res);
            })
            .catch((e) => {
                this.kvsHighestRisk = [];
            });

        this.kvsService
            .getKvsMostRecentVulnerabilities(this.site.Id, scanType)
            .then((res) => {
                this.kvsMostRecentVulnerabilities = res.filter(
                    (i) => i.Severity.toLowerCase() !== 'none'
                );
            })
            .catch((e) => {
                this.kvsMostRecentVulnerabilities = [];
            });

        this.kvsService
            .getKvsVulnerabilitiesOverTime(this.site.Id, 14, scanType)
            .then((res) => {
                this.kvsVulnerabilitiesOverTime = res;
                console.log('over time', res);
            })
            .catch((e) => {
                this.kvsVulnerabilitiesOverTime = [];
            });
    }

    makeURI(component) {
        if (component) {
            return component.replace(/&/g, '%26');
        }
    }

    redirectToDevice(value: any) {
        this.uiService.setControlValue(UI_FILTER_KEY, value);
        this.router.navigate([
            '/site',
            this.makeURI(this.site.Name),
            'vulnerability-scanner',
            'scan-results',
            'by-device',
        ]);
    }

    filterApps() {
        const tempApps = [];
        if (this.isIntResults) {
            for (let app of this.siteAppliances.kvs) {
                if (app.Type == 'IVS' || app.Type == 'RIVS' || app.Type == 'PVS') {
                    tempApps.push(app);
                }
            }
        }
        if (this.isExtResults) {
            for (let app of this.siteAppliances.kvs) {
                if (app.Type == 'EVS') {
                    tempApps.push(app);
                }
                if (app.Type == 'HEVS') {
                    this.kvsScanStatusApps.push(app);
                }
            }
        }
        if (this.isAgtResults) {
            for (let app of this.siteAppliances.kvs) {
                if (app.Type == 'AGT') {
                    tempApps.push(app);
                }
            }
        }
        tempApps.sort((a, b) => {
            return a.Id.localeCompare(b.Id);
        });
        this.kvsApps.App = tempApps;
        this.kvsScanStatusApps.push(...this.kvsApps.App);
        this.appliancesLoaded = true;
    }

    filterResults(results: any[]) {
        let filteredResults: any[] = [];
        if (!this.isExtResults && !this.isAgtResults) {
            for (let r of results) {
                if (r.ScanType == 'IVS' || r.ScanType == 'KVS') {
                    r['AffectedNodesEvsFormatted'] = [];
                    r['AffectedNodesAgtFormatted'] = [];
                    filteredResults.push(r);
                }
            }
            return filteredResults;
        } else if (!this.isIntResults && !this.isAgtResults) {
            for (let r of results) {
                if (r.ScanType == 'EVS' || r.ScanType == 'KVS') {
                    r['AffectedNodesIvsFormatted'] = [];
                    r['AffectedNodesAgtFormatted'] = [];
                    filteredResults.push(r);
                }
            }
            return filteredResults;
        } else if (!this.isIntResults && !this.isExtResults) {
            for (let r of results) {
                if (r.ScanType == 'AGT') {
                    r['AffectedNodesIvsFormatted'] = [];
                    r['AffectedNodesEvsFormatted'] = [];
                    filteredResults.push(r);
                }
            }
            return filteredResults;
        } else {
            return results;
        }
    }

    setKevIssues(kvsIssues: any[]) {
        this.kevIssues = kvsIssues
            .filter((i) => i.KnownExploitedVulnerability.IsKnownExploitedVulnerability)
            .slice(0, kevIssuesMaxCount);
    }
}
