<itc-sidebar (onHide)="hideSidebar()" #sidebar>
    <div header>Install Key / Scan Schedule</div>
    <div content>
        <form class="discovery-agent-scan-panel itc-form col" [formGroup]="scanScheduleForm" (ngSubmit)="onSubmit()">
            <div class="itc-form__field">
                <label>Install Key</label>
                <div class="install-key-container">
                    <input class="install-key-textbox" type="text" formControlName="installKey" readonly>
                    <itc-button class="install-key-button" type="icon" icon="copy"
                                (click)="copyInstallKey()"
                                [disabled]="!scanScheduleForm.controls.installKey.value?.length"></itc-button>
                    <itc-button class="install-key-button" type="icon" icon="fa-arrows-rotate"
                                (click)="confirmRotateInstallKey.show()"
                                [loading]="isRotatingInstallKey"></itc-button>
                </div>
            </div>

            <div class="divider"></div>

            <div class="scan-schedule-container" formGroupName="dailyScan">
                <itc-toggle formControlName="isEnabled" labelPosition="right"
                            [label]="'Daily Scan'"></itc-toggle>
                <div class="scan-schedule-content">
                    <div class="daily-scan-schedule-weekdays-container" formGroupName="days">
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="sun" [label]=false></itc-checkbox>
                            Sun
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="mon" [label]=false></itc-checkbox>
                            Mon
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="tue" [label]=false></itc-checkbox>
                            Tue
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="wed" [label]=false></itc-checkbox>
                            Wed
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="thu" [label]=false></itc-checkbox>
                            Thu
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="fri" [label]=false></itc-checkbox>
                            Fri
                        </div>
                        <div class="daily-scan-schedule-weekday">
                            <itc-checkbox formControlName="sat" [label]=false></itc-checkbox>
                            Sat
                        </div>
                    </div>
                    <div class="daily-scan-schedule-option-container">
                        <label>Option</label>
                        <itc-checkbox formControlName="performVulScan"
                                      [label]="'Application Vulnerability Scan (Windows)'">
                        </itc-checkbox>
                    </div>
                </div>
            </div>

            <div class="scan-schedule-container" formGroupName="deepFileScan">
                <itc-toggle formControlName="isEnabled" labelPosition="right"
                            [label]="'Deep File Scan'"
                            tooltip="Deep File Scan requires Daily Scan to be enabled"
                            [ttHidden]="scanScheduleForm.get('deepFileScan.isEnabled').enabled"></itc-toggle>
                <div class="scan-schedule-content">
                    <label>Repeat Weekly</label>
                    <p-dropdown formControlName="dayOfWeek" [options]="availableWeekdays"></p-dropdown>

                    <itc-collapsible class="accordion" [multiple]="false" [activeIndex]="dailyScanActiveIndex">
                        <itc-panel header="Deep File Scan Settings">
                            <div class="deep-file-scan-options-container" formGroupName="options">
                                <div class="deep-file-scan-option">
                                    <label>Platform Computer Drive Encryption Detection</label>
                                    <itc-checkbox formControlName="computerDriveEncryptionDetection"
                                                  [label]="'Enable'"></itc-checkbox>
                                </div>
                                <div class="deep-file-scan-option">
                                    <label>Sensitive Data Scan Types</label>
                                    <itc-checkbox formControlName="ePhi" [label]="'ePHi (HIPAA)'"></itc-checkbox>
                                    <itc-checkbox formControlName="cardholder"
                                                  [label]="'Cardholder Data (PCI)'"></itc-checkbox>
                                    <itc-checkbox formControlName="gdprPersonal"
                                                  [label]="'GDPR Personal Data (PD)'"></itc-checkbox>
                                    <itc-checkbox formControlName="pii"
                                                  [label]="'Personally Identifiable Information (PII)'"></itc-checkbox>
                                </div>
                                <div class="deep-file-scan-option">
                                    <label>Sensitive Data Scan Options</label>
                                    <itc-checkbox formControlName="zipScan"
                                                  [label]="'Include the scanning of ZIP files'"></itc-checkbox>
                                    <itc-checkbox formControlName="pdfScan"
                                                  [label]="'Include the scanning of PDF files'"></itc-checkbox>
                                    <div class="zip-file-scan-timeout">
                                        PDF timeout
                                        <input type="number" formControlName="pdfTimeoutValue" min="1" max="60" />
                                        minutes (min=1,max=60)
                                    </div>
                                </div>
                            </div>
                        </itc-panel>
                    </itc-collapsible>
                </div>
            </div>

            <itc-collapsible class="accordion" [multiple]="false" [activeIndex]="deepFileScanActiveIndex">
                <itc-panel header="Advanced Options (Windows)">
                    <div class="advanced-option-container" formGroupName="advancedOptions">
                        <ng-container formGroupName="urlTesting">
                            <itc-checkbox formControlName="isEnabled" [label]="'URL Testing'"></itc-checkbox>
                            <ng-container formGroupName="options">
                                <itc-checkbox class="sub-option" formControlName="entertainment"
                                              [label]="'Entertainment'"></itc-checkbox>
                                <itc-checkbox class="sub-option" formControlName="pornography"
                                              [label]="'Pornography'"></itc-checkbox>
                                <itc-checkbox class="sub-option" formControlName="shareware"
                                              [label]="'Shareware'"></itc-checkbox>
                                <itc-checkbox class="sub-option" formControlName="socialMedia"
                                              [label]="'Social Media'"></itc-checkbox>
                                <itc-checkbox class="sub-option" formControlName="warez"
                                              [label]="'Warez'"></itc-checkbox>
                                <itc-checkbox class="sub-option" formControlName="webMail"
                                              [label]="'Web Mail'"></itc-checkbox>
                            </ng-container>
                        </ng-container>
                        <itc-checkbox formControlName="portTesting" [label]="'Port Testing'"></itc-checkbox>
                        <itc-checkbox formControlName="wifiData" [label]="'Wifi Data'"></itc-checkbox>
                        <itc-checkbox formControlName="policies" [label]="'Policies (RSOP)'"></itc-checkbox>
                        <itc-checkbox formControlName="screenSaving" [label]="'Screen Saving Settings'"></itc-checkbox>
                        <itc-checkbox formControlName="usb" [label]="'USB Detection'"></itc-checkbox>
                        <itc-checkbox formControlName="internetAccessibility"
                                      [label]="'Internet Accessibility'"></itc-checkbox>
                        <itc-checkbox formControlName="loginDetails" [label]="'Login Details (LDF)'"></itc-checkbox>
                    </div>
                </itc-panel>
            </itc-collapsible>

            <div *ngIf="!hasAnyAgentsInstalled" style="color: var(--text-critical); margin-top: 8px">
                Scan Schedule can be applied after a Discovery Agent is installed
            </div>
        </form>
    </div>
    <div footer>
        <itc-button
            type="submit"
            [loading]="applyingScanSchedule"
            [disabled]="!hasAnyAgentsInstalled || scanScheduleForm.invalid"
            (onclick)="onSubmit()">
            Apply
        </itc-button>
        <itc-button type="darkText ghost" (onclick)="hideSidebar()">Cancel</itc-button>
    </div>
</itc-sidebar>

<sm-modal class="sm" title="Weekly Scan Day List Change" #deepFilesScanDayGuard>
    <modal-content>
        <p>
            The change in the Daily Scan Schedule will remove
            <b>{{ selectedDeepFilesScanDay }}</b>
            from the days available to run a Deep scan.
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="cancelDayListChange()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="confirmDayListChange()">OK</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="sm" title="Regenerate Install Key" #confirmRotateInstallKey>
    <modal-content>
        <p>
            Regenerating the Install Key will invalidate the prior install key. Please be sure to
            update any install scripts to use the new install key.
        </p>
        <p>Do you wish to proceed?</p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary"
                    (onclick)="confirmRotateInstallKey.hide()"
                    [disabled]="isRotatingInstallKey">
            Cancel
        </itc-button>
        <itc-button type="primary"
                    (onclick)="rotateInstallKey(); confirmRotateInstallKey.hide()">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Confirm Update" #confirmAgentScheduleModal>
    <modal-content>
        <div>Update all agents to the selected scan schedule?</div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary"
                    (onclick)="confirmAgentScheduleModal.hide()"
                    [disabled]="applyingScanSchedule">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="onSubmit()" [loading]="applyingScanSchedule">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>