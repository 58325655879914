import {
    Component,
    inject,
    Input,
    Output,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { DiscoveryAgentTableRow } from '../models/discovery-agents-table-row.model';
import { DiscoveryAgentTableService } from '../services/discovery-agent-table.service';
import { MenuItem } from 'primeng/api';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { combineLatest, map, Observable, Subject, switchMap, takeUntil, zip } from 'rxjs';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';
import {
    getEmptyDiscoveryAgentFilters,
    DiscoveryAgentFilters,
} from '../models/discovery-agent-filters.model';
import { FormControl, FormsModule } from '@angular/forms';
import { TableSorting } from '../../sortable-table/sortable-table.component';
import { DiscoveryAgentEditSidebarComponent } from '../discovery-agent-edit-sidebar/discovery-agent-edit-sidebar.component';
import { take } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { DiscoveryAgentsRunnowRequestModel } from '../models/discovery-agents-runnow-request.model';
import { Organization } from 'app/organizations/organization.model';
import { DiscoveryAgentService } from 'app/shared/discovery-agent/services/discovery-agent.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { DiscoveryAgentFilterDto } from '../models/discovery-agent-filter-dto.model';
import { PaginationFilters } from '../../itc/paginator/itc-paginator.model';
import { DiscoveryAgentSettingsService } from '../services/discovery-agent-settings.service';

@Component({
    selector: 'sds-discovery-agent-table',
    standalone: true,
    imports: [CommonModule, SharedModule, FormsModule, DiscoveryAgentEditSidebarComponent],
    templateUrl: './discovery-agent-table.component.html',
    styleUrls: ['./discovery-agent-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiscoveryAgentTableComponent implements OnInit, OnDestroy {
    @Input({ required: true }) organization: Organization;
    @Output() hasDiscoveryAgents = new EventEmitter<boolean>();

    @ViewChild('confirmRunNow', { static: true }) confirmRunNowModal: SemanticModalComponent;
    @ViewChild('confirmDeepRunNow', { static: true })
    confirmDeepRunNowModal: SemanticModalComponent;
    @ViewChild('confirmUpdateNow', { static: true }) confirmUpdateNowModal: SemanticModalComponent;
    @ViewChild('confirmEnableAutoUpdate', { static: true })
    confirmEnableAutoUpdateModal: SemanticModalComponent;
    @ViewChild('confirmDisableAutoUpdate', { static: true })
    confirmDisableAutoUpdateModal: SemanticModalComponent;
    @ViewChild('confirmCancelScans', { static: true })
    confirmCancelScansModal: SemanticModalComponent;
    @ViewChild('confirmBulkAgentRemoval', { static: true })
    confirmBulkAgentRemovalModal: SemanticModalComponent;
    @ViewChild('editDiscoveryAgentCommentBulk', { static: true })
    editDiscoveryAgentCommentBulkModal: SemanticModalComponent;
    @ViewChild('editDiscoveryAgentLabelBulk', { static: true })
    editDiscoveryAgentLabelBulkModal: SemanticModalComponent;
    @ViewChild('confirmDeleteAgentModal', { static: true })
    confirmDeleteAgentModal: SemanticModalComponent;
    @ViewChild('editDiscoveryAgentSidebar', { static: true })
    editDiscoveryAgentSidebar: DiscoveryAgentEditSidebarComponent;

    checkedDiscoveryAgentsTableRows: CheckedDiscoveryAgentTableRow[];
    first10SelectedAgents: DiscoveryAgentTableRow[] = [];
    selectedCount = 0;
    numAgentsToDelete: number;
    selectedFilters: Partial<DiscoveryAgentFilters> = null;
    globalSelectedDiscoveryAgentIds = new Set<string>();
    globalUnselectedDiscoveryAgentIds = new Set<string>();

    tableInitialized = false;
    isLoadingModal = false;
    loading$: Observable<boolean>;
    isDeleting = false;
    isRunningNow = false;
    isCancelingNow = false;
    bulkLabelControl = new FormControl<string>('');
    isUpdatingBulkLabel = false;
    bulkCommentControl = new FormControl<string>('');
    isUpdatingBulkComment = false;
    isUpdatingNow = false;
    isEnablingAutoUpdate = false;
    isDisablingAutoUpdate = false;

    filteredCount = 0;
    totalCount = 0;

    bulkActions: MenuItem[];
    private readonly discoveryAgentTableService = inject(DiscoveryAgentTableService);
    discoveryAgentFilters$ = this.discoveryAgentTableService.getFilterDataObject$();
    private readonly filteredPagedItems$ = this.discoveryAgentTableService.getFilteredPagedItems();
    private readonly notificationService = inject(NotificationService);
    private readonly discoveryAgentService = inject(DiscoveryAgentService);
    private readonly discoveryAgentSettingsService = inject(DiscoveryAgentSettingsService);
    private readonly unsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.getDiscoveryAgentsTableRows();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onBulkCheckboxClicked() {
        if (this.selectedCount == this.totalCount) {
            this.clearSelection();
            return;
        }

        if (this.selectedCount == 0) {
            this.selectAllInFilter();
            return;
        }

        this.selectAll();
    }

    onPageChange(evt) {
        if (this.tableInitialized) {
            this.discoveryAgentTableService.filterData({
                page: evt.pageIndex,
                limit: evt.pageSize,
            });
        } else {
            this.tableInitialized = true;
        }
    }

    onSelectionChange(agent: CheckedDiscoveryAgentTableRow) {
        if (agent.checked) {
            this.globalSelectedDiscoveryAgentIds.add(agent.Id.trim());
            this.globalUnselectedDiscoveryAgentIds.delete(agent.Id.trim());
            this.selectedCount++;
        } else {
            this.globalSelectedDiscoveryAgentIds.delete(agent.Id.trim());
            this.globalUnselectedDiscoveryAgentIds.add(agent.Id.trim());
            this.selectedCount--;
        }
        this.createBulkActions();
    }

    onSorted(evt: TableSorting) {
        this.discoveryAgentTableService.filterData({
            orderby: evt.sortColumn as string,
            sortby: evt.sortDirection,
        });
    }

    getStatusIconString(scan: string) {
        switch (scan) {
            case 'success':
                return 'success';
            case 'failure':
                return 'failed';
            case 'in progress':
                return 'loading';
            case 'canceled':
                return 'failed';
            default:
                return 'no-data';
        }
    }

    showConfirmDeleteAgentModal(agents: DiscoveryAgentTableRow[]) {
        this.first10SelectedAgents = agents;
        this.numAgentsToDelete = agents.length;
        this.confirmDeleteAgentModal.show({ closable: false });
    }

    deleteAgents() {
        this.isDeleting = true;
        let deleteRequest: Observable<unknown>;

        if (this.first10SelectedAgents.length < 10) {
            let agentIds = this.first10SelectedAgents.map((agent) => agent.Id);
            deleteRequest = this.discoveryAgentService.agentsDeprovisionOrgBulk(
                this.organization.Id,
                {
                    ApplianceStatus: null,
                    ComplianceMonitorStatus: null,
                    OS: null,
                    Label: null,
                    Text: null,
                    ExcludeIds: [],
                    IncludeIds: agentIds,
                }
            );
        } else {
            deleteRequest = this.discoveryAgentService.agentsDeprovisionOrgBulk(
                this.organization.Id,
                this.getDiscoveryAgentFilterDto()
            );
        }

        deleteRequest.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.discoveryAgentTableService.reloadData();
                this.notificationService.toast.success(
                    'Sent',
                    'The request to delete agent sent to the data collectors'
                );
                this.isDeleting = false;
                this.confirmDeleteAgentModal.hide();
            },
            error: () => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem sending the delete request to the data collectors.'
                );
                this.isDeleting = false;
            },
        });
    }

    runAgentsNow() {
        this.isRunningNow = true;
        this.discoveryAgentService
            .getOrgDiscoveryAgentDefaultSettings$(this.organization.Id)
            .pipe(
                switchMap((defaultSettings) => {
                    const request: DiscoveryAgentsRunnowRequestModel = {
                        OrganizationId: this.organization.Id,
                        Filter: this.getDiscoveryAgentFilterDto(),
                        DeepScanSettings: '',
                        Is3ppScan: defaultSettings.Is3ppScan,
                    };
                    return this.discoveryAgentService.agentsRunNowOrgBulk$(request);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success('Sent', 'Scan successfully queued');
                    this.isRunningNow = false;
                    this.confirmRunNowModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'Failed to schedule discovery agents to run now.'
                    );
                    this.isRunningNow = false;
                },
            });
    }

    runAgentsDeepNow() {
        this.isRunningNow = true;
        zip([
            this.discoveryAgentService.getOrgDiscoveryAgentDefaultSettings$(this.organization.Id),
            this.discoveryAgentService.getDeepFileScanSettings$(this.organization.Id),
        ])
            .pipe(
                switchMap(([defaultSettings, deepScanSetting]) => {
                    const request: DiscoveryAgentsRunnowRequestModel = {
                        OrganizationId: this.organization.Id,
                        Filter: this.getDiscoveryAgentFilterDto(),
                        DeepScanSettings: deepScanSetting,
                        Is3ppScan: defaultSettings.Is3ppScan,
                    };
                    return this.discoveryAgentService.agentsRunNowOrgBulk$(request);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success('Sent', 'Scan successfully queued');
                    this.isRunningNow = false;
                    this.confirmDeepRunNowModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'Failed to schedule discovery agents to run now.'
                    );
                    this.isRunningNow = false;
                },
            });
    }

    cancelScans() {
        this.isCancelingNow = true;
        this.notificationService.toast.info('Info', 'Canceling scan');

        this.discoveryAgentService
            .agentsCancelOrgBulk$(this.organization.Id, this.getDiscoveryAgentFilterDto())
            .subscribe({
                next: () => {
                    this.notificationService.toast.success('Sent', 'Scan successfully canceled');
                    this.isCancelingNow = false;
                    this.confirmCancelScansModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'Failed to cancel discovery agents'
                    );
                    this.isCancelingNow = false;
                },
            });
    }

    updateBulkLabel() {
        this.isUpdatingBulkLabel = true;
        this.discoveryAgentService
            .agentsSetLabelOrgBulk(
                this.organization.Id,
                this.bulkLabelControl.value,
                this.getDiscoveryAgentFilterDto()
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the label update has been sent to the data collectors.'
                    );
                    this.isUpdatingBulkLabel = false;
                    this.editDiscoveryAgentLabelBulkModal.hide();
                    this.discoveryAgentTableService.reloadData();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the label update request to the data collector.'
                    );
                    this.isUpdatingBulkLabel = false;
                },
            });
    }

    updateBulkComment() {
        this.isUpdatingBulkComment = true;
        this.discoveryAgentService
            .agentsSetDescriptionOrgBulk(
                this.organization.Id,
                this.bulkCommentControl.value,
                this.getDiscoveryAgentFilterDto()
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the comment update has been sent to the data collectors.'
                    );
                    this.isUpdatingBulkComment = false;
                    this.editDiscoveryAgentCommentBulkModal.hide();
                    this.discoveryAgentTableService.reloadData();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the comment update request to the data collectors.'
                    );
                    this.isUpdatingBulkComment = false;
                },
            });
    }

    updateAgents() {
        this.isUpdatingNow = true;
        this.discoveryAgentService
            .agentsUpdateOrgBulk$(this.organization.Id, this.getDiscoveryAgentFilterDto())
            .subscribe({
                next: () => {
                    this.notificationService.toast.success(
                        'Sent',
                        'A request was sent to update the data collectors. The data collector will update itself after 15 minutes and should be available for use after the update is complete.'
                    );
                    this.isUpdatingNow = false;
                    this.confirmUpdateNowModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the update request to the data collectors.'
                    );
                    this.isUpdatingNow = false;
                },
            });
    }

    enableAutoUpdate() {
        this.isEnablingAutoUpdate = true;
        this.discoveryAgentService
            .agentsSetAutoUpdateOrgBulk(
                this.organization.Id,
                true,
                this.getDiscoveryAgentFilterDto()
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success(
                        'Sent',
                        'A request was sent to enable auto-update. The data collector will enable auto-update after 15 minutes and should be available for use after the update is complete.'
                    );
                    this.isEnablingAutoUpdate = false;
                    this.confirmEnableAutoUpdateModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the enable auto-update request to the data collectors.'
                    );
                    this.isEnablingAutoUpdate = false;
                },
            });
    }

    disableAutoUpdate() {
        this.isDisablingAutoUpdate = true;
        this.discoveryAgentService
            .agentsSetAutoUpdateOrgBulk(
                this.organization.Id,
                false,
                this.getDiscoveryAgentFilterDto()
            )
            .subscribe({
                next: () => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the auto-update setting has been sent to the data collectors.'
                    );
                    this.isDisablingAutoUpdate = false;
                    this.confirmDisableAutoUpdateModal.hide();
                },
                error: () => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the auto-update request to the data collectors.'
                    );
                    this.isDisablingAutoUpdate = false;
                },
            });
    }

    private clearSelection() {
        this.selectedCount = 0;
        this.selectedFilters = null;
        this.globalSelectedDiscoveryAgentIds.clear();
        this.globalUnselectedDiscoveryAgentIds.clear();
        for (let row of this.checkedDiscoveryAgentsTableRows) {
            row.checked = false;
        }
        this.createBulkActions();
    }

    private selectAllInFilter() {
        this.globalSelectedDiscoveryAgentIds.clear();
        this.globalUnselectedDiscoveryAgentIds.clear();
        this.selectedCount = Math.min(this.filteredCount, this.totalCount);
        for (let row of this.checkedDiscoveryAgentsTableRows) {
            row.checked = true;
        }
        this.discoveryAgentFilters$
            .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe((filters) => {
                this.selectedFilters = filters;
            });
        this.createBulkActions();
    }

    private selectAll() {
        this.globalSelectedDiscoveryAgentIds.clear();
        this.globalUnselectedDiscoveryAgentIds.clear();
        this.selectedFilters = getEmptyDiscoveryAgentFilters();
        for (let row of this.checkedDiscoveryAgentsTableRows) {
            row.checked = true;
        }
        this.selectedCount = this.totalCount;
        this.createBulkActions();
    }

    private isAgentSelected(agent: DiscoveryAgentTableRow) {
        const isAgentSelectedSpecifically =
            this.globalSelectedDiscoveryAgentIds.has(agent.Id.trim()) &&
            !this.globalUnselectedDiscoveryAgentIds.has(agent.Id.trim());

        if (!this.selectedFilters) {
            return isAgentSelectedSpecifically;
        }

        const statusMatch =
            !this.selectedFilters.applianceStatus.size ||
            this.selectedFilters.applianceStatus.has(
                agent.Status as 'online' | 'offline' | 'update available' | 'offline > 90 days'
            );
        const osMatch = !this.selectedFilters.os.size || this.selectedFilters.os.has(agent.OS);
        const labelMatch =
            !this.selectedFilters.label.size || this.selectedFilters.label.has(agent.Label);
        const lowerText = this.selectedFilters.text.toLowerCase();
        const textMatch =
            !this.selectedFilters.text?.length ||
            agent.Name?.toLowerCase()?.includes(lowerText) ||
            agent.OS?.toLowerCase()?.includes(lowerText) ||
            agent.Label?.toLowerCase()?.includes(lowerText) ||
            agent.Comment?.toLowerCase()?.includes(lowerText);

        return isAgentSelectedSpecifically || (statusMatch && osMatch && labelMatch && textMatch);
    }

    private getDiscoveryAgentsTableRows(): void {
        this.loading$ = this.filteredPagedItems$.pipe(map((pagedInfo) => pagedInfo.loading));
        this.filteredPagedItems$
            .pipe(
                filterIsNotUndefined(),
                map((pagedInfo) => {
                    const tableRow = pagedInfo.result?.data;
                    return tableRow?.map((row) => ({
                        ...row,
                        checked: this.isAgentSelected(row),
                    }));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((rows) => (this.checkedDiscoveryAgentsTableRows = rows));
        this.filteredPagedItems$
            .pipe(
                map((pagedInfo) => pagedInfo.result?.total_record_count),
                filterIsNotUndefined(),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                if (!this.totalCount) {
                    this.totalCount = count;
                    this.hasDiscoveryAgents.emit(this.totalCount > 0);
                }
                this.filteredCount = count;
                this.createBulkActions();
            });
    }

    private createBulkActions() {
        combineLatest([
            this.discoveryAgentSettingsService.deepFileScanSettings$,
            this.discoveryAgentFilters$.pipe(take(1)),
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([deepFileScanSettings, currentFilters]) => {
                this.bulkActions = [
                    {
                        label: this.selectedCount + ' Selected',
                        items: [
                            {
                                label: 'Select all ' + this.filteredCount + ' items',
                                command: () => this.selectAllInFilter(),
                                disabled:
                                    this.selectedCount == 0 &&
                                    isEqual(this.selectedFilters, currentFilters) &&
                                    this.globalUnselectedDiscoveryAgentIds.size == 0,
                            },
                            {
                                label: 'Clear Selection',
                                command: () => this.clearSelection(),
                                disabled:
                                    this.selectedCount == 0 &&
                                    !(
                                        this.selectedFilters ||
                                        this.globalSelectedDiscoveryAgentIds.size > 0
                                    ),
                            },
                        ],
                    },
                    {
                        label: 'Actions',
                        items: [
                            {
                                label: 'Run Scan Now',
                                disabled: this.selectedCount == 0 || this.isRunningNow,
                                command: () => {
                                    this.confirmRunNowModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                            {
                                label: 'Run Deep File Scan Now',
                                disabled:
                                    this.selectedCount == 0 ||
                                    this.isRunningNow ||
                                    !deepFileScanSettings?.length,
                                command: () => {
                                    this.confirmDeepRunNowModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                            {
                                label: 'Cancel Scans',
                                disabled: this.selectedCount == 0 || this.isCancelingNow,
                                command: () => {
                                    this.confirmCancelScansModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                        ],
                    },
                    {
                        separator: true,
                        items: [
                            {
                                label: 'Update Label',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.bulkLabelControl.setValue('');
                                    this.editDiscoveryAgentLabelBulkModal.show({ closable: false });
                                },
                            },
                            {
                                label: 'Update Comment',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.bulkCommentControl.setValue('');
                                    this.editDiscoveryAgentCommentBulkModal.show({
                                        closable: false,
                                    });
                                },
                            },
                        ],
                    },
                    {
                        separator: true,
                        items: [
                            {
                                label: 'Update Agent',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.confirmUpdateNowModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                            {
                                label: 'Enable Auto Update',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.confirmEnableAutoUpdateModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                            {
                                label: 'Disable Auto Update',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.confirmDisableAutoUpdateModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                label: 'Delete',
                                styleClass: 'danger',
                                disabled: this.selectedCount == 0,
                                command: () => {
                                    this.numAgentsToDelete = this.selectedCount;
                                    this.confirmDeleteAgentModal.show({ closable: false });
                                    this.getFirst10SelectedDiscoveryAgents();
                                },
                            },
                        ],
                    },
                ];
            });
    }

    private getDiscoveryAgentFilterDto(): DiscoveryAgentFilterDto {
        return this.selectedFilters
            ? {
                  ApplianceStatus: Array.from(this.selectedFilters.applianceStatus),
                  ComplianceMonitorStatus: Array.from(this.selectedFilters.cmoStatus),
                  OS: Array.from(this.selectedFilters.os),
                  Label: Array.from(this.selectedFilters.label),
                  Text: this.selectedFilters.text,
                  IncludeIds: Array.from(this.globalSelectedDiscoveryAgentIds),
                  ExcludeIds: Array.from(this.globalUnselectedDiscoveryAgentIds),
              }
            : {
                  ApplianceStatus: null,
                  ComplianceMonitorStatus: null,
                  OS: null,
                  Label: null,
                  Text: null,
                  IncludeIds: Array.from(this.globalSelectedDiscoveryAgentIds),
                  ExcludeIds: Array.from(this.globalUnselectedDiscoveryAgentIds),
              };
    }

    private getFirst10SelectedDiscoveryAgents(): void {
        this.isLoadingModal = true;
        const req: DiscoveryAgentFilterDto = this.getDiscoveryAgentFilterDto();
        const paginationFilter: PaginationFilters = {
            id: this.organization.Id,
            page: 0,
            limit: 10,
            orderby: 'Name',
            sortby: 'asc',
        };
        this.discoveryAgentService
            .getDiscoveryAgents$(req, paginationFilter)
            .pipe(
                map((pagedResult) => pagedResult.data),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (results) => {
                    this.first10SelectedAgents = results;
                    this.isLoadingModal = false;
                },
                error: () => {
                    this.isLoadingModal = false;
                },
            });
    }
}

interface CheckedDiscoveryAgentTableRow extends DiscoveryAgentTableRow {
    checked: boolean;
}
