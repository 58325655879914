<header class="pageHeader">
    <h1>{{ title }}</h1>
    <help-button
        title="User Report Sets"
        text="Define custom Report Sets to quickly generate reports for your exact assessment purpose."
        url="/networkdetective/help/networkdetective/user-report-sets.htm"></help-button>
    <div class="flex-right" *ngIf="loadingComplete">
        <itc-button type="primary" (click)="editReportSetModal()" icon="fa-plus">
            New Report Set
        </itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="generateSearchHeader searchHeader">
        <div style="flex: 1">
            <div>
                <sm-input
                    style="width: 100%"
                    name="reportSetsFilterText"
                    debounce="250"
                    class="reportsFilterInput"
                    [control]="reportSetsFilterField"
                    placeholder="Search keywords..."
                    (modelChange)="filterReportSets()"
                    style="flex: 2"></sm-input>
            </div>
        </div>
        <div>
            <itc-checkbox
                class="unavailableCheckbox"
                [(ngModel)]="hideSystem"
                (onChanged)="filterReportSets()">
                Hide System Report Sets
            </itc-checkbox>
        </div>
        <div>
            <b>{{ filteredReportSets?.length }} of {{ reportSets?.length }}</b>
            Results
        </div>
    </div>
    <div style="overflow-y: auto; max-height: 600px">
        <table
            class="itc-table sortable"
            sortable-table
            sortColumn="Name"
            sortDirection="asc"
            (sorted)="sortReportSets($event)">
            <thead>
                <tr>
                    <th style="width: 30px"></th>
                    <th sortable-column="Name">Name</th>
                    <th sortable-column="Reports">Included Reports</th>
                    <th sortable-column="LastModified">Last Modified</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let set of sortedReportSets">
                    <td>
                        <help-button
                            *ngIf="set.AccountId == 'SYSTEM' || set.SiteId != siteId"
                            text="{{
                                set.AccountId == 'SYSTEM' ? 'System' : 'Global'
                            }} Report Set (Read-Only)"
                            icon="lock-keyhole"
                            [noLink]="true"></help-button>
                    </td>
                    <td style="white-space: nowrap">
                        <span
                            tooltip="{{ set.Name }}"
                            ttPosition="bottom-left"
                            [ttHidden]="set.Name.length < 50">
                            {{ set.Name | truncate : 50 }}
                        </span>
                    </td>
                    <td style="white-space: nowrap">
                        <span
                            tooltip="{{
                                set.ReportsFull | slice : 0 : 3 | listReduce : 'Name' : ', '
                            }}"
                            ttPosition="bottom-left"
                            [ttHidden]="
                                set.ReportsFull[0]?.Name.length +
                                    set.ReportsFull[1]?.Name.length +
                                    set.ReportsFull[2]?.Name.length +
                                    (set.ReportsFull.length > 2
                                        ? 4
                                        : set.ReportsFull.length > 1
                                        ? 2
                                        : 0) <
                                80
                            ">
                            {{
                                set.ReportsFull
                                    | slice : 0 : 3
                                    | listReduce : 'Name' : ', '
                                    | truncate : 80
                            }}
                        </span>
                        <span
                            *ngIf="set.ReportsFull?.length > 3"
                            tooltip="{{ set.ReportsFull | listReduce : 'Name' : '\n' }}"
                            ttPosition="bottom"
                            class="overflowLabel">
                            +{{ set.ReportsFull?.length - 3 }}
                        </span>
                    </td>
                    <td>
                        {{ set.AccountId != 'SYSTEM' ? (set.LastModified | accountDateTime) : '' }}
                    </td>
                    <td style="min-width: 120px" align="right">
                        <itc-button
                            style="display: inline"
                            *ngIf="set.AccountId != 'SYSTEM' && set.SiteId == siteId"
                            tooltip="Edit"
                            type="icon"
                            icon="fa-pencil"
                            (onclick)="editReportSetModal(set)"></itc-button>
                        <itc-button
                            style="display: inline"
                            *ngIf="set.AccountId != 'SYSTEM' && set.SiteId == siteId"
                            tooltip="Delete"
                            type="icon"
                            icon="fa-trash-can"
                            (onclick)="showDeleteModal(set)"></itc-button>
                        <itc-button
                            style="display: inline"
                            tooltip="Clone"
                            type="icon"
                            icon="fa-clone"
                            (onclick)="cloneReportSet(set)"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <sm-modal
        title="{{ selectedSet?.Id == -1 ? 'Create' : 'Update' }} User Report Set"
        class="lg"
        #editorModal>
        <modal-content>
            <form class="ui form" name="editorForm">
                <div class="required field">
                    <label>Report Set Name:</label>
                    <input
                        type="text"
                        id="ReportSetName"
                        name="Report Name"
                        [formControl]="reportSetNameCtrl"
                        placeholder="Report Set Name"
                        style="margin-bottom: 0" />
                    <label class="error" data-rule="unique" style="padding-top: 10px">
                        This Report Set Name is already in use.
                    </label>
                </div>
            </form>

            <div class="generateSearchHeader">
                <div style="width: 60%">
                    <label>Search</label>
                    <div>
                        <sm-input
                            style="width: 100%"
                            name="reportsFilterText"
                            debounce="250"
                            class="reportsFilterInput"
                            [control]="reportsFilterField"
                            placeholder="Search keywords..."
                            (modelChange)="filterReports()"
                            style="flex: 2"></sm-input>
                    </div>
                </div>
                <div class="filterDropdown" >
                    <label for="categoryDropdown">Category</label>
                    <rft-multiselect [(ngModel)]="selectedCategories"
                                     [options]="categories"
                                     optionLabel="Name"
                                     placeholder=""
                                     maxSelectedItemsShown=0
                                     (change)="categoryChecked()"
                                     [msStyle]="{'min-width':'14em'}"
                                     ></rft-multiselect>

                </div>
                <div>
                    <label style="visibility: hidden">Clear Button</label>
                    <itc-button type="secondary" (click)="resetFilters()">Clear</itc-button>
                </div>
                <div>
                    <label style="visibility: hidden">Results</label>
                    <p style="white-space: nowrap; margin-bottom: 0px">
                        <b>{{ filteredReports?.length }} of {{ reports?.length }}</b>
                        Results
                    </p>
                </div>
            </div>

            <div style="overflow-y: auto; max-height: 400px; margin-bottom: -24px">
                <table
                    class="itc-table sortable"
                    sortable-table
                    sortColumn="Name"
                    sortDirection="asc"
                    (sorted)="sortReports($event)">
                    <thead>
                        <tr>
                            <th>
                                <itc-checkbox
                                    [(ngModel)]="reportsAllChecked"
                                    (onChanged)="reportsCheckAll($event)"></itc-checkbox>
                            </th>
                            <th sortable-column="Name">Report Name</th>
                            <th sortable-column="Category">Category</th>
                            <th sortable-column="Type">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let report of sortedReports"
                            [class.selected]="report.IsSelected"
                            (click)="reportChecked(report)">
                            <td>
                                <itc-checkbox [(ngModel)]="report.IsSelected"></itc-checkbox>
                            </td>
                            <td style="white-space: nowrap">
                                {{ report.Name + ' (' + report.FileExtension + ')' }}
                            </td>
                            <td style="white-space: nowrap">{{ report.ModuleName }}</td>
                            <td style="text-overflow: ellipsis">{{ report.ReportType }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </modal-content>
        <modal-actions>
            <div style="float: left">
                <b>{{ selectedCount }}</b>
                Selected
            </div>
            <itc-button
                type="secondary"
                class="close"
                (onclick)="editorModal.hide()"
                [disabled]="saving">
                Cancel
            </itc-button>
            <itc-button
                type="primary"
                (onclick)="saveReportSet()"
                [loading]="saving"
                [disabled]="selectedCount == 0">
                {{ selectedSet?.Id == -1 ? 'Create' : 'Update' }}
            </itc-button>
        </modal-actions>
    </sm-modal>

    <sm-modal title="Delete User Report Set" class="xs" #deleteModal>
        <modal-content>
            <p>
                You are about to delete the user report set
                <b>{{ selectedSet?.Name }}.</b>
                Are you sure?
            </p>
        </modal-content>
        <modal-actions>
            <itc-button
                type="secondary"
                class="close"
                (onclick)="deleteModal.hide()"
                [disabled]="saving">
                Cancel
            </itc-button>
            <itc-button type="danger" (onclick)="deleteReportSet()" [loading]="saving">
                Delete
            </itc-button>
        </modal-actions>
    </sm-modal>
</sds-loader>
