import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    SimpleChanges,
} from '@angular/core';
import { ItcTabsService } from './tabs.service';
import * as wjcCore from '@grapecity/wijmo';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, createUrlTreeFromSnapshot } from '@angular/router';
import { CommonModule, LocationStrategy } from '@angular/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
    selector: 'itc-tabs',
    template: `
        <div class="itc tabs" [class.container]="containerStyle" [class.bordered]="bordered">
            <div class="tabs_header" [class.heightDetails]="true">
                <div
                    *ngFor="let tab of tabs"
                    style="display: flex; align-items: center;  "
                    class="tab"
                    [class.tab-active]="tab?.active"
                    [class.disabled]="tab?.disabled"
                    [hidden]="tab?.hidden"
                    [style.font-weight]="tabs[0]?.tabDetails ? 500 : ''"
                    (click)="tabClicked(tab)"
                    [tooltip]="tab.tooltip ? tab.tooltip : null">
                    <span class="truncatedText" [attr.title]="!tab.tooltip ? tab?.tabTitle : null">
                        {{ tab?.tabTitle }}
                    </span>
                    <span
                        *ngIf="tab.tabDetails"
                        [class.detailsNumberActive]="tab?.active"
                        [class.detailsNumberDisabled]="!tab?.active">
                        {{ tab.tabDetails }}
                    </span>
                </div>
                <div class="tabs_spacer" *ngIf="containerStyle === false"></div>
            </div>
            <div class="tabs_content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [
        `
            .heightDetails {
                height: 32px !important;
            }
            .detailsNumber {
                margin-left: 6px;
                border-radius: 50%;
                width: auto;
                padding: 0 8px;
                height: 20px;
                background-color: #f6f7f8;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .detailsNumberDisabled {
                @extend .detailsNumber;
                background-color: #f6f7f8;
            }

            .detailsNumberActive {
                @extend .detailsNumber;
                background-color: #fff4e5;
            }
        `,
    ],
})
export class ItcTabsComponent implements OnInit {
    @Input() containerStyle = true; // tab style is container if true, standalone if false
    @Input() bordered = false; // whether to show border around content or not.
    @Input() defaultTab: string;
    @Input() hideTabs = false;
    @Output() tabChanged = new EventEmitter();
    @Output() routeUpdated = new EventEmitter();
    @Output() tabsInitialized = new EventEmitter<boolean>();
    tabs: ItcTabComponent[] = [];
    ngUnsubscribe$: Subject<any> = new Subject();

    constructor(
        //private route: ActivatedRoute,
        private tabService: ItcTabsService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private ls: LocationStrategy
    ) {}

    ngOnInit() {
        // tab service handles changes to select tabs, disable, etc
        this.tabService.tabAsObservable
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
            .subscribe((val) => {
                this.processCall(val);
            });
        /* disabled for now, need more work but want to get the tabs out at least.  */
        // this.route.fragment.subscribe((fragment:string) => {
        //     setTimeout(() => {
        //         let activateTab = this.tabs.find(t => t.tabTitle.toLowerCase() == fragment.toLowerCase());
        //         if (activateTab) { this.selectTab(activateTab); }
        //     },100);
        // })
    }

    ngAfterContentInit() {
        // waiting for here to find active tab because we don't have the disabled status in addTab()
        // settimeout to fix ExpressionChangedAfterItHasBeenCheckedError error
        setTimeout(() => {
            let tempTabsArray = [];
            // if tabindex, push to that spot in case they come in out of order
            this.tabs.forEach((t) => {
                if (t.tabIndex) {
                    tempTabsArray[t.tabIndex - 1] = t;
                } else {
                    tempTabsArray.push(t);
                }
            });
            // find first non-disabled tab and set it to active
            tempTabsArray.forEach((t) => {
                let activeTab = tempTabsArray.findIndex((tt) => tt && tt.active);
                if (activeTab >= 0) {
                    return;
                }
                if (!t.disabled) {
                    t.active = true;
                }
                if (this.defaultTab) {
                    t.active = this.defaultTab && t.tabTitle === this.defaultTab;
                }
            });
            // remove empty tab items (that might not be shown with ngif)
            this.tabs = tempTabsArray.filter((t) => t);
            this.cdr.markForCheck();
        }, 2);
    }

    ngAfterViewInit() {
        this.tabsInitialized.emit(true);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    selectTab(tab: ItcTabComponent) {
        if (tab) {
            if (tab.active || tab.disabled) {
                return;
            }

            setTimeout(() => {
                this.tabs.forEach((tab) => {
                    tab.active = false;
                });
                tab.active = true;
                let tabIndex = this.tabs.indexOf(tab);
                this.tabChanged.emit({
                    tabTitle: tab.tabTitle,
                    tabIndex: tabIndex,
                    tabNumber: tabIndex + 1,
                });
                // force wijmo to regenerate components so they display properly after being hidden
                setTimeout(() => {
                    wjcCore.Control.invalidateAll();
                }, 2);
                this.cdr.markForCheck();
            }, 1);
        }
    }

    tabClicked(tab) {
        if (tab.active || tab.disabled) {
            return;
        }
        if (tab.routeLink) {
            let currentRoute = createUrlTreeFromSnapshot(this.route.snapshot, []).toString();
            currentRoute =
                currentRoute.slice(0, currentRoute.lastIndexOf(tab.routeLink[0])) +
                tab.routeLink.join('/');
            this.routeUpdated.emit(tab.routeLink);
            this.ls.pushState('', '', currentRoute, '');
        }
        this.selectTab(tab);
    }

    addTab(tab: ItcTabComponent) {
        // can't set active tab here because we don't have the disabled status yet
        this.tabs.push(tab);
        setTimeout(() => {
            // sort tabs by tabindex and select the first one, this is mainly for when we have ngifs on tabs and tehn come in late
            this.tabs.sort((a, b) => a.tabIndex - b.tabIndex);
            // this.selectTab(this.tabs[0]);
        }, 0);
    }

    removeTab(tab: ItcTabComponent) {
        if (tab.tabTitle && this.tabs.length) {
            let tabIndex = this.tabs.findIndex((t) => t.tabTitle === tab.tabTitle);
            if (tabIndex > -1) {
                this.tabs.splice(tabIndex, 1);
            }
            this.cdr.markForCheck();
        }
    }

    processCall(val) {
        switch (val.action) {
            case 'set':
                let tabIndex =
                    typeof val.tabIndex === 'number'
                        ? val.tabIndex
                        : this.tabs.findIndex((t) => t.tabTitle === val.tabIndex);
                this.cdr.markForCheck();
                this.selectTab(this.tabs[tabIndex]);
                break;
            case 'disable':
                this.tabs[val.tabIndex].disabled = true;
                break;
            case 'enable':
                this.tabs[val.tabIndex].disabled = false;
                break;
            case 'hide':
                this.tabs[val.tabIndex].hidden = true;
        }
    }

    updateTabs() {
        this.cdr.markForCheck();
    }
}

@Component({
    selector: 'itc-tab',
    standalone: true,
    imports: [ItcTabsComponent],
    template: `
        <div [hidden]="!active">
            <ng-content></ng-content>
        </div>
    `,
})
export class ItcTabComponent implements OnDestroy {
    @Input() tabTitle: string;
    @Input() tabDetails: string;
    @Input() set tabIndex(i: number) {
        if (typeof i !== 'number') {
            i = parseInt(i, 10);
        }
        this._tabIndex = i;
    }
    get tabIndex() {
        return this._tabIndex;
    }
    @Input() disabled = false;
    // @Input() routerLink: any; // not using yet, but leaving in for when I add it.
    @Input() hidden = false;
    @Input() tooltip = '';
    @Input() routeLink?: string[];
    active = false;
    tabsRef: ItcTabsComponent;
    _tabIndex: number;

    constructor(tabs: ItcTabsComponent) {
        this.tabsRef = tabs;
        tabs.addTab(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tabTitle || changes.tabDetails || changes.disabled) {
            this.tabsRef.updateTabs();
        }
    }

    ngOnDestroy() {
        this.tabsRef.removeTab(this);
    }
}
