<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Custom Scan Profiles</h1>
    <help-button
        title="Custom Scan Profile"
        text="Create scan profiles to target specific TCP and/or UDP ports. In this way, you can perform low impact scans that address only those ports with which you are concerned."
        url="/vs/help/vulscan/custom-scan-profiles.htm"></help-button>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="scan-profile">
        <div class="scan-profile-header-container">
            <div class="item searchInput">
                <itc-input
                    placeholder="Quick Filter..."
                    [control]="searchKey"
                    (modelChange)="filterProfiles()"
                    debounce="420"
                    [working]="filteringProfiles"
                    class="quickFilter"></itc-input>
            </div>
            <itc-button
                type="primary"
                icon="fa-plus"
                (onclick)="showAddProfileModal()"
                *ngIf="!readonly">
                Create New Profile
            </itc-button>
        </div>

        <div class="overflowTable">
            <table
                sortable-table
                class="itc-table sortable-table-titles-scroll"
                sortColumn="Name"
                sortDirection="asc"
                (sorted)="onProfilesSorted($event)"
                style="table-layout: fixed">
                <thead>
                <tr>
                    <th sortable-column="Name" style="width: 20%">Name</th>
                    <th sortable-column="Comment" style="width: 20%">Comment</th>
                    <th unsortable-column="Ports" style="width: 54%">Ports</th>
                    <th *ngIf="!readonly"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let profile of filteredProfiles">
                    <td truncateText>{{ profile.Name }}</td>
                    <td truncateText>{{ profile.Comment }}</td>
                    <td truncateText>{{ profile.Ports.substring(0, 255) }}</td>
                    <td class="collapsing" *ngIf="!readonly">
                        <itc-button
                            type="icon"
                            icon="fa-pencil"
                            (onclick)="showProfileEditModal(profile)"
                            data-content="Edit"
                            data-position="top center"
                            title="Edit"></itc-button>
                        <itc-button
                            type="icon"
                            icon="fa-trash-can"
                            (onclick)="confirmProfileDelete(profile)"
                            data-content="Delete"
                            data-position="top center"
                            [title]="
                                profile.isRunning
                                    ? 'You cannot delete a task while it\'s running.'
                                    : 'Delete'
                            "
                            [loading]="profile == profileToDelete && attemptingDelete"></itc-button>
                    </td>
                </tr>
                <tr *ngIf="!profiles || profiles.length == 0">
                    <td colspan="4">No profiles</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</sds-loader>

<!-- Add Profile Modal -->
<kvs-custom-profile-wizard-modal
    [site]="site"
    (onSave)="profileSaved($event)"
    #addProfileModal></kvs-custom-profile-wizard-modal>

<!-- Delete Modal with tasks -->
<sm-modal title="Confirm Delete" class="sm" #confirmDeleteProfileModal>
    <modal-content>
        <div *ngIf="associatedScanTasks?.length > 0">
            The Custom Scan Profile
            <b>{{ profileToDelete?.Name }}</b>
            is currently being used in
            <b>{{ associatedScanTasks?.length }}</b>
            Scan Tasks:
            <li
                style="margin-left: 10px; margin-top: 5px"
                *ngFor="let scanTask of associatedScanTasks">
                {{ scanTask.Label }}
            </li>
            <div style="margin-top: 25px">
                Reassign the Scan Profile in the existing Scan Tasks to:
                <p-dropdown
                    [formControl]="reassignedProfileControl"
                    [options]="existingProfileDropdownItems">
                </p-dropdown>
            </div>
            <div style="margin-top: 25px">
                Delete the Custom Scan Profile
                <b>{{ profileToDelete?.Name }}</b>
                ?
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            type="secondary"
            (onclick)="confirmDeleteProfileModal.hide(); deleting = false"></itc-button>
        <itc-button
            label="Delete"
            type="danger"
            (onclick)="deleteProfile()"
            [loading]="deleting"></itc-button>
    </modal-actions>
</sm-modal>

<!-- Delete Modal without task -->
<sm-modal title="Confirm Delete" class="sm" #confirmCancelModal>
    <modal-content>
        <div class="content">
            <div class="header">
                <div>
                    Are you sure you want to delete this Custom Scan Profile
                    <b>{{ profileToDelete?.Name }}</b>
                    ?
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            type="secondary"
            (onclick)="confirmCancelModal.hide()"></itc-button>
        <itc-button
            label="Delete"
            type="danger"
            (onclick)="confirmDeleteModal()"
            [loading]="deleting"></itc-button>
    </modal-actions>
</sm-modal>
