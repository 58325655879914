<table class="itc-table sortable" sortable-table style="margin-bottom: 0" #AuditLogTable>
    <thead>
        <tr>
            <!-- class.sorted is separate because having it in the ngclass object it wouldn't add the class sorted for some reason -->
            <th
                sortable-column="ModifiedDate"
                [ngClass]="{
                    ascending: sortMethod == 'ModifiedDate',
                    descending: sortMethod == '-ModifiedDate'
                }"
                [class.sorted]="sortMethod.replace('-', '') == 'ModifiedDate'"
                (click)="toggleSort('-ModifiedDate')">
                Date
            </th>

            <th
                sortable-column="SiteName"
                *ngIf="!forSite"
                [ngClass]="{
                    ascending: sortMethod == 'SiteName',
                    descending: sortMethod == '-SiteName'
                }"
                [class.sorted]="sortMethod.replace('-', '') == 'SiteName'"
                (click)="toggleSort('-SiteName')">
                Site
            </th>

            <th
                sortable-column="ModifiedBy"
                [ngClass]="{
                    ascending: sortMethod == 'ModifiedBy',
                    descending: sortMethod == '-ModifiedBy'
                }"
                [class.sorted]="sortMethod.replace('-', '') == 'ModifiedBy'"
                (click)="toggleSort('-ModifiedBy')">
                User
            </th>

            <th
                sortable-column="Message"
                [ngClass]="{
                    ascending: sortMethod == 'Message',
                    descending: sortMethod == '-Message'
                }"
                [class.sorted]="sortMethod.replace('-', '') == 'Message'"
                (click)="toggleSort('-Message')">
                Message
            </th>

            <th
                sortable-column="AlertType"
                [ngClass]="{
                    ascending: sortMethod == 'AlertType',
                    descending: sortMethod == '-AlertType'
                }"
                [class.sorted]="sortMethod.replace('-', '') == 'AlertType'"
                (click)="toggleSort('-AlertType')">
                Detail
            </th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let audit of audits"
            (click)="toggleTruncate($event)"
            [class.isAdmin]="audit.ModifiedBy == 'ADMIN'">
            <td class="collapsing">{{ audit?.ModifiedDate | accountDateTime }}</td>
            <td *ngIf="!forSite" class="collapsing">{{ audit.SiteName }}</td>
            <td class="collapsing">{{ audit.ModifiedBy }}</td>
            <td class="collapsing">
                <b>{{ audit.Message }}</b>
            </td>
            <td>
                <b
                    style="color: black"
                    *ngIf="
                        (audit.Message === 'Scan Completed' ||
                            audit.Message === 'Scan Started' ||
                            audit.Message === 'Scan Failed') &&
                            audit.ApplianceId &&
                            audit.ApplianceId != 'HEVS';
                        else elseBlock
                    ">
                    {{ audit.ApplianceId }}-{{ audit.AlertType }}
                </b>
                <ng-template #elseBlock>
                    <b style="color: black">{{ audit.AlertType }}</b>
                </ng-template>
                <br />
                <span style="font-style: italic; color: #555" class="alertText truncated">
                    {{ audit.AlertObject }}
                </span>
            </td>
            <!--  <td><a class="ui basic icon button" routerLink="/AlertHistory/{{audit.SiteId}}/{{audit.AlertGuid}}" data-content="Details" data-position="center">Details</a></td> -->
        </tr>
    </tbody>
</table>
