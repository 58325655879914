import { Component, inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { DiscoveryAgentTableRow } from '../models/discovery-agents-table-row.model';
import { ItcSidebarComponent } from '../../itc/itc-sidebar/itc-sidebar.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { DiscoveryAgentService } from '../../discovery-agent-legacy/discovery-agent.service';
import { NotificationService } from '../../itc/notification/notification.service';
import { SiteSettingService } from '../../../sites/site/settings/site-settings.service';
import { DiscoveryAgentTableService } from '../services/discovery-agent-table.service';
import { UtilityService } from '../../utility.service';

@Component({
    selector: 'sds-discovery-agent-edit-sidebar',
    standalone: true,
    imports: [CommonModule, SharedModule, ItcSidebarComponent, ReactiveFormsModule],
    templateUrl: './discovery-agent-edit-sidebar.component.html',
    styleUrls: ['./discovery-agent-edit-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiscoveryAgentEditSidebarComponent {
    @Input({ required: true }) organizationId: number;
    discoveryAgent: DiscoveryAgentTableRow = {} as DiscoveryAgentTableRow;
    loading = false;
    isOnline = false;
    isUpdating = false;
    isDownloadingAudit = false;
    isDownloadingLogs = false;
    isSubmitting = false;
    @ViewChild('sidebar') sidebar: ItcSidebarComponent;
    private formBuilder = inject(FormBuilder);
    formGroup = this.formBuilder.group({
        isCmoEnabled: [false],
        label: [''],
        comment: [''],
        isAutoUpdateEnabled: [false],
    });
    private originalLabel = '';
    private discoveryAgentService = inject(DiscoveryAgentService);
    private siteSettingsService = inject(SiteSettingService);
    private notificationService = inject(NotificationService);
    private discoveryAgentTableService = inject(DiscoveryAgentTableService);
    private utilityService = inject(UtilityService);

    async show(agent: DiscoveryAgentTableRow) {
        this.loading = true;
        this.isOnline = false;
        this.isUpdating = false;
        this.isDownloadingAudit = false;
        this.isDownloadingLogs = false;
        this.isSubmitting = false;

        this.discoveryAgent = agent;
        this.sidebar.open({ closable: false });

        this.originalLabel = agent.Label;
        this.formGroup.reset({
            isCmoEnabled: agent.IsCmPro,
            label: agent.Label,
            comment: agent.Comment,
            isAutoUpdateEnabled: await this.discoveryAgentService.getAutoUpdateForApplianceById(
                this.organizationId,
                agent.Id
            ),
        });
        this.isOnline = agent?.Status && !agent.Status.includes('offline');

        this.loading = false;
    }

    async updateAgent() {
        this.isUpdating = true;
        try {
            await this.discoveryAgentService.sendUpdateToDiscoveryAgentById(
                this.organizationId,
                this.discoveryAgent.Id
            );
            this.notificationService.toast.success(
                'Sent',
                'A request was sent to update the data collector. The' +
                    ' data collector will update itself after 15 minutes and should be available for use after the update is complete.'
            );
        } catch {
            this.notificationService.toast.error(
                'Error',
                'An error occurred while sending the update request. Please try again later.'
            );
        } finally {
            this.isUpdating = false;
        }
    }

    async downloadAudit() {
        this.isDownloadingAudit = true;
        try {
            const b64 = await this.discoveryAgentService.getAuditForApplianceById(
                this.organizationId,
                this.discoveryAgent.Id
            );
            this.utilityService.downloadFile(b64, this.discoveryAgent.Id.trim() + ' - Audit.zip');
        } catch {
            this.notificationService.toast.error(
                'Error',
                'Audit download for ' + this.discoveryAgent.Id.trim() + ' timed out.'
            );
        } finally {
            this.isDownloadingAudit = false;
        }
    }

    async downloadLogs() {
        this.isDownloadingLogs = true;
        try {
            const b64 = await this.discoveryAgentService.getLogsForApplianceById(
                this.organizationId,
                this.discoveryAgent.Id
            );
            this.utilityService.downloadFile(b64, this.discoveryAgent.Id.trim() + ' - Logs.zip');
        } catch {
            this.notificationService.toast.error(
                'Error',
                'Log download for ' + this.discoveryAgent.Id.trim() + ' timed out.'
            );
        } finally {
            this.isDownloadingLogs = false;
        }
    }

    async onSubmit() {
        this.isSubmitting = true;
        let promises = [];
        if (this.formGroup.controls.label.dirty) {
            promises.push(
                this.siteSettingsService.setApplianceLabel(
                    this.discoveryAgent.Id,
                    this.originalLabel,
                    this.formGroup.controls.label.value,
                    this.organizationId
                )
            );
        }
        if (this.formGroup.controls.isAutoUpdateEnabled.dirty) {
            promises.push(
                this.discoveryAgentService.sendAutoUpdateToApplianceById(
                    this.organizationId,
                    this.discoveryAgent.Id,
                    this.formGroup.controls.isAutoUpdateEnabled.value
                )
            );
        }
        if (this.formGroup.controls.comment.dirty) {
            promises.push(
                this.siteSettingsService.setApplianceDescription(
                    this.discoveryAgent.Id,
                    this.formGroup.controls.comment.value
                )
            );
        }
        if (this.formGroup.controls.isAutoUpdateEnabled.dirty) {
            promises.push(
                this.discoveryAgentService.sendAutoUpdateToApplianceById(
                    this.organizationId,
                    this.discoveryAgent.Id,
                    this.formGroup.controls.isAutoUpdateEnabled.value
                )
            );
        }
        try {
            await Promise.all(promises);
            this.discoveryAgentTableService.reloadData();

            if (!(promises.length === 1 && this.formGroup.controls.isAutoUpdateEnabled.dirty)) {
                this.notificationService.toast.success('Success', 'Changes saved successfully.');
            }
            if (this.formGroup.controls.isAutoUpdateEnabled.dirty) {
                const autoUpdateStatus = this.formGroup.controls.isAutoUpdateEnabled.value
                    ? 'enable'
                    : 'disable';
                this.notificationService.toast.info(
                    'Sent',
                    `A request was sent to ${autoUpdateStatus} auto-update. The data collector will ${autoUpdateStatus} auto-update after 15` +
                        ' minutes and should be available for use after the update is complete.'
                );
            }
            this.sidebar.close();
        } catch {
            this.notificationService.toast.error(
                'Error',
                'An error occurred while saving changes.'
            );
        }

        this.isSubmitting = false;
    }
}
