import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from '@angular/forms';

@Directive({
    standalone: true,
    selector: '[noDuplicates]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: NoDuplicatesValidatorDirective, multi: true },
    ],
})
export class NoDuplicatesValidatorDirective implements Validator {
    @Input() existingNames: string[] = [];

    validate(control: AbstractControl): ValidationErrors | null {
        let errors = {};
        let invalid = false;

        if (this.existingNames.includes(control.value?.toLowerCase().trim())) {
            errors['duplicate'] = true;
            invalid = true;
        }

        return invalid ? errors : null;
    }
}

export function noDuplicates(existing: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
        validationTest(control.value, existing);
}

function validationTest(val, existingNames?: string[]): ValidationErrors | null {
    return val && existingNames.some((e) => e.toLowerCase().trim() === val.toLowerCase().trim())
        ? { duplicate: true }
        : null;
}
