import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { inject } from '@angular/core';
import { SiteService } from '../../../sites';
import { Site } from 'app/sites/shared/site.model';
import { AuthService, Roles, SiteRoles } from '../auth.service';

export const SiteRedirectsGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<UrlTree> => {
    let siteService = inject(SiteService);
    let authService = inject(AuthService);
    let site: Site = siteService.getCurrentSite();
    let router = inject(Router);

    if (route.children.length > 0) return null;

    if (!site) {
        let splitURL = state.url.split('/');
        let routeSiteName = splitURL[1] == 'site' ? decodeURIComponent(splitURL[2]) : '';
        site = await siteService.getSiteByNameLimitedData(routeSiteName);
    }

    let path: string[];
    let userRoles = site.UserRoles;
    let isPrivileged = authService.userIsRoles([Roles.Master, Roles.Admin]);

    /* KVS Site */
    if (siteService.isKVS(site)) {
        // Client goes to vs dashboard
        if (!isPrivileged && userRoles.includes(SiteRoles.Client)) {
            path = ['vulnerability-scanner', 'dashboard'];
        } else {
            path = ['home'];
        }
    }

    /* Compliance Manager Legacy Site */
    if (siteService.isComplianceManager(site)) {
        // SME goes to assessments
        if (!isPrivileged && userRoles.includes(SiteRoles.SME)) {
            path = ['compliance-manager', 'assessments', 'forms'];
        } else {
            path = ['home'];
        }
    }

    /* Compliance Manager GRC Site */
    if (siteService.isComplianceManagerGRC(site)) {
        // SME goes to my work
        // GRC_REPORT_VIEWER goes to reports
        // EMP_PORTAL_ADMIN goes to employee portal setup
        // SDS_ goes to dashboard
        if (!isPrivileged && userRoles.includes(SiteRoles.SME)) {
            path = ['compliance-manager-grc', 'my-work'];
        } else if (
            !isPrivileged &&
            userRoles.includes(SiteRoles.GrcReportViewer) &&
            !userRoles.includes(SiteRoles.Auditor || SiteRoles.Tech || SiteRoles.SiteAdmin)
        ) {
            path = ['compliance-manager-grc', 'results-evidence', 'reports'];
        } else if (!isPrivileged && userRoles.includes(SiteRoles.EmployeePortalAdmin)) {
            path = ['compliance-manager-grc', 'employee-portal', 'setup'];
        } else {
            path = ['compliance-manager-grc', 'dashboard'];
        }
    }

    /* NDPro Web Site */
    if (siteService.isNDProWeb(site) || siteService.isNDPro(site)) {
        // All go to NDP dashboard
        if (!isPrivileged && userRoles.includes(SiteRoles.Client)) {
            path = ['network-detective-pro', 'settings', 'client-view'];
        } else {
            path = ['network-detective-pro', 'dashboard'];
        }
    }

    /* Cyber Hawk Site */
    if (siteService.isCyberHawk(site)) {
        // nothing special yet
        path = ['home'];
    }

    /* Compliance Monitor Site */
    // if (siteService.isCMO(site)) {
    //     // nothing special yet
    //      path = ['home'];
    // }

    // redirect user or show error page if there's no site type thus no path set.
    if (path?.length) {
        return router.createUrlTree(['/', 'site', site.Name, ...path]);
    } else {
        return router.createUrlTree(['/', 'error', '403', 'RFTE121']);
    }
};
