import { Component, Input as RouterInput, inject, ChangeDetectionStrategy } from '@angular/core';
import { ResourcesService } from './resources.service';
import { Router, RouterModule } from '@angular/router';
import * as saveAs from 'file-saver';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { ResourceCategoryInfo, ResourceProduct, ResourceProductInfo } from './resources.model';
import { CommonModule } from '@angular/common';
import { SidebarMenuItem } from 'app/shared/sidebar/menu/sidebar-menu-item.model';
import { Observable, Subject } from 'rxjs';
import { BreadcrumbTrailComponent } from 'app/shared/breadcrumbs/breadcrumb-trail.component';
import { DropdownModule } from 'primeng/dropdown';
import { Option } from 'app/shared/itc/multiselect/multiselect.component';
import { FormsModule } from '@angular/forms';
import { ResourcesCardComponent } from './resources-card/resources-card.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        BreadcrumbTrailComponent,
        DropdownModule,
        ResourcesCardComponent,
    ],
    selector: 'sds-resources',
    template: `
        <sds-breadcrumb-trail [crumbs]="breadcrumbs$ | async"></sds-breadcrumb-trail>
        <header class="pageHeader">
            <h1 *ngIf="product$ | async as product">{{ product.name }}</h1>
        </header>

        <div class="itc form" *ngIf="productKey === 'compliance-manager'">
            <div class="field">
                <label for="standard">Site Type:</label>
                <p-dropdown
                    id="standard"
                    [options]="cm1Standards$ | async"
                    [(ngModel)]="selectedStandard"
                    optionLabel="Label"
                    optionValue="Value"
                    (onChange)="updateStandard()"
                    [style]="{ width: '250px' }"></p-dropdown>
            </div>
        </div>

        <ng-container *ngIf="resources$ | async as allResources">
            <ng-container *ngIf="resourceItems$ | async as resources">
                <div class="itc tabs container">
                    <ul class="tabs_header" style="width: 100%">
                        <li *ngFor="let cat of availableCategories$ | async">
                            <a
                                [routerLink]="cat.url"
                                routerLinkActive="tab-active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="tab">
                                {{ cat.name }}
                            </a>
                        </li>
                    </ul>
                </div>
                <ng-container *ngIf="resources !== 'loading'">
                    <ng-container *ngFor="let resourceCat of resources.categories">
                        <div class="resources-category" *ngIf="resourceCat.enabled">
                            <h2>{{ resourceCat.name }}</h2>
                            <resources-card
                                *ngFor="let resource of resourceCat.items"
                                [resource]="resource"
                                (click)="
                                    customClickCommand(resource.action, resource.link)
                                "></resources-card>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            [class*='resources']:hover {
                text-decoration: none !important;
            }

            .itc.form .field label {
                color: var(--text-secondary);
            }

            .resources-category {
                h2 {
                    flex: 1 1 100%;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 26px;
                }
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
                align-items: center;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
                margin-bottom: 16px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesComponent {
    private resourceService = inject(ResourcesService);
    private notificationService = inject(NotificationService);
    private router = inject(Router);

    // this is renamed input in the import, and just to make it clear
    @RouterInput() set product(prod: string) {
        // compliance manager doesn't have a prod param because of how routing needs to work
        if (typeof prod === 'undefined') {
            prod = 'compliance-manager';
        }
        this.productKey = prod;
        this.resourceService.setProduct({ key: prod, name: prod });
    }
    @RouterInput() set standard(standard: string) {
        if (standard) {
            this.product = 'compliance-manager';
            this.selectedStandard = standard;
            this.resourceService.setStandard(standard);
        } else {
            this.resourceService.setStandard(null);
        }
    }
    @RouterInput() set category(cat: string) {
        this.resourceService.setCategory(cat);
    }

    breadcrumbs$ = this.resourceService.getBreadcrumbs();
    resources$ = this.resourceService.getResources();
    resourceItems$: Observable<ResourceProduct> = this.resourceService.getResourceItems();
    product$: Observable<ResourceProductInfo> = this.resourceService.getProduct();
    cm1Standards$: Observable<Option[]> = this.resourceService.getCM1Standards();
    availableCategories$: Observable<ResourceCategoryInfo[]> =
        this.resourceService.getAvailableCategories();
    productKey: string;
    selectedStandard = '';
    selectedCategory: string = 'all';
    isDownloadingPricelist: boolean = false;
    menuItems: SidebarMenuItem[];
    ngUnsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        let header = document.querySelector('app-nav > header');
        (header.querySelector('.menuItems') as HTMLElement).style.display = 'none';
        (header.querySelector('.rightMenuItems') as HTMLElement).style.display = 'none';
    }

    updateStandard() {
        this.router.navigate(['/', 'resources', this.productKey, this.selectedStandard]);
    }

    customClickCommand(action: string, link: string) {
        if (action) {
            if (action === 'pricelist' && this.isDownloadingPricelist !== true) {
                this.getPriceSheet();
            } else if (action === 'link') {
                window.open(link, '_blank');
            } else if (action === 'download') {
                if (link.lastIndexOf('.pptx') > -1) {
                    this.getFileFromServer(link, 'pptx');
                } else if (link.lastIndexOf('.docx') > -1) {
                    this.getFileFromServer(link, 'docx');
                } else if (link.lastIndexOf('.xlsx') > -1) {
                    this.getFileFromServer(link, 'xlsx');
                }
            }
        }
    }

    /* files are uploaded to AWS so they can be downloaded by grabbing the bites
  instead of having a link someone can more easily share
  In sdsstaticfrankfurt bucket */
    getFileFromServer(fileName: string, type: string) {
        this.notificationService.toast.download('Downloading', 'Downloading ' + fileName);
        this.resourceService.getBrochure(fileName).then((res) => {
            let raw = window.atob(res.Bytes);
            let bytes = new Uint8Array(raw.length);
            let mimetype;
            for (let i = 0; i < raw.length; i++) {
                bytes[i] = raw.charCodeAt(i);
            }
            if (type === 'docx') {
                mimetype =
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            } else if (type === 'pptx') {
                mimetype =
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            } else if (type === 'xlsx') {
                mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            }
            saveAs(new Blob([bytes], { type: mimetype }), res.Filename);
            this.isDownloadingPricelist = false;
        });
    }

    getPriceSheet() {
        this.isDownloadingPricelist = true;
        this.notificationService.toast.download('Downloading', 'Downloading Pricelist.');
        this.resourceService.getPriceSheet().then((res) => {
            let raw = window.atob(res.Bytes);
            let bytes = new Uint8Array(raw.length);
            for (let i = 0; i < raw.length; i++) {
                bytes[i] = raw.charCodeAt(i);
            }
            saveAs(new Blob([bytes], { type: 'application/pdf' }), res.Filename);
            this.isDownloadingPricelist = false;
        });
    }
}
