<div>
    <table class="itc-table bulkTable discovery-agent-table"
           sortable-table
           sortColumn="Name"
           sortDirection="asc"
           [class.tableLoading]="loading$ | async"
           (sorted)="onSorted($event)"
           #DiscoveryAgentTable>
        <thead>
        <tr>
            <th style="width: 50px;">
                <itc-bulk-actions [menuOptions]="bulkActions" [selectedCount]="selectedCount"
                                  [totalCount]="totalCount"
                                  (checkboxClicked)="onBulkCheckboxClicked()">
                </itc-bulk-actions>
            </th>
            <th sortable-column="Status" style="width: 50px;"></th>
            <th sortable-column="Name" style="width: 180px">Device Name</th>
            <th sortable-column="OS" style="width: 130px">OS</th>
            <th sortable-column="Label" style="width: 20%">Label</th>
            <th style="width: 150px">Last 5 Scans</th>
            <th sortable-column="Comment">Comment</th>
            <th style="width: 95px"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let agent of checkedDiscoveryAgentsTableRows">
            <td>
                <itc-checkbox [(ngModel)]="agent.checked" (change)="onSelectionChange(agent)"></itc-checkbox>
            </td>
            <td [ngSwitch]="agent.Status" class="appliance-status">
                <i *ngSwitchCase="'online'" class="icon circle online" tooltip="Online" ttPosition="bottom-right"></i>
                <i *ngSwitchCase="'update available'" class="icons" tooltip="Needs Update" ttPosition="bottom-right">
                    <i class="icon circle online"></i>
                    <i class="top right corner icon circle updateAvailable"></i>
                </i>
                <i *ngSwitchCase="'offline'" class="icon circle offline" tooltip="Offline"
                   ttPosition="bottom-right"></i>
                <i *ngSwitchCase="'offline > 90 days'" class="icon exclamation error"
                   tooltip="Offline > 90 Days" ttPosition="bottom-right"></i>
            </td>
            <td>{{ agent.Name }}</td>
            <td>{{ agent.OS }}</td>
            <td truncateText>{{ agent.Label }}</td>
            <td class="scan-status">
                <div>
                    <status-icon *ngFor="let scan of agent.Last5Scans" [status]="getStatusIconString(scan)"></status-icon>
                </div>
            </td>
            <td truncateText>{{ agent.Comment }}</td>
            <td>
                <div class="action-buttons">
                    <itc-button type="icon"
                                icon="fa-pen-to-square"
                                tooltip="Edit"
                                (onclick)="editDiscoveryAgentSidebar.show(agent)"></itc-button>
                    <itc-button type="icon" icon="fa-trash-can" tooltip="Delete"
                                (onclick)="showConfirmDeleteAgentModal([agent])"></itc-button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
    <itc-paginator *ngIf="discoveryAgentFilters$ | async as filters"
                   [hidden]="!filteredCount" [paginatedApiObject]="{
                       limit: filters.limit,
                       page: filters.page,
                       totalItemCount: filteredCount
                   }" (onPageWithInfo)="onPageChange($event)"></itc-paginator>
</div>

<sm-modal #confirmDeleteAgentModal>
    <modal-title>Delete Discovery Agent</modal-title>
    <modal-content>
        <div>You are about to delete <b>{{ numAgentsToDelete }}</b> discovery agent(s). Are you sure you want to
            delete?
        </div>
        <div class="agent-list-to-delete">
            <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
            <label *ngIf="numAgentsToDelete > first10SelectedAgents.length">
                {{ '+' + (numAgentsToDelete - first10SelectedAgents.length).toLocaleString() + ' More' }}
            </label>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmDeleteAgentModal.hide()">Cancel</itc-button>
        <itc-button type="danger" (onclick)="deleteAgents()" [loading]="isDeleting">Delete</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmRunNow>
    <modal-title>Discovery Agent Run Now Confirmation</modal-title>
    <modal-content>
        Run Discovery Agent Scan Now?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-delete">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmRunNow.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="runAgentsNow()" [loading]="isRunningNow">Run Now</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmDeepRunNow>
    <modal-title>Discovery Agent Run Now Confirmation</modal-title>
    <modal-content>
        Run Discovery Agent Deep File Scan Now?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-delete">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmDeepRunNow.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="runAgentsDeepNow()" [loading]="isRunningNow">Run Now</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmCancelScans>
    <modal-title>Cancel Scans Confirmation</modal-title>
    <modal-content>
        Cancel All Running Scans?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-delete">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmCancelScans.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="cancelScans()" [loading]="isCancelingNow">Cancel</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" #editDiscoveryAgentLabelBulk>
    <modal-title>Update Label</modal-title>
    <modal-content>
        <div class="bulk-label-content">
            <label>Label</label>
            <itc-input [control]="bulkLabelControl"></itc-input>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editDiscoveryAgentLabelBulk.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="updateBulkLabel()" [loading]="isUpdatingBulkLabel">Update</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #editDiscoveryAgentCommentBulk>
    <modal-title>Update Comment</modal-title>
    <modal-content>
        <div class="bulk-comment-content">
            <label>Comment</label>
            <textarea-char-limit [control]="bulkCommentControl" limit="150"
                                 [rows]="4"></textarea-char-limit>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editDiscoveryAgentCommentBulk.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="updateBulkComment()" [loading]="isUpdatingBulkComment">Update</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmUpdateNow>
    <modal-title>Discovery Agent Update Confirmation</modal-title>
    <modal-content>
        Issuing a command to update the data collector will cancel all running scans. Do you wish to update now?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-delete">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmUpdateNow.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="updateAgents()" [loading]="isUpdatingNow">Update</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmEnableAutoUpdate>
    <modal-title>Enable Auto Update Confirmation</modal-title>
    <modal-content>
        Enable auto-update for the selected discovery agents?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-update">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmEnableAutoUpdateModal.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="enableAutoUpdate()" [loading]="isEnablingAutoUpdate">Enable</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal #confirmDisableAutoUpdate>
    <modal-title>Disable Auto Update Confirmation</modal-title>
    <modal-content>
        Disable auto-update for the selected discovery agents?
        <sds-loader [complete]="!isLoadingModal">
            <div class="agent-list-to-update">
                <div *ngFor="let agent of first10SelectedAgents">{{ agent.Name.trim() }}</div>
                <label *ngIf="selectedCount > first10SelectedAgents.length">
                    {{ '+' + (selectedCount - first10SelectedAgents.length).toLocaleString() + ' More' }}
                </label>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmDisableAutoUpdateModal.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="disableAutoUpdate()" [loading]="isDisablingAutoUpdate">Disable
        </itc-button>
    </modal-actions>
</sm-modal>

<sds-discovery-agent-edit-sidebar [organizationId]="organization.Id"
                                  #editDiscoveryAgentSidebar></sds-discovery-agent-edit-sidebar>
