import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UiService } from '../core/ui.service';

import { TZINFOS } from '../settings/general/tzinfos';

@Pipe({ name: 'accountDate', standalone: true })
export class accountDatePipe implements PipeTransform {
    constructor(private uiService: UiService) {}

    transform(date: Date | string): string {
        let origDate = date;

        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (isNaN(date.getTime())) {
            console.warn('Invalid date for datepipe:', origDate);
            return origDate instanceof Date ? origDate.toISOString() : origDate;
        }
        // get saved settings, this gets update when they change settings and set when they log in
        // const portalSettings = JSON.parse(localStorage.getItem(SDSP_SETTINGS));
        // let portalSettings;
        let portalSettings = JSON.parse(this.uiService.getControlValue('accountTimeInfo'));

        // default to 'short' in case we have a problem getting the portal settings for some reason.
        let userFormat = 'MM/dd/yyyy';
        // default to blank for formatted offset so we just use the users timezone.
        let formattedOffset = '';

        if (portalSettings && portalSettings.DateFormat && portalSettings.TZINFO) {
            userFormat = portalSettings.DateFormat;
            const userTZ = portalSettings.TZINFO;
            // get utcoffset for selected TZINFO
            const userTZOffset = TZINFOS.find((tz) => tz.Id == userTZ).BaseUtcOffset;
            // the BaseUtcOffset is in format '05:00:00', and we only need the first 2 parts
            formattedOffset = userTZOffset.split(':').slice(0, 2).join(':');
            // the BaseUtcOffset has a negative for negative, but no + for positive so add that.
            if (formattedOffset.charAt(0) != '-') {
                formattedOffset = `+${formattedOffset}`;
            }
        } else {
            console.log("No portal settings to get settings. Falling back to 'default'.");
        }
        if (typeof date !== 'string' || (typeof date === 'string' && !isNaN(Date.parse(date)))) {
            return new DatePipe('en-us').transform(date, userFormat, formattedOffset);
        } else {
            return '';
        }
    }
}
