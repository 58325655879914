import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, shareReplay } from 'rxjs';
import { DiscoveryAgentFilters } from '../models/discovery-agent-filters.model';
import { PagedInfo } from '../../itc/paginator/itc-paginator.model';
import { DiscoveryAgentTableRow } from '../models/discovery-agents-table-row.model';
import { switchMapWithState } from '../../rxjs-operators/switchmap-with-state';
import { DiscoveryAgentService } from './discovery-agent.service';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';

@Injectable()
export class DiscoveryAgentTableService {
    constructor(private discoveryAgentService: DiscoveryAgentService) {}

    private filterDataObject: BehaviorSubject<DiscoveryAgentFilters> = new BehaviorSubject(null);
    private filteredPagedItems: Observable<PagedInfo<DiscoveryAgentTableRow>> =
        this.filterDataObject.pipe(
            debounceTime(400),
            filterIsNotUndefined(),
            distinctUntilChanged(),
            switchMapWithState((dataObject) =>
                this.discoveryAgentService.getDiscoveryAgents$(dataObject)
            ),
            shareReplay(1)
        );

    getFilterDataObject$(): Observable<DiscoveryAgentFilters> {
        return this.filterDataObject.asObservable();
    }

    getFilteredPagedItems(): Observable<PagedInfo<DiscoveryAgentTableRow>> {
        return this.filteredPagedItems;
    }

    filterData(filter: Partial<DiscoveryAgentFilters>): void {
        this.filterDataObject.next({
            ...this.filterDataObject.value,
            page: 0,
            ...filter,
        });
    }

    reloadData(): void {
        this.filterDataObject.next({
            ...this.filterDataObject.value,
        });
    }
}
