import {
    AfterContentChecked,
    Component,
    OnInit,
    ViewChild,
    isDevMode,
    OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AuthService, Roles } from 'app/core/auth/auth.service';
import { LicenseService } from 'app/core/license.service';
import { NavService, ALL_SITES } from 'app/core/nav/nav.service';
import { SiteService } from './shared/site.service';
import { Site } from './shared/site.model';
import { UiService } from 'app/core/ui.service';
import { NotesService } from './shared/notes/notes.service';
import { KvsService } from './shared/kvs.service';
import { Organization } from 'app/organizations/organization.model';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { SiteDecodePipe } from 'app/shared/siteDecode.pipe';
import { SiteEncodePipe } from 'app/shared/siteEncode.pipe';
import { Subject, takeUntil } from 'rxjs';
import { SiteDashboardService } from './shared/site-dashboard.service';
import { SiteUsersService } from './shared/site-users.service';
import { ItcTabsService } from 'app/shared/itc';
import { SiteDashboard } from './shared/site-dashboard.model';
import { AllSiteComponent } from './site-dashboard/all-site/all-site.component';
import { ComplianceManagerLegacyComponent } from './site-dashboard/compliance-manager-legacy/compliance-manager-legacy.component';
import { ComplianceManagerGrcComponent } from './site-dashboard/compliance-manager-grc/compliance-manager-grc.component';
import { NetworkDetectiveProComponent } from './site-dashboard/network-detective-pro/network-detective-pro.component';
import { CyberHawkComponent } from './site-dashboard/cyber-hawk/cyber-hawk.component';
import { IndocComponent } from './site-dashboard/indoc/indoc.component';
import { VulscanComponent } from './site-dashboard/vulscan/vulscan.component';
import { EndDateService } from 'app/core/auth';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';

const CURRENT_ASSESSMENT: string = '__current';
declare var ago: any;
declare var jQuery: any;

@Component({
    templateUrl: './sites.component.html',
    styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit, AfterContentChecked, OnDestroy {
    products: any;

    constructor(
        private router: Router,
        private authService: AuthService,
        private licenseService: LicenseService,
        private navService: NavService,
        private siteService: SiteService,
        private uiService: UiService,
        private kvsService: KvsService,
        private route: ActivatedRoute,
        private organizationsService: OrganizationsService,
        private siteDecodePipe: SiteDecodePipe,
        private siteEncodePipe: SiteEncodePipe,
        private siteDashboardService: SiteDashboardService,
        private siteUsersService: SiteUsersService,
        private tabService: ItcTabsService,
        private endDateService: EndDateService
    ) {}

    @ViewChild('addSiteModal', { static: true }) addSiteModal: any;
    @ViewChild('moveSiteModal', { static: true }) moveSiteModal: any;
    @ViewChild('smberrormodal', { static: true }) smbErrorModal: any;

    sortBy: string = 'Name';
    sortMethod: string = 'Name';
    sortDirection: string = 'asc';
    sitesDashboard: any[] = [];
    sites: any[];

    searchKey: string;
    filter: string = 'all';
    filteredSites: any[] = [];
    showAllSiteDashboard: boolean = false;
    filterCtrl = new UntypedFormControl();

    sorting: string = 'Name';
    sortedSites: any[];
    sortedSitesChunked: any = {};
    openDetails = '';

    canAdd: boolean;
    hasAgentAccess: boolean = false;
    legacyGuru: boolean;
    agppVersion: number;
    isSMB: boolean;
    isCM: boolean;
    hasSmbProvisioningError: boolean;
    smbErrorMessages: string[];
    hasIndoc: boolean = false;
    sitesUserIds: number[] = [];

    viewMode: string = localStorage.getItem('viewMode') || 'grid';
    viewModeUpdating: boolean = false;
    loadingComplete: boolean;
    sitesLoadingComplete = false;
    complianceManagerLegacySites: SiteDashboard[] = [];
    complianceManagerGrcSites: SiteDashboard[] = [];
    cyberHawkSites: SiteDashboard[] = [];
    networkDetectiveProSites: SiteDashboard[] = [];
    indocSites: SiteDashboard[] = [];
    vulscanSites: SiteDashboard[] = [];
    isSiteRestricted: boolean;
    isRestricted: boolean;
    isClient: boolean = false;
    isIE11: boolean;
    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
    };

    breadcrumbs: Breadcrumb[];

    isKVSPortal: boolean = false;
    organizationName: string;
    selectedOrg: Organization;
    organizations: Organization[];
    isOnInit: boolean = true;
    sidebarCollapsed = false;
    ngUnsubscribe$: Subject<any> = new Subject();
    searchKeye = '';
    @ViewChild('allSitesComp') allSitesComponent: AllSiteComponent;
    @ViewChild('cmGrcComp') grcComponent: ComplianceManagerGrcComponent;
    @ViewChild('cmLeagcyComp') cmLegacyComponent: ComplianceManagerLegacyComponent;
    @ViewChild('ndProComp') ndProComponent: NetworkDetectiveProComponent;
    @ViewChild('chSites') cyberHawkComponent: CyberHawkComponent;
    @ViewChild('indocComp') indocComponent: IndocComponent;
    @ViewChild('vsComp') vulScanComponent: VulscanComponent;

    ngOnInit() {
        this.uiService.setTitle('Sites');

        this.isKVSPortal = this.kvsService.isKVSPortal();
        // if user is kvs, force KVS sites filter
        if (this.isKVSPortal) {
            this.filter = 'kvs';
        }

        this.getUserSitesIds();
        this.filterCtrl.setValue(this.filter);
        this.navService.setSite(ALL_SITES);
        this.navService.setHeaderText('');
        this.isIE11 = !(window as any).ActiveXObject && 'ActiveXObject' in window;

        this.isSiteRestricted = this.authService.userIsRole(Roles.SiteRestricted);
        this.isRestricted = this.authService.userIsRole(Roles.Restricted);
        this.isClient = this.authService.isClient() == true;
        this.canAdd =
            this.authService.userIsRole(Roles.Admin) || this.authService.userIsRole(Roles.Master);
        this.products = this.authService.getProducts();
        this.licenseService.getProvisioning().then((prov) => {
            this.agppVersion = prov.agppVersion;
            this.legacyGuru = prov.legacyGuru;
            this.isSMB = prov.isSMB;
            this.isCM = prov.isCM;
            this.hasSmbProvisioningError = prov.hasSmbProvisioningError;
            this.smbErrorMessages = prov.smbErrorMessages;
            console.log(prov.smbErrorMessages);

            if (this.hasSmbProvisioningError) {
                (this.smbErrorModal as any).show();
            }
        });

        this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params: Params) => {
            this.sitesLoadingComplete = false;
            this.filterCtrl.setValue('all');
            this.organizationName = params['organization'];

            this.breadcrumbs = [{ text: this.organizationName }, { text: 'Sites' }];

            this.organizationsService.setSelectedOrganization(this.organizationName);
            if (this.organizationName && this.organizationName != '__all') {
                this.organizationName = this.siteDecodePipe.transform(this.organizationName);
                if (this.filterCtrl.value === 'withoutOrganization') {
                    this.filterCtrl.setValue('all');
                }
                this.siteDashboardService
                    .getDashboardSiteInOrganization(this.organizationName)
                    .then((sites) => {
                        sites.forEach((site) => {
                            if (site.IsLicenseInactive) {
                                site.Tooltip = this.endDateService.getExpiredTooltip(
                                    site,
                                    null,
                                    site.LicenseEndDate
                                );
                            }
                        });

                        this.filterSitesByType(sites);

                        this.showAllSiteDashboard = true;
                        this.sitesLoadingComplete = true;
                        this.loadingComplete = true;
                    })
                    .catch((err) => this.error(err));

                this.navService.setHeaderText(this.organizationName);
            } else {
                this.siteDashboardService
                    .getSitesByAccountId()
                    .then((sites) => {
                        //Add organization
                        if (sites) {
                            sites.forEach((site) => {
                                if (site.IsLicenseInactive) {
                                    site.Tooltip = this.endDateService.getExpiredTooltip(
                                        site,
                                        null,
                                        site.LicenseEndDate
                                    );
                                }
                            });
                            this.filterSitesByType(sites);

                            this.showAllSiteDashboard = true;
                            this.sitesLoadingComplete = true;
                            this.loadingComplete = true;
                        }
                    })
                    .catch((err) => this.error(err));
                this.navService.setHeaderText('');
                /*this.siteService
                    .getSites()
                    .then((sites) => {
                        console.log(sites);
                        this.process(sites);
                        let _sFromUrl: string = this.navService.loadReturnState();
                        if (_sFromUrl && _sFromUrl == 'login') {
                            this.navService.saveReturnState('sites');

                            this.checkCircleOfTrust();
                        }
                        this.showAllSiteDashboard = true;
                        this.sitesLoadingComplete = true;
                    })
                    .catch((err) => this.error(err));

                // set header text to blank so it doesn't show up because we're not in an organization
                this.navService.setHeaderText('');*/
            }

            // this.organizationsService.setSelectedOrganization(this.organizationName);
            this.organizationsService
                .getOrganizationsObs()
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((orgs) => {
                    if (orgs) {
                        this.organizations = orgs;
                        this.hasAgentAccess = false;
                        if (this.organizationName !== '__all') {
                            console.log('setting organization to', this.organizationName);
                            this.organizationsService
                                .setSelectedOrganization(this.organizationName)
                                .then((org) => (this.selectedOrg = org));
                            if (!this.canAdd) {
                                this.organizationsService
                                    .hasOrgDiscoveryAgentAccess(this.organizationName)
                                    .then((res) => {
                                        this.hasAgentAccess = res;
                                    });
                            }
                        } else {
                            this.organizationsService
                                .setSelectedOrganization('__all')
                                .then((org) => (this.selectedOrg = org));
                        }
                    }
                });

            if (!this.isOnInit) {
                this.resetSearchKeyInChildComponent();
            }
            this.isOnInit = false;
        });
    }

    filterSitesByType(sites: SiteDashboard[]) {
        if (this.products.CMProduct) {
            this.complianceManagerLegacySites = sites.filter((site) =>
                this.siteService.isComplianceManager(site)
            );
        }

        if (this.products.CMGRCProduct) {
            this.complianceManagerGrcSites = sites.filter((site) =>
                this.siteService.isComplianceManagerGRC(site)
            );
        }

        if (this.products.VulScanProduct) {
            this.vulscanSites = sites.filter((site) => this.siteService.isKVS(site));
        }

        if (this.products.CHProduct) {
            this.cyberHawkSites = sites.filter((site) => this.siteService.isCyberHawk(site));
        }

        if (this.products.NdPro) {
            this.networkDetectiveProSites = sites.filter((site) => this.siteService.isNDPro(site));
        }

        this.indocSites = sites.filter((site) => site.IsIndoc && !this.siteService.isNDPro(site));

        this.sitesDashboard = sites;
    }

    setTab() {
        let tabName = JSON.parse(localStorage.getItem('siteTab'));

        if (tabName && this.existTab(tabName)) {
            this.tabService.setTab(tabName);
        } else {
            this.tabService.setTab(1);
        }
    }

    existTab(tabName: string): boolean {
        const tabSiteMap = {
            'All': this.sitesDashboard,
            'Compliance Manager GRC': this.complianceManagerGrcSites,
            'Compliance Manager Legacy': this.complianceManagerLegacySites,
            'Cyber Hawk': this.cyberHawkSites,
            'Indoc': this.indocSites,
            'Network Detective Pro': this.networkDetectiveProSites,
            'VulScan': this.vulscanSites,
        };

        return !!tabSiteMap[tabName] && tabSiteMap[tabName].length > 0;
    }
    tabChanged(tabEvent) {
        localStorage.setItem('siteTab', JSON.stringify(tabEvent.tabTitle));
    }

    resetSearchKeyInChildComponent() {
        this.allSitesComponent?.resetSearchKey();
        this.grcComponent?.resetSearchKey();
        this.cmLegacyComponent?.resetSearchKey();
        this.ndProComponent?.resetSearchKey();
        this.cyberHawkComponent?.resetSearchKey();
        this.indocComponent?.resetSearchKey();
        this.vulScanComponent?.resetSearchKey();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    getUserSitesIds() {
        this.siteUsersService.getSitesUser().then((sitesId) => {
            if (sitesId.length) {
                this.sitesUserIds = sitesId;
            }
        });
    }

    process(sites: Site[]) {
        this.sites = sites;
        this.sites = [];
        let max = sites.length;
        let i = 0;
        for (let site of sites) {
            let s: any = {
                site: site,
                apps: [],
                addons: {},
                managementPlans: {},
                assessment: {},
                siteLoading: false,
            };
            this.sites.push(s);
            if (site.IsIndoc) {
                this.hasIndoc = true;
            }
        }

        //console.log(this.sites);

        this.filterSites();

        this.loadingComplete = true;
    }

    error(err: any) {
        this.loadingComplete = true;
        this.sitesLoadingComplete = true;
        console.error(err);
    }

    filterSites() {
        this.openDetails = '';
        this.filter = this.filterCtrl.value;

        let filtered: any[] = [];
        if (this.searchKey) {
            let key: string = this.searchKey.toLowerCase();
            filtered = this.sites.filter((site) => site.site.Name.toLowerCase().indexOf(key) > -1);
        } else {
            filtered = this.sites;
        }

        if (filtered) {
            filtered = filtered.filter((site) => this.checkFilter(site.site));
            this.filteredSites = filtered;
            this.sortSites();
        }
    }

    checkFilter(site: Site): boolean {
        let hasTodos = site.OpenTodos > 0;
        let hasData = site.LastScan !== void 0;

        var hasOrganization = site.Organization && site.Organization.length > 0;

        return (
            this.filter == 'all' ||
            (this.filter == 'withTodos' && hasTodos) ||
            (this.filter == 'withoutTodos' && !hasTodos) ||
            (this.filter == 'withData' && hasData) ||
            (this.filter == 'withoutData' && !hasData) ||
            (this.filter == 'cyberhawk' && this.siteService.isCyberHawk(site)) ||
            (this.filter == 'auditguru' &&
                (this.siteService.isComplianceManager(site) ||
                    this.siteService.isComplianceManagerGRC(site))) ||
            (this.filter == 'cspro' && site.IsCSPro) ||
            (this.filter == 'indoc' &&
                this.siteService.isIndoc(site) &&
                !this.siteService.isNDPro(site)) ||
            (this.filter == 'ndpro' && this.siteService.isNDPro(site)) ||
            (this.filter == 'kvs' && this.siteService.isKVS(site)) ||
            (this.filter == 'withoutOrganization' && !hasOrganization)
        );
    }

    sortSites() {
        let sorted: any[];
        if (this.sortMethod === 'Issues' || this.sortMethod === 'OpenTodos') {
            sorted = this.filteredSites.sort((a, b) => {
                let av: any = a.site[this.sortMethod];
                let bv: any = b.site[this.sortMethod];

                if (av === av.toString()) {
                    av = av.toLowerCase();
                }
                if (bv === bv.toString()) {
                    bv = bv.toLowerCase();
                }

                return this.sortDirection === 'asc' ? av - bv : bv - av;
            });
        } else {
            sorted = this.filteredSites.sort((a, b) => {
                let av: any = a.site[this.sortMethod];
                let bv: any = b.site[this.sortMethod];

                if (av === av.toString()) {
                    av = av.toLowerCase();
                }
                if (bv === bv.toString()) {
                    bv = bv.toLowerCase();
                }

                return this.sortDirection === 'asc'
                    ? av < bv
                        ? -1
                        : av > bv
                        ? 1
                        : 0
                    : bv < av
                    ? -1
                    : bv > av
                    ? 1
                    : 0;
            });
        }
        this.sortedSites = sorted.map((item) => item.site);
        this.sortedSitesChunked = this.chunkArray(sorted, 4);
    }

    chunkArray(myArray, size) {
        var i = 0;
        var newcols = new Object();

        while (i < size) {
            newcols['col' + i] = [];
            i++;
        }

        myArray.forEach((item) => {
            if (i % size == 0) {
                i = 0;
            }
            newcols['col' + i].push(item);
            i++;
        });
        return newcols;
    }

    isOld(scan: Date): boolean {
        if (!scan) {
            return false;
        }
        return new Date().valueOf() - new Date(scan).valueOf() > 86400 * 1000 * 7;
    }

    getButtonHref(site: Site, product: string): string {
        /* I think this is something that was never used, need to look into */
        let href: string = `/site/${site.Name}/`;

        if (this.siteService.isComplianceManager(site)) {
            if (site.UserRoles == 'AG_SME') href += '/assessments/forms';
            else href += product;
        } else if (this.authService.isClient()) href += 'client';
        else href += product;
        return href;
    }

    /* The version that gets used is in site-card.component, I'll try to remember to remove this when I confirm  */
    // getSiteDetails(site: any) {
    //   if (site.apps.length > 0 || site.siteLoading)
    //     return;

    //   site.siteLoading = true;
    //   this.siteSettingService.getApplianceInfoForReal(site.site.Id)
    //     .then((appliances) => {
    //       site.apps = [];
    //       if (!appliances || !appliances.length) {
    //         // appliance not installed
    //         let notInstalled = true;
    //         site.apps.push({
    //           Id: '<not installed>'
    //         });
    //         site.siteLoading = false;
    //       }
    //       else {
    //         for (let i = 0; i < appliances.length; i++) {
    //           let info = appliances[i]; // TODO update this to work with more than one appliance

    //           let updateStatus = 'unknown';
    //           let updateAvailable = false;
    //           if (info.UpdateStatus == 1) {
    //             updateStatus = 'up-to-date';
    //             updateAvailable = false;
    //           } else if (info.UpdateStatus == 0) {
    //             updateStatus = 'update available';
    //             updateAvailable = true;
    //           }

    //           let lastCheckin = new Date(info.LastCheckin);
    //           let lastCheckinAgo = ago(lastCheckin.getTime(), true) + ' ago';
    //           if (lastCheckin.getFullYear() < 2000) {
    //             //this.noCheckin = true;
    //           }
    //           let types = '';
    //           for (let j = 0; j < info.Types.length; j++) {
    //             if (types)
    //               types += ', ';
    //             types += this.friendlyTypes[info.Types[j]];
    //           }
    //           this.siteSettingService.getApplianceScanStatuses(site.site.Id, info.Id)
    //             .then((scans) => {
    //               for (let sc of scans) {
    //                 let startDate = new Date(sc.start);
    //                 sc.start = ago(startDate.getTime(), true);
    //               }
    //               let app = {
    //                 Id: info.Id,
    //                 Type: types,
    //                 LastCheckin: info.LastCheckin,
    //                 UpdateStatus: updateStatus,
    //                 UpdateAvailabe: updateAvailable,
    //                 IsVirtual: info.IsVirtual,
    //                 IsServer: info.IsServer,
    //                 Scans: scans,
    //                 Status: info.IsOnline ? 'Online' : 'Offline'
    //               };
    //               site.apps.push(app);
    //               if (app.Type.indexOf('Cyber Hawk') > -1) {
    //                 this.siteSettingService.getAddons(site.site.Id).then((s) => {
    //                   site.addons.abds = (s.abdsEnabled) ? true : false;
    //                   // site.addons = "Add-on: ABDS: " + (s.abdsEnabled ? "Enabled" : "Not Enabled");
    //                 });
    //               }
    //               if (app.Type.indexOf('Reporter') > -1) {
    //                 this.scandataService.getManagementPlans(site.site.Id)
    //                   .then((plans) => {
    //                     if (plans.error == 'outdated') {
    //                     }
    //                     else {
    //                       for (let key in plans) {
    //                         // remove null or objects with no length
    //                         if (plans[key] === null || (plans[key].length <= 0)) {
    //                           delete plans[key];
    //                         } else {
    //                           plans[key] = plans[key].length;
    //                         }
    //                       }
    //                       site.managementPlans = plans;
    //                     }
    //                   });
    //               }
    //               if (app.Type.indexOf('Audit Guru') > -1 || app.Type.indexOf('Compliance Manager') > -1) {
    //                 this.assessmentService.getAssessment(site.site.Id, CURRENT_ASSESSMENT)
    //                   .then((assessment) => {
    //                     site.assessment = assessment;
    //                     //this.isAssessmentStarted = false;
    //                     //this.isAssessmentStartable = false;

    //                     //if (this.selectedAssessment) {
    //                     //  console.log("assessment name: " + this.selectedAssessment.Name);
    //                     //  console.log("assessment state: " + this.selectedAssessment.State);
    //                     //}
    //                     //if (this.selectedAssessment && this.selectedAssessment.State == "Started") {
    //                     //  this.isAssessmentStarted = true;
    //                     //  console.log("set this.isAssessmentStarted: " + this.isAssessmentStarted);
    //                     //}

    //                     //if (this.selectedAssessment && this.selectedAssessment.State == "Archived" && assessment.IsLatest) {
    //                     //  this.isAssessmentStartable = true;
    //                     //}

    //                     //if (this.isAssessmentStartable === true) {
    //                     //  this.siteService.hasRunningAuditGuruScan(this.site.Id)
    //                     //    .then((res) => {
    //                     //      if (res) {
    //                     //        this.scansRunning = res;
    //                     //      }
    //                     //    });
    //                     //}

    //                     //this.assessmentLoading = false;
    //                   })
    //                   .catch(() => { });
    //               }

    //             });
    //         }
    //         setTimeout(() => site.siteLoading = false, 1500);
    //       }
    //     })
    //     .catch((err) => {

    //     });
    // }

    toggleDetails(sid) {
        if (sid == this.openDetails) {
            var siteContainer = jQuery('[data-sid=' + sid + ']');
            siteContainer.toggleClass('detailsOpen');
            siteContainer.find('.detailsContainer').slideToggle();
        } else {
            if (this.openDetails !== '') {
                var siteContainer = jQuery('[data-sid=' + this.openDetails + ']');
                siteContainer.removeClass('detailsOpen');
                siteContainer.find('.detailsContainer').slideUp();
            }
            this.openDetails = sid;
            var siteContainer = jQuery('[data-sid=' + sid + ']');
            siteContainer.addClass('detailsOpen');
            siteContainer.find('.detailsContainer').slideDown();
        }
    }

    showAddSiteModal() {
        this.addSiteModal.showModal();
    }

    showMoveSiteModal() {
        this.moveSiteModal.showModal();
    }

    siteAdded(site: Site) {
        if (this.siteService.isNDPro(site) && this.products.NdProWeb) {
            //Navigate to a specific subsite only for ND Pro AND NDPro Web newly created sites
            this.router.navigate([
                'site',
                this.siteEncodePipe.transform(site.Name),
                'network-detective-pro',
            ]);
        } else if (this.siteService.isNDPro(site) && !this.products.NdProWeb) {
            this.router.navigate([
                'site',
                this.siteEncodePipe.transform(site.Name),
                'network-detective-pro',
                'data-collection',
                'end-user-initiated-scan',
            ]);
        } else {
            this.router.navigate(['site', this.siteEncodePipe.transform(site.Name)]);
        }
    }

    siteMoved(organizationName: string) {
        // when sites are moved to unassigned they get brought to that "org"
        // we don't want that, they should go to __all
        organizationName = organizationName === '__unassigned' ? '__all' : organizationName;
        this.router.navigate(['organizations', organizationName.replace(/&/g, '%26')]);
        this.sitesLoadingComplete = false;

        this.siteService.getSitesInOrganization(organizationName).then((sites) => {
            this.process(sites);
            this.sitesLoadingComplete = true;
        });
    }

    toggleViewMode(view: string) {
        this.viewModeUpdating = true;
        this.viewMode = view;
        jQuery('.detailsContainer:visible').hide();
        localStorage.setItem('viewMode', view);
    }

    toggleSort(method): void {
        if (method === this.sortBy) {
            this.sortBy = method.replace('-', '');
            this.sortMethod = method.replace('-', '');
        } else {
            this.sortBy = method;
            this.sortMethod = method;
        }
        this.processSort(this.sortMethod);
    }

    processSort(sort: string) {
        this.sortDirection = sort.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sort.replace('-', '');
        if (isDevMode()) {
            console.log('sortBy: ' + this.sortMethod + ', Direction: ' + this.sortDirection);
        }
        this.sortSites();
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }

    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) || this.cmpTime(a.Created, b.Created)
        );
        // return (this.sortDirection === "asc") ? a[this.sortMethod].localeCompare(b[this.sortMethod]) : b[this.sortMethod].localeCompare(a[this.sortMethod]);
        // if (a[this.sortMethod].toLowerCase() < b[this.sortMethod].toLowerCase()) {
        //   return (this.sortDirection === 'asc') ? -1 : 1;
        // } else if (a[this.sortMethod].toLowerCase() > b[this.sortMethod].toLowerCase()) {
        //   return (this.sortDirection === 'asc') ? 1 : -1;
        // } else {
        //   return 0;
        // }
    }

    ngAfterContentChecked(): void {
        this.setTab();
    }

    parseRiskManagementTitle(title) {
        title = title.replace(/(.*)Manage*?mentPlan/, '$1'); // there was a typo in the key that was coming back, AB is gonna fix it but this regex is here till then, and we can just remove the word if we want.
        title = title.replace(/([A-Z])/g, ' $1');
        return title.trim();
    }

    viewUpdated() {
        this.viewModeUpdating = false;
        this.loadingComplete = true;
    }

    toggleSidebar(val) {
        this.sidebarCollapsed = val;
    }
}
