import {
    catchError,
    map,
    Observable,
    of,
    OperatorFunction,
    scan,
    startWith,
    switchMap,
} from 'rxjs';
import { PagedInfo, PagedResult } from '../itc/paginator/itc-paginator.model';

export function switchMapWithState<TInput, TResult>(
    observableFunction: (value: TInput) => Observable<PagedResult<TResult>>
): OperatorFunction<TInput, PagedInfo<TResult>> {
    return (source: Observable<TInput>) =>
        source.pipe(
            switchMap((value) =>
                observableFunction(value).pipe(
                    map((result) => ({ result, loading: false })),
                    catchError((error) => of({ error, loading: false })),
                    startWith({ error: null, loading: true })
                )
            ),
            scan((state: PagedInfo<TResult>, change: PagedInfo<TResult>) => ({
                ...state,
                ...change,
            }))
        );
}
