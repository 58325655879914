<div class="orgOuterContainer">
    <div
        class="orgContainer"
        [style.cursor]="organization?.Id === -1 ? 'default' : ''"
        (click)="gotoOrganization(organization)"
        [attr.data-sid]="organization?.Id">
        <!-- <itc-button type="icon" icon="fa-chevron-up"></itc-button> -->
        <div class="orgLeft">
            <div class="orgName" title="{{ organization?.Name }}">{{ organization?.Name }}</div>
            <div class="orgSiteCount" *ngIf="organization?.Id !== -1">
                <fa-icon icon="folder" style="color: var(--rft-orange)"></fa-icon>
                <span>
                    <strong>{{ organization?.Sites?.length }}</strong>
                    Sites
                </span>
            </div>
        </div>
        <div class="orgRight">
            <a
                routerLink=""
                class="orgActions"
                [class.active]="actionOpen"
                (click)="toggleActionOpen($event)"
                *ngIf="canModify && organization?.Id !== -1">
                <itc-button type="icon" icon="fa-ellipsis-v"></itc-button>
                <div
                    class="dropdownMenuContainer"
                    #dropdownMenu
                    (mouseout)="actionOpen = false"
                    (mouseover)="actionOpen = true">
                    <ul class="dropdownMenu">
                        <li>
                            <a routerLink="" (click)="showRenameOrganizationModal($event)">
                                Rename
                            </a>
                        </li>
                        <li>
                            <a routerLink="" class="warning" (click)="deleteOrganization($event)">
                                Delete
                            </a>
                        </li>
                    </ul>
                </div>
            </a>
        </div>
    </div>
    <div *ngIf="organization?.FilteredSites?.length" class="filteredSites">
        <ul>
            <li *ngFor="let site of organization?.FilteredSites">
                <sds-organization-site-card [site]="site"></sds-organization-site-card>
            </li>
        </ul>
    </div>
</div>

<!-- <div class="site_container" (click)="gotoOrganization(organization)" [attr.data-sid]="organization?.Id"> -->
<!-- Grid View Blocks-->
<!-- <div class="header" *ngIf="viewMode == 'grid'"> -->
<!-- <p class="siteNameContainer" title="{{ organization?.Name }}">{{ organization?.Name }}</p> -->
<!-- <p class="siteIssuesContainer">Sites: <span >{{ organization?.Sites?.length }}</span></p> -->
<!-- </div> -->
<!-- <div class="content" *ngIf="viewMode == 'grid'"> -->
<!-- for grid view -->
<!-- <a *ngIf="canModify" href="javascript:" (click)="showRenameOrganizationModal($event)">Rename</a> -->
<!-- <a *ngIf="canModify" href="javascript:" (click)="deleteOrganization($event)">Delete</a> -->

<!-- </div> -->
<!-- </div> -->
<sm-modal title="Rename Organization" class="xs" #renameOrganizationModal>
    <modal-content>
        <form name="addOrganizationForm" #addOrgForm="ngForm" novalidate>
            <div *ngIf="renameModalStep === 1">
                Old Org: {{ oldOrganizationName }}
                <label
                    [class.invalid]="
                        !orgName.valid && orgName.errors.regex && (orgName.touched || orgName.dirty)
                    ">
                    Organization Name*
                </label>
                <input
                    [class.invalid]="
                        !orgName.valid && orgName.errors.regex && (orgName.touched || orgName.dirty)
                    "
                    type="text"
                    name="organizationName"
                    [(ngModel)]="newOrganizationName"
                    orgNameValidator
                    [orgNameCurrent]="oldOrganizationName"
                    maxlength="50"
                    #orgName="ngModel"
                    notBlank />
                <label style="margin-top: -12px">
                    Organization names must start with an alphanumeric character, may only include
                    alphanumeric characters, dash, and space and must be less than 50 characters
                    long.
                </label>
                <!-- <label *ngIf="!orgName.valid && orgName.errors.pattern && (orgName.touched || orgName.dirty)">Organization names must start with an alphanumeric character and may only include alphanumeric characters, dash, and space and must be less than 50 characters long.</label> -->
            </div>

            <div *ngIf="renameModalStep == 2">
                <h2>Confirm Rename</h2>
                Organization name will be updated to {{ newOrganizationName }}. All Sites assigned
                will be retained.
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="renameModalGoback()">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="renameModalAdvance()" [disabled]="!addOrgForm?.valid">
            {{ renameModalStep == 1 ? 'Save' : 'OK' }}
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Confirm Deletion" class="xs" #deleteOrganizationModal>
    <modal-content>
        <form class="ui form" name="deleteOrganizationForm">
            <h2>Confirm Deletion</h2>
            <ng-container *ngIf="!organization.Sites.length">
                Are you sure you want to delete this organization?
            </ng-container>
            <ng-container *ngIf="organization.Sites.length">
                This Organization has associated sites. If you delete this Organization, the
                associated sites will be available under "All Sites."
            </ng-container>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button type="primary" (onclick)="deleteModalOk()">Ok</itc-button>
    </modal-actions>
</sm-modal>
