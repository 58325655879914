<div class="sidebarContainer">
    <div class="menuColumnsContainer">
        <div
            class="leftPanel"
            [class.collapsed]="collapsed"
            data-qa="menuContainer"
            [hidden]="!menuItems?.length">
            <div class="sidebarToggle" (click)="toggleCollapsed()">
                <div class="sidebarToggleButton">
                    <i class="fachevronleft"></i>
                </div>
            </div>

            <div class="sidebarMenuContents" style="overflow: hidden">
                <ul *ngFor="let item of menuItems">
                    <li>
                        <a
                            class="sidebarMenuItem parentMenuItem"
                            routerLinkActive="activeItem"
                            [class.openMenu]="item.expanded"
                            [class.noChildren]="!item.children || !item.children.length"
                            [class.disabledLink]="item.disabled"
                            [class.hasBadge]="item.badgeText"
                            [tooltip]="item.label"
                            [ttHidden]="!collapsed"
                            ttPosition="right"
                            (click)="toggleParent(parentContainer, item)"
                            [routerLink]="!item.disabled && item.href ? item.href : null"
                            #parentContainer>
                            <i
                                class="left {{ item.icon }} icon"
                                *ngIf="item.icon.substring(0, 5) === 'icon-'; else fabIcon"></i>
                            <ng-template #fabIcon>
                                <fa-icon [icon]="item.icon" class="fa-lg"></fa-icon>
                            </ng-template>
                            <span class="itemText">{{ item.label }}</span>
                            <itc-tag *ngIf="item.badgeText" type="subtle" status="new" size="small">
                                {{ item.badgeText }}
                            </itc-tag>
                        </a>
                        <ul [style.display]="!item.expanded ? 'none' : 'block'">
                            <li *ngFor="let subItem of item.children">
                                <a
                                    class="sidebarMenuItem childMenuItem"
                                    [routerLinkActive]="['activeChild', 'activeItem']"
                                    [class.openMenu]="subItem.expanded"
                                    [class.subParentMenuItem]="
                                        subItem.children && subItem.children.length
                                    "
                                    [class.hasBadge]="subItem.badgeText"
                                    [class.disabledLink]="subItem.disabled"
                                    (click)="toggleChildParent(childContainer, subItem)"
                                    [routerLink]="!subItem.disabled ? subItem.href : null"
                                    #childContainer>
                                    <span>{{ subItem.label }}</span>
                                    <div
                                        class="sidebarMenuItemCounter"
                                        *ngIf="subItem.badgeText >= 0">
                                        {{ subItem.badgeText }}
                                    </div>
                                    <itc-tag
                                        *ngIf="subItem.badgeText"
                                        type="subtle"
                                        status="new"
                                        size="small">
                                        {{ subItem.badgeText }}
                                    </itc-tag>
                                </a>
                                <ul [style.display]="!subItem.expanded ? 'none' : 'block'">
                                    <li *ngFor="let subSubItem of subItem.children">
                                        <a
                                            class="sidebarMenuItem grandChildMenuItem"
                                            [routerLinkActive]="['activeGrandChild', 'activeItem']"
                                            [class.disabledLink]="subSubItem.disabled"
                                            [routerLink]="
                                                !subSubItem.disabled ? subSubItem.href : null
                                            ">
                                            <span>{{ subSubItem.label }}</span>
                                            <div
                                                class="sidebarMenuItemCounter"
                                                *ngIf="subSubItem.badgeContent >= 0">
                                                {{ subSubItem.badgeContent }}
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="extraItems" *ngIf="menuExtras">
                    <p
                        class="extraItemsHeader"
                        [class.activeParent]="menuExtras?.active"
                        tooltip="Network DetectiveOnline (Beta)"
                        [ttHidden]="!collapsed"
                        ttPosition="right">
                        <i class="left {{ menuExtras.icon }} icon" style="font-size: 16px"></i>
                        <span class="itemText">
                            {{ menuExtras.label }}
                            <span
                                *ngIf="menuExtras.showBetaBadge"
                                class="itc pill badge small betaBadge">
                                Beta
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li *ngFor="let extra of menuExtras.items">
                            <a
                                *ngIf="extra.routerLink"
                                class="sidebarMenuItem childMenuItem"
                                [class.activeItem]="extra.active"
                                [class.disabledLink]="extra.disabled"
                                [routerLink]="!extra.disabled ? extra.routerLink : null">
                                <span>{{ extra.label }}</span>
                            </a>
                            <a
                                *ngIf="extra.href"
                                class="sidebarMenuItem childMenuItem"
                                [class.activeItem]="extra.active"
                                [class.disabledLink]="extra.disabled"
                                [href]="!extra.disabled ? extra.href : null">
                                <span>{{ extra.label }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="sidebarContent">
            <!-- <div class="headerBar" *ngIf="headerText$ | async as headerText">
                <h2>
                    {{ headerText }}
                    <span *ngIf="headerFlair" [innerHtml]="headerFlair"></span>
                </h2>
            </div> -->
            <div class="sidebarContent-inner">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
