import { Injectable } from '@angular/core';

import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { CWRESTCompany, Connection } from './connection.model';
import { SiteMapping } from './site-mapping.model';

import { environment } from 'environments/environment';

import { CONNECTION_TYPES } from './shared/constants';
import { Observable, lastValueFrom } from 'rxjs';
import { ITGlueFlexibleAssetMapping, ITGlueFlexibleAssetMappingSelection } from '../sites/site/assessments/files/files.service';
import { FlexibleAssetObject } from '../settings/it-glue/flexible-asset-types/flexible-asset-types.model';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getConnections(): Promise<Connection[]> {
        const url: string = this.apiUrl + 'Connections';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Connection[]);
        //.catch(this.E.error);
    }
    getConnectionsByType(connectionType: string): Promise<Connection[]> {
        const url: string = this.apiUrl + 'Connections/ByType/' + connectionType;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Connection[])
    }

    getConnectionById(connectionId: number): Promise<Connection> {
        const url: string = this.apiUrl + 'Connections/' + connectionId;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Connection);
        //.catch(this.E.error);
    }

    testLogin(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/TestLogin';
        return this.http
            .post(url, conn)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    testTicket(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/TestTicket';
        return this.http
            .post(url, conn, { responseType: 'text' })
            .toPromise()
            .then((resText) => (!resText || !resText.length ? true : resText));
        //.catch(this.E.error);
    }

    createTicket(siteId: number, assessmentName: string, exportedRisks: any[]): Promise<any> {
        const url: string = this.apiUrl + `Connections/CreateTicket/${siteId}/${assessmentName}`;
        //return this.http.post(url, { exportedRiskList: exportedRiskList, responseType: 'text' })
        return this.http
            .post(url, { exportedRisks: exportedRisks })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    createKvsTicket(
        siteId: number,
        kvsScanResult: any,
        title: string,
        separateTickets: boolean,
        excludeOpen: boolean
    ): Promise<any> {
        const url: string = this.apiUrl + `Connections/CreateKvsTicket/${siteId}`;
        return this.http
            .post(url, {
                KvsScanResult: kvsScanResult,
                Title: title,
                SeparateTickets: separateTickets,
                ExcludeOpen: excludeOpen,
            })
            .toPromise()
            .then((res) => res)
            .catch((ex) => ex);
    }

    getOpenTicketNumbers(siteId: number, ticketIds: any[]): Promise<string[]> {
        const url: string = this.apiUrl + `Connections/GetOpenTicketNumbers/${siteId}`;
        return this.http
            .post(url, ticketIds)
            .toPromise()
            .then((res) => res);
    }

    postConnection(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections';
        return this.http
            .post(url, conn, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    putConnection(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/' + conn.Id;
        return this.http
            .put(url, conn, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    deleteConnection(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/' + conn.Id;
        return this.http
            .delete(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getConnectionTypeName(connectionType: string): string {
        return CONNECTION_TYPES[connectionType];
    }

    getConnectionBySite(siteId: number): Promise<Connection> {
        const url: string = this.apiUrl + 'Connections/BySite/' + siteId;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Connection);
        //.catch(this.E.error);
    }

    getMappings(): Promise<any> {
        const url: string = this.apiUrl + 'Connections/SiteMappings';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    postMapping(map: SiteMapping): Promise<any> {
        const url: string = this.apiUrl + 'Connections/SiteMappings/';
        return this.http
            .post(url, map, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    deleteMapping(map: SiteMapping): Promise<any> {
        const url: string = this.apiUrl + 'Connections/SiteMappings/' + map.SiteId;
        return this.http
            .delete(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    // Below here is the dumb stuff to get Connection type-specific info asynchronously

    getATAccounts(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetATAccounts';
        return this.http
            .post(url, conn)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    onKBMSAccountSelection(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/OnKBMSAccountSelection';
        return this.http
            .post(url, { conn: conn })
            .toPromise()
            .then((res) => res);
    }
    getProductsForMapping(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetProductsForMapping';
        return lastValueFrom(this.http.post(url, conn));
    }
    getItGlueOrgs(conn: Connection): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetItGlueOrgs';
        return lastValueFrom(this.http.post(url, conn));
    }
    getCWSubServiceTypes(conn: Connection, ticket: any): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetCWSubServiceTypes';
        return this.http
            .post(url, { conn: conn, ticketData: ticket })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }
    getNDProFlexibleAssetTypes(): Promise<FlexibleAssetObject[]> {
        const url: string = this.apiUrl + 'NDPro/FlexibleAssets';
        return lastValueFrom(this.http.get(url));
    }
    getItGlueFlexibleAssetTypes(): Promise<FlexibleAssetObject[]> {
        const url: string = this.apiUrl + 'ITGlue/FlexibleAssetTypes';
        return lastValueFrom(this.http.get(url));
    }
    getItGlueFlexibleAssetFields(typeId: number): Promise<FlexibleAssetObject[]> {
        const url: string = this.apiUrl + 'ITGlue/FlexibleAssetFields/' + typeId;
        return lastValueFrom(this.http.get(url));
    }
    getItGlueFlexibleAssetMappings(): Promise<ITGlueFlexibleAssetMapping> {
        const url: string = this.apiUrl + 'ITGlue/FlexibleAssetMappings';
        return lastValueFrom(this.http.get(url));
    }
    saveItGlueFlexibleAssetMappings(mappingSelection: ITGlueFlexibleAssetMappingSelection[]): Promise<any> {
        const url: string = this.apiUrl + 'ITGlue/FlexibleAssetMappings';
        return lastValueFrom(this.http.post(url, mappingSelection));
    }

    getCWServiceItems(conn: Connection, ticket: any): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetCWServiceItems';
        return this.http
            .post(url, { conn: conn, ticketData: ticket })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    findCWRESTCompaniesObservable(conn: Connection, key: string, decrypt: boolean): Observable<CWRESTCompany[]> {

        if (conn.Type !== 'CWREST') {
            throw new Error('Connection type must be of type CW REST');
        }

        const url: string = this.apiUrl + 'Connections/FindCWCompanies';
        return this.http
            .post(url, { conn: conn, searchKey: key, decrypt: decrypt })
    }

    findCWCompanies(conn: Connection, key: string, decrypt: boolean): Promise<any> {
        const url: string = this.apiUrl + 'Connections/FindCWCompanies';
        return this.http
            .post(url, { conn: conn, searchKey: key, decrypt: decrypt })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }
 
    filterCustomers(conn: Connection, key: string, decrypt: boolean): Promise<any> {
        const url: string = this.apiUrl + 'Connections/FilterCustomers';
        return this.http
            .post(url, { conn: conn, searchKey: key, decrypt: decrypt })
            .toPromise()
            .then((res) => res);
    }

    getKBMSContracts(siteId: number, startsWithFilter: string): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetKBMSContracts';
        return this.http
            .post(url, { siteId: siteId, startsWithFilter: startsWithFilter })
            .toPromise()
            .then((res) => res);
    }

    getKBMSContractRecurringServices(
        siteId: number,
        startsWithFilter: string,
        contractId: string
    ): Promise<any> {
        const url: string = this.apiUrl + 'Connections/GetKBMSContractRecurringServices';
        return this.http
            .post(url, {
                siteId: siteId,
                startsWithFilter: startsWithFilter,
                contractId: contractId,
            })
            .toPromise()
            .then((res) => res);
    }

    SyncKBMSUnits(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Connections/SyncKBMSUnits';
        return this.http
            .post(url, { siteId: siteId })
            .toPromise()
            .then((res) => res);
    }

    getBaseUrlForPsaTicket(conn: Connection, siteId: number, ticketId: number): Promise<any> {
        const url: string = this.apiUrl + `Connections/GetBaseUrlForPsaTicket/${siteId}`;
        return lastValueFrom(this.http.post(url, { conn: conn, ticketId: ticketId })).then(
            (res) => res
        );
    }

    /**
     * gets the url of the AutoTask Internal REST API authorization page, user must be redirected there to allow creation of bearer tokens
     * @returns
     */
    getATRestConnectionUrl():Promise<string> {
        const url: string = this.apiUrl + `Connections/ATRestConnectionUrl`;
        return lastValueFrom(this.http.get(url, { })).then(
            (res) => res
        );
    }

    /**
     * completes the connection with a long lasting token
     * this is now for autotask rest
     */
    prepareATRestConnection(authenticationCode:string,enterpriseId:string,resourceId:string,partnerIntegrationsUrl:string): Promise<any> {
        const url: string = this.apiUrl + 'Connections/PrepareATRestConnection';
        return lastValueFrom(this.http.post(url, {
            'authenticationCode': authenticationCode,
            'enterpriseId': enterpriseId,
            'partnerIntegrationsUrl': partnerIntegrationsUrl,
            'resourceId':resourceId,
        }))
            .then((res) => res);
        
    }

    /**
     * sets the connections for the enterprise id (tenant) in this account as denied upon receival of integration denial
     */
    setATRestConnectionDenied(enterpriseId: string): Promise<any> {
        const url: string = this.apiUrl + 'Connections/SetATRestConnectionDenied';
        return lastValueFrom(this.http.put(url, {
            'enterpriseId': enterpriseId,
        }))
            .then((res) => res);
        
    }


    getDattoUnifiedContinuityCompanies(conn: Connection, siteId: number) {
        const url = this.apiUrl + `Connections/GetDUCCompanies/${siteId}`;
        return lastValueFrom(this.http.post(url, conn));
    }
}
