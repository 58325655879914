import { Injectable, isDevMode } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { Site, SiteTypeIcon } from './site.model';
import { AuthService, Roles, SiteRoles } from '../../core/auth/auth.service';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ItcFileUploaderService } from 'app/shared/itc';
import { SiteSettingService } from '../site/settings/site-settings.service';
import { ScanDataService } from 'app/scans';
import { AssessmentService } from './assessment.service';
import { DateService } from 'app/shared/date.service';
import { filter, distinctUntilChanged } from 'rxjs';
import { Base64UrlService } from 'app/shared/base64url.service';
import { SidebarMenuItem } from 'app/shared/sidebar/menu/sidebar-menu-item.model';
import { UniviewService } from '../shared/uniview.service';
import { DattoService } from '../shared/datto.service';
import { K1organizationService } from 'app/settings/shared/k1organization.service';

declare var ago: any;

export const CMTypes: string[] = ['GDPR', 'HIPAA', 'CYBERINSURANCE', 'NIST', 'CMMC', 'UKGDPR'];

@Injectable({ providedIn: 'root' })
export class SiteService {
    private apiUrl: string;

    constructor(
        private http: AuthHttp,
        private messageService: MessageService,
        private authService: AuthService,
        private fileUploader: ItcFileUploaderService,
        private siteSettingService: SiteSettingService,
        private scandataService: ScanDataService,
        private assessmentService: AssessmentService,
        private dateService: DateService,
        private base64UrlService: Base64UrlService,
        private univiewService: UniviewService,
        private dattoService: DattoService,
        private k1OrganizationService: K1organizationService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    private currentSite: Site;
    private currentSite$: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);
    public hasCMSites: boolean;

    app_getCurrentSite(): Promise<Site> {
        return new Promise<Site>((resolve, reject) => {
            if (this.currentSite) {
                resolve(this.currentSite);
                return;
            }

            let sub = this.messageService.on('APP_SET_CURRENT_SITE').subscribe((site: Site) => {
                resolve(site);
                sub.unsubscribe();
            });
        });
    }

    app_setCurrentSite(site: Site) {
        this.currentSite = site;
        this.messageService.broadcast('APP_SET_CURRENT_SITE', site);
    }

    setSiteObs(site: Site) {
        this.currentSite$.next(site);
    }
    getSiteObs() {
        return this.currentSite$.asObservable().pipe(filter(Boolean), distinctUntilChanged());
    }
    resetSiteObs() {
        this.currentSite$.next(null);
    }

    // I had to make different versions because getCurrentSite doesn't reject if we don't have a currentsite
    // so i couldn't use it in the route guards
    getCurrentSite() {
        return this.currentSite;
    }
    setCurrentSite(site: Site) {
        this.currentSite = site;
    }
    isComplianceManager(site: Site) {
        return CMTypes.indexOf(site.Type) > -1;
    }
    isComplianceManagerGRC(site: Site) {
        return site.Type == 'CM2';
    }
    isCSPro(site: Site) {
        return site.Type == 'CM2' && site.IsCSPro;
    }
    isNDPro(site: Site) {
        return site.Type == 'NDPRO';
    }
    isNDProWeb(site: Site) {
        let products = this.authService.getProducts();
        return site.Type === 'NDPRO' && products.NdProWeb;
    }
    isCyberHawk(site: Site) {
        return site.Type == 'standard';
    }
    isIndoc(site: Site) {
        return site.IsIndoc;
    }
    isKVS(site: Site) {
        return site.Type == 'KVS';
    }
    isCMO(site: Site) {
        // we doni't have the CMO site type yet, but we will eventually, now it's just on CM2 sites
        return site.Type === 'CMO' || site.Type === 'CM2';
    }

    getSiteTypeIcons(site: Site): SiteTypeIcon[] {
        let siteTypes: SiteTypeIcon[] = [];

        if (this.isCyberHawk(site)) {
            siteTypes.push({
                title: 'Cyber Hawk',
                class: 'cyberhawk',
            });
        }
        if (site.Type == 'CMMC') {
            siteTypes.push({
                title: 'Compliance Manager - CMMC',
                class: 'compliance-manager cmmc',
            });
        }
        if (site.Type == 'CYBERINSURANCE') {
            siteTypes.push({
                title: 'Compliance Manager - Cyber Insurance',
                class: 'compliance-manager cyberinsurance',
            });
        }
        if (site.Type == 'GDPR') {
            siteTypes.push({
                title: 'Compliance Manager - GDPR',
                class: 'compliance-manager gdpr',
            });
        }
        if (site.Type == 'HIPAA') {
            siteTypes.push({
                title: 'Compliance Manager - HIPAA',
                class: 'compliance-manager hipaa',
            });
        }
        if (site.Type == 'NIST') {
            siteTypes.push({
                title: 'Compliance Manager - NIST',
                class: 'compliance-manager nist',
            });
        }
        if (site.Type == 'UKGDPR') {
            siteTypes.push({
                title: 'Compliance Manager - UK GDPR',
                class: 'compliance-manager ukgdpr',
            });
        }
        if (this.isNDPro(site) || this.isIndoc(site)) {
            if (this.isNDPro(site)) {
                siteTypes.push({
                    title: 'Network Detective Pro',
                    class: 'icon-ndpro',
                });
            } else {
                siteTypes.push({
                    title: 'Indoc',
                    class: 'indoc',
                });
            }
        }
        if (this.isKVS(site)) {
            siteTypes.push({
                title: 'Vulscan',
                class: 'kvs',
            });
        }
        if (this.isComplianceManagerGRC(site) && !site.IsCSPro) {
            siteTypes.push({
                title: 'Compliance Manager GRC',
                class: 'compliance-manager compliance-manager2',
            });
        }
        if (this.isComplianceManagerGRC(site) && site.IsCSPro) {
            siteTypes.push({
                title: 'Compliance Services Pro Sites',
                class: 'compliance-manager2-cspro',
            });
        }

        return siteTypes;
    }

    getSites(): Promise<Site[]> {
        const url: string = this.apiUrl + 'Sites';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSitesInOrganization(organization: string): Promise<Site[]> {
        const url: string =
            this.apiUrl + `OrganizationSites/${this.base64UrlService.encode(organization)}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSiteKVSSitesInOrganization(siteId: number): Promise<Site[]> {
        if (!siteId) {
            return Promise.resolve(new Array<Site>());
        }
        const url: string = this.apiUrl + `SiteOrganizationKVSSites/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSitesWithData(): Promise<Site[]> {
        const url: string = this.apiUrl + 'Sites/WithData';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSite(id: number): Promise<Site> {
        const url: string = this.apiUrl + 'Sites/' + id;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    // used to get a pared down version of site object
    getSiteByNameLimitedData(name: string): Promise<Site> {
        const url: string = this.apiUrl + `SiteByName`;
        if (name.indexOf('%') >= 0) {
            name = decodeURIComponent(name);
        }
        return this.http
            .post(url, { SiteName: name })
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    getSiteByName(name: string): Promise<Site> {
        const url: string = this.apiUrl + 'Sites/?name=' + encodeURIComponent(name);
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    postSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + 'Sites';
        return this.http
            .post(url, site)
            .toPromise()
            .then((res) => res);
    }

    provisionSite(
        site: Site,
        license: any,
        useExisting: boolean,
        organizationName: string,
        organizationId: number,
        provisionApp: boolean = true
    ) {
        const url: string = this.apiUrl + 'Sites/Provision';
        return this.http
            .post(url, {
                site: site,
                license: license,
                useExisting: useExisting,
                organizationName: organizationName,
                organizationId: organizationId,
                provisionApp: provisionApp,
            })
            .toPromise()
            .then((res) => res);
    }

    provisionKVSApplianceOnSite(
        site: Site,
        license: any,
        kvsType: string,
        kvsAppId?: string
    ): Promise<any> {
        const url: string = this.apiUrl + `Sites/ProvisionKVSApplianceOnSite`;
        return this.http
            .post(url, {
                site: site,
                license: license,
                kvsType: kvsType,
                kvsAppId: kvsAppId,
            })
            .toPromise()
            .then((res) => res);
    }

    provisionRDCApplianceOnSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + `Sites/ProvisionRDCApplianceOnSite`;
        return this.http
            .post(url, { site: site })
            .toPromise()
            .then((res) => res);
    }

    deleteKVSAppliance(applianceId: string, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/Delete`;
        return this.http
            .post(url, { SiteId: siteId })
            .toPromise()
            .then((res) => res);
    }

    deleteSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + `Sites/${site.Id}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    createGdprAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CreateGdprAssessment';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    createGdprTodo5(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CreateGdprTodo5';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setCmmcLevel(siteId: number, level: string): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/SetCmmcLevel/' + level;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    answerTodoYN(siteId: number, alert: any, accepted: boolean) {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/AnswerTodoYN';
        return this.http
            .post(url, { alert: alert, accepted: accepted })
            .toPromise()
            .then((res) => res);
    }

    rescanGdprCdfs(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprCdfs';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprNetwork(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprNetwork';

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprPush(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprPush';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprIvul(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprIvul';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    doPrescan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DoPrescan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    doAzurescan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DoAzurescan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    uploadCdfAlerts(formdata: FormData): Promise<any> {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        //headers.append('Content-Type', 'multipart/form-data');
        let options = { headers: headers };

        const url: string = this.apiUrl + 'Sites/UploadCdf';
        return this.http
            .post(url, formdata, options)
            .toPromise()
            .then((res) => res);
    }

    uploadCdf(formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile('Sites/UploadCdf', formdata);
    }

    uploadAttachment(formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile('Sites/UploadAttachment', formdata);
    }

    downloadAttachment(siteId: number, attachmentId: string): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DownloadAttachment/' + attachmentId;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    downloadAttachmentsZip(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DownloadAttachmentsZip';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartCurrentAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartCurrentAssessment';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartInternalScans(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartInternalScans';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartPersonalDataScan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartPersonalDataScan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartPersonalDataWorksheet(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartPersonalDataWorksheet';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartExternalScan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartExternalScan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    skipUpdate(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/SkipUpdate';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    startCyberhawkLevel1Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/StartCyberhawkLevel1Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    startCyberhawkLevel2Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/StartCyberhawkLevel2Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    cancelCyberhawkLevel1Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CancelCyberhawkLevel1Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    cancelCyberhawkLevel2Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CancelCyberhawkLevel2Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCyberhawkScanStatuses(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ScanStatus`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCurrencySymbol(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/CurrencySymbol`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/AccountInfo`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteContactInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Contact`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    hasRftServer(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/HasRftServer`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getRollbackToTodoImpact(siteId: number, alerttype: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RollBackToTodoImpact/${alerttype}/`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rollbackToTodo(siteId: number, alerttype: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RollBackToTodo/${alerttype}/`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getRiskSnapshotReportSets(siteId: number): Promise<any[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/ReportSets`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getRiskSnapshotReportList(siteId: number, reportSetName: string): Promise<any[]> {
        let url: string =
            this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/List`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    downloadRiskSnapshotReport(
        siteId: number,
        reportSetName: string,
        reportName: string
    ): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/Download?reportName=${reportName}`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    downloadRiskSnapshotReportSetZip(siteId: number, reportSetName: string): Promise<any> {
        let url: string =
            this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/DownloadZip`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    deleteRiskSnapshotPublishedReport(
        siteId: number,
        reportSetName: string,
        reportName: string
    ): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/Report?reportName=${reportName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    deleteRiskSnapshotPublishedReportSet(siteId: number, reportSetName: string): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    setRiskSnapshotUseCompensatingControl(
        siteId: number,
        useCompensatingControl: boolean
    ): Promise<any> {
        let url: string = this.apiUrl + 'Sites/RiskSnapshot/UseCompensatingControl';
        return this.http
            .post(url, {
                siteId: siteId,
                useCompensatingControl: useCompensatingControl,
            })
            .toPromise()
            .then((res) => res);
    }

    setRiskSnapshotUseConnector(siteId: number, useConnector: boolean): Promise<any> {
        let url: string = this.apiUrl + 'Sites/RiskSnapshot/UseConnector';
        return this.http
            .post(url, { siteId: siteId, useConnector: useConnector })
            .toPromise()
            .then((res) => res);
    }

    getRiskSnapshotUseCompensatingControl(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/UseCompensatingControl`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getRiskSnapshotUseConnector(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/UseConnector`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    startRiskSnapshotScans(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/StartScan`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    hasRunningAuditGuruScan(siteId: number): Promise<boolean> {
        let url: string = this.apiUrl + `Sites/${siteId}/HasRunningAuditGuruScan`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as boolean);
    }

    checkEmployeeSubdomain(siteId: number, subdomain: string): Promise<boolean> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/checkEmployeeSubdomain?name=` + subdomain;
        return this.http
            .get(url, { responseType: 'text' })
            .toPromise()
            .then((resText) => resText == 'true');
        //.catch(this.E.error);
    }
    getEmployeePortalSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorPortalSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorPortalSettings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorTrackerInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorTracker`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    setEmployeePortalSettings(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings`;
        return this.http
            .post(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    setVendorPortalSettings(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorPortalSettings`;
        return this.http
            .post(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorManagement/Assesments`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    updateVendorAssessment(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorManagement/Assesment`;
        return this.http
            .put(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    deleteVendorAssessment(siteId: number, settings: any): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/VendorManagement/Assesment/${settings.Id}/${settings.Name}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    createVendorAssessment(data: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/VendorManagement/Assesment`;
        return this.http
            .post(url, data)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getEmployeeTrackerTrainings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeeTracker/Trainings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getEmployeeTrackerPolicies(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeeTracker/Policies`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalGroup(siteId: number, group: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups`;
        return this.http
            .put(url, group)
            .toPromise()
            .then((res) => res);
    }

    importEmployeePortalGroups(siteId: number, groups: any[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/GroupsImport`;
        return this.http
            .post(url, groups)
            .toPromise()
            .then((res) => res);
    }
    addEmployeePortalGroup(siteId: number, group: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups`;
        return this.http
            .post(url, group)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalGroup(siteId: number, groupId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups/${groupId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    importEmployeePortalEmployees(siteId: number, employees: any[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/EmployeesImport`;
        return this.http
            .post(url, employees)
            .toPromise()
            .then((res) => res);
    }
    updateEmployeePortalEmployee(siteId: number, employee: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees`;
        return this.http
            .put(url, employee)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalEmployee(siteId: number, employee: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees`;
        return this.http
            .post(url, employee)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalEmployee(siteId: number, employeeId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees/${employeeId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getEmployeePortalTraining(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalTraining(siteId: number, training: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .put(url, training)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalTraining(siteId: number, training: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .post(url, training)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalTraining(siteId: number, trainingId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings/${trainingId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalPolicy(siteId: number, policy: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies`;
        return this.http
            .put(url, policy)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalPolicy(siteId: number, policy: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies`;
        return this.http
            .post(url, policy)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalPolicy(siteId: number, policyId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies/${policyId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteConnectors(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/GetConnectors`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateConnectorLabel(siteId: number, connectorId: string, label: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EditConnector/${connectorId}`;

        return this.http
            .post(url, { label: label })
            .toPromise()
            .then((res) => res);
    }

    deleteConnector(siteId: number, connectorId: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/DeleteConnector/${connectorId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    rotateSiteKey(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RotateSiteKey`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setSiteKeyEnabled(siteId: number, enabled: boolean): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteKeyEnabled`;
        let postbody: any = { enabled: enabled };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    redirectToManualDataCollectorUrl(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `SiteRedirectToManualDataCollectorUrl/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    isIkValid(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `IsIkValid/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    isCaraIkValid(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `IsCaraIkValid/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }
    generateManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteGenerateManualDataCollectorUrl`;
        let postbody: any = { siteId: siteId };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getManualDataCollectorFiles(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollector/Files/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteManualDataCollectorFile(siteId: number, filename: string) {
        let encFilename = btoa(filename);
        console.log('encfilename: ' + encFilename);
        const url: string = this.apiUrl + `SiteManualDataCollector/Files/${siteId}/${encFilename}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    /* Used for site details component */
    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        KVS: 'Vulnerability Scanner',
        NDPro: 'Network Detective Pro',
    };
    siteTypeIcons: any[];

    getSiteDetails(site: any) {
        console.log('getting site details');
        if (site.apps.length > 0 || site.siteLoading) {
            return;
            //CARA - Cyberattack Risk Assessment
        }
        site.siteLoading = true;
        this.siteSettingService
            .getApplianceInfoForReal(site.site.Id)
            .then((appliances) => {
                console.log('appliances', appliances);
                site.apps = [];
                if (!appliances || !appliances.length) {
                    // appliance not installed
                    let notInstalled = true;
                    site.apps.push({
                        Id: '<not installed>',
                    });
                    site.siteLoading = false;
                } else {
                    for (let i = 0; i < appliances.length; i++) {
                        let info = appliances[i]; // TODO update this to work with more than one appliance

                        let updateStatus = 'unknown';
                        let updateAvailable = false;
                        if (info.UpdateStatus == 1) {
                            updateStatus = 'up-to-date';
                            updateAvailable = false;
                        } else if (info.UpdateStatus == 0) {
                            updateStatus = 'update available';
                            updateAvailable = true;
                        }

                        let types = '';
                        for (let j = 0; j < info.Types.length; j++) {
                            if (types) {
                                types += ', ';
                            }
                            types += this.friendlyTypes[info.Types[j]];
                        }
                        this.siteSettingService
                            .getApplianceScanStatuses(site.site.Id, info.Id)
                            .then((scans) => {
                                for (let sc of scans) {
                                    let startDate = new Date(sc.start);
                                    sc.start = this.dateService.relativeTime(startDate);
                                }
                                let appLastCheckin = new Date(info.LastCheckin);
                                let app = {
                                    Id: info.Id,
                                    Type: types,
                                    LastCheckin:
                                        appLastCheckin.getFullYear() < 2000
                                            ? 'NEVER'
                                            : info.LastCheckin,
                                    UpdateStatus: updateStatus,
                                    UpdateAvailable: updateAvailable,
                                    IsVirtual: info.IsVirtual,
                                    IsServer: info.IsServer,
                                    Scans: scans,
                                    Status: info.IsOnline ? 'Online' : 'Offline',
                                };
                                site.apps.push(app);
                                if (app.Type.indexOf('Cyber Hawk') > -1) {
                                    this.siteSettingService.getAddons(site.site.Id).then((s) => {
                                        site.addons.abds = s.abdsEnabled ? true : false;
                                        // site.addons = "Add-on: ABDS: " + (s.abdsEnabled ? "Enabled" : "Not Enabled");
                                    });
                                }
                                if (app.Type.indexOf('Reporter') > -1) {
                                    if (types.indexOf('Cyber') >= 0) {
                                        this.scandataService
                                            .getCyberhawkManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    } else {
                                        this.scandataService
                                            .getIndocManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    }
                                }
                                if (
                                    app.Type.indexOf('Audit Guru') > -1 ||
                                    app.Type.indexOf('Compliance Manager') > -1
                                ) {
                                    this.assessmentService
                                        .getAssessment(site.site.Id, '__current')
                                        .then((assessment) => {
                                            site.assessment = assessment;
                                        })
                                        .catch(() => {});
                                }
                            });
                    }
                    setTimeout(() => (site.siteLoading = false), 1500);
                }
            })
            .catch((err) => {});
    }

    setManagementPlans(site: any, plans: any) {
        if (plans.error !== 'outdated') {
            for (let key in plans) {
                // remove null or objects with no length
                if (plans[key] === null || plans[key].length <= 0) {
                    delete plans[key];
                } else {
                    plans[key] = plans[key].length;
                }
            }
            site.managementPlans = plans;
        }
    }

    generateCyberattackDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/GenerateCaraDataCollectorUrl`;
        let postbody: any = {};
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getCyberattackDataCollectorFiles(orgId: number) {
        const url: string = this.apiUrl + `CaraDataCollector/Files/${orgId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteCyberattackDataCollectorFile(siteId: number, filename: string) {
        let encFilename = btoa(filename);
        console.log('encfilename: ' + encFilename);
        const url: string = this.apiUrl + `CaraDataCollector/Files/${siteId}/${encFilename}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getCyberattackCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `CaraDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteCyberattackCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `CaraDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    redirectToCyberattackCollectorUrl(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `RedirectToCaraDataCollectorUrl/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    goToSite(site) {
        this.app_setCurrentSite(site);
        this.setSiteObs(site);
    }

    sendInvitationEmployeeTraining(employeeId: number, siteId: number) {
        const url: string = this.apiUrl + `sendInvitationEmployeeTraining/${siteId}`;

        return this.http
            .post(url, employeeId)
            .toPromise()
            .then((res) => res);
    }

    getEvidenceList(siteId: number) {
        const url: string = this.apiUrl + `existingevidences/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getEvidenceTypes(siteId: number) {
        const url: string = this.apiUrl + `getevidencetypes/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getSiteTypeTitle(site: Site): string {
        if (this.isComplianceManagerGRC(site)) {
            return 'Compliance Manager GRC';
        } else if (this.isComplianceManager(site)) {
            return 'Compliance Manager';
        } else if (this.isKVS(site)) {
            return 'Vulnerability Scanner';
        } else if (this.isNDPro(site)) {
            return 'Network Detective Pro';
        } else if (this.isCyberHawk(site)) {
            return 'Cyber Hawk';
        } else {
            return 'Reporter';
        }
    }

    getOrganizationSitesInSameOrganizationByType(
        organizationId: number,
        siteId: number,
        siteType: string
    ) {
        const url: string =
            this.apiUrl +
            `OrganizationSitesInSameOrganizationByType/${organizationId}/${siteId}/${siteType}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    addDataExplorerCounts(site: Site, dataExplorerNode: SidebarMenuItem[]) {
        if (!dataExplorerNode) {
            return;
        }

        this.scandataService
            .getDomainInfo(site.Id)
            .then((info) => {
                if (info.Domain) {
                    dataExplorerNode.find((item) => item.label === 'Users').badgeContent =
                        (Number(info.Domain.Details.Users) || 0) +
                        (Number(info.Domain.Details.LocalAccounts) || 0) +
                        '';
                    dataExplorerNode.find((item) => item.label === 'Computers').badgeContent =
                        info.Domain.Details.Computers || 0;
                    dataExplorerNode.find((item) => item.label === 'Databases').badgeContent =
                        info.Domain.Details.Databases || 0;
                    dataExplorerNode.find((item) => item.label === 'Applications').badgeContent =
                        info.Domain.Details.ApplicationsTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Printers').badgeContent =
                        info.Domain.Details.PrintersTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Non-AD Devices').badgeContent =
                        info.Domain.Details.NonADTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Security Groups').badgeContent =
                        info.Domain.Details.SecurityGroupsTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'DNS').badgeContent =
                        info.Domain.Details.DNSTotal || 0;
                }
            })
            .catch((err) => console.error(err));
    }

    /* Home Menu Item logic */
    getHomeMenuItems(site: Site): SidebarMenuItem {
        let user = this.authService.getIdentity();
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles.includes(SiteRoles.Tech);
        let isAdmin = siteRoles.includes(SiteRoles.SiteAdmin);
        let isAuditor = siteRoles.includes(SiteRoles.Auditor);
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let ch = this.isCyberHawk(site);
        let cm = this.isComplianceManager(site);
        let cmgrc = this.isComplianceManagerGRC(site);
        let indoc = this.isNDProWeb(site);
        let kvs = this.isKVS(site);
        let ndpro = this.isNDPro(site);

        let homeNode: SidebarMenuItem = {
            label: 'Home',
            url: 'home',
            icon: 'house-blank',
            children: [],
        };

        if (!cmgrc && !ndpro) {
            homeNode.children.push({
                label: 'Dashboard',
                href: 'home/dashboard',
                icon: 'icon-dashboard',
            });
        }

        if ((ch || cm || indoc || kvs || cmgrc || ndpro) && !isAuditor) {
            homeNode.children.push({
                label: 'Data Collectors',
                href: 'home/data-collectors',
                icon: 'icon-appliance',
            });
        }

        if (isAdmin || isPrivileged) {
            homeNode.children.push({
                label: 'Users',
                href: 'home/users',
                icon: 'icon-user',
            });
            homeNode.children.push({
                label: 'Roles',
                href: 'home/roles',
                icon: 'icon-users',
            });
        }

        if (cmgrc && (isAdmin || isAuditor || isTech || isPrivileged))
            homeNode.children.push({
                label: 'Groups',
                href: 'home/groups',
                icon: 'icon-users',
            });

        if (((ch || cm) && !isAuditor) || cmgrc) {
            homeNode.children.push({
                label: 'Admin Alerts',
                href: 'home/admin-alerts',
                icon: 'icon-notifications',
            });
        }

        if (isAdmin || isPrivileged) {
            homeNode.children.push({
                label: 'Advanced Options',
                href: 'home/advanced',
                icon: 'icon-advanced-options',
            });
        }

        if (!isAuditor && ch && (user.distributorId == 'FMIT' || site.HasFusebill)) {
            homeNode.children.push({
                label: 'Add-ons',
                href: 'home/addons',
                icon: 'icon-addons',
            });
        }

        return homeNode;
    }

    /* Indoc Menu Item Logic */
    getIndocMenuItems(site: Site): SidebarMenuItem {
        let products = this.authService.getProducts();
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let indocNode = {
            label: products.NdPro ? 'Network Detective' : 'InDoc',
            url: 'indoc',
            icon: 'icon-indoc',
            children: [],
        };

        if (products.NdPro) {
            indocNode.children.push({
                label: 'Dashboard',
                href: 'indoc/dashboard',
                icon: 'icon-dashboard',
            });

            if (!isClientView) {
                indocNode.children.push({
                    label: 'Data Collection',
                    url: 'indoc/data-collection',

                    children: [
                        {
                            label: 'Cyberattack Risk Scan',
                            href: 'indoc/data-collection/cybersecurity-assessment-scan',
                        },

                        {
                            label: 'End-User Initiated Scan',
                            href: 'indoc/data-collection/end-user-initiated-scan',
                        },
                        {
                            label: 'Scan Data',
                            href: 'indoc/data-collection/scandata',
                        },
                    ],
                });
            }
        } else {
            indocNode.children.push({
                label: 'Dashboard',
                href: 'indoc/iddashboard',
                icon: 'icon-dashboard',
            });
        }

        let dataExplorerNode = {
            label: 'Data Explorer',
            url: 'indoc/data-explorer',
            children: [
                {
                    label: 'Users',
                    href: 'indoc/data-explorer/users',
                    icon: 'icon-users',
                },
                {
                    label: 'Computers',
                    href: 'indoc/data-explorer/computers',
                    icon: 'icon-computer',
                },
                {
                    label: 'Databases',
                    href: 'indoc/data-explorer/databases',
                    icon: 'icon-db',
                },
                {
                    label: 'Applications',
                    href: 'indoc/data-explorer/applications',
                    icon: 'icon-application',
                },
                {
                    label: 'Printers',
                    href: 'indoc/data-explorer/printers',
                    icon: 'icon-print',
                },
                {
                    label: 'Non-AD Devices',
                    href: 'indoc/data-explorer/nonad-devices',
                    icon: 'icon-mobility',
                },
                {
                    label: 'Security Groups',
                    href: 'indoc/data-explorer/security-groups',
                    icon: 'icon-people',
                },
                {
                    label: 'DNS',
                    href: 'indoc/data-explorer/dns',
                    icon: 'icon-indoc2',
                },
            ],
        };
        this.addDataExplorerCounts(site, dataExplorerNode.children);

        // this.indocMenuItems = dataExplorerNode.children;

        if (!isClientView) {
            indocNode.children.push(dataExplorerNode);
        }

        let settingsNode = {
            label: 'Settings',
            url: 'indoc/settings',
            icon: 'icon-settings',
            children: [
                {
                    label: 'Client View',
                    href: 'indoc/settings/client-view',
                    icon: 'user circle',
                },
            ],
        };

        if (isAdmin || isTech || isClientView || isPrivileged) {
            indocNode.children.push(settingsNode);
        }

        return indocNode;
    }

    /* NDPro Menu Item Logic */
    getNDPMenuItems(site: Site): SidebarMenuItem {
        let products = this.authService.getProducts();
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let ndProNode: SidebarMenuItem = {
            label: 'Network Detective Pro',
            url: 'network-detective-pro',
            icon: 'icon-ndpro',
            children: [],
        };

        if (isAdmin || isTech || isPrivileged) {
            if (!products.NdProWeb) {
                ndProNode.children.push({
                    label: 'Dashboard',
                    href: 'network-detective-pro/dashboard',
                });
            }

            if (!products.NdProWeb) {
                ndProNode.children.push({
                    label: 'Data Collection',
                    url: 'network-detective-pro/data-collection',
                    children: [
                        {
                            label: 'Cyberattack Risk Assessment',
                            href: 'network-detective-pro/data-collection/cybersecurity-assessment-scan',
                        },
                        {
                            label: 'End-user Initiated Scan',
                            href: 'network-detective-pro/data-collection/end-user-initiated-scan',
                        },
                        {
                            label: 'Scan Data',
                            href: 'network-detective-pro/data-collection/scandata',
                        },
                    ],
                });

                let ndDataExplorerNode = {
                    label: 'Data Explorer',
                    url: 'network-detective-pro/data-explorer',
                    children: [
                        {
                            label: 'Users',
                            href: 'network-detective-pro/data-explorer/users',
                            badgeContent: '0',
                        },
                        {
                            label: 'Computers',
                            href: 'network-detective-pro/data-explorer/computers',
                            badgeContent: '0',
                        },
                        {
                            label: 'Databases',
                            href: 'network-detective-pro/data-explorer/databases',
                            badgeContent: '0',
                        },
                        {
                            label: 'Applications',
                            href: 'network-detective-pro/data-explorer/applications',
                            badgeContent: '0',
                        },
                        {
                            label: 'Printers',
                            href: 'network-detective-pro/data-explorer/printers',
                            badgeContent: '0',
                        },
                        {
                            label: 'Non-AD Devices',
                            href: 'network-detective-pro/data-explorer/nonad-devices',
                            badgeContent: '0',
                        },
                        {
                            label: 'Security Groups',
                            href: 'network-detective-pro/data-explorer/security-groups',
                            badgeContent: '0',
                        },
                        {
                            label: 'DNS',
                            href: 'network-detective-pro/data-explorer/dns',
                            badgeContent: '0',
                        },
                    ],
                };

                this.addDataExplorerCounts(site, ndDataExplorerNode.children);

                // this.ndpDataExplorerItems = ndDataExplorerNode.children;
                ndProNode.children.push(ndDataExplorerNode);
            }

            if (products.NdProWeb) {
                ndProNode.children.push({
                    label: 'Dashboard',
                    href: 'network-detective-pro/dashboard',
                });

                ndProNode.children.push({
                    label: 'audIT',
                    href: 'network-detective-pro/audit',
                    badgeText: 'New',
                });

                ndProNode.children.push({
                    label: 'Assessments',
                    href: 'network-detective-pro/assessments',
                });

                ndProNode.children.push({
                    label: 'Automation',
                    href: 'network-detective-pro/automation',
                });

                ndProNode.children.push({
                    label: 'Data Explorer',
                    href: 'network-detective-pro/data-explorer',
                });

                ndProNode.children.push({
                    label: 'Reports',
                    href: 'network-detective-pro/reports',
                });
            }

            let ndpSettingsNode;
            if (products.NdProWeb) {
                ndpSettingsNode = {
                    label: 'Settings',
                    url: 'network-detective-pro/settings',
                    children: [
                        {
                            label: 'Report Settings',
                            href: 'network-detective-pro/settings/report-preferences',
                        },
                        {
                            label: 'Issue Overrides',
                            href: 'network-detective-pro/settings/issue-overrides',
                        },
                        {
                            label: 'User Report Sets',
                            href: 'network-detective-pro/settings/user-report-sets',
                        },
                        {
                            label: 'Automation Settings',
                            href: 'network-detective-pro/settings/automation-settings',
                        },
                        {
                            label: 'Client View',
                            href: 'network-detective-pro/settings/client-view',
                        },
                    ],
                };
            } else {
                ndpSettingsNode = {
                    label: 'Settings',
                    url: 'network-detective-pro/settings',
                    icon: 'icon-settings',
                    children: [
                        {
                            label: 'Client View',
                            href: 'network-detective-pro/settings/client-view',
                            icon: 'user circle',
                        },
                    ],
                };
            }
            ndProNode.children.push(ndpSettingsNode);
        }

        if (isClientView) {
            let settingsNode = {
                label: 'Settings',
                url: 'network-detective-pro/settings',
                icon: 'icon-settings',
                children: [
                    {
                        label: 'Client View',
                        href: 'network-detective-pro/settings/client-view',
                        icon: 'user circle',
                    },
                ],
            };
            ndProNode.children.push(settingsNode);
        }

        return ndProNode;
    }

    /* CMGRC Menu Item Logic */
    getCMGRCMenuItems(site: Site): Promise<SidebarMenuItem> {
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
        let isSME = siteRoles && siteRoles.indexOf(SiteRoles.SME) > -1;
        let isAuditor = siteRoles && siteRoles.indexOf(SiteRoles.Auditor) > -1;
        let isEmpPortalAdmin = siteRoles && siteRoles.indexOf(SiteRoles.EmployeePortalAdmin) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let uniview = false;
        let datto = false;
        let dattoWorkplace = false;
        let evidenceCompliance = false;
        let isGrcReportViewer = siteRoles && siteRoles.indexOf(SiteRoles.GrcReportViewer) > -1;

        return Promise.allSettled([
            this.univiewService.isUniviewEnabled(site.Id),
            this.dattoService.isDattoEnabled(site.Id),
            this.dattoService.isDattoWorkplaceEnabled(site.Id),
            this.k1OrganizationService.isK1OrganizationMapped(site.Id),
        ]).then((res) => {
            uniview = res[0].status === 'fulfilled' ? res[0].value : false;
            datto = res[1].status === 'fulfilled' ? res[1].value : false;
            dattoWorkplace = res[2].status === 'fulfilled' ? res[2].value : false;
            evidenceCompliance = res[3].status === 'fulfilled' ? res[3].value : false;

            let cmGRCNode: SidebarMenuItem = {
                label: 'Compliance Manager GRC',
                url: 'compliance-manager-grc',
                icon: 'icon-audit-guru',
                children: [],
            };

            if (isTech || isAdmin || isAuditor || isPrivileged) {
                cmGRCNode.children.push({
                    label: 'Dashboard',
                    href: 'compliance-manager-grc/dashboard',
                    icon: 'icon-dashboard',
                });

                cmGRCNode.children.push({
                    label: 'My Work',
                    href: 'compliance-manager-grc/my-work',
                });

                cmGRCNode.children.push({
                    label: 'Standards & Controls',
                    href: 'compliance-manager-grc/standards-controls',
                    icon: 'icon-audit',
                });

                cmGRCNode.children.push({
                    label: 'Policies & Procedures',
                    href: 'compliance-manager-grc/policies-procedures',
                    icon: 'icon-audit',
                });

                cmGRCNode.children.push({
                    label: 'Rapid Baseline Assessment',
                    href: 'compliance-manager-grc/rapid-baseline',
                    icon: 'icon-assessment',
                });

                if (isTech || isAdmin || isPrivileged) {
                    let cmGrcDataCollectionNode = {
                        label: 'Data Collection',
                        url: 'compliance-manager-grc/data-collection',
                        icon: 'icon-list',
                        children: [],
                    };
                    cmGrcDataCollectionNode.children.push(
                        {
                            label: 'LAN Scan',
                            href: 'compliance-manager-grc/data-collection/lan',
                            icon: 'icon-audit',
                        },
                        {
                            label: 'Discovery Agent Scans',
                            href: 'compliance-manager-grc/data-collection/discovery-agents',
                            icon: 'icon-audit',
                        },
                        {
                            label: 'Cloud Scan',
                            href: 'compliance-manager-grc/data-collection/cloud',
                            icon: 'icon-audit',
                        },
                        {
                            label: 'Computer Scan',
                            href: 'compliance-manager-grc/data-collection/computer',
                            icon: 'icon-audit',
                        },
                        {
                            label: 'External VulScan',
                            href: 'compliance-manager-grc/data-collection/external',
                            icon: 'icon-audit',
                        },
                        {
                            label: 'Internal VulScan',
                            href: 'compliance-manager-grc/data-collection/internal',
                            icon: 'icon-audit',
                        }
                    );

                    if (uniview) {
                        cmGrcDataCollectionNode.children.push({
                            label: 'Unitrends',
                            href: 'compliance-manager-grc/data-collection/uniview',
                            icon: 'icon-audit',
                        });
                    }

                    if (datto) {
                        cmGrcDataCollectionNode.children.push({
                            label: 'Datto Continuity',
                            href: 'compliance-manager-grc/data-collection/datto',
                            icon: 'icon-audit',
                        });
                    }

                    if (dattoWorkplace) {
                        cmGrcDataCollectionNode.children.push({
                            label: 'Datto Workplace',
                            href: 'compliance-manager-grc/data-collection/workplace',
                            icon: 'icon-audit',
                        });
                    }

                    if (evidenceCompliance) {
                        cmGrcDataCollectionNode.children.push({
                            label: 'IT Complete Modules',
                            href: 'compliance-manager-grc/data-collection/it-complete',
                            icon: 'icon-audit',
                        });
                    }

                    cmGrcDataCollectionNode.children.push({
                        label: 'Scan Data',
                        href: 'compliance-manager-grc/data-collection/scans',
                        icon: 'icon-audit',
                    });

                    cmGRCNode.children.push(cmGrcDataCollectionNode);
                }

                cmGRCNode.children.push({
                    label: 'Technical Review',
                    href: 'compliance-manager-grc/technical-review',
                    icon: 'icon-audit',
                });

                cmGRCNode.children.push({
                    label: 'Controls Assessment',
                    href: 'compliance-manager-grc/controls-assessment',
                    icon: 'icon-audit',
                });

                cmGRCNode.children.push({
                    label: 'Requirements Assessment',
                    href: 'compliance-manager-grc/requirements-assessment',
                    icon: 'icon-audit',
                });

                cmGRCNode.children.push({
                    label: 'Plan of Action & Milestone',
                    href: 'compliance-manager-grc/plan-action',
                    icon: 'icon-audit',
                });
            }
            // SME only get My Work and Worksheets
            if (isSME) {
                cmGRCNode.children.push({
                    label: 'My Work',
                    href: 'compliance-manager-grc/my-work',
                });
            }

            let RANDEChildren = [];

            if (isAdmin || isAuditor || isTech || isGrcReportViewer || isPrivileged) {
                RANDEChildren.push({
                    label: 'Reports',
                    href: 'compliance-manager-grc/results-evidence/reports',
                    icon: 'icon-audit',
                });
            }

            if (isAdmin || isAuditor || isTech || isPrivileged) {
                RANDEChildren.push({
                    label: 'Files / Exhibits',
                    href: 'compliance-manager-grc/results-evidence/files-exhibits',
                    icon: 'icon-audit',
                });
            }

            if (isSME || isAdmin || isAuditor || isTech || isPrivileged) {
                RANDEChildren.push({
                    label: 'Worksheets',
                    href: 'compliance-manager-grc/results-evidence/worksheets',
                    icon: 'icon-audit',
                });
            }

            if (
                !isEmpPortalAdmin ||
                isTech ||
                isAdmin ||
                isClientView ||
                isSME ||
                isAuditor ||
                isGrcReportViewer ||
                isPrivileged
            ) {
                cmGRCNode.children.push({
                    label: 'Results & Evidence',
                    url: 'compliance-manager-grc/results-evidence',
                    icon: 'icon-list',
                    children: RANDEChildren,
                });
            }

            if (isAdmin || isAuditor || isTech || isPrivileged) {
                cmGRCNode.children.push({
                    label: 'Archived Assessments',
                    href: 'compliance-manager-grc/archived-assessments',
                });
            }

            if (isAdmin || isPrivileged) {
                cmGRCNode.children.push({
                    label: 'Settings',
                    url: 'compliance-manager-grc/settings',
                    icon: 'icon-list',
                    children: [
                        {
                            label: 'Report Settings',
                            href: 'compliance-manager-grc/settings/report-preferences/text',
                            icon: 'icon-check-box-checked',
                        },
                        {
                            label: 'Manage Invites',
                            href: 'compliance-manager-grc/settings/manage-invites',
                            icon: 'icon-check-box-checked',
                        },
                        {
                            label: 'IT Complete',
                            href: 'compliance-manager-grc/settings/it-complete',
                            icon: 'icon-list',
                        },
                        {
                            label: 'Schedules',
                            href: 'compliance-manager-grc/settings/schedules',
                            icon: 'icon-schedule',
                        },
                    ],
                });

                //agNode.children.push(
                //    { label: 'Audit History', href: 'compliance-manager-grc/audit-history', icon: 'icon-list' }
                //);
            }

            if (isAdmin || isAuditor || isPrivileged) {
                cmGRCNode.children.push({
                    label: 'Employee Portal',
                    url: 'compliance-manager-grc/employee-portal',
                    icon: 'icon-list',
                    children: [
                        {
                            label: 'Setup',
                            href: 'compliance-manager-grc/employee-portal/setup',
                            icon: 'icon-check-box-checked',
                        },
                        {
                            label: 'Employee Tracker',
                            href: 'compliance-manager-grc/employee-portal/employee-tracker',
                            icon: 'icon-check-box-checked',
                        },
                    ],
                });

                cmGRCNode.children.push({
                    label: 'Vendor Management',
                    url: 'compliance-manager-grc/vendor-management',
                    icon: 'icon-list',
                    children: [
                        {
                            label: 'Setup',
                            href: 'compliance-manager-grc/vendor-management/setup',
                            icon: 'icon-check-box-checked',
                        },
                        {
                            label: 'Vendor Tracker',
                            href: 'compliance-manager-grc/vendor-management/vendor-tracker',
                            icon: 'icon-check-box-checked',
                        },
                    ],
                });
            } else if (isEmpPortalAdmin) {
                cmGRCNode.children.push({
                    label: 'Employee Portal',
                    url: 'compliance-manager-grc/employee-portal',
                    icon: 'icon-list',
                    children: [
                        {
                            label: 'Setup',
                            href: 'compliance-manager-grc/employee-portal/setup',
                            icon: 'icon-check-box-checked',
                        },
                        {
                            label: 'Employee Tracker',
                            href: 'compliance-manager-grc/employee-portal/employee-tracker',
                            icon: 'icon-check-box-checked',
                        },
                    ],
                });
            }

            return cmGRCNode;
        });
    }

    /* CM Legacy Menu Item Logic */
    getCMMenuItems(site: Site): SidebarMenuItem {
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
        let isSME = siteRoles && siteRoles.indexOf(SiteRoles.SME) > -1;
        let isAuditor = siteRoles && siteRoles.indexOf(SiteRoles.Auditor) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let cmNode: SidebarMenuItem = {
            label: 'Compliance Manager',
            url: 'compliance-manager',
            icon: 'icon-audit-guru',
            children: [],
        };

        if (isTech || isAdmin || isAuditor || isPrivileged) {
            cmNode.children.push({
                label: 'To Do',
                href: 'compliance-manager/todo',
                icon: 'icon-list',
            });
        }
        if (!isSME) {
            cmNode.children.push({
                label: 'Assessments',
                url: 'compliance-manager/assessments',
                icon: 'icon-assessment',
                children: [
                    {
                        label: 'Dashboard',
                        href: 'compliance-manager/assessments/dashboard',
                        icon: 'icon-dashboard',
                    },
                    {
                        label: 'Forms',
                        href: 'compliance-manager/assessments/forms',
                        icon: 'icon-check-box-checked',
                    },
                    {
                        label: 'Files',
                        href: 'compliance-manager/assessments/files',
                        icon: 'icon-folder',
                    },
                    {
                        label: 'Reports',
                        href: 'compliance-manager/assessments/reports',
                        icon: 'icon-audit',
                    },
                    {
                        label: 'Issues',
                        href: 'compliance-manager/assessments/issues',
                        icon: 'icon-audit',
                    },
                ],
            });
        } else {
            cmNode.children.push({
                label: 'Assessments',
                url: 'compliance-manager/assessments',
                icon: 'icon-assessment',
                children: [
                    {
                        label: 'Forms',
                        href: 'compliance-manager/assessments/forms',
                        icon: 'icon-check-box-checked',
                    },
                ],
            });
        }

        if (!(isSME || isClientView)) {
            cmNode.children.push({
                label: 'Risk Updates',
                href: 'compliance-manager/risksnapshot',
                icon: 'icon-list',
            });
        }

        if (isAdmin || isPrivileged) {
            cmNode.children.push({
                label: 'Settings',
                url: 'compliance-manager/settings',
                icon: 'icon-settings',
                children: [
                    {
                        label: 'Scan Settings',
                        href: 'compliance-manager/settings/scanwizard',
                        icon: 'icon-gears',
                    },
                    {
                        label: 'Scan Schedules',
                        href: 'compliance-manager/settings/scan-schedules',
                        icon: 'icon-schedule',
                    },
                    {
                        label: 'Report Settings',
                        href: 'compliance-manager/settings/reports',
                        icon: 'icon-reports-alt',
                        children: [
                            {
                                label: 'Text',
                                href: 'compliance-manager/settings/reports/text',
                            },
                            {
                                label: 'My Logo',
                                href: 'compliance-manager/settings/reports/my-logo',
                            },
                            {
                                label: 'Theme',
                                href: 'compliance-manager/settings/reports/theme',
                            },
                            {
                                label: 'Cover Images',
                                href: 'compliance-manager/settings/reports/cover-images',
                            },
                        ],
                    },
                    {
                        label: 'Manage Invites',
                        href: 'compliance-manager/settings/invites',
                        icon: 'icon-manageinvites',
                    },
                    {
                        label: 'IT Complete',
                        href: 'compliance-manager/settings/itcomplete',
                        icon: 'icon-itc-mark',
                    },
                ],
            });
        } else if (isTech) {
            cmNode.children.push({
                label: 'Settings',
                url: 'compliance-manager/settings',
                icon: 'icon-settings',
                children: [
                    {
                        label: 'Scan Settings',
                        href: 'compliance-manager/settings/scanwizard',
                        icon: 'icon-gears',
                    },
                    {
                        label: 'Scan Schedules',
                        href: 'compliance-manager/settings/scan-schedules',
                        icon: 'icon-schedule',
                    },
                ],
            });
        }
        return cmNode;
    }

    getCMOMenuItems(site: Site): SidebarMenuItem {
        let siteRoles = this.authService.getCurrentSiteRoles();
        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isAuditor = siteRoles && siteRoles.indexOf(SiteRoles.Auditor) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        if (isTech || isAdmin || isAuditor || isPrivileged) {
            let complianceMonitorNode = {
                label: 'Compliance Monitor',
                url: 'compliance-monitor',
                icon: 'icon-compliance-monitor',
                badgeText: 'New',
                children: [
                    {
                        label: 'Status',
                        href: 'compliance-monitor/status',
                        icon: 'icon-audit',
                    },
                    {
                        label: 'Devices',
                        href: 'compliance-monitor/devices',
                        icon: 'icon-audit',
                    },
                    {
                        label: 'Benchmarks',
                        href: 'compliance-monitor/benchmarks',
                    },
                ],
            };

            if (!isAuditor || isPrivileged) {
                complianceMonitorNode.children.push({
                    label: 'Scan Settings',
                    href: 'compliance-monitor/scan-settings',
                    icon: 'icon-audit',
                });
            }
            return complianceMonitorNode;
        }
    }

    /* Cyber Hawk Menu Item Logic */
    getCHMenuItems(site: Site): SidebarMenuItem {
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let chNode = {
            label: 'Cyber Hawk',
            url: 'cyber-hawk',
            icon: 'icon-cyberhawk',
            children: [],
        };

        if (isTech || isAdmin || isPrivileged)
            chNode.children.push(
                {
                    label: 'To Do',
                    href: 'cyber-hawk/todo',
                    icon: 'icon-list',
                },
                {
                    label: 'Alerts',
                    href: 'cyber-hawk/alerts',
                    icon: 'icon-notifications',
                },
                {
                    label: 'Smart Tags',
                    href: 'cyber-hawk/smart-tags',
                    icon: 'icon-tag',
                }
            );

        if (isAdmin || isTech || isPrivileged)
            chNode.children.push({
                label: 'Settings',
                url: 'cyber-hawk/settings',
                icon: 'icon-settings',
                children: [
                    {
                        label: 'Scan Settings',
                        href: 'cyber-hawk/settings/scanwizard',
                        icon: 'icon-gears',
                    },
                    {
                        label: 'Scan & Notification Schedules',
                        href: 'cyber-hawk/settings/scan-schedules',
                        icon: 'icon-schedule',
                    },
                    {
                        label: 'Policy Configuration',
                        href: 'cyber-hawk/settings/policies',
                        icon: 'icon-policy',
                    },
                    {
                        label: 'Email Groups',
                        href: 'cyber-hawk/settings/email-groups',
                        icon: 'icon-email-groups',
                    },
                    {
                        label: 'Summary Emails',
                        href: 'cyber-hawk/settings/summary-emails',
                        icon: 'icon-mail',
                    },
                    {
                        label: 'Email Configuration',
                        href: 'cyber-hawk/settings/email-configuration',
                        icon: 'icon-mail-settings',
                    },
                    {
                        label: 'Weekly Notice',
                        href: 'cyber-hawk/settings/weekly-notice',
                        icon: 'icon-event',
                    },
                ],
            });
        return chNode;
    }

    /* VulScan Menu Item Logic */
    getVSMenuItems(site: Site): SidebarMenuItem {
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);

        let kvsNode: SidebarMenuItem = {
            label: 'VulScan',
            url: 'vulnerability-scanner',
            icon: 'icon-vulnerability-scanner',
            children: [],
        };
        kvsNode.children.push({
            label: 'Dashboard',
            href: 'vulnerability-scanner/dashboard',
            icon: 'icon-dashboard',
        });

        kvsNode.children.push({
            label: 'Scan Results',
            href: 'vulnerability-scanner/scan-results',
            icon: 'icon-file-search',
        });

        let kvsReportsChildren = [
            {
                label: 'Weekly Trend Report',
                href: 'vulnerability-scanner/reports/weekly',
                icon: 'icon-reports',
            },
            {
                label: 'Monthly Trend Report',
                href: 'vulnerability-scanner/reports/monthly',
                icon: 'icon-reports',
            },
            {
                label: 'Open Ports Report',
                href: 'vulnerability-scanner/reports/open-ports',
                icon: 'icon-reports',
            },
        ];

        kvsNode.children.push({
            label: 'Reports',
            url: 'vulnerability-scanner/reports',
            icon: 'icon-reports',
            children: kvsReportsChildren,
        });

        if (isAdmin || isTech || isPrivileged) {
            let kvsSettingsChildren = [
                {
                    label: 'General',
                    href: 'vulnerability-scanner/settings/general',
                    icon: 'icon-settings',
                },
            ];

            if (isAdmin || isPrivileged) {
                kvsSettingsChildren.push({
                    label: 'Report Settings',
                    href: 'vulnerability-scanner/settings/report-preferences/text',
                    icon: 'icon-check-box-checked',
                });
            }
            kvsSettingsChildren = kvsSettingsChildren.concat([
                {
                    label: 'Scan Credentials',
                    href: 'vulnerability-scanner/settings/scan-credentials',
                    icon: 'icon-account-circle',
                },
                {
                    label: 'Custom Scan Profiles',
                    href: 'vulnerability-scanner/settings/custom-scan-profiles',
                    icon: 'icon-account-circle',
                },
                {
                    label: 'Scan and Notification Tasks',
                    href: 'vulnerability-scanner/settings/scan-notification-task',
                    icon: 'icon-tasks-alt',
                },
                {
                    label: 'Exclusion Rules',
                    href: 'vulnerability-scanner/settings/exclusion-rules',
                    icon: 'icon-ballot-check',
                },
                {
                    label: 'VulScan Proxy Settings',
                    href: 'vulnerability-scanner/settings/proxy-settings',
                    icon: 'icon-settings',
                },
            ]);

            if (isAdmin || isPrivileged) {
                kvsSettingsChildren.push({
                    label: 'IT Complete',
                    href: 'vulnerability-scanner/settings/it-complete',
                    icon: 'icon-list',
                });
            }
            kvsNode.children.push({
                label: 'Settings',
                url: 'vulnerability-scanner/settings',
                icon: 'icon-settings',
                children: kvsSettingsChildren,
            });
        }
        return kvsNode;
    }
}
