import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ApplianceStatusStatsModel } from '../models/appliance-status-stats.model';
import { ChartData } from 'chart.js';
import { DonutChartComponent } from '../../itc/donut-chart/donut-chart.component';

@Component({
    selector: 'sds-da-appliance-status-card',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './appliance-status-card.component.html',
    styleUrls: ['./appliance-status-card.component.scss'],
})
export class ApplianceStatusCardComponent implements OnInit {
    @Input({ required: true }) applianceStatusData: ApplianceStatusStatsModel;

    data: ChartData<'doughnut'>;

    private readonly onlineColor = DonutChartComponent.SUCCESS_COLOR;
    private readonly updateAvailableColor = '#166EFA';
    private readonly offlineColor = DonutChartComponent.FAILED_COLOR;
    private readonly offlineMoreThan90DaysColor = '#811508';

    ngOnInit(): void {
        this.data = this.buildChartData();
    }

    private buildChartData(): ChartData<'doughnut'> {
        const labels = ['Online', 'Update Available', 'Offline', 'Offline >90 Days'];
        const data = [this.applianceStatusData.Online, this.applianceStatusData.UpdateAvailable,
            this.applianceStatusData.Offline,
            this.applianceStatusData.OfflineMoreThan90Days];
        const backgroundColor = [this.onlineColor, this.updateAvailableColor, this.offlineColor,
            this.offlineMoreThan90DaysColor];

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor,
                },
            ],
        };
    }
}
