import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { LastScanResultStatsModel } from '../models/last-scan-result-stats.model';
import { ChartData } from 'chart.js';
import { DonutChartComponent } from '../../itc/donut-chart/donut-chart.component';

@Component({
    selector: 'sds-da-last-scan-result-card',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './last-scan-result-card.component.html',
    styleUrls: ['./last-scan-result-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LastScanResultCardComponent implements OnInit {
    @Input({ required: true }) scanResultData: LastScanResultStatsModel;

    data: ChartData<'doughnut'>;

    private readonly successColor = DonutChartComponent.SUCCESS_COLOR;
    private readonly inProgressColor = '#9EE7A1';
    private readonly canceledColor = '#FFCF33';
    private readonly failedColor = DonutChartComponent.FAILED_COLOR;

    ngOnInit(): void {
        this.data = this.buildChartData();
    }

    private buildChartData(): ChartData<'doughnut'> {
        const labels = ['Success', 'In Progress', 'Canceled', 'Failed'];
        const data = [this.scanResultData.Success, this.scanResultData.InProgress, this.scanResultData.Canceled,
            this.scanResultData.Failed];
        const backgroundColor = [this.successColor, this.inProgressColor, this.canceledColor, this.failedColor];

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor,
                },
            ],
        };
    }
}
