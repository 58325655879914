import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import * as saveAs from 'file-saver';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-manage-appliance',
    templateUrl: './manage-appliance.component.html',
    styleUrls: ['./manage-appliance.component.css'],
})
export class ManageApplianceComponent implements OnInit {
    manageType: string;
    manageTarget: string;
    manageRunning: boolean = false;
    isQueuedOrRunning: boolean = false;

    modalTitle: string;
    modalContent: string;

    reports: any[] = [];
    sortedReports: any[] = [];
    pagedReports: any[] = [];

    scans: any[] = [];
    sortedScans: any[] = [];
    pagedScans: any[] = [];

    pagedHevsScans: any[] = [];
    initPage: boolean;

    lastSelectedIndex: number = -1;

    timeDateFormat: string = 'medium';

    activeDownload: string = '';
    activeDelete: string = '';
    isCancelling: boolean = false;
    isUnqueuing: boolean = false;
    autoUpdateSetting: boolean = false;

    @ViewChild('manageCollectorModal', { static: true }) manageCollectorModal: any;

    @Input() siteId: number = -1;
    @Input() isHostedEVS: boolean = false;
    @Input() disabled: boolean = false;
    @Input() disableDelete: boolean = false;
    @Input() set app(value: any) {
        this._app = value;
        this.appId = value.Id;
        this.appType = value.Type;
        console.log(value);
    }
    get app() {
        return this._app;
    }
    @Input() showAppInfo = false;
    @Input() displayType: string = 'button';
    @Input() icon: string = 'fa-pencil';
    @Input() displayLabel: string = 'Manage';

    _app: any;
    appId: string;
    appType: string;

    constructor(
        private siteSettingService: SiteSettingService,
        private notificationService: NotificationService,
        private datepipe: DatePipe
    ) {}

    ngOnInit() {}

    start() {
        this.manageType = '';
        if (this.isHostedEVS) {
            this.manageType = 'HEVS';
            //get data right away since we are not depending on a run menu
            this.getHEVSData();
        }
        this.updateModalSize();
        this.manageCollectorModal.show({ closable: false });
    }

    updateModalSize() {
        let modalClass = 'tiny';
        if (this.showAppInfo) {
            modalClass = 'small';
        }
        if (
            this.manageType == 'Manage Scans' ||
            this.manageType == 'Manage Reports' ||
            this.isHostedEVS
        ) {
            modalClass = 'large';
        }
        this.manageCollectorModal.modal.nativeElement.classList.remove('large', 'tiny', 'small');
        this.manageCollectorModal.modal.nativeElement.classList.add(modalClass);
    }

    showOverview() {

        if (this.manageRunning) {
            this.manageRunning = false;
            this.manageCollectorModal.hide();
        }

        this.manageType = '';
        this.updateModalSize();
    }

    getHEVSData() {
        this.manageRunning = true;
        this.modalTitle = 'Fetching Scans...';
        this.notificationService.toast.info(
            'Fetching Scans',
            'Requesting scans from ' + this.appId
        );
        this.siteSettingService
            .getHEVSScansBySiteId(this.siteId)
            .then((res) => {
                //console.log(res);
                this.modalTitle = 'Manage Data Collector Scans - ' + this.appId;

                this.reports = res;
                this.sortReports();
                this.activeDelete = '';
                this.activeDownload = '';
                this.isCancelling = false;
                this.isUnqueuing = false;
                this.manageRunning = false;
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'Scan fetch for ' + this.appId + ' failed.'
                );
                this.manageType = '';
                this.manageRunning = false;
            });
    }

    runManage(ddValue: string) {
        this.manageTarget = this.appId;
        this.manageType = ddValue;
        this.updateModalSize();
        if (ddValue == 'Update') {
            this.modalTitle = 'Warning - ' + this.appId;
            this.modalContent =
                'Issuing a command to update the data collector will cancel all running scans. Do you wish to update now?';
        } else if (ddValue == 'Set Auto-Update') {
            this.manageRunning = true;
            this.modalTitle = 'Requesting Auto-Update Status...';
            this.siteSettingService
                .getAutoUpdateForApplianceById(this.appId)
                .then((res) => {
                    this.autoUpdateSetting = res;
                    this.modalTitle = (res ? 'Dis' : 'En') + 'able Auto-update - ' + this.appId;
                    this.modalContent = 'Do you wish to ' + (res ? 'dis' : 'en') + 'able auto-update?';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Health Check') {
            this.doHealthCheck();
        } else if (ddValue == 'Download Logs') {
            this.manageRunning = true;
            this.modalTitle = 'Downloading Logs...';
            this.notificationService.toast.download(
                'Downloading Logs',
                'Requesting log files from ' + this.appId
            );
            this.siteSettingService
                .getLogsForApplianceById(this.manageTarget)
                .then((b64) => {
                    let raw = window.atob(b64);
                    let bytes = new Uint8Array(raw.length);
                    for (let i = 0; i < raw.length; i++) {
                        bytes[i] = raw.charCodeAt(i);
                    }
                    let blob = new Blob([bytes]);

                    saveAs(blob, this.manageTarget + ' - Logs.zip');
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Log download for ' + this.appId + ' timed out.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Manage Scans') {
            this.manageRunning = true;
            this.modalTitle = 'Fetching Scans...';
            this.notificationService.toast.info(
                'Fetching Scans',
                'Requesting scans from ' + this.appId
            );
            this.siteSettingService
                .getScansForApplianceById(this.manageTarget, this.siteId)
                .then((res) => {
                    //console.log(res);
                    this.modalTitle = 'Manage Data Collector Scans - ' + this.appId;
                    this.reports = res;
                    this.sortReports();
                    this.activeDelete = '';
                    this.activeDownload = '';
                    this.isCancelling = false;
                    this.isUnqueuing = false;
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Scan fetch for ' + this.appId + ' failed.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Manage Reports') {
            this.manageRunning = true;
            this.modalTitle = 'Fetching Reports...';
            this.notificationService.toast.info(
                'Fetching Reports',
                'Requesting reports from ' + this.appId
            );
            this.siteSettingService
                .getReportsForApplianceById(this.manageTarget)
                .then((res) => {
                    this.modalTitle = 'Manage Reports - ' + this.appId;
                    this.reports = res;
                    this.sortReports();
                    this.activeDelete = '';
                    this.activeDownload = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Report fetch for ' + this.appId + ' timed out.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Download Audit') {
            this.manageRunning = true;
            this.modalTitle = 'Requesting Audit...';
            this.notificationService.toast.download(
                'Downloading Audit',
                'Requesting audit file from ' + this.appId
            );
            this.siteSettingService
                .getAuditForApplianceById(this.manageTarget)
                .then((b64) => {
                    let raw = window.atob(b64);
                    let bytes = new Uint8Array(raw.length);
                    for (let i = 0; i < raw.length; i++) {
                        bytes[i] = raw.charCodeAt(i);
                    }
                    let blob = new Blob([bytes]);

                    saveAs(blob, this.manageTarget + ' - Audit.zip');
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Audit download for ' + this.appId + ' timed out.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        }
    }

    doHealthCheck() {
        this.manageRunning = true;
        this.modalTitle = 'Requesting Health Check...';
        this.notificationService.toast.info(
            'Health Check',
            'Requesting health check information from ' + this.appId
        );
        if (this.siteId > 0) {
            this.doHealthCheckForSiteAppliance();
        } else {
            this.doHealthCheckForAccountAppliance();
        }
    }
    doHealthCheckForAccountAppliance() {
        this.siteSettingService
            .getHealthCheckForApplianceById(this.manageTarget)
            .then((res) => {
                this.healthCheckSuccess(res);
            })
            .catch((err) => {
                this.healthCheckError();
            });
    }
    doHealthCheckForSiteAppliance() {
        this.siteSettingService
            .getHealthCheckForApplianceBySiteAndId(this.siteId, this.manageTarget)
            .then((res) => {
                this.healthCheckSuccess(res);
            })
            .catch((err) => {
                this.healthCheckError();
            });
    }
    healthCheckSuccess(result: any) {
        this.modalTitle = 'Health Check - ' + this.appId + ' - ' + this.datepipe.transform(new Date(), this.timeDateFormat, 'UTC');
        this.modalContent = result;
        this.manageRunning = false;
    }
    healthCheckError() {
        this.notificationService.toast.error(
            'Error',
            'Health check for ' + this.appId + ' timed out.'
        );
        this.manageType = '';
        this.manageRunning = false;
    }

    doManage() {
        this.manageCollectorModal.hide();
        if (this.manageType == 'Update') {
            this.manageRunning = true;
            this.modalTitle = 'Sending Command to Appliance...';
            this.siteSettingService
                .sendUpdateToApplianceById(this.manageTarget)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'A request was sent to update the data collector. The data collector will update itself after 15 minutes and should be available for use after the update is complete.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (this.manageType == 'Set Auto-Update') {
            this.manageRunning = true;
            this.modalTitle = 'Sending Command to Appliance...';
            this.siteSettingService
                .sendAutoUpdateToApplianceById(this.manageTarget, !this.autoUpdateSetting)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the auto-update setting has been sent to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the auto-update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        }
    }

    copy(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationService.toast.success('Copied', 'Successfully copied to clipboard.');
    }

    sortReports(sorting?: TableSorting) {
        //console.log(sorting);
        if (!sorting) {
            if (this.manageType == 'Manage Reports')
                this.sortedReports = this.reports.sort((a, b) =>
                    a.setId.toLowerCase().localeCompare(b.setId.toLowerCase())
                );
            else if (this.manageType == 'Manage Scans')
                this.sortedReports = this.reports.sort((a, b) =>
                    a.StartDateTime.localeCompare(b.StartDateTime)
                );
            else if (this.manageType == 'HEVS') {
                this.sortedReports = this.reports.sort((a, b) =>
                    a.StartDateTime.localeCompare(b.StartDateTime)
                );
            }
        } else {
            console.log(`sorting.sortColumn: ${sorting.sortColumn}`);
            switch (sorting.sortColumn) {
                default:
                    this.sortedReports = this.reports.sort((a, b) => {
                        return a[sorting.sortColumn]
                            .toString()
                            .toLowerCase()
                            .localeCompare(b[sorting.sortColumn].toString().toLowerCase());
                    });
                    break;
            }
            if (sorting.sortDirection == 'desc') this.sortedReports.reverse();
        }
        this.lastSelectedIndex = -1;

        this.reports = [...this.sortedReports];
    }
    downloadReport(report: any) {
        this.activeDownload = report.reportPath;
        this.notificationService.toast.download(
            'Downloading Report',
            'Requesting report file from ' + this.manageTarget
        );
        this.siteSettingService
            .getReportById(this.manageTarget, report)
            .then((b64) => {
                this.activeDownload = '';

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(
                    blob,
                    this.manageTarget +
                        ' - Report ' +
                        report.reportName +
                        '-' +
                        (621355968000000000 + new Date(report.timeUtc).getTime() * 10000) +
                        '.zip'
                );
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'Report download for ' + this.manageTarget + ' failed.'
                );
                this.activeDownload = '';
            });
    }

    downloadHEVSScans() {
        this.activeDownload = 'true';
        this.notificationService.toast.download('Downloading Scans', 'Downloading HEVS scan files');
        this.siteSettingService
            .getScansForHEVS(this.getSelected())
            .then((b64) => {
                this.activeDownload = '';

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, 'Hosted External Vulnerability - Scans.zip');
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'Scan download for Hosted External Vulnerability failed.'
                );
                this.activeDownload = '';
            });
    }

    downloadScans() {
        this.activeDownload = 'true';
        this.notificationService.toast.download(
            'Downloading Scans',
            'Downloading scan files from ' + this.manageTarget
        );
        this.siteSettingService
            .getScansForAppliance(this.manageTarget, this.getSelected())
            .then((b64) => {
                this.activeDownload = '';

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.manageTarget + ' - Scans.zip');
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'Scan download for ' + this.manageTarget + ' failed.'
                );
                this.activeDownload = '';
            });
    }

    deleteReport(report: any) {
        if (confirm('Are you sure you want to delete the selected file?')) {
            this.activeDelete = report.reportPath;
            this.notificationService.toast.warning(
                'Deleting Report',
                'Deleting report file from ' + this.manageTarget
            );
            this.siteSettingService
                .deleteReportById(this.manageTarget, report)
                .then((res) => {
                    this.activeDelete = '';

                    this.notificationService.toast.success(
                        'Deleted Report',
                        'Report files successfully deleted.'
                    );
                    let idx = this.reports.findIndex((u) => u.reportPath == report.reportPath);
                    if (idx >= 0) {
                        this.reports.splice(idx, 1);
                        this.sortReports();
                    }
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Report deletion for ' + this.manageTarget + ' failed.'
                    );
                    this.activeDelete = '';
                });
        }
    }

    deleteHEVSScans() {
        if (confirm('Are you sure you want to delete the selected file(s)?')) {
            this.activeDelete = 'true';
            this.notificationService.toast.warning(
                'Deleting Scans',
                'Deleting scan files from Hosted External Vulnerability Scans'
            );
            this.siteSettingService
                .deleteHEVSScans(this.getSelected())
                .then((res) => {
                    this.activeDelete = '';

                    this.notificationService.toast.success(
                        'Deleted Scans',
                        'Scan files successfully deleted.'
                    );
                    this.reports = this.reports.filter((a) => a.flag !== true);
                    this.sortReports();
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Scan deletion for Hosted External Vulnerability Scans failed.'
                    );
                    this.activeDelete = '';
                });
        }
    }
    deleteScans() {
        if (confirm('Are you sure you want to delete the selected file(s)?')) {
            this.activeDelete = 'true';
            this.notificationService.toast.warning(
                'Deleting Scans',
                'Deleting scan files from ' + this.manageTarget
            );
            this.siteSettingService
                .deleteScans(this.manageTarget, this.getSelected())
                .then((res) => {
                    this.activeDelete = '';

                    this.notificationService.toast.success(
                        'Deleted Scans',
                        'Scan files successfully deleted.'
                    );
                    this.reports = this.reports.filter((a) => a.flag !== true);
                    this.sortReports();
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Scan deletion for ' + this.manageTarget + ' failed.'
                    );
                    this.activeDelete = '';
                });
        }
    }

    unqueueHEVSScans() {
        this.isUnqueuing = true;
        this.notificationService.toast.info(
            'Removing from Scan Queue',
            'Removing selected scans from queue for ' + this.manageTarget
        );
        this.siteSettingService
            .unqueueHEVSScans(this.getSelected())
            .then((res) => {
                this.isUnqueuing = false;
                this.notificationService.toast.success(
                    'Scans Cancelled',
                    'Scans successfully removed from queue.'
                );
                this.reports = this.reports.filter((a) => a.flag !== true);
                this.sortReports();
            })
            .catch((err) => {
                this.isUnqueuing = false;
                this.notificationService.toast.error(
                    'Error',
                    'Scan queue cancellation for Hosted External Vulnerability Scans failed.'
                );
            });
    }
    unqueueScans() {
        this.isUnqueuing = true;
        this.notificationService.toast.info(
            'Removing from Scan Queue',
            'Removing selected scans from queue '
        );
        this.siteSettingService
            .unqueueScans(this.getSelected())
            .then((res) => {
                this.isUnqueuing = false;
                this.notificationService.toast.success(
                    'Scans Cancelled',
                    'Scans successfully removed from queue.'
                );
                this.reports = this.reports.filter((a) => a.flag !== true);
                this.sortReports();
            })
            .catch((err) => {
                this.isUnqueuing = false;
                this.notificationService.toast.error('Error', 'Scan queue cancellation failed.');
            });
    }

    cancelHEVSScans() {
        this.isCancelling = true;
        this.notificationService.toast.info(
            'Cancelling Running Scans',
            'Cancelling selected running scans '
        );
        this.siteSettingService
            .cancelHEVSScans(this.getSelected())
            .then((res) => {
                this.isCancelling = false;
                this.notificationService.toast.success(
                    'Scans Cancelled',
                    'Scans successfully removed from queue.'
                );
            })
            .catch((err) => {
                this.isCancelling = false;
                this.notificationService.toast.error(
                    'Error',
                    'Running scan cancellation for ' + this.manageTarget + ' failed.'
                );
            });
    }
    cancelScans() {
        this.isCancelling = true;
        this.notificationService.toast.info(
            'Cancelling Running Scans',
            'Cancelling selected running scans for ' + this.manageTarget
        );
        this.siteSettingService
            .cancelScans(this.manageTarget, this.getSelected())
            .then((res) => {
                this.isCancelling = false;
                this.notificationService.toast.success(
                    'Scans Cancelled',
                    'Scans successfully removed from queue.'
                );
            })
            .catch((err) => {
                this.isCancelling = false;
                this.notificationService.toast.error(
                    'Error',
                    'Running scan cancellation for ' + this.manageTarget + ' failed.'
                );
            });
    }

    process(settings: any) {
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                this.timeDateFormat = s.Value + ' hh:mm:ss a';
            }
        }
    }

    rowSelect(event: any, report: any) {
        this.isQueuedOrRunning =
            report.Status === 'Queued' ||
            report.Status === 'Requested' ||
            report.Status.includes('Running');

        if (
            (this.manageType == 'Manage Scans' || this.manageType == 'HEVS') &&
            !(this.activeDelete || this.activeDownload || this.isCancelling || this.isUnqueuing)
        ) {
            let init = report.flag;
            if (!event.ctrlKey && !event.shiftKey) this.clearSelection();
            if (event.shiftKey) {
                document.getSelection().removeAllRanges();
                let current = this.getCurrentIndex(report);
                if (this.lastSelectedIndex > -1) {
                    if (this.lastSelectedIndex < current)
                        this.selectRowsBetween(this.lastSelectedIndex, current);
                    else this.selectRowsBetween(current, this.lastSelectedIndex);
                }
                this.lastSelectedIndex = current;
            } else {
                report.flag = !init;
            }
            if (!init) {
                this.lastSelectedIndex = this.getCurrentIndex(report);
            }
        }
    }
    clearSelection() {
        for (let r of this.reports) {
            r.flag = false;
        }
    }
    canCancelQueue() {
        let anySelected = false;
        for (let r of this.reports) {
            if (r.flag == true) {
                anySelected = true;
                if (r.Status != 'Queued') return false;
            }
        }
        return anySelected;
    }

    canCancelScan() {
        let anySelected = false;
        for (let r of this.reports) {
            if (r.flag == true) {
                anySelected = true;
                if (r.Status.indexOf('Run') < 0) return false;
            }
        }
        return anySelected;
    }

    getSelected() {
        let selectedReports: any[] = [];
        for (let r of this.reports) {
            if (r.flag == true) {
                selectedReports.push(r);
            }
        }
        return selectedReports;
    }

    selectRowsBetween(start, end) {
        let currentIndex = 0;
        this.sortedReports.forEach((row) => {
            if (currentIndex >= start && currentIndex <= end) {
                row.flag = true;
            }
            currentIndex++;
        });
    }
    getCurrentIndex(report: any) {
        let i = 0;
        for (let r of this.sortedReports) {
            if (r === report) {
                return i;
            }
            i++;
        }
    }
}
