import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ItcTooltipDirective } from '../shared/itc/itc-tooltip.directive';

import { OrganizationsService } from './organizations.service';
import { OrganizationsComponent } from './organizations.component';
import { SharedModule } from '../shared/shared.module';
import { OrganizationGridComponent } from './organization-grid/organization-grid.component';
import { OrganizationCardComponent } from './organization-card/organization-card.component';
import { OrganizationSiteCardComponent } from './organization-card/oranization-site-card.component';
import { OrganizationSidebarComponent } from './organization-sidebar/organization-sidebar.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, RouterModule],
    declarations: [
        OrganizationsComponent,
        OrganizationGridComponent,
        OrganizationCardComponent,
        OrganizationSidebarComponent,
        OrganizationSiteCardComponent,
    ],
    exports: [OrganizationsComponent, OrganizationSidebarComponent],
    providers: [],
})
export class OrganizationsModule {}
