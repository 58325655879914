<itc-sidebar #sidebar>
    <div header>Manage Discovery Agent {{ discoveryAgent.Id }}</div>
    <div content>
        <sds-loader [complete]="!loading">
            <div class="edit-discovery-agent-sidebar">
                <div class="field">
                    <label>Device Name</label>
                    <div>{{ discoveryAgent.Name }}</div>
                </div>

                <div class="field">
                    <label>Operating System</label>
                    <div>{{ discoveryAgent.OS }}</div>

                </div>

                <div class="field">
                    <label>Label</label>
                    <itc-input [control]="formGroup.controls.label"></itc-input>
                </div>

                <div class="field">
                    <label>Comment</label>
                    <textarea-char-limit [control]="formGroup.controls.comment" limit="150"
                                         [rows]="4"></textarea-char-limit>
                </div>
                <itc-toggle [control]="formGroup.controls.isAutoUpdateEnabled"
                            [label]="'Enable Auto-Update'" labelPosition="right"></itc-toggle>
                <div class="divider"></div>
                <div class="button-field">
                    <label>Update</label>
                    <itc-button type="secondary" (onclick)="updateAgent()"
                                [disabled]="discoveryAgent.Status !== 'update available'"
                                [loading]="isUpdating" tooltip="Agent needs to be online" [ttHidden]="isOnline">
                        {{
                            !isOnline || discoveryAgent.Status == 'update available' ? 'Run' :
                                'Agent is Up to Date'
                        }}
                    </itc-button>
                </div>
                <div class="button-field">
                    <label>Download Audit</label>
                    <itc-button type="secondary" icon="fa-download" (onclick)="downloadAudit()"
                                [disabled]="!isOnline"
                                [loading]="isDownloadingAudit" tooltip="Agent needs to be online" [ttHidden]="isOnline">
                        Download
                    </itc-button>
                </div>
                <div class="button-field">
                    <label>Download Logs</label>
                    <itc-button type="secondary" icon="fa-download" (onclick)="downloadLogs()"
                                [disabled]="!isOnline"
                                [loading]="isDownloadingLogs" tooltip="Agent needs to be online" [ttHidden]="isOnline">
                        Download
                    </itc-button>
                </div>
            </div>
        </sds-loader>
    </div>
    <div footer>
        <itc-button type="submit"
                    [loading]="isSubmitting || loading || isUpdating || isDownloadingLogs ||
                    isDownloadingAudit"
                    (onclick)="onSubmit()">
            Save
        </itc-button>
        <itc-button type="darkText ghost" (onclick)="this.sidebar.close()">
            Cancel
        </itc-button>
    </div>
</itc-sidebar>