<sds-modal-debug
    [modals]="['previousModal', 'downloadModal']"
    [scope]="this"
    *ngIf="false"></sds-modal-debug>

<ngxtimeout
    *ngIf="!survey?.IsLocked"
    [ngxInactivityTimeout]="20"
    [ngxInactivityWarning]="5"
    (ngxTimeoutCallback)="handleTimeout()"
    (ngxTimeoutEnd)="handleTimeoutEnd()"></ngxtimeout>
<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs" *ngIf='!isInArchived'></sds-breadcrumb-trail>

<sds-loader [complete]="loadingComplete">
    <div style="display: flex; flex-direction: column">
        <div class="informHeaderContainer">
            <header class="pageHeader cm1HasSelectAssessment">
                <h1 [ngClass]="{archivedAssessmentTitle:isInArchived}">
                    {{ getLocalizedSurveyName() }}
                </h1>
                <div class="flex-right">
                    <sm-select
                        [control]="languageCtrl"
                        (onChange)="changeLanguage($event)"
                        *ngIf="showLanguageCtrl && !isInArchived">
                        <option *ngFor="let language of languageMap" [value]="language.value">
                            {{ language.label }}
                        </option>
                    </sm-select>
               </div>
            </header>
            <div>
                <itc-banner type="info"
                            closable="true"
                            *ngIf="showTip">
                    Use the <b>multi-field select feature</b> to copy and paste worksheet responses to save time when filling out the worksheet.
                    <a href="https://www2.rapidfiretools.com/cm-grc/help/cm-grc-docs/worksheet-completion-tips.htm" target="_blank">Learn More</a>
                </itc-banner>
            </div>
            <div
                class="item searchInput"
                style="display: flex; max-width: 800px; margin-bottom: 8px; margin-top: -16px">
                <!-- <sm-input placeholder="Search..." [(model)]="searchKey" (modelChange)="filterSites()" debounce="420" icon="icon-search"></sm-input> -->
                <sm-input
                    placeholder="Search Topics"
                    [(model)]="filter.keyWords"
                    debounce="420"
                    icon="icon-search"
                    style="width: 100%"></sm-input>
                <itc-button
                    type="primary"
                    label="Search"
                    (onclick)="filterTopics()"
                    style="margin-left: 10px"></itc-button>
                <!-- <button class="ui basic basicBlue icon button label" (click)="filterTopics()"> -->
                <!-- <input type="search" class="ui input" placeholder="Filter Topics" [(ngModel)]="filter.keyWords"> -->
            </div>
            <a
                class="usePreviousResponseLink"
                href="javascript:"
                (click)="previousResponseWarningModal.show()"
                *ngIf="hasPrevious && !survey?.IsReadOnly">
                Use All Previous Responses
            </a>
            <div style="display: flex; flex-wrap: wrap">
                <div class="flex-left" style="flex: 1 1 auto; align-self: center">
                    <a href="#" (click)="showNumbers = !showNumbers; $event.preventDefault()">
                        <span>{{ !showNumbers ? 'Show #' : 'Hide #' }}</span>
                    </a>
                    |
                    <a href="#" (click)="expandAll()">Expand All</a>
                    |
                    <a href="#" (click)="collapseAll()">Collapse All</a>
                    <a
                        style="padding-left: 40px"
                        href="javascript:void(0)"
                        (click)="populateResponse = false; downloadModal.show()">
                        Download
                    </a>
                </div>
                <div class="flex-right" style="display: flex" *ngIf='!isInArchived'>
                    <itc-button
                        label="Invite Others"
                        type="primary"
                        icon="fa-user-plus"
                        class="inviteButton"
                        *ngIf="!isSme"
                        (onclick)="
                            inviteModalLoading(true);
                            inviteSME.inviteOthers(
                                siteId,
                                informDecrypted,
                                null,
                                null,
                                this.informName,
                                this.siteName,
                                this.siteType
                            )
                        "
                        [loading]="inviteButtonLoading"></itc-button>
                    <div class="item saveCloseButtons" *ngIf="!survey?.IsReadOnly">
                        <div *ngIf="isSme">
                            <div *ngIf="!survey?.IsLocked">
                                <ng-container *ngTemplateOutlet="saveButton"></ng-container>
                                <ng-container *ngTemplateOutlet="saveReturnButton"></ng-container>
                            </div>
                            <div *ngIf="survey?.IsLocked">
                                <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                            </div>
                        </div>

                        <div *ngIf="!isSme">
                            <div *ngIf="!survey?.IsLocked">
                                <ng-container *ngTemplateOutlet="saveButton"></ng-container>
                                <ng-container *ngTemplateOutlet="saveReturnButton"></ng-container>
                                <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                            </div>
                            <div *ngIf="survey?.IsLocked">
                                <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                            </div>
                        </div>
                    </div>

                    <!-- Save and Close Buttons when read only -->
                    <div class="item saveCloseButtons" *ngIf="survey?.IsReadOnly">
                        <itc-button
                            label="Done"
                            type="primary"
                            [loading]="isSaving"
                            (onclick)="closeInFormAndReturn()"></itc-button>
                    </div>
                </div>
            </div>

            <div class="informHeader" *ngIf='!isInArchived'>
                <div *ngIf="survey?.IsLocked" style="background-color: yellow">
                    {{ survey?.LockedFirstname }} {{ survey?.LockedLastname }} ({{
                        survey?.LockedUsername
                    }}) is currently working on this form and locked. Read-only view.
                </div>
            </div>
        </div>
        <div *ngIf="doPaging" style="padding-bottom: 15px">
            <itc-button
                *ngIf="doPaging"
                [disabled]="currentPage == 0"
                label="Previous Page"
                type="primary"
                (onclick)="pageBack()"></itc-button>
            <itc-button
                *ngIf="doPaging"
                [disabled]="currentPage == pagedCategories?.length - 1"
                label="Next Page"
                type="primary"
                (onclick)="pageNext()"></itc-button>
            Page {{ currentPage + 1 }} of {{ pagedCategories?.length }}
        </div>
        <div class="nav-horizontal-divider"></div>

        <!-- Bulk responder Section -->
        <div class="bulk-responder" [hidden]="!showBulkResponder">
            <!-- Bulk Responder Filter -->
            <div class="bulk-responder-item">
                <!-- Title -->
                <div class="bulk-responder-item-line">
                    <span class="bulk-responder-title">Filter</span>
                </div>

                <!-- Filter Input -->
                <div
                    class="bulk-responder-item-line input-group input-group-sm bulk-responder-filter-group">
                    <input
                        type="search"
                        class="form-control input-sm hide-clear"
                        placeholder="Key Words"
                        ng-model="filter.keyWords" />
                    <span class="input-group-btn">
                        <itc-button
                            type="primary"
                            icon="glyphicon glyphicon-filter"
                            ttposition="top"
                            tooltip="Filter topics"
                            data-delay='{"show": 800, "hide": 50}'
                            (onclick)="filterTopics()"></itc-button>
                    </span>
                    <span class="input-group-btn">
                        <itc-button
                            type="primary"
                            ttposition="top"
                            tooltip="Clear filter"
                            icon="glyphicon glyphicon-remove"
                            (onclick)="clearFilter()"></itc-button>
                    </span>
                </div>

                <!-- Filter Select All/Deselect All-->
                <div
                    class="bulk-responder-item-line input-group input-group-sm bulk-responder-filter-group">
                    <itc-button
                        type="primary"
                        ttposition="top"
                        tooltip="Select all filtered topics"
                        (onclick)="bulkResponderSelectAll()">
                        Select All Filtered
                    </itc-button>
                    <itc-button
                        type="primary"
                        ttposition="top"
                        tooltip="Deselect all filtered topics"
                        (onclick)="bulkResponderSelectNone()">
                        Deselect All Filtered
                    </itc-button>
                </div>
            </div>

            <!-- Bulk Responder Entry -->
            <div class="bulk-responder-item bulk-responder-item-divider">
                <!-- Bulk Entry Title -->
                <div class="bulk-responder-item-line">
                    <span class="bulk-responder-title">Bulk Entry</span>
                </div>

                <!-- Bulk Entry Response -->
                <div class="bulk-responder-item-line">
                    <!-- if no topics selected or topics selected of different types -->
                    <div *ngIf="bulkResponder.responseStyle == -1">
                        <input
                            type="text"
                            class="form-control input-sm"
                            disabled
                            placeholder="Select topics of same response type"
                            NgClass="{'bulk-responder-error': bulkResponder.hasError}" />
                    </div>

                    <!-- if topics selected are Multiple Choice Response -->
                    <div *ngIf="bulkResponder.responseStyle == 1">
                        <sm-select
                            class="form-control input-sm response-font-size"
                            [(model)]="bulkResponder.response">
                            <option
                                *ngFor="let option of bulkResponder.responseChoices"
                                value="{{ option }}">
                                {{ option }}
                            </option>
                        </sm-select>
                        <!--<select class="form-control input-sm response-font-size" ng-model="bulkResponder.response" ng-options="option for option in bulkResponder.responseChoices"></select>-->
                    </div>

                    <!-- if topics selected are FreeForm (or None) Response -->
                    <div
                        *ngIf="
                            bulkResponder.responseStyle == 2 || bulkResponder.responseStyle == 0
                        ">
                        <input
                            type="text"
                            class="form-control input-sm bulk-responder-textbox"
                            placeholder="Response"
                            [(ngModel)]="bulkResponder.response" />
                    </div>

                    <!-- if topics selected are CheckBox Response -->
                    <div *ngIf="bulkResponder.responseStyle == 3">
                        <sm-select
                            class="form-control input-sm response-font-size"
                            [(model)]="bulkResponder.response">
                            <option>Checked</option>
                            <option>Unchecked</option>
                        </sm-select>
                        <!--<select class="form-control input-sm response-font-size" [(ngModel)]="bulkResponder.response">
                            <option>Checked</option>
                            <option>Unchecked</option>
                        </select>-->
                    </div>
                </div>

                <!-- Bulk Entry Note -->
                <div class="bulk-responder-item-line">
                    <input
                        type="text"
                        class="form-control input-sm bulk-responder-textbox"
                        placeholder="Note"
                        [(ngModel)]="bulkResponder.note" />
                </div>

                <!-- Bulk Entry Respondent -->
                <div class="bulk-responder-item-line">
                    <input
                        type="text"
                        class="form-control input-sm bulk-responder-textbox"
                        placeholder="Respondent"
                        [(ngModel)]="bulkResponder.respondent" />
                </div>
            </div>

            <!-- Apply to Selected -->
            <div class="bulk-responder-item bulk-responder-apply">
                <itc-button type="primary" (onclick)="applyBulkEntry()">
                    Apply to Selected
                </itc-button>
            </div>
        </div>
        <div class="informContentContainer" [class.helpOpen]="helpOpen">
            <!-- Black line separating pagination from InForm Content -->
            <div
                class="category-horizontal-divider"
                NgClass="{'category-horizontal-divider-bulk-responder': showBulkResponder}"></div>

            <div class="ui info icon message" *ngIf="totalCategories <= 0">
                <i class="info circle icon"></i>
                <div class="content">
                    <div class="header">No Relevant Data was Found</div>
                    <p>
                        Compliance Manager did not discover any relevant data for this assessment
                        form. No responses are required for this form.
                    </p>
                </div>
            </div>

            <!-- InForm Content -->
            <div id="inform-content" class="container-fluid category-scroll" style="width: 100%">
                <form id="informForm">
                    <!-- Overall Instructions -->
                    <div class="inform-instructions row-full">
                        <div class="row-col-fill padding-right">
                            <span class="inform-instructions-text">{{ survey?.Instructions }}</span>
                        </div>
                    </div>

                    <ng-container *ngIf="doPaging">
                        <inform-category
                            *ngFor="
                                let category of this.pagedCategories[this.currentPage];
                                let categoryIndex = index
                            "
                            [category]="category"
                            [scope]="this"
                            categoryIndex="{{ previousCategoryIndex + categoryIndex + 1 }}"
                            [readOnly]="survey?.IsReadOnly"></inform-category>
                    </ng-container>
                    <ng-container *ngIf="!doPaging">
                        <inform-category
                            *ngFor="
                                let category of this.survey?.Categories;
                                let categoryIndex = index
                            "
                            [category]="category"
                            [scope]="this"
                            categoryIndex="{{ categoryIndex + 1 }}"
                            [readOnly]="survey?.IsReadOnly"></inform-category>
                    </ng-container>
                </form>
            </div>
            <!-- InForm Content -->
            <!-- Bottom Buttons -->
            <div class="informFooter" style="margin-bottom: 25px" *ngIf="totalCategories > 0">
                <!-- <div style="display: inline-block; padding-top: 10px;"><a href="#" onclick="$('.page_content').animate({ scrollTop: 0 }, 'fast'); return false;">Jump To Top</a></div> -->
                <div id="informStatusBar" *ngIf='!isInArchived' >
                    <div class="leftFooterButtons" class="left floated buttons" *ngIf="doPaging">
                        <itc-button
                            *ngIf="doPaging"
                            [disabled]="currentPage == 0"
                            label="Previous Page"
                            type="primary"
                            (onclick)="pageBack()"></itc-button>
                        <itc-button
                            *ngIf="doPaging"
                            [disabled]="currentPage == pagedCategories?.length - 1"
                            label="Next Page"
                            type="primary"
                            (onclick)="pageNext()"></itc-button>
                        Page {{ currentPage + 1 }} of {{ pagedCategories?.length }}
                    </div>
                    <div *ngIf="!doPaging" style="display: inline-block">
                        {{ totalRequiredRemaining }} required remaining
                    </div>
                    <div class="footerButtons" class="right floated buttons">
                        <!-- Save and Close Buttons when not read only -->
                        <div class="item saveCloseButtons" *ngIf="!survey?.IsReadOnly">
                            <div *ngIf="isSme">
                                <div *ngIf="!survey?.IsLocked">
                                    <ng-container *ngTemplateOutlet="saveButton"></ng-container>
                                    <ng-container
                                        *ngTemplateOutlet="saveReturnButton"></ng-container>
                                </div>
                                <div *ngIf="survey?.IsLocked">
                                    <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                                </div>
                            </div>
                            <div *ngIf="!isSme">
                                <div *ngIf="!survey?.IsLocked">
                                    <ng-container *ngTemplateOutlet="saveButton"></ng-container>
                                    <ng-container
                                        *ngTemplateOutlet="saveReturnButton"></ng-container>
                                    <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                                </div>
                                <div *ngIf="survey?.IsLocked">
                                    <ng-container *ngTemplateOutlet="returnButton"></ng-container>
                                </div>
                            </div>
                        </div>

                        <!-- Save and Close Buttons when read only -->
                        <div class="item saveCloseButtons" *ngIf="survey?.IsReadOnly">
                            <itc-button
                                label="Done"
                                type="primary"
                                [loading]="isSaving"
                                (onclick)="
                                    survey?.IsReadOnly ? closeInFormAndReturn() : closeInForm()
                                "></itc-button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px; margin-bottom: 30px">
                <a
                    href="#"
                    onclick="$('.sidebarContent').animate({ scrollTop: 0 }, 'fast'); return false;">
                    Jump To Top
                </a>
            </div>
        </div>
        <div class="sideDrawerHelp" #sideDrawer>
            <a [routerLink]="[]" (click)="closeHelp()">X</a>
            <sds-loader [complete]="helpLoadingComplete" [hideVisibility]="true">
                <iframe src=""></iframe>
            </sds-loader>
        </div>
    </div>
</sds-loader>

<sm-modal title="Download Form" class="xs" #downloadModal>
    <modal-content>
        <p>
            Download a copy of the current form to your computer. You can optionally include
            populated responses.
        </p>
        <div>
            <itc-checkbox id="cbPopulateResponse" [(ngModel)]="populateResponse">
                Include Responses
            </itc-checkbox>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button label="Cancel" type="secondary" class="cancel"></itc-button>
        <itc-button
            label="OK"
            type="primary"
            icon="fa-check"
            class="approve"
            (onclick)="download()"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Form Incomplete" class="xs" #closeGuardModal>
    <modal-content>
        <p>
            This form is not complete. There are {{ totalRequiredRemaining }} remaining. Save and
            close form?
        </p>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Save Only"
            type="secondary"
            class="close"
            (onclick)="saveInForm()"></itc-button>
        <itc-button
            label="Save and Return"
            type="primary"
            class="approve"
            (onclick)="saveAndCloseInForm()"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Previous Entries" class="fs" #previousModal>
    <modal-content>
        <div style="width: calc(100% - 2px)">
            <p>The table below shows the answers supplied in the previous assessment.</p>
            <wj-flex-grid
                #previousFlex
                [isReadOnly]="true"
                [itemsSource]="previousTableData"
                (loadedRows)="resizeFlexRows(previousFlex)">
                <wj-flex-grid-column
                    *ngFor="let col of previousColumns"
                    [header]="col.header"
                    [dataMap]="col.showDropDown"
                    [binding]="col.colId"
                    [width]="'*'"
                    [wordWrap]="true"></wj-flex-grid-column>
            </wj-flex-grid>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button label="Close" type="secondary" class="cancel"></itc-button>
        <itc-button
            label="Use Previous Responses"
            type="primary"
            class="approve"
            *ngIf="!survey?.IsReadOnly"
            (onclick)="copyPreviousTable()"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Use All Previous Responses" class="xs" #previousResponseWarningModal>
    <modal-content>
        <p>
            This option will automatically enter your previous response, except for worksheet
            tables, and checklists which may change with new scan data.
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            class="approve"
            (onclick)="copyAllPreviousResponses()"
            *ngIf="hasPrevious && !survey?.IsReadOnly">
            Use All Previous Responses
        </itc-button>
    </modal-actions>
</sm-modal>

<ng-template #saveButton >
    <itc-button *ngIf='!isInArchived'
        label="Save"
        type="primary"
        [disabled]="survey?.IsLocked"
        [loading]="isSaving"
        (onclick)="saveInForm()"></itc-button>
</ng-template>

<ng-template #saveReturnButton>
    <itc-button *ngIf='!isInArchived'
        label="Save and Return"
        type="primary"
        [disabled]="survey?.IsLocked"
        [loading]="isSaving"
        (onclick)="checkRequiredBeforeClosing()"></itc-button>
</ng-template>

<ng-template #returnButton>
    <itc-button *ngIf='!isInArchived'
        label="Return"
        type="primary"
        [disabled]="survey?.IsLocked"
        [loading]="isSaving"
        (onclick)="closeInFormAndReturn()"></itc-button>
</ng-template>
<sds-invite-sme (loadedCallback)="inviteModalLoading(false)" #inviteSME></sds-invite-sme>

</sds-loader>
