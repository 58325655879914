import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { DateService } from 'app/shared/date.service';
import { PrimeNGDateFormattingPipe } from 'app/shared/primeNG/date-formatting.pipe';
import { Calendar, CalendarModule } from 'primeng/calendar';

@Component({
    selector: 'range-calendar',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        PrimeNGDateFormattingPipe,
    ],
    template: `
        <p-calendar
            [(ngModel)]="tempValue"
            inputId="dateRangeCalendar"
            selectionMode="range"
            (onClose)="calendarClosed()"
            (onSelect)="checkCalendarClose()"
            (onShow)="processCalendar()"
            (onMonthChange)="processCalendar()"
            (onYearChange)="processCalendar()"
            (onInput)="processCalendar()"
            [dateFormat]="dateFormat$ | async | primeNGDateFormat"
            panelStyleClass="rangeCalendar"
            [maxDate]="maxDate"
            [style]="{ width: '210px' }"
            appendTo="body"
            #dateRange>
            <ng-template pTemplate="date" let-dayInfo>
                <span
                    [attr.data-day]="dayInfo.day"
                    [attr.data-month]="dayInfo.month"
                    [attr.data-year]="dayInfo.year">
                    {{ dayInfo.day }}
                </span>
            </ng-template>
        </p-calendar>
    `,
    styles: [
        `
            ::ng-deep {
                .p-datepicker.rangeCalendar {
                    table td {
                        padding: 0;
                        & > span {
                            margin: 2px 0;
                            flex: 1;
                            width: 32px;
                            height: 32px;
                            &.p-disabled {
                                background-color: unset;
                            }
                        }
                        &:has(span.p-first-range-highlight:not(.p-disabled)) {
                            position: relative;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 2px;
                                bottom: 2px;
                                left: 50%;
                                width: 50%;
                                height: calc(100% - 4px);
                                background-color: #ffcbab;
                            }
                        }
                        &:has(span.p-last-range-highlight:not(.p-disabled)) {
                            position: relative;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 2px;
                                bottom: 2px;
                                right: 50%;
                                width: 50%;
                                height: calc(100% - 4px);
                                background-color: #ffcbab;
                            }
                        }
                    }
                    .p-highlight {
                        &.p-in-range-highlight {
                            background-color: #ffcbab;
                            color: var(--text-primary);
                            border-radius: 0;
                        }
                        & > span:hover {
                            background: transparent !important;
                        }
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RangeCalendarComponent),
        },
    ],
})
export class RangeCalendarComponent implements ControlValueAccessor {
    private dateService = inject(DateService);
    private cdr = inject(ChangeDetectorRef);

    @Input() maxDate = new Date();
    @Output() onClose: EventEmitter<Date[]> = new EventEmitter();

    dateFormat$ = this.dateService.getDateFormat();

    @ViewChild('dateRange') pCalendar: Calendar;

    checkCalendarClose() {
        if (this.pCalendar.value[0] && this.pCalendar.value[1]) {
            this.pCalendar.hideOverlay();
            this.removeClasses();
        } else {
            this.processCalendar();
        }
    }

    processCalendar() {
        setTimeout(() => {
            let dateRange = this.pCalendar.value;
            this.removeClasses();
            // make them all in range
            document.querySelectorAll('.p-highlight').forEach((el) => {
                el.classList.add('p-in-range-highlight');
            });
            let startSelector: string;
            let startDate: HTMLSpanElement;
            let endSelector: string;
            let endDate: HTMLSpanElement;

            // figure out first and last
            if (dateRange[0]) {
                startSelector = `.p-datepicker-calendar span[data-day="${dateRange[0].getDate()}"][data-month="${dateRange[0].getMonth()}"][data-year="${dateRange[0].getFullYear()}"]`;
                startDate = document.querySelector(startSelector);
            }
            if (dateRange[1]) {
                endSelector = `.p-datepicker-calendar span[data-day="${dateRange[1].getDate()}"][data-month="${dateRange[1].getMonth()}"][data-year="${dateRange[1].getFullYear()}"]`;
                endDate = document.querySelector(endSelector);
            }
            if (startDate) {
                if (endDate) {
                    startDate.parentElement.classList.add('p-first-range-highlight');
                }
                startDate.parentElement.classList.remove('p-in-range-highlight');
            }
            if (endDate) {
                endDate.parentElement.classList.add('p-last-range-highlight');
                endDate.parentElement.classList.remove('p-in-range-highlight');
            }
        }, 0);
    }

    calendarClosed() {
        if (this.tempValue && this.tempValue[1] === null) {
            this.tempValue[1] = new Date(this.tempValue[0]);
            this.tempValue[1].setHours(23, 59, 59, 999);
        }
        this.value = [...this.tempValue];
        this.onClose.emit([...this.value]);
    }

    removeClasses() {
        document
            .querySelectorAll(
                '.p-first-range-highlight, .p-last-range-highlight, .p-inrange-highlight'
            )
            .forEach((el) => {
                el.classList.remove(
                    'p-first-range-highlight',
                    'p-last-range-highlight',
                    'p-inrange-highlight'
                );
            });
    }

    tempValue: Date[];

    /* Stuff to use either ngModel or FormControl */
    innerValue: Date[]; // inner variable that you want to call it
    set value(value: Date[]) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): Date[] {
        return this.innerValue;
    }

    setInnerValue(value: Date[]) {
        this.innerValue = value;
        this.onChange(value);
        this.onTouch();
    }

    onChange = (value: Date[]) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: Date[]): void {
        if (value !== this.innerValue) {
            this.tempValue = value;
            this.innerValue = value;
            this.cdr.markForCheck();
        }
    }
}
