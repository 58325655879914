<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader"><h1>Roles</h1></header>

<sds-loader [complete]="loadingComplete">
    <div class="roleOuterContainer">
        <ng-container *ngFor="let role of roles">
            <div class="roleContainer" *ngIf="role.enabled">
                <ng-container
                    *ngTemplateOutlet="
                        userContainer;
                        context: {
                            $implicit: role,
                        }
                    "></ng-container>
            </div>
        </ng-container>
    </div>
    <div class="roleOuterContainer">
        <ng-container *ngFor="let role of otherRoles">
            <div class="roleContainer" *ngIf="role.enabled">
                <ng-container
                    *ngTemplateOutlet="
                        userContainer;
                        context: {
                            $implicit: role,
                        }
                    "></ng-container>
            </div>
        </ng-container>
    </div>
</sds-loader>

<ng-template #userContainer let-role>
    <header>
        <h2>{{ role.name }}</h2>
        <itc-button type="primary" class="right floated" (onclick)="showModal(role)" icon="fa-plus">
            Add User
        </itc-button>
    </header>
    <div class="users">
        <div *ngIf="role.users.length == 0" class="userItem">
            <p>No Users</p>
        </div>
        <div *ngFor="let user of role.users" class="userItem">
            <p truncateText truncateWidth="calc(100% - 80px)">
                <fa-icon
                    icon="globe"
                    *ngIf="user.GlobalRoleId === 1 || user.GlobalRoleId === 3"
                    tooltip="This user has a Global Access Level that grants administrator privileges regardless of any assigned Site Roles.">
                    (Global Admin)
                </fa-icon>
                {{ user.Username }}
            </p>
            <div *ngIf="orgLevelDiscoveryAgents && isSuperUser && role.showDAToggle">
                <itc-toggle
                    [on]="user.OrgRole.indexOf('DISCOVERY_AGENT') > -1"
                    (onChanged)="toggleUserInOrgRole(user, 'DISCOVERY_AGENT', $event)"
                    tooltip="{{
                        (user.OrgRole.indexOf('DISCOVERY_AGENT') > -1 ? 'Dis' : 'En') +
                            'able Discovery Agent Access' +
                            (overrideDA
                                ? '. All users currently have access to Discovery Agents through a global setting in the Users page.'
                                : '')
                    }}"></itc-toggle>
            </div>
            <itc-button
                type="icon"
                icon="fa-trash-can"
                (onclick)="removeUserFromRole(user, role.key)"
                tooltip="Delete"></itc-button>
        </div>
    </div>
</ng-template>

<sm-modal
    title="Add User(s) as '{{
        site?.Type === 'CM2' && selectedRole?.name === 'Client View'
            ? 'Reports Viewer'
            : selectedRole?.name
    }}'"
    class="sm addUserToRoleModal"
    #modal>
    <modal-content>
        <p>Select users to add</p>
        <form name="addUsersToRoleForm">
            <div>
                <div style="display: inline-flex; flex-direction: column; width: 100%">
                    <itc-checkbox
                        style="display: inline-block; margin-bottom: 8px"
                        *ngFor="let user of availableUsers; let i = index"
                        [control]="fcs[i]"
                        [name]="'fcs' + i"
                        (onChanged)="validateForm()"
                        [label]="user.Username"
                        [tooltip]="getLabelTitle(user, selectedRole)"
                        ttPosition="bottom-right"></itc-checkbox>
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            [disabled]="!validForm"
            (onclick)="addSelectedUsersToSelectedRole()">
            Add
        </itc-button>
    </modal-actions>
</sm-modal>
