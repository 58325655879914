import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'itc-quick-filter',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './quick-filter.component.html',
    styleUrls: ['./quick-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuickFilterComponent implements OnInit, OnDestroy {
    @Input() label: string;
    @Input() count: number;
    @Input() control: FormControl<boolean>;
    @Input() checked: boolean;
    @Output() onChange = new EventEmitter<boolean>();
    private readonly ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            this.writeValue(value);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    writeValue(value: boolean) {
        this.checked = value;
    }

    handleClick() {
        this.writeValue(!this.checked);
        if (this.control) {
            this.control.setValue(this.checked);
            this.control.markAsTouched();
            this.control.markAsDirty();
        }

        this.onChange.emit(this.checked);
    }
}
