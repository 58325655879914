<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<div class="discovery-agent page">
    <ng-container *ngIf="discoveryAgentSidebarData$ | async as discoveryAgentSidebarData; else loading">
        <header class="pageHeader">
            <div class="title-container">
                <h1>Organization Discovery Agents</h1>
                <div class="title-button-container">
                    <itc-button *ngIf="installKey$ | async as installKey" type="secondary"
                                icon="fa-copy"
                                iconPlacement="after"
                                (onclick)="copy(installKey)">
                        <b>Install Key: </b>
                        {{ installKey }}
                    </itc-button>
                    <itc-button type="primary" (onclick)="scanScheduleSidebar.open()">
                        Configure Install Key/Scan Schedule
                    </itc-button>

                    <sds-discovery-agent-scan-schedule-sidebar
                                                 [organizationId]="(organization$ | async).Id"
                                                 [discoveryAgentSidebarData]="discoveryAgentSidebarData"
                                                 [hasAnyAgentsInstalled]="hasDiscoveryAgents"
                                                 #sidebar
                    ></sds-discovery-agent-scan-schedule-sidebar>
                </div>
            </div>
        </header>

        <ng-container *ngIf="allChartData$ | async as allChartData; else loading">
            <div class="cards-container">
                <sds-da-last-scan-result-card [scanResultData]="allChartData.scanResultData">
                </sds-da-last-scan-result-card>
                <sds-da-appliance-status-card [applianceStatusData]="allChartData.applianceStatusData">
                </sds-da-appliance-status-card>
                <!-- Removed temporarily. Will be added back in with Compliance Monitor Update
                <sds-da-compliance-monitor-card [complianceMonitorData]="allChartData.complianceMonitorData">
                </sds-da-compliance-monitor-card>
                -->
            </div>

            <div *ngIf="appliancesCountByQuickFilter$ | async as appliancesCountByFilter; else loading"
                 class="table-container">
                <sds-discovery-agent-quick-filter
                    [appliancesCountByFilter]="appliancesCountByFilter"></sds-discovery-agent-quick-filter>
                <sds-discovery-agent-text-filter></sds-discovery-agent-text-filter>
                <sds-discovery-agent-table [organization]="(organization$ | async)" (hasDiscoveryAgents)="setHasDiscoveryAgents($event)"></sds-discovery-agent-table>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #loading>
        <sm-loader class="inverted"></sm-loader>
    </ng-template>
</div>
