import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceItem } from '../resources.model';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RFT_URL } from 'app/core/constants';
import { TruncateTextDirective } from 'app/shared/truncateText.directive';

@Component({
    selector: 'resources-card',
    standalone: true,
    imports: [CommonModule, TruncateTextDirective, NgOptimizedImage],
    template: `
        <div class="card {{ resource.class }}">
            <div class="card-image">
                <img
                    [ngSrc]="getResourceIcon(resource)"
                    [alt]="resource.title"
                    [width]="resource.class === 'poweredServices' ? 220 : 140"
                    [height]="resource.class === 'poweredServices' ? 70 : 180" />
            </div>
            <div class="card-body">
                <p class="card-type">{{ resource.type }}</p>
                <p class="card-title" truncateText truncateLines="2">{{ resource.title }}</p>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                .card {
                    width: 220px;
                    box-shadow: var(--elevation-xs);
                    cursor: pointer;
                    &:hover {
                        box-shadow: var(--elevation-sm);
                    }

                    .card-image {
                        background-color: var(--background-secondary);
                        text-align: center;
                        width: 220px;
                        height: 180px;
                    }

                    &.poweredServices {
                        .card-image {
                            height: 70px;
                        }
                    }

                    .card-body {
                        padding: 8px 16px 16px;
                        font-size: 14px;
                        line-height: 22px;
                        .card-type {
                            font-weight: 400;
                            color: var(--text-secondary);
                            margin-bottom: 8px;
                        }
                        .card-title {
                            font-weight: 500;
                            color: var(--text-primary);
                            margin-bottom: 0;
                            height: 42px;
                        }
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesCardComponent {
    @Input() resource: ResourceItem;

    getResourceIcon(resource: ResourceItem): string {
        return `${RFT_URL}/data/resource-images/${resource.icon}`;
    }
}
