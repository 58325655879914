import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ExportService } from 'app/core/export.service';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingService } from 'app/settings/shared/setting.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { TZINFOS } from '../general/tzinfos';
import { UiService } from 'app/core/ui.service';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { Subject, takeUntil } from 'rxjs';
import { Organization } from 'app/organizations/organization.model';

@Component({
    selector: 'sds-data-collectors',
    templateUrl: './data-collectors.component.html',
    styleUrls: ['./data-collectors.component.css'],
})
export class DataCollectorsComponent implements OnInit, OnDestroy {
    constructor(
        private datePipe: DatePipe,
        private exportService: ExportService,
        private router: Router,
        private settingService: SettingService,
        private siteService: SiteService,
        private siteSettingService: SiteSettingService,
        private uiService: UiService,
        private organizationsService: OrganizationsService
    ) {}

    @ViewChild('dataCollectorsTable', { read: ElementRef }) dataCollectorsTable: ElementRef;
    @ViewChild('dcManager', { static: true }) dcManager;

    allOrganizations: Organization[] = [];
    dataCollectors: any[] = [];
    filtered1: any[] = [];
    filteredCollectors: any[] = [];
    filterCtrl = new UntypedFormControl();
    sortedCollectors: any[] = [];
    pagedCollectors: any[] = [];
    errorCollectors: any[] = []; //this contains any data collectors that throw a 404 error when trying to get the tasks
    count: number = 0;
    tracker: number = 0;
    exporting: boolean = false;
    ngUnsubscribe$: Subject<any> = new Subject();
    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        RDC: 'Remote Data Collector',
        KVS: 'Vulnerability Scanner',
        AGT: 'Discovery Agent',
    };

    pageSize: number = 20;
    sizes: any = [10, 20, 50, 100];
    pageNumber: number = 0;
    initPage: boolean;
    accountwideTypes: any = {};

    timeDateFormat: string;
    timezone: string;

    siteCtrl: UntypedFormControl = new UntypedFormControl();
    dcSites: any[];

    selectedSiteType: Site;
    selectedAppliance: any;

    loadingComplete: boolean = false;

    allSites: any[]; //This only contains the sites that appear on the Sites page.

    breadcrumbs = [
        { path: '..', text: 'Admin' },
        { path: '.', text: 'Data Collectors' },
    ];

    ngOnInit() {
        this.uiService.setTitle('Data Collectors');
        this.filterCtrl.setValue('all');
        this.subscribeAllOrganization();
        this.settingService.getSettings().then((settings) => this.getTDFormat(settings));
        this.organizationsService.getAllOrganizations();
    }

    subscribeAllOrganization() {
        this.organizationsService
            .getOrganizationsObs()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((orgs) => {
                if (orgs) {
                    this.allOrganizations = orgs;
                    this.allSites = [];
                    for (let org of orgs) {
                        this.allSites.push(...org.Sites);
                    }
                    this.getDataCollectors(this.allSites);
                }
            });
    }

    finishLoading() {
        this.settingService.getSettings().then((settings) => this.convertTimeZone(settings));
        this.count = this.dataCollectors.length;
        this.initPage = true;
        this.filterCollectors();
        this.loadingComplete = true;
    }

    ngDoCheck() {
        if (this.loadingComplete) {
            this.count = this.filteredCollectors.length;
        }
    }

    getDataCollectors(allSites: any[]) {
        this.siteSettingService
            .getApplianceInfoForReal()
            .then((dataCollectors) => {
                for (let i = 0; i < dataCollectors.length; i++) {
                    let info = dataCollectors[i];
                    let dcTypes = new Set<string>();
                    for (let j = 0; j < info.Types.length; j++) {
                        if (info.Types[j] === 'KVS') {
                            if (info.KvsType === 'EVS') {
                                dcTypes.add('External Vulnerability Scanner');
                            } else if (info.KvsType === 'PVS') {
                                dcTypes.add('Portable Vulnerability Scanner');
                                info.IsVirtual = true;
                            } else {
                                dcTypes.add('Internal Vulnerability Scanner');
                            }
                        }
                        else {
                            dcTypes.add(this.friendlyTypes[info.Types[j]]);
                        }
                        if (!this.accountwideTypes[this.friendlyTypes[info.Types[j]]])
                            this.accountwideTypes[this.friendlyTypes[info.Types[j]]] = true;
                    }

                    let lastCheckin = this.dateProcess(info.LastCheckin);
                    //use to replace the table value with 'Never' for the ones that haven't checked in without changing the LastCheckin value to a string. Makes sorting Dates easier.
                    let hasCheckedIn: boolean = lastCheckin.getFullYear() < 2000 ? false : true;

                    let isAvailable = false;

                    let site: Site;

                    if (!info.Types.includes('AGT')) {
                        for (let i = 0; i < allSites.length; i++) {
                            if (allSites[i].Name == info.SiteName) {
                                isAvailable = true;
                                site = allSites[i];
                                break;
                            }
                        }
                    } else {
                        let organizaion = this.allOrganizations.find(
                            (org) => org.Name === info.OrganizationName
                        );
                        if (organizaion) {
                            isAvailable = true;
                        }
                    }

                    let isDuplicate = false;

                    //creates an array of the associated sites to more easily display them in the table and also assign a site's availability for when routing to it later.
                    for (let collector of this.dataCollectors) {
                        if (collector.Id == info.Id) {
                            //if the Id already exists, then it is a duplicate and should not be created again
                            isDuplicate = true;
                            collector.SiteName.push({
                                Name: info.SiteName,
                                IsAvailable: isAvailable,
                                site: site,
                            });
                            collector.SiteSearchString += ' ,' + info.SiteName;
                            if (!collector.IsAvailable && isAvailable) {
                                //used to determine if the manage icon should be available.
                                collector.IsAvailable = isAvailable;
                            }
                            break;
                        }
                    }

                    if (!isDuplicate) {
                        //stops the creation of a new one of the ID already exists
                        if (dcTypes) {
                            //any inspectors that are not typed are excluded.
                            this.dataCollectors.push({
                                Id: info.Id,
                                Description: info.Description ? info.Description : '',
                                Type: info.IsServer
                                    ? 'Server'
                                    : info.IsVirtual
                                        ? 'Virtual'
                                        : 'Physical',
                                DCType: [...dcTypes].join(', '),
                                SiteName: [
                                    {
                                        Name: info.SiteName,
                                        IsAvailable: isAvailable,
                                        site: site,
                                    },
                                ],
                                Organization: info.OrganizationName,
                                Activated:
                                    info.ActivationDate.length > 0 ? 'Activated' : 'Not Activated',
                                RunningTasks: info.RunningTasks,
                                QueuedTasks: info.QueuedTasks,
                                UpdateStatus: info.UpdateStatus ? 'Current' : 'Updates Available',
                                LastCheckin: lastCheckin, //used for the base time for conversion and also for sorting
                                TimezoneAdjustedTime: lastCheckin, //used to display the timezone converted time
                                HasCheckedIn: hasCheckedIn,
                                Status: info.IsOnline ? 'Online' : 'Offline',
                                IsAvailable: isAvailable,
                                //the filter requires a string in [fields], and directly accessing the site names for it is difficult through SiteName,
                                //so all sites are put into SiteSearchString and is used for the filter.
                                SiteSearchString: info.SiteName,
                            });
                        }
                    }
                }
            })
            .then(() => {
                this.finishLoading();
            });
    }

    filterCollectors(filteredData?: any) {
        if (filteredData) {
            this.filteredCollectors = filteredData;
        } else {
            this.filteredCollectors = this.dataCollectors;
        }
        if (this.filterCtrl.value != 'all') {
            this.filteredCollectors = this.checkFilter();
        }
        this.filtered1 = this.filteredCollectors;
        this.sortCollectors();
    }

    sortCollectors(sorting?: TableSorting) {
        if (!sorting) {
            this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
            );
        } else {
            switch (sorting.sortColumn) {
                case 'status':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.Status.toLowerCase().localeCompare(b.Status.toLowerCase())
                    );
                    break;
                case 'type':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.Type.toLowerCase().localeCompare(b.Type.toLowerCase())
                    );
                    break;
                case 'dcType':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.DCType.split(',')[0]
                            .toLowerCase()
                            .localeCompare(b.DCType.split(',')[0].toLowerCase())
                    );
                    break;
                case 'organization':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.Organization.toLowerCase().localeCompare(b.Organization.toLowerCase())
                    );
                    break;
                case 'activated':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.Activated.toLowerCase().localeCompare(b.Activated.toLowerCase())
                    );
                    break;
                case 'runningTasks':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.RunningTasks > b.RunningTasks
                            ? 1
                            : a.RunningTasks < b.RunningTasks
                            ? -1
                            : 0
                    );
                    break;
                case 'queuedTasks':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.QueuedTasks > b.QueuedTasks ? 1 : a.QueuedTasks < b.QueuedTasks ? -1 : 0
                    );
                    break;
                case 'updateStatus':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.UpdateStatus.toLowerCase().localeCompare(b.UpdateStatus.toLowerCase())
                    );
                    break;
                case 'checkin':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.LastCheckin > b.LastCheckin ? 1 : a.LastCheckin < b.LastCheckin ? -1 : 0
                    );
                    break;
                case 'manage':
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.IsAvailable > b.IsAvailable ? 1 : a.IsAvailable < b.IsAvailable ? -1 : 0
                    );
                    break;
                case 'desc': //description
                    let tempSort: any;
                    if (sorting.sortDirection == 'desc')
                        tempSort = this.filteredCollectors.sort((a, b) =>
                            a.Description == '' ? -1 : 1
                        );
                    //sort empty vs not empty first
                    else
                        tempSort = this.filteredCollectors.sort((a, b) =>
                            a.Description == '' ? 1 : -1
                        );
                    tempSort.sort((a, b) =>
                        a.Description == ''
                            ? 0
                            : a.Description.toLowerCase().localeCompare(b.Description.toLowerCase())
                    ); //sort ignoring empty
                    this.sortedCollectors = tempSort;
                    break;
                default:
                    this.sortedCollectors = this.filteredCollectors.sort((a, b) =>
                        a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc') {
                //descending
                this.sortedCollectors.reverse();
            }

            this.filteredCollectors = [...this.sortedCollectors];
        }
    }

    checkFilter() {
        let filtered: any[] = [];
        for (let dc of this.filteredCollectors) {
            if (dc.DCType.includes(this.filterCtrl.value)) {
                filtered.push(dc);
            }
        }
        return filtered;
    }

    showSelectSite(dc: any) {
        //populate the options with only sites that are available
        this.dcSites = [];
        this.selectedAppliance = dc;
        for (let option of dc.SiteName) {
            if (option.IsAvailable) this.dcSites.push(option);
        }

        if (dc.SiteName[0].Name == '') {
            //set organization
            this.organizationsService.setSelectedOrganization(dc.Organization);
            //if siteName is empty, then the data collector is not assigned to any site
            this.router.navigate([
                '/organizations',
                dc.Organization.replace(/&/g, '%26'),
                'discovery-agents',
            ]);
        } else {
            if (dc.SiteName.length == 1) {
                //navigate to the site if there is only one site for the data collector
                if (this.siteService.isComplianceManagerGRC(dc.SiteName[0].site)) {
                    this.router.navigate([
                        'site',
                        dc.SiteName[0].Name.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                    ]);
                } else if (this.siteService.isKVS(dc.SiteName[0].site)) {
                    if (dc.DCType.includes('External Vulnerability Scanner')) {
                        this.router.navigate([
                            'site',
                            dc.SiteName[0].Name.replace(/&/g, '%26'),
                            'home',
                            'data-collectors',
                            'external',
                        ]);
                    } else if (dc.DCType.includes('Discovery Agent')) {
                        this.router.navigate([
                            'site',
                            dc.SiteName[0].Name.replace(/&/g, '%26'),
                            'home',
                            'data-collectors',
                            'discovery-agent',
                        ]);
                    } else if (dc.DCType.includes('Reporter')) {
                        this.router.navigate([
                            'site',
                            dc.SiteName[0].Name.replace(/&/g, '%26'),
                            'home',
                            'data-collectors',
                            'reporter',
                        ]);
                    } else {
                        this.router.navigate([
                            'site',
                            dc.SiteName[0].Name.replace(/&/g, '%26'),
                            'home',
                            'data-collectors',
                            'internal',
                        ]);
                    }
                } else {
                    this.router.navigate([
                        'site',
                        dc.SiteName[0].Name.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                    ]);
                }
            } else {
                this.siteCtrl.setValue('Select Site');
                this.dcManager.show();
            }
        }
    }
    siteSelected() {
        this.selectedSiteType = this.allSites.find((a) => {
            return a.Name == this.siteCtrl.value;
        });
    }

    toDataCollector() {
        this.dcManager.hide();

        if (
            this.selectedSiteType &&
            this.siteService.isComplianceManagerGRC(this.selectedSiteType)
        ) {
            this.router.navigate([
                'site',
                this.siteCtrl.value.replace(/&/g, '%26'),
                'home',
                'data-collectors',
            ]);
        } else {
            if (this.siteService.isKVS(this.selectedSiteType)) {
                if (this.selectedAppliance?.DCType == 'External Vulnerability Scanner') {
                    this.router.navigate([
                        'site',
                        this.siteCtrl.value.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                        'external',
                    ]);
                } else if (this.selectedAppliance?.DCType == 'Discovery Agent') {
                    this.router.navigate([
                        'site',
                        this.siteCtrl.value.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                        'discovery-agent',
                    ]);
                } else if (this.selectedAppliance?.DCType == 'Reporter') {
                    this.router.navigate([
                        'site',
                        this.siteCtrl.value.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                        'reporter',
                    ]);
                } else {
                    this.router.navigate([
                        'site',
                        this.siteCtrl.value.replace(/&/g, '%26'),
                        'home',
                        'data-collectors',
                        'internal',
                    ]);
                }
            } else {
                this.router.navigate([
                    'site',
                    this.siteCtrl.value.replace(/&/g, '%26'),
                    'home',
                    'data-collectors',
                ]);
            }
        }
    }

    closeDcManager() {
        this.dcManager.hide();
    }

    exportTable(fileName: string) {
        this.exporting = true;
        this.exportService.DCtableExport(this.dataCollectorsTable, fileName, 'csv');
        this.exporting = false;
    }

    getTDFormat(settings: any) {
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                this.timeDateFormat = s.Value + ' hh:mm:ss a';
            }
        }
    }

    dateProcess(date: string) {
        //The time from info.LastCheckin is in UTC, but for some reason, using it to create a new Date() will keep the same exact time, but as the locale time.
        // i.e., 6:00 UTC becomes 6:00 EST. Use Date.UTC() to explicitly make info.LastCheckin UTC and then it will convert to the locale time.
        let dateString = new String(date);
        let dateAndTime: any[] = dateString.split('T'); //info.LastCheckin's format is YYYY-MM-DDThh:mm:ss. Split at T
        let checkinDate: number[] = dateAndTime[0].split('-'); //split up the date
        let checkinTime: number[] = dateAndTime[1].split(':'); //split up the time
        return new Date(
            Date.UTC(
                checkinDate[0],
                checkinDate[1] - 1,
                checkinDate[2],
                checkinTime[0],
                checkinTime[1],
                checkinTime[2]
            )
        ); //Date.UTC has months run from 0 to 11
    }

    convertTimeZone(settings: any) {
        let settingsTZ = '';
        for (let s of settings) {
            if (s.Name == 'TZINFO') {
                settingsTZ = s.Value;
            }
        }
        let tzRef: any;

        let supportsDST = false;
        for (let tz of TZINFOS) {
            if (tz.Id == settingsTZ) {
                tzRef = tz;
                supportsDST = tz.SupportsDaylightSavingTime;
            }
        }
        let offset = tzRef?.BaseUtcOffset.slice(0, -3); // timezone offset format is originally '-00:00:00' or '00:00:00'. this removes the last ':00'
        if (offset) {
            offset[0] == '-' ? offset : (offset = '+' + offset); //the GMT offset requires a - or +, so this adds the + for positive offsets
        }
        if (supportsDST) {
            //let testDate = new Date();
            for (let dc of this.dataCollectors) {
                if (this.checkDSTCalendar(dc.LastCheckin, tzRef)) {
                    //check to see if the date is during DST
                    if (settingsTZ == 'Lord Howe Standard Time') {
                        dc.TimezoneAdjustedTime = this.datePipe.transform(
                            dc.LastCheckin,
                            this.timeDateFormat,
                            '+11:00'
                        );
                    } else {
                        let newOffset;
                        let posOrNeg = offset[0] == '-' ? offset[0] : '+'; //take the + or - from the offset string
                        let temp =
                            posOrNeg == '-'
                                ? parseInt(offset.slice(1, 3)) - 1
                                : parseInt(offset.slice(1, 3)) + 1; //get the first two digits from the format -00:00, convert to a number, and +- 1.
                        let end = offset.slice(3); //take the last three parts of -00:00
                        newOffset = posOrNeg + temp + end; //piece it all together
                        dc.TimezoneAdjustedTime = this.datePipe.transform(
                            dc.LastCheckin,
                            this.timeDateFormat,
                            newOffset
                        );
                    }
                } else {
                    //If the date is not during DST
                    dc.TimezoneAdjustedTime = this.datePipe.transform(
                        dc.LastCheckin,
                        this.timeDateFormat,
                        offset
                    );
                }
            }
        } else {
            for (let dc of this.dataCollectors) {
                dc.TimezoneAdjustedTime = this.datePipe.transform(
                    dc.LastCheckin,
                    this.timeDateFormat,
                    offset
                );
            }
        }
    }

    checkDSTCalendar(date: Date, timezone: any) {
        //first acquire the rules that govern daylight saving time. start/end week either corresponds to the week number or the ordinal number of the day of the week (i.e. 2nd Sunday)
        let startMonth: number, startWeek: number, startDayofWeek: number;
        let endMonth: number, endWeek: number, endDayofWeek: number;
        for (let rule of timezone.AdjustmentRules) {
            if (rule.DateEnd.startsWith('9999')) {
                //save the Month, Week, and DayOfWeek that Daylight Savings begins
                startMonth = rule.DaylightTransitionStart.Month;
                startWeek = rule.DaylightTransitionStart.Week;
                startDayofWeek = rule.DaylightTransitionStart.DayOfWeek;
                //save the Month, Week, and DayOfWeek that Daylight Savings ends
                endMonth = rule.DaylightTransitionEnd.Month;
                endWeek = rule.DaylightTransitionEnd.Week;
                endDayofWeek = rule.DaylightTransitionEnd.DayOfWeek;
            }
        }
        //check to see if the month falls between the start and end months for DST
        if (startMonth < date.getMonth() + 1 && date.getMonth() + 1 < endMonth) {
            return true;
        }
        //if the date is in the starting month
        else if (date.getMonth() + 1 == startMonth) {
            let DSTStartDate: Date;
            let count = 0; //this goes up every time the start day of the week is reached, so it goes up each week.
            let year = new Date().getFullYear();
            let dayTracker: Date;
            for (let day = 1; day <= 31; day++) {
                let testDate = new Date(year, startMonth - 1, day);
                if (startMonth != testDate.getMonth()) {
                    //for months without 31 days, the date will roll over to the next month, so terminate the loop  if the month changes.
                    break;
                }
                if (testDate.getDay() == startDayofWeek) {
                    count++;
                    dayTracker = testDate; //keep track of each date that day is on.
                }
                //this checks for the ordinal number of the day of the week, i.e. 2nd Sunday.
                //the week corresponds to the ordinal number of the day of the week. 5th week would just be the last of that day.
                if (count == startWeek) {
                    //loop will terminate if the day is hit. It will finish if the specified is the 5th day and there is no 5th that year. The last was stored already.
                    break;
                }
            }
            DSTStartDate = dayTracker;
            if (date >= DSTStartDate) {
                return true;
            } else {
                return false;
            }
        }

        //if the date is in the ending month
        else if (date.getMonth() + 1 == endMonth) {
            let DSTEndDate: Date;
            let count = 0;
            let year = new Date().getFullYear();
            let dayTracker: Date;
            for (let day = 1; day <= 31; day++) {
                let testDate = new Date(year, endMonth - 1, day);
                if (startMonth != testDate.getMonth()) {
                    //for months without 31 days, the date will roll over to the next month, so terminate the loop if the month changes.
                    break;
                }
                if (testDate.getDay() == startDayofWeek) {
                    count++;
                    dayTracker = testDate;
                }
                if (count == endWeek) {
                    break;
                }
            }
            DSTEndDate = dayTracker;
            if (date >= DSTEndDate) {
                return false;
            } else {
                return true;
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
        this.organizationsService.resetObs();
    }
}
