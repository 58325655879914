<div class="discovery-agent-quick-filter">
    <itc-quick-filter label="Online"
                      [count]="appliancesCountByFilter.Online"
                      [control]="selectedFilterGroup.controls.online">
    </itc-quick-filter>
    <itc-quick-filter label="Offline"
                      [count]="appliancesCountByFilter.Offline"
                      [control]="selectedFilterGroup.controls.offline">
    </itc-quick-filter>
    <itc-quick-filter label="Update Available"
                      [count]="appliancesCountByFilter.UpdateAvailable"
                      [control]="selectedFilterGroup.controls.updateAvailable">
    </itc-quick-filter>
    <!-- Moved out of the scope of the epic. Will be added later.
    <div style="font-weight: 500">
        Compliance Monitor:
    </div>
    <itc-quick-filter label="Enabled"
                      [count]="appliancesCountByFilter.ComplianceMonitorEnabled"
                      [control]="selectedFilterGroup.controls.CMoEnabled">
    </itc-quick-filter>
    <itc-quick-filter label="Not Enabled"
                      [count]="appliancesCountByFilter.ComplianceMonitorDisabled"
                      [control]="selectedFilterGroup.controls.CMoDisabled">
    </itc-quick-filter>
    <itc-quick-filter label="Not Supported"
                      [count]="appliancesCountByFilter.ComplianceMonitorNotSupported"
                      [control]="selectedFilterGroup.controls.CMoNotSupported">
    </itc-quick-filter>
    -->
</div>