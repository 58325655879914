<sds-loader [complete]="loadingComplete">
    <header class="pageHeader" style="margin-top: 8px">
        <h1>Audit Log</h1>
        <span *ngIf="audits" class="circleLabelCount">{{ filteredAudits?.length }}</span>
        <sds-audit-search-filter
            [audits]="audits"
            (onFiltered)="filteredAudits = $event; sortAudits()"></sds-audit-search-filter>
        <div class="flex-right" style="align-self: flex-end">
            <itc-button
                label="Download Log"
                color="white"
                type="primary"
                icon="fa-download"
                style="white-space: nowrap"
                [loading]="exporting"
                (onclick)="exportTable(site.Name + ' - AuditLog')"></itc-button>
        </div>
    </header>

    <div class="fullWidthContent" style="clear: both; overflow-x: auto; padding-bottom: 0">
        <sds-audit-table [audits]="pagedAudits" (sortBy)="processSort($event)"></sds-audit-table>
        <sds-audit-table [audits]="sortedAudits" #AuditLogTable hidden="hidden"></sds-audit-table>
    </div>
    <itc-paginator
        [data]="filteredAudits"
        (onPage)="pagedAudits = $event"
        [hidden]="!filteredAudits?.length"></itc-paginator>
    <empty-state
        icon="results"
        *ngIf="!filteredAudits?.length"
        title="No Audit Events Found"
        text="No audit events were found matching the given criteria."></empty-state>
</sds-loader>
