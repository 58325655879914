import { Component, ComponentRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { Connection } from 'app/connections/connection.model';
import { ConnectionService } from 'app/connections/connection.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { Site } from 'app/sites/shared/site.model';
import { SiteMapping } from 'app/connections/site-mapping.model';
import { SiteService } from 'app/sites/shared/site.service';
import { AuthService } from 'app/core/auth';
import { UiService } from 'app/core/ui.service';

import { AddConnectionComponent } from './modals/add-connection.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../shared/setting.service';
import { Setting } from '../shared/setting.model';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { FilesService } from 'app/sites/site/assessments/files/files.service';
import { BehaviorSubject, Subject, filter, switchMap, takeUntil } from 'rxjs';
import { SiteEncodePipe } from '../../shared/siteEncode.pipe';
import { MenuItem } from 'primeng/api';
import { DeleteConnectionComponent } from './modals/delete-connection.component';

@Component({
    templateUrl: './connection-settings.component.html',
    styleUrls: ['./connection-settings.component.css'],
})
export class ConnectionSettingsComponent implements OnInit {
    itglueenabled: boolean = false;
    isSyncingITGlue: boolean = false;
    constructor(
        private connService: ConnectionService,
        private siteService: SiteService,
        private authService: AuthService,
        private uiService: UiService,
        private connectionService: ConnectionService,
        private routerService: Router,
        private route: ActivatedRoute,
        private location: Location,
        private settingService: SettingService,
        private notificationService: NotificationService,
        private filesService: FilesService,
        private siteEncodePipe: SiteEncodePipe
    ) {}

    @ViewChild('addConnection', { static: true })
    addConnectionModal: ComponentRef<AddConnectionComponent>;
    @ViewChild('deleteConnection', { static: true })
    deleteConnectionModal: ComponentRef<DeleteConnectionComponent>;

    connections: Connection[] = [];
    sortedConnections: Connection[] = [];
    connectionSort: TableSorting = { sortColumn: 'Alias', sortDirection: 'asc' };

    mappings: SiteMapping[] = [];
    sortedMappings: SiteMapping[] = [];
    mappingSort: TableSorting = { sortColumn: 'SiteName', sortDirection: 'asc' };

    allSites: Site[] = [];
    sites: Site[] = [];

    loadingComplete: boolean;

    chNoSites = false;
    cmNoSites = false;
    kvsNoSites = false;
    ndpwebNoSites = false;

    dropdownMenuOptions: MenuItem[];

    provisionedProducts: { name: string; code: string }[] = [];

    connDict: any = {
        ATWS: 'Autotask SOAP',
        ATREST: 'Autotask',
        CW: 'ConnectWise',
        CWREST: 'ConnectWise REST',
        KBMS: 'Kaseya BMS',
        TP: 'TigerPaw',
        ITG: 'IT Glue',
        DWID: 'Dark Web ID',
        DUC: 'Datto Unified Continuity',
    };

    breadcrumbs = [
        { path: '..', text: 'Admin' },
        { path: '.', text: 'Connections' },
    ];

    pageLoadedSubject$: Subject<boolean> = new BehaviorSubject<boolean>(false);
    ngUnsubsribe$: Subject<boolean> = new Subject();

    ngOnInit() {
        this.uiService.setTitle('Connections');
        this.settingService.getSetting('ITGLUE_ORG_SYNC_ENABLED').then((itglueenabled) => {
            if (itglueenabled) {
                this.itglueenabled = itglueenabled.Value === 'TRUE';
            }
        });
        this.loadingComplete = false;
        let products = this.authService.getProducts();
        if (products.CHProduct) {
            this.provisionedProducts.push({ name: 'Cyber Hawk', code: 'CH' });
        }
        if (products.CMProduct) {
            this.provisionedProducts.push({ name: 'Compliance Manager', code: 'CM' });
        }
        if (products.CMGRCProduct) {
            this.provisionedProducts.push({ name: 'Compliance Manager GRC', code: 'CMGRC' });
        }
        if (products.VulScanProduct) {
            this.provisionedProducts.push({ name: 'VulScan', code: 'VS' });
        }
        if (products.NdProWeb) {
            this.provisionedProducts.push({ name: 'Network Detective Pro', code: 'NDP' });
        }
        this.provisionedProducts.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        if (!this.provisionedProducts.length) {
            this.loadingComplete = true;
        } else {
            this.siteService.getSites().then((sites) => {
                this.chNoSites = true;
                this.cmNoSites = true;
                this.kvsNoSites = true;
                this.ndpwebNoSites = true;
                for (let site of sites) {
                    if (this.chNoSites && site.Type == 'standard') {
                        this.chNoSites = false;
                        //break;
                    }
                    if (
                        this.cmNoSites &&
                        (this.siteService.isComplianceManager(site) ||
                            this.siteService.isComplianceManagerGRC(site))
                    ) {
                        this.cmNoSites = false;
                        //break
                    }
                    if (this.kvsNoSites && site.Type == 'KVS') {
                        this.kvsNoSites = false;
                    }
                    if (this.ndpwebNoSites && this.siteService.isNDPro(site) && products.NdProWeb) {
                        this.ndpwebNoSites = false;
                    }
                }
                if (!this.chNoSites || !this.cmNoSites || !this.kvsNoSites || !this.ndpwebNoSites) {
                    this.connectionService
                        .getConnections()
                        .then((conns) => this.onConnections(conns));
                } else {
                    this.loadingComplete = true;
                }
            });
        }
        this.setDropdownOptions(null);
    }

    ngOnDestroy() {
        this.ngUnsubsribe$.next(true);
        this.ngUnsubsribe$.complete();
        this.pageLoadedSubject$.complete();
    }

    onConnections(conns: Connection[]) {
        if (conns) {
            this.connections = conns;
            this.sortedConnections = conns.sort((a, b) =>
                a.Alias.toLowerCase().localeCompare(b.Alias.toLowerCase())
            );
            this.doURLAction();

            if (!this.allSites.length) {
                this.siteService.getSites().then((sites) => {
                    this.onSites(sites);
                });
            } else {
                this.onSites(this.allSites);
            }
        }
    }

    doURLAction() {
        //checks if an action parameter has been passed to this route,
        //this is used to open the add connection popup when returning from autotask login
        this.pageLoadedSubject$
            .pipe(
                filter((v) => v == true),
                switchMap((v) => this.route.paramMap),
                filter((p) => p.get('action') == 'addatrestconn'),
                switchMap((p) => this.route.queryParamMap),
                takeUntil(this.ngUnsubsribe$)
            )
            .subscribe((parms) => {
                this.location.replaceState(this.routerService.url.split('/addatrestconn')[0]);

                if (
                    parms.get('authenticationCode') &&
                    parms.get('enterpriseId') &&
                    parms.get('partnerIntegrationsUrl')
                ) {
                    if (parms.get('editConnectionId')) {
                        this.connectionService
                            .getConnectionById(+parms.get('editConnectionId'))
                            .then((c) => {
                                (this.addConnectionModal as any).showPreparedModal(
                                    c,
                                    parms.get('authenticationCode'),
                                    parms.get('enterpriseId'),
                                    parms.get('resourceId'),
                                    parms.get('partnerIntegrationsUrl'),
                                    this.connections,
                                    true
                                );
                            });
                    } else {
                        (this.addConnectionModal as any).showPreparedModal(
                            {
                                Type: 'ATREST',
                            } as Connection,
                            parms.get('authenticationCode'),
                            parms.get('enterpriseId'),
                            parms.get('resourceId'),
                            parms.get('partnerIntegrationsUrl'),
                            this.connections,
                            false
                        );
                    }
                } else {
                    //if a denial notification received it means that all atrest connections for the same enterprise id are disconnected
                    if (parms.get('enterpriseId') && parms.get('denied') == 'true') {
                        this.connectionService
                            .setATRestConnectionDenied(parms.get('enterpriseId'))
                            .then(() => {
                                if (parms.get('editConnectionId')) {
                                    this.connectionService
                                        .getConnectionById(+parms.get('editConnectionId'))
                                        .then((c) => {
                                            (this.addConnectionModal as any).showModal(
                                                c,
                                                this.connections,
                                                true,
                                                null,
                                                true
                                            );
                                        });
                                } else {
                                    (this.addConnectionModal as any).showModal(
                                        null,
                                        this.connections,
                                        false,
                                        null,
                                        true,
                                        'ATREST'
                                    );
                                }
                            });
                    } else {
                        if (parms.get('editConnectionId')) {
                            this.connectionService
                                .getConnectionById(+parms.get('editConnectionId'))
                                .then((c) => {
                                    (this.addConnectionModal as any).showModal(
                                        c,
                                        this.connections,
                                        true,
                                        null,
                                        true
                                    );
                                });
                        } else {
                            (this.addConnectionModal as any).showModal(
                                null,
                                this.connections,
                                false,
                                null,
                                true,
                                'ATREST'
                            );
                        }
                    }
                }
            });
        this.pageLoadedSubject$
            .pipe(
                filter((v) => v == true),
                switchMap((v) => this.route.paramMap),
                filter((p) => p.get('action') == 'adddattounifiedconn'),
                switchMap((p) => this.route.queryParamMap),
                takeUntil(this.ngUnsubsribe$)
            )
            .subscribe((parms) => {
                let returnSite = this.siteEncodePipe.transform(parms.get('previousSite'));
                let returnUrl = `../../site/${returnSite}/network-detective-pro/assessments/data-collection/datto-unified-continuity-scan`;
                this.location.replaceState(this.routerService.url.split('/adddattounifiedconn')[0]);

                if (this.connections && this.connections.findIndex((c) => c.Type == 'DUC') > -1) {
                    this.notificationService.toast.info(
                        'Cannot add Connection',
                        `A Datto Unified Continuity connection already exists.<br/><br/><a href="${returnUrl}">Return To Site</a>`,
                        { autoClose: false }
                    );
                } else {
                    (this.addConnectionModal as any).showModal(
                        null,
                        this.connections,
                        false,
                        null,
                        false,
                        'DUC',
                        returnUrl
                    );
                }
            });
    }

    onSites(sites: Site[]) {
        if (sites.length) {
            console.log('sites length: ' + sites.length);
            this.allSites = sites.map((x) => Object.assign({}, x));

            this.loadingComplete = false;

            this.connService
                .getMappings()
                .then((mappings: SiteMapping[]) => {
                    let maps: SiteMapping[] = [];
                    for (let conn of this.connections) {
                        // Add existing site mappings from response
                        for (let site of conn.Sites) {
                            let existing = mappings.find((m) => m.SiteId == site.Id);

                            if (!existing) continue;

                            let map: SiteMapping = {
                                AccountId: conn.AccountId,
                                ConnectionId: conn.Id,
                                ConnectionName: conn.Alias,
                                SiteId: site.Id,
                                SiteName: site.Name,
                                ExtraData: existing.ExtraData,
                            };
                            maps.push(map);

                            // Remove from the list of sites available to the AddMappingComponent
                            let index = sites.findIndex((s) => s.Name == site.Name);
                            if (index > 0) sites.splice(index, 1);
                        }
                    }

                    this.mappings = maps;
                    this.sortedMappings = maps.sort((a, b) =>
                        a.SiteName.toLowerCase().localeCompare(b.SiteName.toLowerCase())
                    );

                    this.sites = sites.sort((a, b) =>
                        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                    );
                    console.log('this.sites length: ' + this.sites.length);
                    this.loadingComplete = true;

                    this.pageLoadedSubject$.next(true);
                })
                .catch((err) => {
                    console.error(err);
                    this.loadingComplete = true;
                });
        }
    }

    selectConnection(conn: Connection) {
        this.setDropdownOptions(conn);
    }

    setDropdownOptions(conn: Connection = null): void {
        this.dropdownMenuOptions = [
            {
                label: 'Pause Connection',
                icon: 'fa6-pause',
                visible: this.itglueenabled && conn?.Type == 'ITG',
                command: () => {
                    this.toggleITGConnection(false);
                },
                disabled: false,
            },
            {
                label: 'Resume Connection',
                icon: 'fa6-play',
                visible: !this.itglueenabled && conn?.Type == 'ITG',
                command: () => {
                    this.toggleITGConnection(true);
                },
                disabled: false,
            },
            {
                label: 'Edit',
                icon: 'fa6-pen-to-square',
                visible: true,
                command: () => {
                    this.showAddConnectionModal(conn?.Id);
                },
                disabled: false,
            },
            {
                label: 'Set Up Organizations',
                icon: 'fa6-gear',
                visible: conn?.Type == 'ITG',
                command: () => {
                    this.setupOrgs();
                },
                disabled: !this.itglueenabled,
            },
            {
                label: 'Flexible Asset Mapping',
                icon: 'fa6-right-left',
                visible: conn?.Type == 'ITG',
                command: () => {
                    this.setupFlexibleAssetTypes();
                },
                disabled: false,
            },
            {
                label: 'Sync Now',
                icon: 'fa6-arrows-rotate',
                visible: conn?.Type == 'ITG',
                command: () => {
                    this.syncNowITGlue();
                },
                disabled: !this.itglueenabled || this.isSyncingITGlue,
            },
            {
                separator: true,
            },
            {
                label: 'Delete',
                icon: 'fa6-trash',
                styleClass: 'danger',
                command: () => {
                    (this.deleteConnectionModal as any).showModal(conn);
                },
                disabled: false,
            },
        ];
    }

    getMapExtra(map: SiteMapping): string {
        let conn = this.connections.find((c) => c.Id == map.ConnectionId);

        if (!conn) return '';

        let extra: any = {};

        if (map.ExtraData) extra = JSON.parse(map.ExtraData);

        if (conn.Type == 'ATWS') return extra.AccountName;
        else if (conn.Type == 'CW' || conn.Type == 'CWREST') return extra.CompanyName;
        else return '(not required)';
    }

    error(err: any) {
        console.log(err);
    }

    sortConnections(sorting?: TableSorting) {
        if (!sorting) {
            sorting = this.connectionSort;
        }
        this.connectionSort = sorting;
        this.sortedConnections = this.connections.sort((a, b) => {
            if (sorting.sortDirection == 'asc')
                return a[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(b[sorting.sortColumn].toLowerCase());
            else
                return b[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(a[sorting.sortColumn].toLowerCase());
        });
    }

    sortMappings(sorting?: TableSorting) {
        if (!sorting) {
            sorting = this.mappingSort;
        }
        this.mappingSort = sorting;
        this.sortedMappings = this.mappings.sort((a, b) => {
            if (sorting.sortDirection == 'asc')
                return a[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(b[sorting.sortColumn].toLowerCase());
            else
                return b[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(a[sorting.sortColumn].toLowerCase());
        });
    }

    // Add / Remove Connections from list

    addConn(conn: Connection) {
        this.connections.push(conn);
        this.sortConnections();
    }

    editConn(conn: Connection) {
        let editIndex = this.connections.findIndex((c) => c.Id == conn.Id);
        if (editIndex > -1) {
            this.connections.splice(editIndex, 1, conn);
            this.sortConnections();
        }
    }

    removeConnection(conn: Connection) {
        this.mappings = this.mappings.filter((map) => map.ConnectionId != conn.Id);
        this.sortMappings();
        let removeIndex = this.connections.findIndex((c) => c.Id == conn.Id);
        if (removeIndex > -1) {
            this.connections.splice(removeIndex, 1);
            this.sortConnections();
        }
    }

    // Add / Remove SiteMappings from list

    addMap(map: SiteMapping) {
        let name = map.SiteName.toLowerCase();
        let index = 0;

        for (let i = -1; i < this.mappings.length; i++) {
            if (i == this.mappings.length - 1) {
                index = this.mappings.length - 1;
            } else if (this.mappings[i + 1].SiteName.toLowerCase() > name) {
                index = i + 1;
                break;
            }
        }

        this.mappings.splice(index, 0, Object.assign({}, map)); // deep copy the map so it doesn't lose values when they are reset on AddMappingComponent
        this.sortMappings();
        this.sites.splice(
            this.sites.findIndex((s) => s.Id == map.SiteId),
            1
        );
    }

    removeMapping(map: SiteMapping) {
        this.mappings.splice(this.mappings.indexOf(map), 1);
        this.sortMappings();
        this.insertNewSiteToSites(map.SiteId);
    }

    showAddConnectionModal(connectionId: number) {
        //this is always an edit so if connection not found then it was deleted in another tab
        this.connectionService.getConnectionById(connectionId).then((c) => {
            (this.addConnectionModal as any).showModal(c, null, true);
        });
    }

    setupOrgs() {
        this.routerService.navigateByUrl('settings/connections/it-glue-matching');
    }

    setupFlexibleAssetTypes() {
        this.routerService.navigateByUrl('settings/connections/flexible-asset-types');
    }

    toggleITGConnection(val) {
        let accountId = this.authService.getIdentity().accountId;
        let sets: Setting[] = [];
        sets.push({
            AccountId: accountId,
            Name: 'ITGLUE_ORG_SYNC_ENABLED',
            Value: val ? 'TRUE' : 'FALSE',
        });

        this.settingService
            .updateSettings(sets)
            .then(() => {
                this.itglueenabled = val;

                console.log(this.itglueenabled);
                if (val)
                    this.notificationService.toast.success(
                        'Success',
                        'IT Glue integration was started successfully.'
                    );
                else
                    this.notificationService.toast.success(
                        'Success',
                        'IT Glue integration was paused successfully.'
                    );
            })
            .catch(() => {
                this.notificationService.toast.error(
                    'Error',
                    'There was an error pausing ITGlue integration.'
                );
            });
    }

    sortAvailableSites() {
        this.sites = [
            ...this.sites.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())),
        ];
    }

    insertNewSiteToSites(mapSiteId: number) {
        let site = this.allSites.find((s) => {
            return s.Id === mapSiteId;
        });
        if (site) {
            this.sites.push(site);
            this.sortAvailableSites();
        }
    }

    syncNowITGlue() {
        this.isSyncingITGlue = true;
        this.filesService.syncITGlue().then(() => {
            this.isSyncingITGlue = false;
            this.notificationService.toast.success(
                'Success',
                'A sync has been manually scheduled and will begin shortly.'
            );
        });
    }
}
