import { inject, Injectable } from '@angular/core';
import { Alert } from 'app/alerts';
import { HttpClient } from '@angular/common/http';
import { Audit, AuditPaginationFilters } from './audit.model';
import { BehaviorSubject, EMPTY, lastValueFrom, Observable, shareReplay } from 'rxjs';
import { environment } from 'environments/environment';
import { PagedInfo, PagedResult } from 'app/shared/itc/paginator/itc-paginator.model';
import { switchMapWithState } from 'app/shared/rxjs-operators/switchmap-with-state';

@Injectable({ providedIn: 'root' })
export class AuditService {
    private apiUrl: string = environment.apiUrl;
    private http = inject(HttpClient);

    private filterDataObject: BehaviorSubject<AuditPaginationFilters> = new BehaviorSubject(null);
    private filteredAndPagedItems: Observable<PagedInfo<Audit>> = this.filterDataObject.pipe(
        switchMapWithState((_) => this.fetchPagedAndFilteredAlertHistoryBySite()),
        shareReplay(1)
    );

    getAlertHistory(): Promise<Audit[]> {
        const url: string = this.apiUrl + 'AlertHistory';
        return lastValueFrom(this.http.get<Audit[]>(url));
        //.catch(this.E.error);
    }

    getAlertHistoryBySite(siteId: number, limit?: number): Promise<Audit[]> {
        const url: string =
            this.apiUrl + 'AlertHistory/' + siteId + (limit ? '?limit=' + limit : '');
        return lastValueFrom(this.http.get<Audit[]>(url)).then((res) => res);
        //.catch(this.E.error);
    }

    // getPagedAlertHistoryBySite(
    //     siteId: number,
    //     page: number,
    //     limit: number,
    //     orderby: string,
    //     sortby: string
    // ): Promise<Audit[]> {
    //     const url: string =
    //         this.apiUrl +
    //         `AlertHistory/${siteId}?page=${page}&limit=${limit}&sort_by=${sortby}&order_by=${orderby}`;
    //     return this.http
    //         .get(url)
    //         .toPromise()
    //         .then((res) => res as Audit[]);
    // }

    async getHistoryForAlert(alert: Alert): Promise<Audit[]> {
        const url: string = this.apiUrl + 'AlertHistory/' + alert.SiteId + '/' + alert.Guid;
        return lastValueFrom(this.http.get<Audit[]>(url));
    }

    /* Paginated API stuff */

    fetchPagedAndFilteredAlertHistoryBySite(): Observable<PagedResult<Audit>> {
        let auditFilters = this.filterDataObject.getValue();
        if (auditFilters) {
            const url: string =
                this.apiUrl +
                `FilteredAlertHistory/${auditFilters.id}?page=${auditFilters.page}&limit=${auditFilters.limit}&sort_by=${auditFilters.sortby}&order_by=${auditFilters.orderby}`;
            let req = {
                MinimumModifiedDate: auditFilters.dateRange[0] || null,
                MaximumModifiedDate: auditFilters.dateRange[1] || null,
                MessageFilter: auditFilters.messageFilter,
                IncludeAdminMessages: auditFilters.includeAdminMessages,
            };
            return this.http.post<PagedResult<Audit>>(url, req);
        } else {
            return EMPTY;
        }
    }

    getFilterDataObject(): Observable<AuditPaginationFilters> {
        return this.filterDataObject.asObservable();
    }

    getPagedAndFilteredItems(): Observable<PagedInfo<Audit>> {
        return this.filteredAndPagedItems;
    }

    filterData(filter: Partial<AuditPaginationFilters>): void {
        this.filterDataObject.next({ ...this.filterDataObject.getValue(), ...filter });
    }
}
