<sm-modal title="Add Site" class="xs" [overrideOverflow]="step !== 1" #modal>
    <modal-content>
        <form class="ui itc form" name="addSiteForm" *ngIf="step == 1">
            <div class="required field">
                <label>Site Name</label>
                <input
                    type="text"
                    name="siteName"
                    [(ngModel)]="newSite.Name"
                    notBlank
                    (input)="setSiteName($event)"
                    [pattern]="siteNameRegExpString"
                    #siteName="ngModel" />
                <label
                    class="errorMsg"
                    *ngIf="siteName.dirty && siteName.value !== '' && siteName.invalid">
                    Site names may only include alphanumeric characters, dash, and space.
                </label>
            </div>

            <div class="grouped fields">
                <label>Site Type</label>

                <div *ngIf="products.CM2">
                    <itc-radio
                        name="sitetype"
                        value="CM2"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="hasCm2Violation || expiredProducts['Compliance Manager GRC']"
                        [tooltip]="
                            expiredProducts['Compliance Manager GRC']
                                ? expiredProducts['Compliance Manager GRC'].DisabledTooltip
                                : null
                        "
                        [ttTitle]="
                            expiredProducts['Compliance Manager GRC']
                                ? expiredProducts['Compliance Manager GRC'].DisabledTooltipTitle
                                : null
                        "
                        ttPosition="bottom">
                        Compliance Manager GRC
                    </itc-radio>
                </div>

                <div *ngIf="products.CH || products.CH2019">
                    <itc-radio
                        name="siteType"
                        value="CH"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="hasCHViolation || expiredProducts['Cyber Hawk']"
                        [tooltip]="
                            expiredProducts['Cyber Hawk']
                                ? expiredProducts['Cyber Hawk'].DisabledTooltip
                                : null
                        "
                        [ttTitle]="
                            expiredProducts['Cyber Hawk']
                                ? expiredProducts['Cyber Hawk'].tooltipTitle
                                : null
                        "
                        ttPosition="bottom">
                        Cyber Hawk
                    </itc-radio>
                </div>

                <div *ngIf="products.NdPro">
                    <itc-radio
                        name="siteType"
                        value="NDPRO"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="expiredProducts['Network Detective Pro']"
                        [tooltip]="
                            expiredProducts['Network Detective Pro']
                                ? expiredProducts['Network Detective Pro'].DisabledTooltip
                                : null
                        "
                        [ttTitle]="
                            expiredProducts['Network Detective Pro']
                                ? expiredProducts['Network Detective Pro'].DisabledTooltipTitle
                                : null
                        "
                        ttPosition="bottom">
                        Network Detective Pro
                    </itc-radio>
                </div>

                <div *ngIf="products.KVS">
                    <itc-radio
                        name="siteType"
                        value="KVS"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="hasKvsViolation || expiredProducts['VulScan']"
                        [tooltip]="
                            expiredProducts['VulScan']
                                ? expiredProducts['VulScan'].DisabledTooltip
                                : null
                        "
                        [ttTitle]="
                            expiredProducts['VulScan']
                                ? expiredProducts['VulScan'].DisabledTooltipTitle
                                : null
                        "
                        ttPosition="bottom">
                        VulScan
                    </itc-radio>
                </div>

                <div *ngIf="products.KCM || products.UCM">
                    <itc-radio
                        name="siteType"
                        value="XCM"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="hasAGViolation">
                        Compliance Manager
                    </itc-radio>
                </div>

                <div style="margin-left: 20px" *ngIf="products.KCM || products.UCM">
                    <div *ngIf="products.AGGDPR">
                        <itc-radio
                            name="complianceType2"
                            value="GDPR"
                            [control]="cmSiteTypeCtrl"
                            [disabled]="hasAGViolation">
                            EU GDPR
                        </itc-radio>
                    </div>

                    <div *ngIf="products.AGHIPAA">
                        <itc-radio
                            name="complianceType2"
                            value="HIPAA"
                            [control]="cmSiteTypeCtrl"
                            [disabled]="hasAGViolation">
                            HIPAA
                        </itc-radio>
                    </div>
                    <div *ngIf="products.AGCYBERINSURANCE">
                        <itc-radio
                            name="complianceType2"
                            value="CYBERINSURANCE"
                            [control]="cmSiteTypeCtrl"
                            [disabled]="hasAGViolation">
                            Cyber Insurance
                        </itc-radio>
                    </div>
                    <div *ngIf="products.AGNIST">
                        <itc-radio
                            name="complianceType2"
                            value="NIST"
                            [control]="cmSiteTypeCtrl"
                            [disabled]="hasAGViolation">
                            NIST
                        </itc-radio>
                    </div>
                    <div *ngIf="products.AGCMMC">
                        <itc-radio
                            name="complianceType2"
                            value="CMMC"
                            [control]="cmSiteTypeCtrl"
                            [disabled]="hasAGViolation">
                            CMMC
                        </itc-radio>
                    </div>
                </div>

                <div *ngIf="products.KSM || products.USM">
                    <itc-radio
                        name="siteType"
                        value="XSM"
                        [control]="baseSiteTypeCtrl"
                        [disabled]="hasCHViolation">
                        Security Manager
                    </itc-radio>
                </div>

                <ng-container *ngIf="products.LegacySites">
                    <a
                        *ngIf="showLegacySiteTypes === false && (products.AG || products.AG2019)"
                        (click)="showLegacySiteTypes = true"
                        style="display: block; cursor: pointer; margin-top: 4px">
                        Show Legacy Site Types
                    </a>

                    <div *ngIf="(products.AG || products.AG2019) && showLegacySiteTypes === true">
                        <itc-radio
                            name="siteType"
                            value="AG"
                            [control]="baseSiteTypeCtrl"
                            [disabled]="hasAGViolation || expiredProducts['Compliance Manager']"
                            [tooltip]="
                                expiredProducts['Compliance Manager']
                                    ? expiredProducts['Compliance Manager'].DisabledTooltip
                                    : null
                            "
                            [ttTitle]="
                                expiredProducts['Compliance Manager']
                                    ? expiredProducts['Compliance Manager'].DisabledTooltipTitle
                                    : null
                            "
                            ttPosition="bottom">
                            Compliance Manager
                            <span style="color: #666">(Legacy)</span>
                        </itc-radio>
                    </div>

                    <div
                        style="margin-left: 20px"
                        *ngIf="showLegacySiteTypes === true && (products.AG || products.AG2019)">
                        <div *ngIf="products.AGGDPR">
                            <itc-radio
                                name="complianceType"
                                value="GDPR"
                                [control]="agSiteTypeCtrl">
                                EU GDPR
                            </itc-radio>
                        </div>

                        <div *ngIf="products.AGUKGDPR">
                            <itc-radio
                                name="complianceType"
                                value="UKGDPR"
                                [control]="agSiteTypeCtrl">
                                UK GDPR
                            </itc-radio>
                        </div>

                        <div *ngIf="products.AGHIPAA">
                            <itc-radio
                                name="complianceType"
                                value="HIPAA"
                                [control]="agSiteTypeCtrl">
                                HIPAA
                            </itc-radio>
                        </div>

                        <div *ngIf="products.AGCYBERINSURANCE">
                            <itc-radio
                                name="complianceType"
                                value="CYBERINSURANCE"
                                [control]="agSiteTypeCtrl">
                                Cyber Insurance
                            </itc-radio>
                        </div>

                        <div *ngIf="products.AGNIST">
                            <itc-radio
                                name="complianceType"
                                value="NIST"
                                [control]="agSiteTypeCtrl">
                                NIST CSF
                            </itc-radio>
                        </div>

                        <div *ngIf="products.AGCMMC">
                            <itc-radio
                                name="complianceType"
                                value="CMMC"
                                [control]="agSiteTypeCtrl">
                                CMMC / NIST 800-171
                            </itc-radio>
                        </div>
                    </div>
                </ng-container>
            </div>
        </form>

        <form class="ui form" name="licenseForm" *ngIf="step == 2 && !userIsKaseya">
            <div class="ui message">
                Choose a method of licensing this {{ siteTypeDisplay }} site.
            </div>

            <div class="field">
                <itc-radio
                    name="licenseType"
                    [(ngModel)]="licenseType"
                    value="existing"
                    [disabled]="!availableLicenses.length"
                    (click)="licenseType = availableLicenses.length ? 'existing' : licenseType">
                    Existing License
                </itc-radio>
                <!-- <div class="ui radio checkbox" [class.disabled]="!availableLicenses.length">
                    <input type="radio" name="licenseType" [(ngModel)]="licenseType" value="existing" [disabled]="!availableLicenses.length" />
                    <label (click)="licenseType = (availableLicenses.length ? 'existing' : licenseType)">Existing License</label>
                </div> -->
                <span style="color: red; font-size: 0.9em" [hidden]="availableLicenses.length">
                    <i class="warning sign icon"></i>
                    {{
                        newSite.Type == 'CH' || newSite.Type == 'XSM'
                            ? 'Select available licenses from Subscription Options Below'
                            : 'No suitable licenses available'
                    }}
                </span>
            </div>

            <sm-select
                [control]="licenseCtrl"
                label="Available Licenses"
                [hidden]="licenseType != 'existing'">
                <option *ngFor="let lic of availableLicenses; let i = index" [value]="i">
                    {{ lic.Model.replace('GDPR', 'CM') }} (renews
                    {{ lic.Expiration | date : 'MM/dd/yyyy' }})
                </option>
            </sm-select>
            <br *ngIf="licenseType == 'existing'" />

            <itc-radio
                name="licenseType"
                [(ngModel)]="licenseType"
                value="new"
                (onclick)="licenseType = 'new'">
                New Subscription
            </itc-radio>

            <!-- <div class="field">
                <div class="ui radio checkbox">
                    <input type="radio" name="licenseType" [(ngModel)]="licenseType" value="new" />
                    <label (click)="licenseType = 'new'">New Subscription</label>
                </div>
            </div>-->

            <sm-select
                [control]="newSubCtrl"
                label="Subscription Options"
                [hidden]="licenseType != 'new'">
                <option *ngFor="let opt of subscriptionOpts; let i = index" [value]="i">
                    {{ opt.name }}
                </option>
            </sm-select>

            <!-- Monthly vs. Annual -->
            <!-- never mind.... -->
        </form>

        <form class="ui form" name="licenseForm" *ngIf="step == 2 && userIsKaseya">
            <div class="ui message" *ngIf="!availableLicenses.length">
                No available licenses. Please contact your sales representative.
            </div>
            <div class="ui message" *ngIf="availableLicenses.length">
                Select an available license for the new site.
            </div>

            <sm-select
                [control]="licenseCtrl"
                label="Available Licenses"
                *ngIf="availableLicenses.length">
                <option *ngFor="let lic of availableLicenses; let i = index" [value]="i">
                    {{ lic.Model.replace('GDPR', 'CH') }} (renews
                    {{ lic.Expiration | date : 'MM/dd/yyyy' }})
                </option>
            </sm-select>
        </form>
        <form class="ui itc form" name="organizationForm" *ngIf="step == 3">
            <itc-banner type="info">
                Select Organizational Folder for
                <span style="font-weight: 500">{{ newSite.Name }}</span>
            </itc-banner>
            <div class="field">
                <p-dropdown
                    [(ngModel)]="selectedOrganizationId"
                    [options]="organizationChoices"
                    optionValue="Id"
                    optionLabel="Name"
                    placeholder="Select Organization"
                    (onChange)="selectOrganization($event)"
                    #organizationSelection
                    [ngModelOptions]="{ standalone: true }"></p-dropdown>
            </div>
            <div class="required field" *ngIf="selectedOrganizationId == -1">
                <label>New Organizational Folder Name</label>
                <input
                    type="text"
                    name="siteName"
                    [(ngModel)]="newOrganizationName"
                    (ngModelChange)="setNewOrganizationName($event)"
                    data-rules="regex"
                    [attr.data-regex]="orgNameRegExpString" />
                <label class="error" data-rule="regex">
                    Organization names may only include alphanumeric characters, dash, and space and
                    must be less than 50 characters long.
                </label>
            </div>
        </form>

        <form class="ui form" name="confirmForm" *ngIf="step == 4">
            <ng-container *ngIf="!isSMB || (isSMB && !hasViolation)">
                <h4 style="margin-bottom: 0">
                    <span *ngIf="licenseType != 'kvs'">Please Confirm</span>
                    <span *ngIf="licenseType == 'kvs'" style="display: block">
                        Automatically provision Internal Vulnerability Scanner for the new site?
                    </span>
                </h4>
                <div *ngIf="licenseType == 'kvs'" class="grouped fields">
                    <itc-radio
                        name="provisionVulScan"
                        [control]="autoProvisionVulScanCtrl"
                        value="true">
                        Yes
                    </itc-radio>
                    <br />
                    <itc-radio
                        name="provisionVulScan"
                        [control]="autoProvisionVulScanCtrl"
                        value="false">
                        No
                    </itc-radio>

                    <!-- <div class="field">
                        <div class="ui radio checkbox">
                            <input type="radio" name="provisionVulScan" [formControl]="autoProvisionVulScanCtrl" value="true" id="yesVulScan">
                            <label for="yesVulScan">Yes</label>
                        </div>
                    </div> -->
                    <!-- <div class="field">
                        <div class="ui radio checkbox">
                            <input type="radio" name="provisionVulScan" [formControl]="autoProvisionVulScanCtrl" value="false" id="noVulScan">
                            <label for="noVulScan">No</label>
                        </div>
                    </div> -->
                </div>
                <itc-banner type="info">
                    <ng-container *ngIf="!isSMB && licenseType == 'new'">
                        <span *ngIf="license.level == 'ag100'">
                            You are about to create a new Compliance Manager assessment site. You
                            have an unlimited number of Compliance Manager 100 licenses, so there
                            will be no additional charge.
                        </span>
                        <span *ngIf="license.level != 'ag100'">
                            <!--You are about to start a new <b>Audit Guru for GDPR</b> annual subscription and will be billed an additional <b>{{getFormattedPrice()}}</b> per month.-->

                            <span *ngIf="licenseSiteType == 'AG'">
                                You are about to start a new
                                <b>Compliance Manager for {{ getDisplayName(newSite.Type) }}</b>
                                annual subscription, and will be billed an additional
                                <b>{{ getFormattedPrice() }}</b>
                                per month.
                            </span>
                            <span *ngIf="licenseSiteType == 'CH'">
                                You are about to start a new
                                <b>Cyber Hawk</b>
                                annual subscription, and will be billed an additional
                                <b>{{ getFormattedPrice() }}</b>
                                per month.
                            </span>
                            <span
                                *ngIf="
                                    licenseSiteType == 'KVS' &&
                                    (autoProvisionVulScanCtrl.value == 'true' || products.HEVS)
                                ">
                                You are about to create a new
                                <b>VulScan</b>
                                site. This site will count against your licensed total.
                            </span>

                            <span
                                *ngIf="
                                    licenseSiteType == 'KVS' &&
                                    autoProvisionVulScanCtrl.value == 'false'
                                ">
                                You are about to create a new
                                <b>VulScan</b>
                                site. This site will not count against your licensed total.
                            </span>
                            <span *ngIf="licenseSiteType == 'XCM'">
                                You are about to start a new
                                <b>Compliance Manager for {{ getDisplayName(newSite.Type) }}</b>
                                annual subscription, and will be billed an additional
                                <b>{{ getFormattedPrice() }}</b>
                                per month.
                            </span>
                            <span *ngIf="licenseSiteType == 'XSM'">
                                You are about to start a new
                                <b>Security Manager</b>
                                annual subscription, and will be billed an additional
                                <b>{{ getFormattedPrice() }}</b>
                                per month.
                            </span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="!isSMB && licenseType == 'existing'">
                        You are about to create a new site using the existing subscription:
                        <b>{{ license.model.replace('GDPR', 'CM') }}</b>
                        .
                    </ng-container>

                    <ng-container *ngIf="isSMB">
                        Your site is being created. You will be redirected once it is done.
                    </ng-container>
                    <ng-container *ngIf="licenseType == 'cyberhawk'">
                        You are about to create a new Cyber Hawk site named
                        <b>{{ newSite.Name }}</b>
                        . This action will not incur any charges.
                    </ng-container>
                    <ng-container *ngIf="licenseType == 'kvs'">
                        <span *ngIf="autoProvisionVulScanCtrl.value == 'true' || products.HEVS">
                            You are about to create a new
                            <b>VulScan</b>
                            site. This site will count against your licensed total.
                        </span>
                        <span *ngIf="autoProvisionVulScanCtrl.value == 'false'">
                            You are about to create a new
                            <b>VulScan</b>
                            site. This site will not count against your licensed total.
                        </span>
                    </ng-container>
                    <ng-container *ngIf="licenseType == 'cm2'">
                        You are about to create a new
                        <b>Compliance Manager GRC</b>
                        site. This site will count against your licensed total.
                    </ng-container>
                    <ng-container *ngIf="licenseType == 'NDPRO'">
                        You are about to create a new
                        <b>Network Detective Pro</b>
                        site. This site will count against your licensed total.
                    </ng-container>
                </itc-banner>
            </ng-container>
            <ng-container *ngIf="isSMB && hasViolation">
                <div>
                    <p>The maximum number of computers allowed has been exceeded for:</p>
                    <span *ngFor="let smbErrorMessage of smbErrorMessages">
                        - {{ smbErrorMessage }}
                        <br />
                    </span>
                    <br />
                    <p>Please contact your Account Manager to upgrade your license.</p>
                </div>
            </ng-container>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button
            *ngIf="step == 1"
            type="secondary"
            [class.cancel]="true"
            [disabled]="step == 3 && nextLoading"
            (onclick)="goBack()">
            {{ step == 1 ? 'Cancel' : 'Back' }}
        </itc-button>
        <itc-button
            *ngIf="step != 1"
            type="secondary"
            [class.back]="true"
            [disabled]="step == 3 && nextLoading"
            (onclick)="goBack()">
            {{ step == 1 ? 'Cancel' : 'Back' }}
        </itc-button>
        <itc-button
            type="primary"
            [loading]="nextLoading"
            [disabled]="!canProceed || hasViolation || (siteName?.invalid && siteName?.dirty)"
            (onclick)="advance()">
            {{ step == 4 || isSMB ? 'Confirm' : 'Next' }}
        </itc-button>
    </modal-actions>
</sm-modal>
