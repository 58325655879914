import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    NgForm,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { GlobalUser } from 'app/settings/shared/global-user.model';
import { PasswordFormComponent } from 'app/shared/password-form/password-form.component';
import { SharedModule } from 'app/shared/shared.module';
import { FmitUser, SiteUser, SiteUsersService } from 'app/sites';
import { AuthService, Roles } from '../auth';
import { SettingService } from 'app/settings';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { PreferencesService } from '../preferences/preferences.service';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';
import { MessageService } from '../message.service';
import { GlobalRoles } from '../auth';
import { Option } from 'app/shared/multiselect/multiselect.component';

export type CurrentUser = {
    userId: number;
    roleId: number;
};

declare var $: any;

@Component({
    selector: 'add-edit-user-form',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, PasswordFormComponent],
    template: `
        <div class="ui modal xs" #userAddModal>
            <div class="ui header">
                <p class="modalTitle">
                    {{ editMode ? 'Edit User' : 'Add User' }}
                </p>
            </div>
            <div class="content">
                <itc-tabs [class.noTabBar]="!allowExisting" (tabChanged)="tabChanged($event)">
                    <itc-tab tabTitle="New Users">
                        <form class="ui form" name="newUserForm" #userForm="ngForm">
                            <div class="required field">
                                <label for="username">Username/Email Address:</label>
                                <input
                                    type="text"
                                    name="username"
                                    [(ngModel)]="user.Username"
                                    [attr.disabled]="editMode ? true : null"
                                    notBlank
                                    [email]="!editMode ? true : null"
                                    #username="ngModel" />
                                <itc-banner
                                    type="error"
                                    *ngIf="
                                        username.errors &&
                                        (username.errors['email'] ||
                                            username.errors['duplicate']) &&
                                        !editMode
                                    ">
                                    <ng-container *ngIf="username.errors['email']">
                                        Username must be an email address
                                    </ng-container>
                                    <ng-container *ngIf="username.errors['duplicate']">
                                        Username already exists, please choose a unique username
                                    </ng-container>
                                </itc-banner>
                            </div>

                            <div class="two fields">
                                <div class="required field">
                                    <label for="first_name">First Name:</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        [(ngModel)]="user.FirstName"
                                        notBlank />
                                </div>
                                <div class="required field">
                                    <label for="last_name">Last Name:</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        [(ngModel)]="user.LastName"
                                        notBlank />
                                </div>
                            </div>

                            <password-form-fields
                                *ngIf="editMode"
                                [(ngModel)]="user.Password"
                                name="PasswordForm"
                                [unchanged]="editMode ? true : false"
                                (onError)="passwordError = $event"></password-form-fields>

                            <password-form-fields
                                *ngIf="!editMode"
                                [(ngModel)]="user.Password"
                                name="PasswordForm"
                                [unchanged]="editMode ? true : false"
                                (onError)="passwordError = $event"
                                notBlank></password-form-fields>

                            <itc-banner type="error" *ngIf="passwordError">
                                {{ passwordError }}
                            </itc-banner>

                            <div *ngIf="allowTwoFactor" style="padding-bottom: 8px">
                                <itc-checkbox
                                    label="Two-Factor Enabled"
                                    name="twoFactorEnabled"
                                    [(ngModel)]="user.TwoFactorEnabled"
                                    [disabled]="!user.TwoFactorEnabled"
                                    (change)="onTwoFactorEnabledChange($event)"></itc-checkbox>
                            </div>

                            <div class="required field" *ngIf="userType === 'global'">
                                <label for="roleSelect">Global Access Role:</label>
                                <div [tooltip]="editRoleTooltip">
                                    <p-dropdown
                                        [(ngModel)]="user.AccessRole"
                                        name="AccessRole"
                                        [options]="globalRoles"
                                        optionLabel="label"
                                        optionValue="value"
                                        [disabled]="user.IsManagedByAccessGroups"
                                        [required]="!editMode"
                                        inputId="roleSelect"
                                        #roleSelect></p-dropdown>
                                </div>
                            </div>
                        </form>
                    </itc-tab>
                    <itc-tab *ngIf="allowExisting" tabTitle="Existing User(s)">
                        <form
                            class="ui form"
                            name="existingUserForm"
                            #existingUserForm="ngForm"
                            [formGroup]="existingUsersForm">
                            <itc-multiselect
                                formControlName="existingUsersDisplay"
                                [options]="existingUserOptions"
                                [maxItems]="1"
                                [typable]="false"></itc-multiselect>
                        </form>
                    </itc-tab>
                </itc-tabs>
            </div>
            <div class="modalScrollingShadow"></div>
            <div class="actions">
                <itc-button
                    label="Close"
                    type="secondary"
                    [disabled]="addUserPending"
                    (onclick)="hideUserAddModal()"></itc-button>

                <itc-button
                    type="primary "
                    [loading]="addUserPending"
                    [disabled]="
                        (newUserType === 'new' && (!userForm.dirty || userForm.invalid)) ||
                        (newUserType === 'existing' &&
                            (!existingUsersForm.dirty || existingUsersForm.invalid)) ||
                        passwordError
                    "
                    (onclick)="processForm()">
                    {{ editMode ? 'Save' : 'Add' }}
                </itc-button>
            </div>
        </div>
        <!-- </modal-actions>
                </sm-modal> -->

        <sm-modal class="sm" title="Disable MFA" #userDisableMfaModal>
            <modal-content>
                <itc-banner type="warning" title="Warning">
                    Disabling MFA for a user removes a vital security protection for the user
                    account. Are you sure?
                </itc-banner>
            </modal-content>
            <modal-actions>
                <itc-button
                    type="secondary"
                    label="Cancel"
                    (onclick)="cancelDisableUserMfa()"></itc-button>
                <itc-button type="primary" label="OK" (onclick)="disableUserMfa()"></itc-button>
            </modal-actions>
        </sm-modal>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            ::ng-deep {
                itc-tabs.noTabBar {
                    div.tabs_header {
                        display: none !important;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditUserFormComponent {
    authService = inject(AuthService);
    cdr = inject(ChangeDetectorRef);
    fb = inject(FormBuilder);
    messageService = inject(MessageService);
    notificationService = inject(NotificationService);
    prefService = inject(PreferencesService);
    settingService = inject(SettingService);
    siteUsersService = inject(SiteUsersService);

    @Input() editUser: GlobalUser | SiteUser;
    @Input() userType: 'global' | 'site' = 'global';
    @Input() siteId: number;
    @Input() allowExisting = false;
    @Input() set existingUsers(val: FmitUser[]) {
        if (val) {
            this.processExisting(val);
        }
    }
    fmitUsers: FmitUser[] = [];
    existingUserOptions: Option[] = [];
    @Output() onError = new EventEmitter<string>();
    @Output() userAdded = new EventEmitter<SiteUser | GlobalUser>();
    @Output() userEdited = new EventEmitter<SiteUser | GlobalUser>();
    @Output() usersAdded = new EventEmitter<SiteUser[] | GlobalUser[]>();
    @ViewChild('userAddModal') userAddModal: ElementRef;
    @ViewChild('userDisableMfaModal') userDisableMfaModal: SemanticModalComponent;
    @ViewChild('userForm') userForm: NgForm; // this is the form for adding/editing users, existing form is existingUsersForm

    user: GlobalUser | SiteUser = new SiteUser();
    editMode = false;
    allowTwoFactor: boolean;
    mfaEnabled = false;
    passwordError = '';
    currentUser: CurrentUser;
    globalRoles = [...GlobalRoles];

    editRoleTooltip = 'Edit Global Access Role';
    showDeleteMFAWarning = false;
    addUserPending = false;
    /* existingUsersForm is just for the dropdown of
    already existing users to add to a site, adding/editing is
    handled by the userForm */
    existingUsersForm = new FormGroup({
        existingUsersDisplay: new FormControl<string[]>(
            [],
            [Validators.required, Validators.minLength(1)]
        ),
    });
    newUserType: 'new' | 'existing' = 'new';

    ngOnInit() {
        let identity = this.authService.getIdentity();
        this.currentUser = {
            userId: parseInt(identity['userId'], 10),
            roleId: parseInt(identity['roleId'], 10),
        };

        if (this.currentUser.roleId === Roles.Master) {
            if (this.globalRoles.findIndex((r) => r.label === 'All') < 0) {
                this.globalRoles.unshift({ label: 'All', value: 3 });
            }
        }

        this.prefService.checkTOTP().then((mfaEnabled) => {
            this.mfaEnabled = mfaEnabled;
        });
    }

    show(user?) {
        this.processUser(user);
        this.messageService.broadcast('RESET_PASSWORD_FORM');

        // timeout to wait for the form to be marked as pristine
        setTimeout(() => {
            $(this.userAddModal.nativeElement)
                .modal({
                    closable: false,
                    duration: 200,
                })
                .modal('show');
        }, 100);
    }

    async processUser(user) {
        this.editMode = user !== undefined;

        if (!this.editMode) {
            if (this.userType === 'global') {
                this.user = new GlobalUser();
                this.user.AccessRole = Roles.SiteRestricted;
            } else {
                this.user = new SiteUser();
            }
        } else {
            this.user = JSON.parse(JSON.stringify(user)) as GlobalUser;
            if (this.userType === 'global') {
                this.user.IsManagedByAccessGroups =
                    await this.settingService.getIsGlobalUserManagedByAccessGroups(this.user.Id);
                this.editRoleTooltip = this.user.IsManagedByAccessGroups
                    ? "This user's Global Access Role is managed through KaseyaOne. Use Admin Settings > IT Complete page to change the Access Group settings."
                    : 'Edit Global Access Role';
            }

            this.allowTwoFactor =
                this.editMode &&
                this.mfaEnabled &&
                this.currentUser.roleId == (Roles.Master || Roles.Admin);
            this.allowTwoFactor = true;
        }
        // mark as pristine because it's marking dropdown as dirty and enabling the save button
        this.userForm.form.markAsPristine();
        this.cdr.markForCheck();
    }

    processExisting(allUsers: FmitUser[]) {
        this.fmitUsers = allUsers;
        this.existingUserOptions = Array.from(allUsers, (eu) => {
            return { Label: eu.Username, Value: eu.Username };
        });
    }

    onTwoFactorEnabledChange(evt: any) {
        if (!evt.target.checked) {
            this.userDisableMfaModal.show({ closable: false });
        }
        return false;
    }

    disableUserMfa() {
        if (!(this.user instanceof SiteUser)) {
            this.user.TwoFactorEnabled = false;
            this.userForm.form.markAsDirty();
            this.userDisableMfaModal.hide();
            $(this.userAddModal.nativeElement).modal('show');
        }
    }

    cancelDisableUserMfa() {
        if (!(this.user instanceof SiteUser)) {
            this.user.TwoFactorEnabled = true;
            this.userDisableMfaModal.hide();
            $(this.userAddModal.nativeElement).modal('show');
        }
    }

    hideUserAddModal() {
        $(this.userAddModal.nativeElement).modal('hide');
        setTimeout(() => {
            this.resetForm();
        }, 200);
    }

    resetForm() {
        this.user = new SiteUser();
        this.userForm.reset();
        if (this.allowExisting) {
            this.existingUsersForm.get('existingUsersDisplay').setValue([]);
            this.existingUsersForm.get('existingUsersDisplay').markAsPristine();
        }
        this.messageService.broadcast('RESET_PASSWORD_FORM');
        this.allowTwoFactor = false;
    }

    addGlobalUser() {
        if (this.addUserPending) return;

        this.addUserPending = true;

        this.settingService
            .postGlobalUser(this.user as GlobalUser)
            .then((res) => {
                this.addUserPending = false;

                let username = this.user.Username;
                this.notificationService.toast.success(
                    'Success',
                    `New user '<span class="breakWord">${username}</span>' added to account.`
                );

                if (!(this.user instanceof SiteUser)) {
                    this.user.Id = res.Id;
                    this.user.Password = null;
                    this.hideUserAddModal();
                    this.userAdded.emit(this.user);
                }
            })
            .catch((res) => {
                this.addUserPending = false;

                if (res.status == 403) {
                    let username = this.user.Username;
                    this.notificationService.toast.error(
                        'Error',
                        `The user '<span class="breakWord">${username}</span>' already exists in the system. Please enter a different username.`
                    );
                    this.userForm.form.controls['username'].setErrors({ duplicate: true });
                }
            });
    }

    editGlobalUser() {
        if (this.addUserPending) return;

        this.addUserPending = true;

        this.settingService
            .putGlobalUser(this.user as GlobalUser)
            .then((res) => {
                this.addUserPending = false;
                this.hideUserAddModal();
                this.userEdited.emit(this.user);

                let username = this.user.Username;
                this.notificationService.toast.success(
                    'Success',
                    `User '${username}' was updated.`
                );
            })
            .catch((res) => {
                this.addUserPending = false;
                let username = this.user.Username;
                this.notificationService.toast.error(
                    'Error',
                    `There was an error updating '${username}'. Please try again.`
                );
                this.hideUserAddModal();
            });
    }

    addSiteUser() {
        if (this.addUserPending) return;

        let user = this.user as SiteUser;

        if (this.newUserType == 'new') {
            this.addUserPending = true;
            this.siteUsersService
                .postSiteUser(this.siteId, user)
                .then((res) => {
                    this.addUserPending = false;

                    let username = user.Username;
                    this.notificationService.toast.success(
                        'Success',
                        `New user '${username}' added to site.`
                    );

                    user.UserId = res.Id;
                    user.SiteId = this.siteId;
                    delete user.Password;

                    this.userAdded.emit(JSON.parse(JSON.stringify(user)));
                    this.userForm.reset();
                    this.existingUsersForm.get('existingUsersDisplay').setValue([]);
                    this.existingUsersForm.markAsPristine();
                    this.hideUserAddModal();
                })
                .catch((res) => {
                    this.addUserPending = false;

                    if (res.status == 403) {
                        this.userForm.form.controls['username'].setErrors({
                            duplicate: true,
                        });

                        let username = user.Username;

                        if (this.fmitUsers.findIndex((u) => u.Username == username) > -1) {
                            this.notificationService.toast.error(
                                'Error',
                                `The user '${username}' already exists. To add this user to the site, select Existing User(s).`
                            );
                        } else {
                            this.notificationService.toast.error(
                                'Error',
                                `The user '${username}' already exists but is not associated with this account. Please enter a different username to create a New User.`
                            );
                        }
                    }
                });
        } else if (this.newUserType == 'existing') {
            if (!this.existingUsersForm.get('existingUsersDisplay').value) {
                return;
            }

            let users = [];
            for (let u of this.existingUsersForm.get('existingUsersDisplay').value) {
                let user = new SiteUser();
                user.Username = u;
                users.push(user);
            }

            this.addUserPending = true;
            this.siteUsersService
                .addExistingUsersToSite(this.siteId, users)
                .then((res) => {
                    let usersAdded = res.UsersAdded;
                    this.usersAdded.emit(usersAdded);

                    this.addUserPending = false;

                    if (usersAdded.length == users.length) {
                        this.notificationService.toast.success(
                            'Success',
                            'All selected users were added to the site.'
                        );
                    } else if (usersAdded.length > 0) {
                        this.notificationService.toast.warning(
                            'Partial Success',
                            'Some users were not added to the site.'
                        );
                    } else {
                        this.notificationService.toast.error(
                            'Error',
                            'No users were added to the site.'
                        );
                    }
                    this.userForm.reset();
                    this.existingUsersForm.get('existingUsersDisplay').setValue([]);
                    this.existingUsersForm.markAsPristine();
                    this.hideUserAddModal();
                })
                .catch((err) => {
                    this.addUserPending = false;
                    this.hideUserAddModal();
                    this.notificationService.toast.error(
                        'Error',
                        'Something went wrong. No users were added to the site.'
                    );
                });
        }
    }

    tabChanged(evt) {
        this.newUserType = evt.tabIndex === 0 ? 'new' : 'existing';
    }

    processForm() {
        if (this.userType === 'global') {
            if (this.editMode) {
                this.editGlobalUser();
            } else {
                this.addGlobalUser();
            }
        } else {
            this.addSiteUser();
        }
    }
}
