<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Connections</h1>
    <help-button
        title="Connections"
        text="Here you can set up and enable integrations with PSA and other systems."
        url="/portal/rft-portal/set-up-integration-w-psa-web-console.htm"></help-button>

    <div class="flex-right">
        <itc-dropdown-button classList="openLeft">
            <button-content>
                <itc-button type="primary" icon="fa-plus" [disabled]="!loadingComplete">
                    New
                    <fa-icon icon="chevron-down" class="cdownlogo"></fa-icon>
                </itc-button>
            </button-content>
            <dropdown-content>
                <ul>
                    <li>
                        <itc-button
                            type="ghost darkText"
                            (onclick)="addConnection.showModal(null, connections)">
                            Add Connection
                        </itc-button>
                    </li>
                    <li>
                        <itc-button
                            type="ghost darkText"
                            [disabled]="!connections.length"
                            (onclick)="addMapping.showModal()">
                            Add Site Mapping
                        </itc-button>
                    </li>
                </ul>
            </dropdown-content>
        </itc-dropdown-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="ui grid dashboard" *ngIf="chNoSites && cmNoSites && kvsNoSites && ndpwebNoSites">
        <div class="nine wide column">
            <div class="ui grid">
                <div class="twelve wide column">
                    <itc-banner type="info" title="No Sites">
                        There are no sites available to associate with this functionality.
                        <br />
                        Create a new Cyber Hawk, Compliance Manager, Network Detective Pro or
                        Vulnerability Scanner site to access this functionality.
                    </itc-banner>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            !provisionedProducts.length || !chNoSites || !cmNoSites || !kvsNoSites || !ndpwebNoSites
        ">
        <div class="settingsPage">
            <div *ngIf="!connections.length">
                <itc-banner type="info" title="You do not have any Connections set up yet.">
                    You can add one Connection per Cyber Hawk, Compliance Manager, Network Detective
                    Pro and Vulnerability Scanner site. Get started by creating a Connection.
                </itc-banner>

                <itc-button
                    label="Add"
                    type="primary"
                    icon="fa-plus"
                    class="right floated"
                    (onclick)="addConnection.showModal()"></itc-button>
            </div>

            <itc-tabs>
                <itc-tab tabTitle="Connections">
                    <table
                        id="integrationsTable"
                        class="itc-table"
                        *ngIf="connections.length"
                        sortable-table
                        sortColumn="Alias"
                        sortDirection="asc"
                        (sorted)="sortConnections($event)">
                        <thead>
                            <tr>
                                <th sortable-column="Alias">Name</th>
                                <th sortable-column="Type">Type</th>
                                <th sortable-column="Username">Login</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let conn of sortedConnections">
                                <td>
                                    {{ connDict[conn.Type] !== 'IT Glue' ? conn.Alias : 'IT Glue' }}
                                </td>
                                <td>
                                    {{ connDict[conn.Type] }}
                                </td>
                                <td>
                                    {{
                                        connDict[conn.Type] !== 'IT Glue' ? conn.Username : '******'
                                    }}
                                </td>
                                <td class="collapsing rightaligned">
                                    <itc-button type="icon"
                                                *ngIf="conn.Type != 'ITG'"
                                                icon="fa-pen-to-square"
                                                (onclick)="showAddConnectionModal(conn.Id)"
                                                ttposition="top"
                                                tooltip="Edit"></itc-button>
                                    <itc-button type="icon"
                                                *ngIf="conn.Type != 'ITG'"
                                                icon="fa-trash"
                                                (onclick)="deleteConnection.showModal(conn)"
                                                ttposition="top"
                                                tooltip="Delete"></itc-button>
                                    <dropdown-button *ngIf="conn.Type == 'ITG'"
                                                     buttonType="icon"
                                                     buttonIcon="fa-ellipsis-v"
                                                     buttonSize="small"
                                                     panelTop="true"
                                                     [showDropdownIcon]="false"
                                                     [disabled]="false"
                                                     [style]="{ width: '186px' }"
                                                     (click)="selectConnection(conn)"
                                                     [menuOptions]="dropdownMenuOptions"></dropdown-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </itc-tab>

                <itc-tab tabTitle="Site Mappings" [disabled]="!connections.length">
                    <div *ngIf="connections.length && !mappings.length">
                        <itc-banner type="info" title="You have not mapped any Connections yet.">
                            Now that you have a Connection, you can associate it to a Site.
                        </itc-banner>
                    </div>

                    <!-- Site Mappings Table -->
                    <table
                        id="siteMappingsTable"
                        class="itc-table"
                        *ngIf="connections.length && mappings.length"
                        sortable-table
                        (sorted)="sortMappings($event)"
                        sortColumn="SiteName"
                        sortDirection="asc">
                        <thead>
                            <tr>
                                <th sortable-column="SiteName">Site</th>
                                <th sortable-column="ConnectionName">Connection</th>
                                <th>Account / Company</th>
                                <th style="width: 65px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let map of sortedMappings">
                                <td>
                                    {{ map.SiteName }}
                                </td>
                                <td>
                                    {{ map.ConnectionName }}
                                </td>
                                <td>
                                    {{ getMapExtra(map) }}
                                </td>
                                <td class="collapsing">
                                    <itc-button
                                        type="icon"
                                        icon="fa-trash"
                                        (onclick)="deleteMapping.showModal(map)"
                                        tooltip="Delete"
                                        ttposition="top"></itc-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </itc-tab>
            </itc-tabs>
        </div>
    </div>
</sds-loader>

<add-connection-modal
    #addConnection
    (onAdd)="addConn($event)"
    (onEdit)="editConn($event)"></add-connection-modal>

<del-connection-modal
    #deleteConnection
    (onDelete)="removeConnection($event)"></del-connection-modal>

<add-mapping-modal
    #addMapping
    [sites]="sites"
    [connections]="connections"
    [provisionedProducts]="provisionedProducts"
    (onAdd)="addMap($event)"></add-mapping-modal>

<del-mapping-modal
    #deleteMapping
    [connections]="connections"
    (onDelete)="removeMapping($event)"></del-mapping-modal>
