import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterIsNotUndefined } from '../../rxjs-operators/filter-is-not-undefined';

@Injectable()
export class DiscoveryAgentSettingsService {
    private _deepFileScanSettings = new BehaviorSubject<string>(null);
    get deepFileScanSettings(): string {
        return this._deepFileScanSettings.value;
    }
    get deepFileScanSettings$(): Observable<string> {
        return this._deepFileScanSettings.pipe(filterIsNotUndefined());
    }
    set deepFileScanSettings(value: string) {
        this._deepFileScanSettings.next(value);
    }

    private _deepFileScanSchedule$ = new BehaviorSubject<string>(null);
    get deepFileScanSchedule(): string {
        return this._deepFileScanSchedule$.value;
    }
    get deepFileScanSchedule$(): Observable<string> {
        return this._deepFileScanSchedule$.pipe(filterIsNotUndefined());
    }
    set deepFileScanSchedule(value: string) {
        this._deepFileScanSchedule$.next(value);
    }

    private _installKey$ = new BehaviorSubject<string>(null);
    get installKey(): string {
        return this._installKey$.value;
    }
    get installKey$(): Observable<string> {
        return this._installKey$.pipe(filterIsNotUndefined());
    }
    set installKey(value: string) {
        this._installKey$.next(value);
    }
}
