import { Injectable, inject } from '@angular/core';
import { NotificationService } from './itc/notification/notification.service';
import * as saveAs from 'file-saver';

export interface UtilityOptions {
    showNotification: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    notificationService = inject(NotificationService);

    constructor() {}

    async copyToClipboard(content, options?: UtilityOptions) {
        try {
            await navigator.clipboard.writeText(content);
            if (options?.showNotification === true) {
                console.log('Content copied to clipboard');
                this.notificationService.toast.info('', 'Content copied to clipboard');
            }
        } catch (err) {
            console.error('Failed to copy: ', err);
            if (options?.showNotification === true) {
                this.notificationService.toast.info('', 'Failed to copy to clipboard');
            }
        }
    }

    downloadFile(content: unknown, fileName: string) {
        let raw = window.atob(content as string);
        let bytes = new Uint8Array(raw.length);
        for (let i = 0; i < raw.length; i++) {
            bytes[i] = raw.charCodeAt(i);
        }
        let blob = new Blob([bytes]);

        saveAs(blob, fileName);
    }

    cmp(a, b, sortDirection) {
        if (a > b) return sortDirection === 'asc' ? 1 : -1;
        if (a < b) return sortDirection === 'asc' ? -1 : 1;
        return 0;
    }
    cmpTime(a, b, sortDirection) {
        if (sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }
    cmpBoolean(a, b, sortDirection) {
        if (a === b) {
            return 0;
        }
        if (sortDirection === 'asc') {
            return a ? 1 : -1;
        } else {
            return a ? -1 : 1;
        }
    }
    cmpAlphaNumeric(a, b, sortDirection) {
        if (sortDirection === 'asc') {
            return a.toLowercase().localeCompare(b.toLowerCase(), undefined, {
                numeric: true,
                sensitivity: 'base',
                ignorePunctuation: true,
            });
        } else {
            return b.toLowercase().localeCompare(a.toLowerCase(), undefined, {
                numeric: true,
                sensitivity: 'base',
                ignorePunctuation: true,
            });
        }
    }
}
