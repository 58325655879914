import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UiService } from '../../core/ui.service';
import { NavService } from '../nav/nav.service';

@Component({
    // selector: 'sds-403',
    template: `
        <div class="forbiddenContainer">
            <h1>Access Denied</h1>
            <p class="errorMessage">{{ errorMessage }}</p>

            <itc-button routerLink="['/', 'organizations']">Return to Organizations</itc-button>
        </div>
    `,
    styles: [
        `
            .forbiddenContainer {
                padding: 25px;

                .errorMessage {
                    font-size: 16px;
                }
                itc-button {
                    margin-top: 25px;
                }
            }
        `,
    ],
})
export class ForbiddenComponent implements OnInit {
    private uiService = inject(UiService);
    private route = inject(ActivatedRoute);
    private navService = inject(NavService);

    errorCode: string;
    errorMessage: string = 'Your account does not have access to the requested page.';
    errorMessages: { [key: string]: string };

    ngOnInit() {
        this.errorMessages = {
            RFTE102:
                'You do not have the appropriate global access permissions to access this resource.',
            RFTE103: 'You do not have access to this products resources.',
            RFTE105:
                'You do not have the appropriate site specific permissions to access this resource.',
            RFTE110: 'Network Detective Pro is not enabled for this site.',
            RFTE111: 'Cyber Hawk is not enabled for this site.',
            RFTE112: 'Compliance Manager is not enabled for this site.',
            RFTE114: 'Compliance Manager GRC is not enabled for this site.',
            RFTE115: 'Vulnerability Scanner is not enabled for this site.',
            RFTE116: 'CSPro is not enabled for this site.',
            RFTE117: 'There are no CSPro enabled sites in this organization.',
            RFTE118: 'Selected organization does not have any of the required site types.',
            RFTE119: 'Network Detective Pro is not enabled for this account.',
            RFTE120: 'Your account is not provisioned for NDPro Web.',
            RFTE121: 'Site Type missing for the selected site.',
        };

        this.navService.setHeaderText(''); // clear any header if there is one

        let routeSS = this.route.snapshot;
        if (routeSS.params.error) {
            this.setError(routeSS.params.error);
        } else if (routeSS.queryParams.e) {
            this.setError(routeSS.queryParams.e);
        }

        this.uiService.setTitle('Access Denied');
    }

    setError(e) {
        this.errorCode = e;
        this.errorMessage = this.errorMessages[e];
    }
}
