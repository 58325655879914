import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';

@Component({
    selector: '<itc-panel>',
    standalone: true,
    imports: [CommonModule],
    template: `
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcCollapsiblePanelComponent {
    @Input() header: string;
    @Input() disabled = false;

    collapsibleRef: ItcCollapsibleComponent;

    constructor(panels: ItcCollapsibleComponent) {
        this.collapsibleRef = panels;
        this.collapsibleRef.addPanel(this);
    }
}

@Component({
    selector: 'itc-collapsible',
    standalone: true,
    imports: [CommonModule, AccordionModule, ItcCollapsiblePanelComponent],
    template: `
        <p-accordion
            styleClass="itc-accordion"
            expandIcon="fa6-chevron-down"
            collapseIcon="fa6-chevron-up"
            [multiple]="multiple"
            [activeIndex]="activeIndex">
            <p-accordionTab
                *ngFor="let panel of panels"
                [header]="panel.header"
                [disabled]="panel.disabled">
                <ng-content></ng-content>
            </p-accordionTab>
        </p-accordion>
    `,
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcCollapsibleComponent {
    panels: ItcCollapsiblePanelComponent[] = [];
    @Input() allActive: boolean;
    @Input() multiple: boolean;
    @Input() activeIndex: number | number[];

    ngOnInit() {
        this.allActive = typeof this.allActive === 'undefined' ? true : this.allActive;
        this.multiple = typeof this.multiple === 'undefined' ? true : this.multiple;
    }

    addPanel(panel: ItcCollapsiblePanelComponent) {
        this.panels.push(panel);
        if (this.activeIndex != undefined) {
            return;
        }
        this.activeIndex = 0;
        if (this.allActive && this.multiple) {
            this.activeIndex = [...Array.from({ length: this.panels.length }, (_, i) => i)];
        }
    }
}
