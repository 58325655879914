import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    AfterViewInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Audit } from '../audit.model';

declare var $: any;

@Component({
    selector: 'sds-audit-table',
    templateUrl: './audit-table.component.html',
    styleUrls: ['./audit-table.component.scss'],
})
export class AuditTableComponent implements OnInit {
    constructor(private router: Router) {
        if (!environment.production) console.log('audit-table.constructor');
    }

    @Input() audits: Audit[];
    @Input() forSite: boolean = false;
    @Output() onFiltered = new EventEmitter<Audit[]>();
    @Output() sortBy = new EventEmitter<string>();
    sortMethod: string;
    @ViewChild('AuditLogTable', { static: true }) auditLogTable: any;

    ngOnInit() {
        if (!environment.production) console.log('audit-table.nginit');
        this.sortMethod = '-ModifiedDate';
    }

    detail(guid: string) {}

    toggleTruncate(e): void {
        var $alertElement = $(e.srcElement).closest('tr').find('.alertText');
        $alertElement.toggleClass('truncated');
    }

    toggleSort(method): void {
        if (method === this.sortMethod) {
            this.sortMethod = method.replace('-', '');
        } else {
            this.sortMethod = method;
        }
        this.sortBy.emit(this.sortMethod);
    }
}
