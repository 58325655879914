import { PaginationFilters } from '../../itc/paginator/itc-paginator.model';

export interface DiscoveryAgentFilters extends PaginationFilters {
    applianceStatus: Set<TDiscoveryAgentApplianceStatus>;
    cmoStatus: Set<TDiscoveryAgentCmoStatus>;
    os: Set<string>;
    label: Set<string>;
    text: string;
}

export function getEmptyDiscoveryAgentFilters(): Partial<DiscoveryAgentFilters> {
    return {
        applianceStatus: new Set(),
        cmoStatus: new Set(),
        os: new Set(),
        label: new Set(),
        text: '',
    };
}

export type TDiscoveryAgentApplianceStatus =
    | 'online'
    | 'offline'
    | 'update available'
    | 'offline > 90 days';
export type TDiscoveryAgentCmoStatus = 'enabled' | 'disabled' | 'not supported';
