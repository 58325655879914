import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import * as saveAs from 'file-saver';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';

export class IndocRoutingModule {}
@Injectable({ providedIn: 'root' })
export class FilesService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getFilesMetadata(siteId: number, assessmentName: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Files?assessmentName=${assessmentName}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getFilesExhibits(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/FilesExhibits`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getArchivedFilesExhibits(siteId: number, assessmentGuid: string): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/ArchivedFilesExhibits?assessmentGuid=${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getFilesMetadataObs(siteId: number, assessmentName: string): Observable<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Files?assessmentName=${assessmentName}`;

        return this.http.get(url);
    }

    getConnectorFilesMetadata(siteId: number, fileType: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ConnectorFiles?filetype=${fileType}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setConnectorFilesToMerge(siteId: number, alerttype: string, selectedFiles: any[]) {
        const url: string = this.apiUrl + `Sites/${siteId}/ConnectorFiles`;

        return this.http
            .post(url, { selectedFiles: selectedFiles, alertType: alerttype })
            .toPromise()
            .then((res) => res);
    }

    getConnectorFilesZip(siteId: number, selectedFiles: any[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ConnectorFilesZip`;

        return this.http
            .post(url, { selectedFiles: selectedFiles })
            .toPromise()
            .then((res) => res);
    }

    deleteConnectorFiles(siteId: number, selectedFiles: any[]) {
        const url: string = this.apiUrl + `Sites/${siteId}/DeleteConnectorFiles`;

        return this.http
            .post(url, { selectedFiles: selectedFiles })
            .toPromise()
            .then((res) => res);
    }

    getFilesWithAttachmentIdCount(siteId: number, fileid: string): Promise<any> {
        try {
            const url: string =
                this.apiUrl + `Sites/${siteId}/FilesWithAttachmentCount?fileid=${fileid}`;

            return this.http
                .get(url)
                .toPromise()
                .then((res) => res);
        } catch (e) {
            console.log('fileservice error: ' + e);
        }
    }

    deleteFile(siteId: number, fileid: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/DeleteFile?fileid=${fileid}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    syncITGlue(): Promise<any> {
        const url: string = this.apiUrl + `SyncITGlue`;
        return this.http
            .post(url, {})
            .toPromise()
            .then((res) => res as any);
    }

    checkITGlueOauth(siteId: number, formId: string, topicId: string) {
        const url: string = this.apiUrl + `CheckItGlueOAuth`;

        return this.http
            .post(url, { siteid: siteId, formid: formId, topicid: topicId })
            .toPromise()
            .then((res) => res as any);
    }

    checkITGlueOauthDataExplorer(
        siteId: number,
        assessmentType: string,
        objectType: string,
        detailId: string
    ) {
        const url: string = this.apiUrl + `CheckItGlueOAuth`;

        return this.http
            .post(url, {
                siteid: siteId,
                assessmentType: assessmentType,
                objectType: objectType,
                detailId: detailId,
            })
            .toPromise()
            .then((res) => res as any);
    }

    searchITGlueFiles(siteId: number, searchterm: string, orgid: string): Promise<any> {
        const url: string = this.apiUrl + `ITGlueFiles`;

        return this.http
            .post(url, { siteid: siteId, searchterm: searchterm, orgid: orgid })
            .toPromise()
            .then((res) => res as any);
    }

    getITGlueOrganizations(
        siteId: number,
        instanceurl: string,
        apikey: string,
        statusid?: number,
        orgtypeid?: number,
        connectionid?: number,
        decrypt?: boolean
    ): Promise<any> {
        const url: string = this.apiUrl + `ItGlueOrganizations`;

        let postBody: any = { instanceurl: instanceurl, apikey: apikey, siteId: siteId };
        if (statusid !== undefined) {
            postBody.statusid = statusid;
        }

        if (orgtypeid !== undefined) {
            postBody.orgtypeid = orgtypeid;
        }

        if (connectionid !== undefined) {
            postBody.connectionid = connectionid;
        }

        if (decrypt !== undefined) {
            postBody.decrypt = decrypt;
        }

        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res as any);
    }

    async getITGlueOrganizationStatuses(
        siteId: number,
        instanceurl: string,
        apikey: string,
        connectionid?: number
    ): Promise<Array<ITGlueObject>> {
        const url: string = this.apiUrl + `ItGlueOrganizationStatuses`;
        let postBody: any = { instanceurl: instanceurl, apikey: apikey, siteId: siteId };

        if (connectionid !== undefined) {
            postBody.connectionid = connectionid;
        }

        return firstValueFrom(this.http.post(url, postBody));
    }

    async getITGlueOrganizationTypes(
        siteId: number,
        instanceurl: string,
        apikey: string,
        connectionid?: number
    ): Promise<Array<ITGlueObject>> {
        const url: string = this.apiUrl + `ItGlueOrganizationTypes`;
        let postBody: any = { instanceurl: instanceurl, apikey: apikey, siteId: siteId };

        if (connectionid !== undefined) {
            postBody.connectionid = connectionid;
        }

        return firstValueFrom(this.http.post(url, postBody));
    }

    public deserializeITGlueConfig(json: string): ITGlueConfig {
        return JSON.parse(json) as ITGlueConfig;
    }

    getImageDataURL(image: string) {
        return firstValueFrom(this.http.get(image, { responseType: 'blob' }))
            .then((data) => {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(data);
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                });
            })
            .catch((err) => {
                console.log('error getting image data', err);
                return null;
            });
    }

    async downloadAndRename(url: string, filename: string): Promise<void> {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            saveAs(blobUrl, filename);
            URL.revokeObjectURL(blobUrl);
        } catch (err) {
            console.error('Error in fetching and downloading file:', err);
        }
    }
    downloadGrcAttachment(
        siteId: number,
        attachmentId: string,
        assessmentGuid: string
    ): Promise<any> {
        const url: string = `${this.apiUrl}/Sites/${siteId}/DownloadGrcAttachment?attachmentId=${attachmentId}&assessmentGuid=${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    downloadGrcAttachmentsZip(siteId: number, assessmentGuid: string): Promise<any> {
        const url: string = `${this.apiUrl}/Sites/${siteId}/DownloadGrcAttachmentsZip?assessmentGuid=${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
}

export interface Details {
    'name': string;
    'created-at': Date;
    'updated-at': Date;
}

export interface ITGlueObject {
    id: string;
    type: string;
    attributes: Details;
}

export interface ITGlueConfig {
    Types: Array<ITGlueSetting>;
    Statuses: Array<ITGlueSetting>;
}

export interface ITGlueSetting {
    Id: string;
    Name: string;
    Value: boolean;
}


export interface ITGlueFlexibleAssetMapping {
    FatMappingSelections: ITGlueFlexibleAssetMappingSelection[];
    LastModifiedUtcTicks: number;
}

export interface ITGlueFlexibleAssetMappingSelection {
    DataType: string;
    DataField: string;
    ITGlueFlexibleAssetFieldType: string;
    ITGlueFlexibleAssetType: string;
    ITGlueFlexibleAssetTypeId: number
    ITGlueFlexibleAssetField: string;
    ITGlueFlexibleAssetFieldId: number;
    ITGlueFlexibleAssetFieldNameKey: string;
    ITGlueFlexibleAssetFieldSelectionType: string;
}
