import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NdpService } from '../../sites/shared/ndp.service';
import {
    NdpCategory,
    NdpReport,
    NdpUserReportSet,
} from '../../sites/site/network-detective-pro/ndpro.model';
import { SplitCamelCasePipe } from '../split-camel-case.pipe';
import { uniqBy as _uniqBy } from 'lodash-es';
import { FormControl } from '@angular/forms';
import { TableSorting } from '../sortable-table/sortable-table.component';
import { SemanticModalComponent } from '../../semantic-legacy/components/modal/modal';
import { FormService } from '../../core/form.service';
import { NotificationService } from '../itc/notification/notification.service';
declare var $: any;

interface NdpReportGenerate extends NdpReport {
    IsSelected: boolean;
    IsTempSelected: boolean;
    ReportType: string;
}
interface NdpReportSet extends NdpUserReportSet {
    ReportsFull?: NdpReportGenerate[];
    IsSelected?: boolean;
}
@Component({
    selector: 'ndp-user-report-sets-portable',
    templateUrl: './ndp-user-report-sets-portable.component.html',
    styleUrls: ['./ndp-user-report-sets-portable.component.scss'],
})
export class NdpUserReportSetsPortableComponent implements OnInit {
    loadingComplete: boolean = false;
    reportSets: NdpReportSet[] = [];
    filteredReportSets: NdpReportSet[];
    sortedReportSets: NdpReportSet[];
    categories: NdpCategory[] = [];
    selectedCategories: NdpCategory[] = [];
    reports: NdpReportGenerate[] = [];
    filteredReports: NdpReportGenerate[];
    sortedReports: NdpReportGenerate[];
    reportsFilterField: FormControl<string> = new FormControl('');
    reportSetsFilterField: FormControl<string> = new FormControl('');
    reportSetNameCtrl: FormControl<string> = new FormControl();
    @ViewChild('editorModal') editorModal: SemanticModalComponent;
    @ViewChild('deleteModal') deleteModal: SemanticModalComponent;
    @ViewChild('reportsListModal') reportsListModal: SemanticModalComponent;
    hideSystem: boolean = false;
    selectedCategoriesCount = 0;
    selectedReportSetsCount = 0;
    currentSort: TableSorting = null;
    currentSetSort: TableSorting = null;
    selectedSet: NdpReportSet = null;
    reportsAllChecked = false;
    selectedCount: number;
    saving: boolean = false;

    constructor(
        private ndpService: NdpService,
        private splitCamelCasePipe: SplitCamelCasePipe,
        private formService: FormService,
        private notificationService: NotificationService
    ) {}

    /**
     * Input the site Id if this is in site settings.
     *
     * @param { number } siteId
     */
    _siteId: number = -1;
    get siteId() { return this._siteId; }
    @Input() set siteId(value) {
        this._siteId = value;
        if (this._siteId != null) {
            this.initReports();
        }
    }
    @Input() title: string = 'User Report Sets';

    ngOnInit(): void {
        
    }

    initReports() {
        this.ndpService.getReports(this.siteId).then((res) => {
            this.reports = [];
            (res as NdpReportGenerate[]).forEach((report) => {
                if (!report.IsBeta) {
                    report.ReportType = this.getType(report);
                    report.ModuleName = this.splitCamelCasePipe.transform(report.ModuleName);
                    this.categories.push({ Name: report.ModuleName, IsSelected: false });
                    this.reports.push(report);
                }
            });
            this.categories = _uniqBy(this.categories, 'Name');
            this.ndpService.getUserReportSets(this.siteId).then((res) => {
                (res as NdpReportSet[]).forEach((reportSet) => {
                    reportSet.ReportsFull = this.getReportsFull(reportSet.Reports);
                    this.reportSets.push(reportSet);
                });
                this.loadingComplete = true;
                this.filterReportSets();
            });
            this.filterReports();
        });
    }

    ngAfterViewInit(): void {
        $('#categoryDropdown').dropdown({
            action: 'nothing',
            selectOnKeydown: false,
        });
        $('#reportsetDropdown').dropdown({
            action: 'nothing',
            selectOnKeydown: false,
        });
    }

    getReportsFull(reportStrings: string[]) {
        let rValue = [];
        reportStrings.forEach((r) => {
            let match = this.reports.find((report) => r == `${report.Module}-${report.Name}`);
            if (match) rValue.push(match);
        });
        return rValue;
    }

    filterReportSets(): void {
        this.filteredReportSets = this.reportSets.filter(
            (r) =>
                (!this.hideSystem || r.AccountId != 'SYSTEM') &&
                (!this.reportSetsFilterField.value ||
                    r.Name.toLowerCase().indexOf(this.reportSetsFilterField.value.toLowerCase()) >
                        -1)
        );
        this.sortReportSets();
    }
    sortReportSets(sorting?: TableSorting): void {
        if (!sorting) {
            if (!this.currentSort) sorting = { sortColumn: 'Name', sortDirection: 'asc' };
            else sorting = this.currentSort;
        }
        this.currentSetSort = sorting;
        switch (sorting.sortColumn) {
            case 'Name':
                this.sortedReportSets = this.filteredReportSets.sort((a, b) =>
                    a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                );
                break;
            case 'Reports':
                this.sortedReportSets = this.filteredReportSets.sort((a, b) =>
                    (a.ReportsFull.length > 0 ? a.ReportsFull[0].Name : 'zzzzzz')
                        .toLowerCase()
                        .localeCompare(b.ReportsFull.length > 0 ? b.ReportsFull[0].Name : 'zzzzzz')
                );
                break;
            case 'LastModified':
                this.sortedReportSets = this.filteredReportSets.sort((a, b) => {
                    return (
                        (a.AccountId != 'SYSTEM' ? 1 : 0) - (b.AccountId != 'SYSTEM' ? 1 : 0) ||
                        new Date(a.LastModified).getTime() - new Date(b.LastModified).getTime()
                    );
                });
                break;
            default:
                this.sortedReportSets = this.filteredReportSets.sort((a, b) =>
                    a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                );
                break;
        }

        if (sorting.sortDirection == 'desc') {
            this.sortedReportSets.reverse();
        }
    }

    filterReports(): void {
        let anyCategoryChecked = this.selectedCategories.length > 0;
        this.filteredReports = this.reports.filter(
            (r) =>
                (this.selectedCategories.find((c) => c.Name === r.ModuleName) ||
                    !anyCategoryChecked) &&
                (this.getAssociatedReportSets(r)?.find((rs) => rs.IsSelected) ||
                    this.selectedReportSetsCount == 0) &&
                (!this.reportsFilterField.value ||
                    r.Name.toLowerCase().indexOf(this.reportsFilterField.value.toLowerCase()) >
                        -1 ||
                    r.FileExtension.toLowerCase().indexOf(
                        this.reportsFilterField.value.toLowerCase()
                    ) > -1)
        );
        this.sortReports();
    }

    sortReports(sorting?: TableSorting): void {
        if (!sorting) {
            if (!this.currentSort) sorting = { sortColumn: 'Name', sortDirection: 'asc' };
            else sorting = this.currentSort;
        }
        this.currentSort = sorting;
        switch (sorting.sortColumn) {
            case 'Name':
                this.sortedReports = this.filteredReports.sort((a, b) =>
                    a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                );
                break;
            case 'Category':
                this.sortedReports = this.filteredReports.sort((a, b) =>
                    a.ModuleName.toLowerCase().localeCompare(b.ModuleName.toLowerCase())
                );
                break;
            case 'Type':
                this.sortedReports = this.filteredReports.sort((a, b) =>
                    a.ReportType.toLowerCase().localeCompare(b.ReportType.toLowerCase())
                );
                break;
            default:
                this.sortedReports = this.filteredReports.sort((a, b) =>
                    a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                );
                break;
        }

        if (sorting.sortDirection == 'desc') {
            this.sortedReports.reverse();
        }
    }

    reportsCheckAll(setValue = null): void {
        this.reportsAllChecked = setValue !== null ? setValue : !this.reportsAllChecked;
        this.filteredReports.forEach((r) => {
            r.IsSelected = this.reportsAllChecked;
        });
        this.reportChecked();
    }

    reportChecked(report: NdpReportGenerate = null): void {
        if (report) report.IsSelected = !report.IsSelected;
        this.reportsAllChecked = this.filteredReports.every((r) => r.IsSelected);
        this.selectedCount = this.reports.filter((c) => c.IsSelected).length;
    }

    categoryChecked(): void {
        this.selectedCategoriesCount = this.selectedCategories.length;
        this.filterReports();
    }

    reportSetChecked(evt: Event, reportSet: NdpReportSet): void {
        evt.stopPropagation();
        evt.preventDefault();
        reportSet.IsSelected = !reportSet.IsSelected;
        this.selectedReportSetsCount = this.reportSets.filter((c) => c.IsSelected).length;
        this.filterReports();
    }

    resetFilters(): void {
        this.clearCategories();
        this.clearReportSets();
        this.reportsFilterField.setValue('');
        this.filterReports();
    }

    clearCategories(evt?: Event): void {
        evt?.stopPropagation();
        this.selectedCategories=[];
        this.selectedCategoriesCount = 0;
        this.filterReports();
    }

    clearReportSets(evt?: Event): void {
        evt?.stopPropagation();
        this.reportSets.forEach((c) => (c.IsSelected = false));
        this.selectedReportSetsCount = 0;
        this.filterReports();
    }
    getType(report: NdpReportGenerate): string {
        let rValue = '';
        if (report.IsChange) {
            rValue = 'Change';
        }
        if (report.IsInform) {
            if (rValue) {
                rValue += ', ';
            }
            rValue += 'inForm';
        }
        return rValue ? rValue : 'Standard';
    }

    getAssociatedReportSets(report: NdpReportGenerate): NdpReportSet[] {
        let rValue = [];
        this.reportSets?.forEach((rs) => {
            if (rs.Reports.indexOf(`${report.Module}-${report.Name}`) > -1) rValue.push(rs);
        });
        return rValue;
    }

    editReportSetModal(selectedSet: NdpReportSet = null) {
        if (!selectedSet)
            this.selectedSet = {
                Name: '',
                Reports: [],
                Id: -1,
                SiteId: this.siteId,
                AccountId: '',
                LastModified: new Date(),
            };
        else this.selectedSet = selectedSet;
        this.reportSetNameCtrl.setValue(this.selectedSet.Name);
        this.reports.forEach(
            (r) => (r.IsSelected = this.selectedSet.Reports.indexOf(`${r.Module}-${r.Name}`) > -1)
        );
        this.reportChecked();
        this.resetFilters();
        this.formService.clear('editorForm');
        this.editorModal.show({ closable: false });
    }

    saveReportSet(): void {
        let valid = this.formService.validate('editorForm');

        if (valid) {
            if (
                this.reportSets.some((rs) => {
                    return (
                        this.reportSetNameCtrl.value.trim() == rs.Name &&
                        this.selectedSet.Id != rs.Id
                    );
                })
            ) {
                var fields = this.formService.clear('editorForm');
                for (var i = 0; i < fields.length; i++) {
                    var field = fields[i];
                    this.formService.error(field, 'unique');
                }

                valid = false;
            }
        }
        if (valid) {
            this.saving = true;
            this.selectedSet.Name = this.reportSetNameCtrl.value.trim();
            this.selectedSet.Reports = [];
            this.reports.forEach((r) => {
                if (r.IsSelected) {
                    this.selectedSet.Reports.push(`${r.Module}-${r.Name}`);
                }
            });
            this.ndpService.saveUserReportSets(this.siteId, this.selectedSet).then((res) => {
                let reportSet = res as NdpReportSet;
                reportSet.ReportsFull = this.getReportsFull(reportSet.Reports);
                if (this.selectedSet.Id == -1) {
                    this.reportSets.push(reportSet);
                    this.notificationService.toast.success(
                        'Success.',
                        `The ${this.selectedSet.Name} User Report Set has been created.`
                    );
                } else {
                    let idx = this.reportSets.findIndex((set) => set.Id == this.selectedSet.Id);
                    if (idx > -1) this.reportSets[idx] = reportSet;
                    this.notificationService.toast.success(
                        'Success.',
                        `The ${this.selectedSet.Name} User Report Set has been updated.`
                    );
                }
                this.filterReportSets();
                this.editorModal.hide();
                this.saving = false;
            });
        }
    }
    cloneReportSet(selectedSet: NdpReportSet) {
        let oldName = selectedSet.Name;
        let copySet = JSON.parse(JSON.stringify(selectedSet));
        copySet.Id = -1;
        copySet.Name += ' (clone' + this.getCloneNumber(copySet.Name) + ')';

        this.ndpService.saveUserReportSets(this.siteId, copySet).then((res) => {
            let reportSet = res as NdpReportSet;
            reportSet.ReportsFull = this.getReportsFull(reportSet.Reports);
            this.reportSets.push(reportSet);
            this.filterReportSets();
            this.notificationService.toast.success(
                'Success.',
                `The ${oldName} User Report Set has been cloned.`
            );
        });
    }

    getCloneNumber(cloneBaseName: string) {
        let rValue = 0;
        let matched = true;
        while (matched) {
            rValue++;
            matched = this.reportSets.some((rs) => {
                return (
                    rs.Name == cloneBaseName + ' (clone' + (rValue == 1 ? '' : ' ' + rValue) + ')'
                );
            });
        }

        return rValue == 1 ? '' : ' ' + rValue;
    }

    deleteReportSet(): void {
        this.saving = true;
        this.ndpService.deleteUserReportSet(this.siteId, this.selectedSet.Id).then((res) => {
            let idx = this.reportSets.findIndex((set) => set.Id == this.selectedSet.Id);
            this.reportSets.splice(idx, 1);
            this.filterReportSets();
            this.deleteModal.hide();
            this.saving = false;
            this.notificationService.toast.success(
                'Success.',
                `The ${this.selectedSet.Name} User Report Set has been deleted.`
            );
        });
    }

    showDeleteModal(selectedSet: NdpReportSet) {
        this.selectedSet = selectedSet;
        this.deleteModal.show({ closable: false });
    }

    showReportsList(selectedSet: NdpReportSet) {
        this.selectedSet = selectedSet;
        this.reportsListModal.show();
    }
}
