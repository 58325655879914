import { Injectable } from '@angular/core';
import {
    DiscoveryAgentAdvancedOptionDto,
    DiscoveryAgentScanSettingsDto,
} from '../models/discovery-agent-scan-settings.dto';
import { DiscoveryAgentSidebarData } from '../models/discovery-agent-sidebar-data.model';

@Injectable({
    providedIn: 'root',
})
export class DiscoveryAgentScanDataAdapterService {
    mapDiscoveryAgentSettingsDTOToDiscoveryAgentSidebarData(
        installKey: string,
        discoveryAgentSettingsDTO: DiscoveryAgentScanSettingsDto,
        deepFileScanSettingsString: string,
        deepFileScanSettingsSchedule: string,
    ): DiscoveryAgentSidebarData {
        const dailyScanDays = new Set(discoveryAgentSettingsDTO.ScanDays?.split(','));
        const deepFileScanSettings = new Set(deepFileScanSettingsString?.split('|'));

        return {
            installKey,
            dailyScan: {
                isEnabled: dailyScanDays.size > 0,
                days: {
                    sun: dailyScanDays.has('0'),
                    mon: dailyScanDays.has('1'),
                    tue: dailyScanDays.has('2'),
                    wed: dailyScanDays.has('3'),
                    thu: dailyScanDays.has('4'),
                    fri: dailyScanDays.has('5'),
                    sat: dailyScanDays.has('6'),
                },
                performVulScan: discoveryAgentSettingsDTO.Is3ppScan,
            },
            deepFileScan: {
                isEnabled: deepFileScanSettingsSchedule?.length > 0,
                dayOfWeek: this.mapWeekDayLabelToValue(deepFileScanSettingsSchedule),
                options: {
                    computerDriveEncryptionDetection: deepFileScanSettings.has('-driveencryption'),
                    ePhi: deepFileScanSettings.has('-hipaadeep'),
                    cardholder: deepFileScanSettings.has('-pcideep'),
                    gdprPersonal: deepFileScanSettings.has('-gdprdeep'),
                    pii: deepFileScanSettings.has('-pii'),
                    zipScan: deepFileScanSettings.has('-zipChecked'),
                    pdfScan: deepFileScanSettings.has('-pdftimeout'),
                    pdfTimeoutValue: Array.from(deepFileScanSettings).reduce((timeout, setting, index, array) => {
                        if (setting === '-pdftimeout' && index + 1 < array.length) {
                            return parseInt(array[index + 1], 10);
                        }
                        return timeout;
                    }, 5),
                },
            },
            advancedOptions: {
                urlTesting: {
                    isEnabled: discoveryAgentSettingsDTO.advancedOptions.urlTesting,
                    options: {
                        entertainment: discoveryAgentSettingsDTO.advancedOptions.testEntertainment,
                        pornography: discoveryAgentSettingsDTO.advancedOptions.testPornography,
                        shareware: discoveryAgentSettingsDTO.advancedOptions.testShareware,
                        socialMedia: discoveryAgentSettingsDTO.advancedOptions.testSocialMedia,
                        warez: discoveryAgentSettingsDTO.advancedOptions.testWarez,
                        webMail: discoveryAgentSettingsDTO.advancedOptions.testWebMail,
                    },
                },
                portTesting: discoveryAgentSettingsDTO.advancedOptions.portTesting,
                wifiData: discoveryAgentSettingsDTO.advancedOptions.wifiData,
                policies: discoveryAgentSettingsDTO.advancedOptions.policies,
                screenSaving: discoveryAgentSettingsDTO.advancedOptions.screenSaverSettings,
                usb: discoveryAgentSettingsDTO.advancedOptions.usbDetection,
                internetAccessibility: discoveryAgentSettingsDTO.advancedOptions.internetAccessibility,
                loginDetails: discoveryAgentSettingsDTO.advancedOptions.loginDetails,
            },
        };
    }

    mapDiscoveryAgentSidebarDataToDeepFileScanSettingsString(data: DiscoveryAgentSidebarData): string {
        const settings = [];

        if (data.deepFileScan.options.computerDriveEncryptionDetection) {
            settings.push('-driveencryption');
        }
        if (data.deepFileScan.options.ePhi) {
            settings.push('-hipaadeep');
        }
        if (data.deepFileScan.options.cardholder) {
            settings.push('-pcideep');
        }
        if (data.deepFileScan.options.gdprPersonal) {
            settings.push('-gdprdeep');
        }
        if (data.deepFileScan.options.pii) {
            settings.push('-pii');
        }
        if (data.deepFileScan.options.zipScan) {
            settings.push('-zipChecked');
        }
        if (data.deepFileScan.options.pdfScan) {
            settings.push('-pdftimeout');
            settings.push(data.deepFileScan.options.pdfTimeoutValue.toString());
        }

        return settings.join('|');
    }

    mapDiscoveryAgentSidebarDataToWeeklyScanDayString(data: DiscoveryAgentSidebarData): string {
        const days = [];

        if (data.dailyScan.days.sun) {
            days.push('0');
        }
        if (data.dailyScan.days.mon) {
            days.push('1');
        }
        if (data.dailyScan.days.tue) {
            days.push('2');
        }
        if (data.dailyScan.days.wed) {
            days.push('3');
        }
        if (data.dailyScan.days.thu) {
            days.push('4');
        }
        if (data.dailyScan.days.fri) {
            days.push('5');
        }
        if (data.dailyScan.days.sat) {
            days.push('6');
        }

        return days.join('');
    }

    mapDiscoveryAgentSidebarDataToAdvancedOptions(data: DiscoveryAgentSidebarData): DiscoveryAgentAdvancedOptionDto {
        return {
            urlTesting: data.advancedOptions.urlTesting.isEnabled,
            testEntertainment: data.advancedOptions.urlTesting.options.entertainment,
            testPornography: data.advancedOptions.urlTesting.options.pornography,
            testShareware: data.advancedOptions.urlTesting.options.shareware,
            testSocialMedia: data.advancedOptions.urlTesting.options.socialMedia,
            testWarez: data.advancedOptions.urlTesting.options.warez,
            testWebMail: data.advancedOptions.urlTesting.options.webMail,
            portTesting: data.advancedOptions.portTesting,
            wifiData: data.advancedOptions.wifiData,
            policies: data.advancedOptions.policies,
            screenSaverSettings: data.advancedOptions.screenSaving,
            usbDetection: data.advancedOptions.usb,
            internetAccessibility: data.advancedOptions.internetAccessibility,
            loginDetails: data.advancedOptions.loginDetails,
        };
    }

    mapWeekDayLabelToValue(label: string) {
        switch (label) {
            case 'Sunday':
                return 'sun';
            case 'Monday':
                return 'mon';
            case 'Tuesday':
                return 'tue';
            case 'Wednesday':
                return 'wed';
            case 'Thursday':
                return 'thu';
            case 'Friday':
                return 'fri';
            case 'Saturday':
                return 'sat';
            default:
                return null;
        }
    }

    mapWeekDayValueToLabel(value: string) {
        switch (value) {
            case 'sun':
                return 'Sunday';
            case 'mon':
                return 'Monday';
            case 'tue':
                return 'Tuesday';
            case 'wed':
                return 'Wednesday';
            case 'thu':
                return 'Thursday';
            case 'fri':
                return 'Friday';
            case 'sat':
                return 'Saturday';
            default:
                return '';
        }
    }
}
