<div class="input-container">
    <sm-input
        name="searchInput"
        debounce="200"
        [control]="searchKeyControl"
        (input)="searchSites()"
        class="searchInput"
        placeholder="Search keywords..."></sm-input>
    <div class="filterSitesCheck">
        <strong>{{ filteredSites?.length }} of {{ allSites?.length }}</strong>
        &nbsp;Results
    </div>
</div>

<div>
    <table
        class="itc-table scrollable sortable top-aligned"
        sortable-table
        sortColumn="Name"
        sortDirection="asc"
        (sorted)="onSortSites($event)">
        <thead>
            <tr>
                <th sortable-column="Name">Site</th>
                <th sortable-column="Organization">Organization</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let site of filteredSites" [class.expiredSite]="site.IsLicenseInactive">
                <td
                    class="textWidth"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="linkColor breakWord"
                        [routerLink]="!site.IsLicenseInactive ? site.GoToSiteLink : null"
                        (click)="siteDashboardService.goToSite(site)">
                        {{ site.Name }}
                    </a>
                </td>
                <td
                    class="truncateColor"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="breakWord link-org"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToOrganization(site)">
                        {{ site.Organization ? site.Organization : 'Unassigned' }}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <empty-state
        icon="results"
        title="No Sites Found"
        text="No sites were found matching the given criteria."
        *ngIf="!filteredSites?.length"></empty-state>
</div>
