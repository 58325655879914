import { Injectable } from '@angular/core';
import { AuthHttp } from 'app/core/auth';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LastScanResultStatsModel } from '../models/last-scan-result-stats.model';
import { ApplianceStatusStatsModel } from '../models/appliance-status-stats.model';
import { ComplianceMonitorStatsModel } from '../models/compliance-monitor-stats.model';
import { DiscoveryAgentFilterDto } from '../models/discovery-agent-filter-dto.model';
import { DiscoveryAgentScanSettingsDto } from '../models/discovery-agent-scan-settings.dto';
import { DiscoveryAgentAdvancedOptions } from '../../discovery-agent-legacy/discovery-agent-advanced-options.model';
import { DiscoveryAgentModel } from '../models/discovery-agent.model';
import { DiscoveryAgentsRunnowRequestModel } from '../models/discovery-agents-runnow-request.model';
import { DiscoveryAgentFilters } from '../models/discovery-agent-filters.model';
import { DiscoveryAgentTableRow } from '../models/discovery-agents-table-row.model';
import { PagedResult, PaginationFilters } from '../../itc/paginator/itc-paginator.model';

@Injectable({
    providedIn: 'root',
})
export class DiscoveryAgentService {
    private readonly apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getInstallKey(organizationId: number): Observable<DiscoveryAgentModel> {
        const url: string = this.apiUrl + `Organization/${organizationId}/InstallKey`;
        return this.http.get(url);
    }

    getLastScanResultStats(organizationId: number): Observable<LastScanResultStatsModel> {
        const url = `${this.apiUrl}DiscoveryAgent/${organizationId}/Appliances/LastScanResultStatuses`;
        return this.http.get(url);
    }

    getApplianceStatusStats(organizationId: number): Observable<ApplianceStatusStatsModel> {
        const url = `${this.apiUrl}DiscoveryAgent/${organizationId}/Appliances/Statuses`;
        return this.http.get(url);
    }

    getComplianceMonitorStats(organizationId: number): Observable<ComplianceMonitorStatsModel> {
        const url = `${this.apiUrl}DiscoveryAgent/${organizationId}/Appliances/ComplianceMonitorStatuses`;
        return this.http.get(url);
    }

    getOrgDiscoveryAgentDefaultSettings$(
        organizationId: number
    ): Observable<DiscoveryAgentScanSettingsDto> {
        const url: string = this.apiUrl + `DiscoveryAgent/Settings/${organizationId}`;
        return this.http.get(url);
    }

    getDeepFileScanSettings$(organizationId: number): Observable<string> {
        const url: string =
            this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/DeepFileScanSettings`;
        return this.http.get(url);
    }

    getDeepFileScanSettingsStatus$(organizationId: number): Observable<string> {
        const url: string =
            this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/DeepFileScanSettingsStatus`;
        return this.http.get(url);
    }

    getDiscoveryAgents$(
        request: DiscoveryAgentFilters
    ): Observable<PagedResult<DiscoveryAgentTableRow>>;
    getDiscoveryAgents$(
        request: DiscoveryAgentFilterDto,
        paginationFilter: PaginationFilters
    ): Observable<PagedResult<DiscoveryAgentTableRow>>;

    getDiscoveryAgents$(
        request: any,
        paginationFilter?: PaginationFilters
    ): Observable<PagedResult<DiscoveryAgentTableRow>> {
        let url: string;
        let req: any;

        if (paginationFilter) {
            url = `${this.apiUrl}DiscoveryAgent/${paginationFilter.id}?Page=${paginationFilter.page}&Limit=${paginationFilter.limit}&sort_by=${paginationFilter.sortby}&order_by=${paginationFilter.orderby}`;
            req = request;
        } else {
            url = `${this.apiUrl}DiscoveryAgent/${request.id}?Page=${request.page}&Limit=${request.limit}&sort_by=${request.sortby}&order_by=${request.orderby}`;
            req = {
                ApplianceStatus: Array.from(request.applianceStatus),
                ComplianceMonitorStatus: Array.from(request.cmoStatus),
                OS: Array.from(request.os),
                Label: Array.from(request.label),
                Text: request.text,
                IncludeIds: [],
                ExcludeIds: [],
            };
        }

        return this.http.post(url, req);
    }

    scheduleAgentForOrg$(
        organizationId: number,
        days: string,
        deepScanSettings: string,
        weeklyScanDay: string,
        deepFileScanStatus: boolean,
        is3ppScan: boolean,
        advancedOptions: DiscoveryAgentAdvancedOptions
    ): Observable<unknown> {
        const url = this.apiUrl + `VulscanAgent/${organizationId}/ScheduleForOrg`;
        return this.http.post(url, {
            scheduleDays: days,
            deepScanSettings: deepScanSettings,
            weeklyScanDay: weeklyScanDay,
            deepFileScanStatus: deepFileScanStatus,
            is3ppScan: is3ppScan,
            advancedOptions: advancedOptions,
        });
    }

    agentsRunNowOrgBulk$(request: DiscoveryAgentsRunnowRequestModel): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${request.OrganizationId}/RunNowBulk`;
        return this.http.post(url, {
            DeepScanSettings: request.DeepScanSettings,
            Is3ppScan: request.Is3ppScan,
            Filter: request.Filter,
        });
    }

    agentsUpdateOrgBulk$(
        organizationId: number,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/UpdateBulk`;
        return this.http.post(url, filter);
    }

    agentsCancelOrgBulk$(
        organizationId: number,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${organizationId}/CancelAllScansBulk`;
        return this.http.post(url, filter);
    }

    agentsDeprovisionOrgBulk(
        organizationId: number,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${organizationId}/DeprovisionBulk`;
        return this.http.post(url, filter);
    }

    agentsSetDescriptionOrgBulk(
        organizationId: number,
        description: string,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${organizationId}/SetDescriptionBulk`;
        return this.http.post(url, { Description: description, Filter: filter });
    }

    agentsSetLabelOrgBulk(
        organizationId: number,
        label: string,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/SetLabelBulk`;
        return this.http.post(url, { Label: label, Filter: filter });
    }

    agentsSetAutoUpdateOrgBulk(
        organizationId: number,
        autoUpdate: boolean,
        filter: DiscoveryAgentFilterDto
    ): Observable<unknown> {
        const url = this.apiUrl + `DiscoveryAgent/${organizationId}/SetAutoUpdateBulk`;
        return this.http.post(url, { Enabled: autoUpdate, Filter: filter });
    }
}
