import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    ViewRef,
} from '@angular/core';
import { Router, NavigationEnd, RouterModule } from '@angular/router';

import { SidebarMenuItem, SidebarMenuExtras } from '../menu/sidebar-menu-item.model';
import { UiService } from '../../../core/ui.service';
import { NavService } from '../../../core/nav/nav.service';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ItcTagComponent } from 'app/shared/itc';

declare var $: any;

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, FontAwesomeModule, ItcTagComponent],
    selector: 'sds-sidebar-layout',
    templateUrl: './sidebar-layout.component.html',
    styleUrls: ['./sidebar-layout.component.css'],
})
export class SidebarLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private router: Router,
        private uiService: UiService,
        private _cdr: ChangeDetectorRef,
        private navService: NavService
    ) {}

    @Input() set menuItems(val: SidebarMenuItem[]) {
        if (val && val.length) {
            this._menuItems = val;
            this.processMenuItems();
        }
    }
    get menuItems() {
        return this._menuItems;
    }
    _menuItems: SidebarMenuItem[];

    @Input() set menuExtras(val: SidebarMenuExtras) {
        if (val?.items.length) {
            this._menuExtras = val;
            this.processMenuItems();
        }
    }
    get menuExtras() {
        return this._menuExtras;
    }
    _menuExtras: SidebarMenuExtras;

    loadingComplete: boolean;
    collapsed: boolean;
    collapseButton: HTMLElement;
    routerSub: any;
    headerText$: Observable<string>;
    headerFlair: string;
    headerFlairSub: Subscription;
    menuExtraActive = false;

    ngOnInit() {
        this.routerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.processMenuItems();
            });

        this.headerText$ = this.navService.getHeaderTextObservable();
        this.headerFlairSub = this.navService
            .getHeaderFlairObservable()
            .subscribe((flair: string) => {
                this.headerFlair = flair;
            });
        let collapsed = this.uiService.getControlValue('SIDEBAR_COLLAPSED');
        this.collapsed = collapsed == 'true';
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
    }

    processMenuItems() {
        // figurout which items should be expanded, we can't use activeRouter because parent items are toggles and not links
        this.menuItems.forEach((parentItem) => {
            this.isActive(parentItem);
            if (parentItem.children) {
                parentItem.children.forEach((childItem) => {
                    this.isActive(childItem);
                    if (childItem.children) {
                        childItem.children.forEach((grandChildItem) => {
                            this.isActive(grandChildItem);
                        });
                    }
                });
            }
        });

        if (this.menuExtras) {
            this.menuExtras.active = this.menuExtraActive = false;
            this.menuExtras.items.forEach((item) => {
                this.isActive(item, true);
            });
            this.menuExtras.active = this.menuExtraActive;
            if (this.menuExtras.active) {
                this.menuItems.find((mi) => mi.active).active = false;
            }
        }
    }

    ngAfterViewInit() {
        this.collapseButton = document.querySelector('.sidebarToggle') as HTMLElement;
    }

    toggleParent(el, item) {
        let openParent = this.menuItems.find((mi) => mi.expanded == true);
        if (!item.children || item.children.length == 0) {
            return false;
        }
        $('.parentMenuItem.openMenu + ul')
            .stop()
            .slideUp(200, () => {
                openParent.expanded = false;
            });
        if (item !== openParent) {
            let menuContainer = el.parentNode.querySelector('ul');
            $(menuContainer)
                .stop()
                .slideToggle(200, () => {
                    item.expanded = !item.expanded;
                    menuContainer.style.height = 'auto';
                });
        }
    }

    toggleChildParent(el, item) {
        let childMenu = el.parentNode.querySelector('ul');
        $(childMenu)
            .stop()
            .slideToggle(200, () => {
                item.expanded = !item.expanded;
            });
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.uiService.setControlValue('SIDEBAR_COLLAPSED', this.collapsed);
        // keep collapsebutton on screen for just a short while so it's not so abrupt
        this.collapseButton.classList.add('forceShow');
        setTimeout(() => {
            this.collapseButton.classList.remove('forceShow');
        }, 750);
    }

    isActive(menuItem: SidebarMenuItem, extra = false): void {
        if (extra) {
            let isActive = this.router.url.includes(menuItem.routerLink);
            menuItem.active = isActive;
            this.menuExtras.active = isActive;
            if (isActive) {
                this.menuExtraActive = true;
            }
        } else {
            /* To make sure the parent menu opens when refreshing page or clicking link to come from another, must be set as a "url" and not "href" */
            let url = menuItem.url || menuItem.href;
            /* routerlinkactive doesn't work because in our version of angular returning null
            for a routerlink it always shows as active */
            let isActive = this.router.url.includes(url);
            menuItem.expanded = isActive;
        }
    }
}
