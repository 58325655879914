import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { AppliancesCountByFilterModel } from '../models/appliances-count-by-filter.model';
import { CommonModule } from '@angular/common';
import { QuickFilterComponent } from '../../itc/quick-filter/quick-filter.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DiscoveryAgentTableService } from '../services/discovery-agent-table.service';
import {
    DiscoveryAgentFilters,
    TDiscoveryAgentApplianceStatus,
    TDiscoveryAgentCmoStatus,
} from '../models/discovery-agent-filters.model';
import { FormGroupControls } from '../../form-group-controls.type';

@Component({
    selector: 'sds-discovery-agent-quick-filter',
    standalone: true,
    imports: [SharedModule, CommonModule, QuickFilterComponent],
    templateUrl: './discovery-agent-quick-filter.component.html',
    styleUrls: ['./discovery-agent-quick-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiscoveryAgentQuickFilterComponent implements OnInit, OnDestroy {
    @Input({ required: true }) appliancesCountByFilter: AppliancesCountByFilterModel;
    readonly selectedFilterGroup: FormGroup<FormGroupControls<QuickFilterGroup>> =
        this.formBuilder.group({
            online: [false],
            offline: [false],
            updateAvailable: [false],
            CMoEnabled: [false],
            CMoDisabled: [false],
            CMoNotSupported: [false],
        });
    private readonly ngOnDestroy$ = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private discoveryAgentTableService: DiscoveryAgentTableService
    ) {}

    ngOnInit() {
        this.selectedFilterGroup.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
            .subscribe((value) => {
                this.discoveryAgentTableService.filterData(
                    this.mapFilterGroupToFilterObject(value)
                );
            });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private mapFilterGroupToFilterObject(
        filterGroup: Partial<QuickFilterGroup>
    ): Partial<DiscoveryAgentFilters> {
        const applianceStatus = new Set<TDiscoveryAgentApplianceStatus>();
        const cmoStatus = new Set<TDiscoveryAgentCmoStatus>();

        if (filterGroup.online) {
            applianceStatus.add('online');
        }
        if (filterGroup.offline) {
            applianceStatus.add('offline');
            applianceStatus.add('offline > 90 days');
        }
        if (filterGroup.updateAvailable) {
            applianceStatus.add('update available');
        }

        if (filterGroup.CMoEnabled) {
            cmoStatus.add('enabled');
        }
        if (filterGroup.CMoDisabled) {
            cmoStatus.add('disabled');
        }
        if (filterGroup.CMoNotSupported) {
            cmoStatus.add('not supported');
        }

        if (applianceStatus.size === 4) {
            applianceStatus.clear();
        }
        if (cmoStatus.size === 3) {
            cmoStatus.clear();
        }

        return {
            applianceStatus: applianceStatus,
            cmoStatus: cmoStatus,
        };
    }
}

interface QuickFilterGroup {
    online: boolean;
    offline: boolean;
    updateAvailable: boolean;
    CMoEnabled: boolean;
    CMoDisabled: boolean;
    CMoNotSupported: boolean;
}
