import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';

@Component({
    selector: 'itc-sidebar',
    standalone: true,
    imports: [CommonModule, SidebarModule],
    template: `
        <p-sidebar
            [(visible)]="visible"
            appendTo="body"
            [blockScroll]="true"
            position="right"
            [baseZIndex]="1000"
            [dismissible]="closable"
            [showCloseIcon]="closable"
            [styleClass]="styleClass"
            (onShow)="onShow.emit()"
            (onHide)="onHide.emit()"
            [style]="{ width: width }">
            <ng-template pTemplate="header">
                <h3><ng-content select="[header]"></ng-content></h3>
            </ng-template>
            <ng-template pTemplate="content">
                <ng-content select="[content]"></ng-content>
            </ng-template>
            <ng-template pTemplate="footer">
                <ng-content select="[footer]"></ng-content>
            </ng-template>
        </p-sidebar>
    `,
    styles: [
        `
            :host {
                display: block;
            }
        `,
        `
            ::ng-deep .itc-sidebar.p-sidebar {
                top: 56px;
                height: calc(100% - 56px);

                .p-sidebar-header {
                    justify-content: space-between;
                    padding: 16px;
                    h3 {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .p-sidebar-content {
                    padding: 16px;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcSidebarComponent {
    private cdr = inject(ChangeDetectorRef);

    @Input() header: string;
    @Input() styleClass: string = 'itc-sidebar';
    @Input() width: string = '448px';
    @Output() onShow = new EventEmitter<void>();
    @Output() onHide = new EventEmitter<void>();
    visible = false;
    closable = true;

    open(data?: { closable: boolean }): void {
        this.visible = true;
        this.closable = data?.closable ?? true;
        this.cdr.markForCheck();
    }
    close(): void {
        this.visible = false;
        this.cdr.markForCheck();
    }
}
