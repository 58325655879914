import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { ItcTagComponent } from '../tag/itc-tag.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export interface Option {
    Label: string;
    Value: string;
    Disabled?: boolean;
}
/* DO NOT USE THIS COMPONENT YET, I'LL REMOVE THIS WHEN IT'S READY, FOR NOW
USE ItcMultiselect */

@Component({
    selector: 'rft-multiselect',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MultiSelectModule,
        ItcTagComponent,
        FontAwesomeModule,
    ],
    template: `
        <div [class.msGrowSelection]="growSelection">
            <p-multiSelect
                [(ngModel)]="value"
                [options]="options"
                [optionLabel]="optionLabel"
                [optionDisabled]="optionDisabled"
                [optionValue]="optionValue ? optionValue : null"
                [inputId]="inputId || null"
                [selectionLimit]="selectionLimit || null"
                [placeholder]="placeholder || null"
                display="chip"
                [style]="msStyle"
                filterPlaceHolder="Search"
                [showClear]="true"
                [resetFilterOnHide]="resetFilterOnHide"
                styleClass="multiSelectWithHeader"
                panelStyleClass="{{ panelStyleClass }} multiSelectWithHeader"
                appendTo="body"
                dropdownIcon="fa6-chevron-down"
                clearIcon="fa6-circle-xmark"
                (onClear)="clearItem()"
                (onChange)="updateMaxCount()"
                [disabled]="disabled"
                #ms>
                <ng-template let-value pTemplate="selectedItems">
                    <ng-container *ngIf="ms.value?.length === 0 || !ms.value">
                        <ng-container *ngIf="emptyIsAll">
                            <itc-tag type="chip" color="default" [closable]="false">
                                {{ allString }}
                            </itc-tag>
                        </ng-container>
                        <ng-container *ngIf="!emptyIsAll">
                            {{ ms.placeholder }}
                        </ng-container>
                    </ng-container>
                    <ngContainer
                        *ngIf="
                            (ms.value?.length && ms.value?.length <= maxSelectedItemsShown) ||
                            growSelection
                        ">
                        <div class="chips" style="display: inline-block">
                            <itc-tag
                                type="chip"
                                [closable]="true"
                                color="default"
                                *ngFor="let option of ms.value"
                                (closed)="clearItem(option)">
                                {{ option[optionLabel] }}
                            </itc-tag>
                        </div>
                    </ngContainer>
                    <itc-tag
                        type="chip"
                        [closable]="true"
                        *ngIf="
                            !growSelection &&
                            ms.value?.length &&
                            ms.value?.length > maxSelectedItemsShown
                        "
                        (closed)="clearItem()">
                        {{ ms.value.length }}
                        Selected
                    </itc-tag>
                </ng-template>
                <ng-template pTemplate="clearicon">
                    <fa-icon
                        *ngIf="ms.value?.length && ms.value?.length <= maxSelectedItemsShown"
                        icon="circle-xmark"
                        style="color: var(--text-secondary)"></fa-icon>
                </ng-template>
            </p-multiSelect>
        </div>
    `,
    styles: [
        `
            :host::ng-deep {
                .msGrowSelection {
                    .p-multiselect-label {
                        white-space: unset;
                    }
                    p-multiselect {
                        min-height: 32px;
                        height: auto;
                        .p-multiselect-label-container {
                            height: auto;
                            min-height: 30px;
                            padding-top: 4px;
                            padding-bottom: 4px;

                            .chips {
                                display: flex !important;
                                flex-wrap: wrap;
                                gap: 8px;
                            }
                        }
                        &.p-inputwrapper-filled {
                            .p-multiselect-label-container {
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RftMultiselectComponent),
        },
    ],
})
export class RftMultiselectComponent implements ControlValueAccessor {
    private cdr = inject(ChangeDetectorRef);

    @Input() options: Option[];
    @Input() inputId: string;
    @Input() placeholder: string;
    @Input() emptyIsAll = false;
    @Input() allString = 'All';
    @Input() selectionLimit: number;
    @Input() maxSelectedItemsShown = 5;
    @Input() msStyle: object;
    @Input() optionLabel = 'Label';
    @Input() optionValue: string;
    @Input() optionDisabled: string = 'Disabled';
    @Input() growSelection = false;
    @Input() disabled = false;
    @Input() resetFilterOnHide: boolean = false;
    @Input() panelStyleClass: string;
    @Output() change = new EventEmitter<Option[]>();
    @Output() clear = new EventEmitter<Option[]>();
    @ViewChild('ms') ms: MultiSelect;

    /* Use widths of actual elements to set maxCount instead of having to hardcode it each time */
    updateMaxCount(): void {
        this.change.emit(this.innerValue);
        if (this.maxSelectedItemsShown !== 0) {
            setTimeout(() => {
                let beforeCount = this.value.length - 1;
                let containerWidth = this.ms.el.nativeElement.querySelector(
                    '.p-multiselect-label-container'
                ).offsetWidth;
                let labelWidth =
                    this.ms.el.nativeElement.querySelector('.p-multiselect-label').offsetWidth;
                if (labelWidth >= containerWidth) {
                    this.maxSelectedItemsShown = beforeCount;
                    this.cdr.markForCheck();
                }
            }, 1);
        }
    }

    clearItem(item?: Option) {
        if (!item) {
            setTimeout(() => {
                this.setInnerValue([]);
                this.cdr.markForCheck();
                this.clear.emit(this.innerValue);
                this.change.emit(this.innerValue);
                return;
            }, 100);
        } else {
            this.setInnerValue(this.innerValue.filter((i) => i !== item));
            this.cdr.markForCheck();
            this.clear.emit(this.innerValue);
            this.change.emit(this.innerValue);
        }
    }

    /* Stuff to use either ngModel or FormControl */
    innerValue: any; // inner variable that you want to call it
    set value(value: any[]) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): any[] {
        return this.innerValue;
    }

    setInnerValue(value: any[]) {
        this.innerValue = value;
        this.onChange(value);
        this.onTouch();
    }

    onChange = (value: any) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.cdr.markForCheck();
        }
    }
}
