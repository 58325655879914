<div class="input-container">
    <sm-input
        name="searchInput"
        debounce="200"
        [control]="searchKeyControl"
        (input)="searchSites()"
        class="searchInput"
        placeholder="Search keywords..."></sm-input>

    <div class="filterSitesCheck">
        <strong>{{ filteredSites?.length }} of {{ allSites?.length }}</strong>
        &nbsp;Results
    </div>
</div>

<div>
    <table
        class="itc-table scrollable sortable top-aligned"
        sortable-table
        sortColumn="Name"
        sortDirection="asc"
        (sorted)="onSortSites($event)">
        <thead>
            <tr>
                <th sortable-column="Name">Site</th>
                <th sortable-column="Organization">Organization</th>
                <th sortable-column="OpenTodos" class="toDo">To Do</th>
                <th sortable-column="ApplianceOnline" class="appliance">Appliance</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let site of filteredSites" [class.expiredSite]="site.IsLicenseInactive">
                <td
                    class="textWidth"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="linkColor breakWord"
                        [routerLink]="!site.IsLicenseInactive ? site.GoToSiteLink : null"
                        (click)="siteDashboardService.goToSite(site)">
                        {{ site.Name }}
                    </a>
                </td>
                <td
                    style="color: black"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        style="color: black"
                        class="breakWord"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToOrganization(site)">
                        {{ site.Organization }}
                    </a>
                </td>
                <td
                    [ngStyle]="{
                        'padding-bottom': 'inherit',
                        'padding-top': site.OpenTodos > 0 ? '6px' : '',
                    }"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <span
                        [tooltip]="site.IsLicenseInactive ? null : 'Open ToDos'"
                        (click)="siteDashboardService.goToTodo(site)"
                        *ngIf="site.OpenTodos > 0"
                        class="ui label circular circleCount todoStyle">
                        {{ site.OpenTodos }}
                    </span>

                    <fa-icon
                        [tooltip]="site.IsLicenseInactive ? null : 'Open ToDos'"
                        class="iconOpenTodo"
                        (click)="siteDashboardService.goToTodo(site)"
                        *ngIf="site.OpenTodos === 0"
                        [icon]="['fas', 'circle-check']"></fa-icon>
                </td>
                <td
                    style="padding-bottom: inherit"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <span
                        style="cursor: pointer"
                        (click)="siteDashboardService.goToSite(site)"
                        class="ui label circular circleCount"
                        [ngStyle]="getDynamicStyles(site.ApplianceOnline)">
                        {{ site.ApplianceOnline ? 'Online' : 'Offline' }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <empty-state
        icon="results"
        title="No Sites Found"
        text="No sites were found matching the given criteria."
        *ngIf="!filteredSites?.length"></empty-state>
</div>
