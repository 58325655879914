import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ReportTheme } from './themes';

@Component({
    selector: 'sds-report-style-dialog',
    templateUrl: './style-dialog.component.html',
    styleUrls: ['./style-dialog.component.css'],
})
export class ReportStyleDialogComponent implements OnInit {
    constructor() {}

    @ViewChild('modal', { static: true }) modal: any;

    @Output('onUpdate') onUpdate = new EventEmitter<any>();

    theme: ReportTheme = { DefaultFontSize: 20 } as ReportTheme;

    palette: any[] = [];
    colorother: string = '#D03E73';
    themeTableName: string;

    @ViewChild('fontSelect', { static: true }) fontSelect: any;
    fontCtrl = new UntypedFormControl();
    fontFamilies = ['Arial', 'Helvetica', 'Optima', 'Tahoma', 'Times New Roman'];

    ngOnInit() {}

    showModal(theme: any) {
        this.theme = theme;

        this.themeTableName = this.theme.ThemeName.replace(/ |\(customized\)/g, '');

        this.fontCtrl.setValue(this.theme.DefaultFontName);
        this.fontSelect.setSelected(this.theme.DefaultFontName);

        this.palette = [];
        for (let c of this.theme.ChartTopFivePaletteIntList) {
            this.palette.push(`#${c.toString(16)}`);
        }

        this.modal.show({ closable: false });
    }

    onColorChange(index, ev) {
        this.palette[index] = ev;
    }

    changeChartQuality(style) {
        this.theme.ChartQuality = style;
    }

    onChangeRiskMeter(meter) {
        if (meter == 'old') {
            this.theme.UseNewRiskMeter = false;
            // only set usenewriskmeter if modern report
            if (this.theme.ReportModernizationEnabled) {
                this.theme.UseNewRiskMeter2020 = false;
            }
        } else if (meter == 'new') {
            this.theme.UseNewRiskMeter = true;
            // only set usenewriskmeter if modern report
            if (this.theme.ReportModernizationEnabled) {
                this.theme.UseNewRiskMeter2020 = false;
            }
        } else if (meter == 'modern') {
            this.theme.UseNewRiskMeter2020 = true;
            // this is only shwon when there's modern, but doing the check to match other items.
            if (this.theme.ReportModernizationEnabled) {
                this.theme.UseNewRiskMeter = false;
            }
        }
    }

    onChangeTableType(val) {
        this.theme.ReportModernizationTableType = val;
    }

    saveStyle() {
        this.theme.DefaultFontName = this.fontCtrl.value;

        let colors = [];
        for (let c of this.palette) {
            colors.push(parseInt(c.replace('#', ''), 16));
        }

        this.theme.ChartTopFivePaletteIntList = colors;

        this.onUpdate.emit(this.theme);

        this.modal.hide();
    }

    scrollToModalTop() {
        // the scrolling content modal doesn't scroll to top automatically, so do it
        this.modal.modal.nativeElement.querySelector('.content.scrolling').scrollTop = 0;
    }
}
